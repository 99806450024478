
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 'auto',
        },
    },
};

function getStyles(name, selectedItems, theme) {
    return {
        fontWeight:
            selectedItems.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultipleSelectChip({
    options,
    label,
    labelRightComponent,
    labelSize = 16,
    required,
    description,
    value = [], // Default to an empty array if value is undefined
    helperText,
    style,
    onChange, // onChange prop to handle changes
    isViewMode = false, // New prop to toggle view mode
    sicon,
    ...props
}) {
    const theme = useTheme();

    // Create a comma-separated string of selected names
    const selectedNames = options
        .filter(option => value.map(item => item.id).includes(option.id))
        .map(option => option.name)
        .join(', ');

    // Handle change and pass both id and name
    const handleChange = (event) => {
        const selectedIds = event.target.value;
        const selectedItems = options.filter(option => selectedIds.includes(option.id));
        console.log('Selected Items:', selectedItems); // Log selected items
        onChange(selectedItems); // Pass items with both id and name
    };

    const errorMessageFontSize = 14;

    return (
        <Box mb={2} mt={0}>
            <Stack direction="column" spacing={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <InputLabel
                        style={{
                            fontSize: labelSize,
                            color: isViewMode ? 'grey' : 'black',
                        }}
                    >
                        <Box display="flex" alignItems="center">
                            {sicon && <Box mr={2}>{sicon}</Box>}
                            {label}
                            {required && (
                                <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
                                    *
                                </Typography>
                            )}
                        </Box>
                    </InputLabel>
                    {labelRightComponent && (
                        <Box>{labelRightComponent}</Box>
                    )}
                </Stack>
                {description && <Typography>{description}</Typography>}
                {isViewMode ? (
                    // Display selected values in view mode
                    <Box
                        style={{
                            ...style,
                            backgroundColor: '#FBFCFF',
                            width: '100%',
                            padding: '8px 16px',


                        }}
                    >
                        <Box ml={3}>
                            {selectedNames || ''}
                        </Box>
                    </Box>
                ) : (
                    // Display dropdown in edit mode
                    <FormControl fullWidth>
                        <Select
                            style={{
                                ...style,
                                backgroundColor: '#FBFCFF',
                                width: '100%',
                                position: 'relative',
                            }}
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={value.map(item => item.id) || []} // Use ids for selected values
                            onChange={handleChange} // Handle change and log values
                            input={<OutlinedInput id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {options
                                        .filter(option => selected.includes(option.id))
                                        .map(({ id, name }) => (
                                            <Chip key={id} label={name} />
                                        ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                            IconComponent={(props) => (
                                <KeyboardArrowDownIcon {...props} sx={{ marginRight: '1rem' }} />
                            )}
                        >
                            {options.map(({ id, name }) => (
                                <MenuItem
                                    key={id}
                                    value={id}
                                    style={getStyles(id, value.map(item => item.id), theme)}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Stack>
            {helperText && (
                <Typography variant="body2" color="error" style={{ fontSize: errorMessageFontSize, marginTop: '0.5rem' }}>
                    {helperText}
                </Typography>
            )}
        </Box>
    );
}
