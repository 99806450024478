import { Box, Paper, Typography, Grid, Container } from '@mui/material';
import React from 'react';
import { OFMailAtSign, OFCallIcon } from '../../../icons/CustomIcon'
import { ReactComponent as WhatsappIcon } from "../../../assets/icons/WhatsApp.svg"
import { useRecoilState } from 'recoil';
import { perInfoState } from '../../../recoil/profile-atom';
import { toProper } from '../../../utils/UtilFunctions';
import MaterialTable from '../../../helpers/tables/MaterialTable';


const ViewHistory = () => {


    const [userInfo] = useRecoilState(perInfoState);
    return (


        <Box padding={4}>


            {/* <Container maxWidth={'xl'} > */}


            <Grid container>




                <Grid item xs={12} md={12}>
                    {/* <Paper elevation={3} sx={{ minheight: '12rem', borderRadius: '18px', padding: 2 }}>

                        <Grid container> */}

                    <Grid item xs={12} md={12}>
                        View History
                    </Grid>

                    {/* </Grid>

                    </Paper> */}


                </Grid>
            </Grid>
            {/* </Container> */}
        </Box>








    );
}

export default ViewHistory;
