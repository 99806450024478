import React from 'react'


import { Box, Container, Grid } from '@mui/material'

import { OFIdeaCard } from './OFIdeaCard'
import IdeaCardDisplay from './IdeaCardDisplay'

const IdeaCardContainer = ({ title, description, titleBGColor, contentBGColor, noOfCards, defaultCard }) => {
    return (

        <IdeaCardDisplay
            title={title}
            description={description}
            defaultCard={defaultCard}
            noOfCards={noOfCards}

        />

    )
}

export default IdeaCardContainer