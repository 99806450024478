import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { dealerRegistrationState, isLoggedInState, perInfoState } from '../recoil/profile-atom';
import { useRecoilState } from 'recoil';

const ProtectedRoute = ({ element, allowedRoles }) => {
    // const { auth } = useContext(AuthContext);
    const location = useLocation();

    const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
    const [perInfo, setPerInfo] = useRecoilState(perInfoState);

    const dealerRegistrationStateFetched = useRecoilState(dealerRegistrationState);
    console.log("dealerRegistrationState" + JSON.stringify(dealerRegistrationStateFetched))

    console.log(" login role =>  " + allowedRoles)
    console.log(" allowed role => " + perInfo?.roles)

    const isAuthorised = isLoggedIn &&
        perInfo?.roles &&
        allowedRoles.some(role => perInfo?.roles?.includes(role));

    return isAuthorised ? (
        element
    ) : (
        <Navigate to="/unauthorised" state={{ from: location }} />
    );
};

export default ProtectedRoute;


// import React from 'react';
// import { Navigate, useLocation } from 'react-router-dom';
// import { useRecoilValue } from 'recoil';
// import { bsellerRegistrationState, dealerRegistrationState, franchiserRegistrationState, isLoggedInState, perInfoState } from '../recoil/profile-atom';
// import { getNextIncompleteStep } from './UtilFunctions';

// const ProtectedRoute = ({ element, allowedRoles, step, role }) => {
//     const location = useLocation();
//     const isLoggedIn = useRecoilValue(isLoggedInState);
//     const perInfo = useRecoilValue(perInfoState);
//     const dealerProgress = useRecoilValue(dealerRegistrationState);
//     const franchiserProgress = useRecoilValue(franchiserRegistrationState);
//     const sellerProgress = useRecoilValue(bsellerRegistrationState);

//     let roleProgress;
//     switch (role) {
//         case 'DEALER':
//             roleProgress = dealerProgress;
//             break;
//         case 'FRANCHISER':
//             roleProgress = franchiserProgress;
//             break;
//         case 'SELLER':
//             roleProgress = sellerProgress;
//             break;
//         default:
//             return <Navigate to="/unauthorised" state={{ from: location }} />;
//     }

//     const nextIncompleteStep = getNextIncompleteStep(roleProgress, role);

//     const isAuthorised = isLoggedIn &&
//         perInfo?.roles &&
//         allowedRoles.some(r => perInfo?.roles?.includes(r));

//     if (isAuthorised) {
//         if (step > nextIncompleteStep) {
//             return <Navigate to={`/${role.toLowerCase()}/registration/step/${nextIncompleteStep}`} state={{ from: location }} />;
//         }
//         return element;
//     } else {
//         return <Navigate to="/unauthorised" state={{ from: location }} />;
//     }
// };

// export default ProtectedRoute;


// import React from 'react';
// import { Navigate, useLocation } from 'react-router-dom';
// import { useRecoilValue } from 'recoil';
// import {
//     bsellerRegistrationState,
//     dealerRegistrationState,
//     franchiserRegistrationState,
//     isLoggedInState,
//     perInfoState
// } from '../recoil/profile-atom';
// import { validRegistrationStep } from './ValidRegistrationStep';


// const ProtectedRoute = ({ element, allowedRoles, step, role }) => {
//     const location = useLocation();
//     const isLoggedIn = useRecoilValue(isLoggedInState);
//     const perInfo = useRecoilValue(perInfoState);
//     const dealerProgress = useRecoilValue(dealerRegistrationState);
//     const franchiserProgress = useRecoilValue(franchiserRegistrationState);
//     const sellerProgress = useRecoilValue(bsellerRegistrationState);


//     let roleProgress;
//     switch (role) {
//         case 'DEALER':
//             roleProgress = dealerProgress;
//             break;
//         case 'FRANCHISER':
//             roleProgress = franchiserProgress;
//             break;
//         case 'SELLER':
//             roleProgress = sellerProgress;
//             break;
//         default:
//             return <Navigate to="/unauthorised" state={{ from: location }} />;
//     }


//     const nextIncompleteStep = validRegistrationStep(roleProgress, role);

//     console.log('Passed step:', step);
//     console.log('Next incomplete step:', nextIncompleteStep);






//     const isAuthorised = isLoggedIn && perInfo?.roles?.some(r => allowedRoles.includes(r));

//     if (isAuthorised) {
//         if (nextIncompleteStep === -1) {

//             return element;
//         }



//         if (nextIncompleteStep !== -1 && step > nextIncompleteStep) {
//             return <Navigate to={`/${role.toLowerCase()}/registration/step/${nextIncompleteStep}`} state={{ from: location }} />;
//         }


//         else
//             return element;
//     } else {

//         return <Navigate to="/unauthorised" state={{ from: location }} />;
//     }
// };

// export default ProtectedRoute;
