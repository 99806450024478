
import React from 'react';
import {
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
  Box
} from '@mui/material';
import styled from '@emotion/styled';
import ErrorIcon from '@mui/icons-material/Error';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .Mui-disabled': {
    background: '#dddddd70',
  },
  '& input:disabled': {
    WebkitTextFillColor: 'black',
    cursor: 'not-allowed',
  },
  '& .MuiOutlinedInput-root': {
    borderWidth: '1px', // Reduced border width
    borderColor: theme.palette.grey[400],
    '&:hover fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.primary.main,
    },
  },
}));

function CustomInput({
  isViewMode,
  label,
  labelRightComponent,
  sicon, // prefix Icon
  eicon, // postfix Icon
  description = null,
  onChange,
  placeholder,
  size = 'small',
  type = 'text',
  pattern,
  labelSize,
  maxDate,
  minDate,
  helperText,
  error = null,
  disabled = false,
  readOnly = false,
  maxLength = 500,
  boxProps,
  required,
  marginBottom = 0,
  marginTop = 0,
  onNextLine,

  value, // Controlled value from Controller or parent
  ...props
}) {

  // console.log("isViewMode inside:", isViewMode);
  // console.log('Value:', value); // Should log the current value
  // console.log('onChange:', onChange); // Should log the onChange function
  const errorMessageFontSize = 14;

  return (
    <Box {...boxProps} mb={marginBottom} mt={marginTop}>
      <Stack direction={onNextLine ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
        <InputLabel style={{ fontSize: labelSize, color: '#000000', marginBottom: isViewMode ? 2 : 4 }}>
          {isViewMode ? (
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'grey' }}>
              {sicon && <InputAdornment position="start">{sicon}</InputAdornment>}
              <Box sx={{ ml: sicon ? 1 : 0 }}>{label}</Box>
            </Typography>
          ) : (
            label
          )}
          {required && !isViewMode && (
            <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
              *
            </Typography>
          )}
        </InputLabel>
        <InputLabel>{labelRightComponent}</InputLabel>
      </Stack>
      {description && <Typography>{description}</Typography>}
      {isViewMode ? (
        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ ml: 5 }}>{value}</Box>
        </Typography>
      ) : (
        <StyledTextField
          {...props}
          value={value} // Controlled value
          onChange={onChange} // Controlled onChange
          disabled={disabled}
          type={type}
          size={size}
          fullWidth
          placeholder={placeholder}
          sx={{
            backgroundColor: '#FBFCFF',
          }}
          InputProps={{
            inputProps: {
              maxLength,
              pattern,
              max: maxDate,
              min: minDate,
              step: 'any',
            },
            readOnly,
            endAdornment: error && props?.id !== 'password' ? (
              <InputAdornment position="end">
                <ErrorIcon color="error" />
              </InputAdornment>
            ) : eicon ? (
              <InputAdornment position="end">{eicon}</InputAdornment>
            ) : null,
            startAdornment: sicon ? (
              <InputAdornment position="start">{sicon}</InputAdornment>
            ) : null,
          }}
          error={!!error}
        />
      )}
      {helperText && (
        <Typography variant="body2" color="error" style={{ fontSize: errorMessageFontSize, marginTop: '0.5rem' }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
}

export default CustomInput;
