
import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Grid, Stack, Typography, Box } from "@mui/material";
import {
    OFBusinessOwner, OFDealerOrDistributor, OFFranchise, OFInvestor, OFLoanSupport,
    OFNeedASpace, OFPartnership, OFSellMyBusiness, OFSpace
} from "../../icons/CustomIcon";

import { isLoggedInState } from "../../recoil/profile-atom";
import { useRecoilState } from "recoil";
import { DialogCommonHooks } from "../../helpers/modal/DialogCommonHooks";
import LoginForm from "../signin/LoginForm";

export default function SignUp({ handleClose }) {

    const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);

    // const { currentUser, setCurrentUser } = useContext(UserContext);

    const [showBusinessOptions, setShowBusinessOptions] = useState(false);
    const navigate = useNavigate();



    const handleBusinessOwnerClick = () => {
        setShowBusinessOptions(true);
    };

    const handleBackClick = () => {
        if (showBusinessOptions) {
            // Go back to the initial options view
            setShowBusinessOptions(false);
        } else {
            // Navigate to the home page
            navigate('/');
            handleClose(); // Close the popup
        }
    };

    const processSignUp = (userType) => {

        const userClicked = `${userType}`


        if (isLoggedIn) {



            switch (userClicked) {
                case 'franchiser':
                    navigate('/dashboard', { state: { type: 'franchiser' } });
                    break;
                case 'dealer':
                    navigate('/dashboard', { state: { type: 'dealer' } });
                    break;
                case 'bcollab':
                    navigate('/dashboard', { state: { type: 'bcollab' } });
                    break;
                case 'sellBusiness':
                    navigate('/dashboard', { state: { type: 'sellBusiness' } });
                    break;
                case 'investor':
                    navigate('/dashboard', { state: { type: 'investor' } });
                    break;
                case 'needSpace':
                    navigate('/need-space', { state: { type: 'needSpace' } });
                    break;
                case 'loanSupport':
                    navigate('/loan-support', { state: { type: 'loanSupport' } });
                    break;
                default:
                    console.error('Unhandled user type for authenticated user:', userType);
            }



        } else {
            switch (userClicked) {
                case 'franchiser':
                    navigate('/franchise/registration/step/1', { state: { type: 'franchiser' } });
                    break;
                case 'dealer':
                    navigate('/dealer/registration/step/1', { state: { type: 'dealer' } });
                    break;
                case 'partnership':
                    navigate('/partner/registration/step/1', { state: { type: 'partnership' } });
                    break;
                case 'sellBusiness':
                    navigate('/sell-business/registration/step/1', { state: { type: 'sellBusiness' } });
                    break;
                case 'investor':
                    navigate('/investor/registration/step/1', { state: { type: 'investor' } });
                    break;
                case 'needSpace':
                    navigate('/need-space', { state: { type: 'needSpace' } });
                    break;
                case 'loanSupport':
                    navigate('/loan-support', { state: { type: 'loanSupport' } });
                    break;
                default:
                    console.error('Unhandled user type for unauthenticated user:', userType);
            }
        }


        handleClose();
    }


    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => setOpenDialog(true);
    const handleCloseResgister = () => setOpenDialog(false);

    const handleSignin = () => {

        handleOpen()
        // handleClose()

    }

    return (
        <Box
            px={{ xs: 2, md: 3 }}
            // pb={{ xs: 2, md: 3 }}
            //minHeight="60vh"
            justifyContent="center"
            flexDirection="column"
            display="flex"
            marginTop={1}
        >
            <Box >
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Stack direction="row" spacing={1}>
                            <Button
                                variant="text"
                                onClick={handleBackClick}
                                sx={{
                                    color: '#0d51d9',
                                    textTransform: 'none'
                                }}
                            >
                                <Typography
                                    variant="Content"
                                    component="span"
                                    sx={{ color: '#01845A2' }}
                                >
                                    Back
                                </Typography>
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item sx={{ textAlign: "right" }}>
                        <Typography>
                            {/* <Link to="/signup" style={{ color: "#0daee2" }}>
                                Sign in
                            </Link> */}
                            <span
                                style={{ color: "#0daee2", cursor: "pointer", textDecoration: "underline" }}
                                onClick={handleSignin}
                            >
                                Sign in
                            </span>

                        </Typography>
                    </Grid>
                </Grid>
                <Box mt={0} mb={1}>
                    <Typography variant="h3" sx={{ lineHeight: '75.63px' }}>Register</Typography>
                </Box>

                <Stack alignItems="center" padding={1}>
                    <Stack spacing={2} p={2}>
                        {showBusinessOptions ? (
                            <>
                                <Button
                                    variant="contained"
                                    //onClick={() => navigate('/business-owner/franchise')}

                                    //onClick={() => navigate('/franchise?type=franchise')}
                                    //     const location= useLocation();
                                    // const queryParams = new URLSearchParams(location.search);
                                    // const type = queryParams.get('type');


                                    onClick={() => processSignUp('franchiser')}
                                    //     const location= useLocation();
                                    // const type = location.state?.type;
                                    fullWidth
                                    startIcon={<OFFranchise />}
                                    sx={{
                                        //width: '80%',
                                        height: '5rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: 'rgba(176, 198, 255, 0.6)',
                                        },
                                        paddingLeft: '3rem',
                                        gap: 2,
                                        textAlign: 'left',
                                        border: '2px solid #DCE7FF' // Adds the border with color #DCE7FF
                                    }}
                                >
                                    <Typography variant='Content' sx={{ flexGrow: 1, color: '#0D2659' }}>Franchise</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => processSignUp('dealer')}
                                    fullWidth
                                    startIcon={<OFDealerOrDistributor />}
                                    sx={{
                                        // width: '100%',
                                        height: '5rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: 'rgba(176, 198, 255, 0.6)',
                                        },
                                        paddingLeft: '3rem',
                                        gap: 2,
                                        textAlign: 'left',
                                        border: '2px solid #DCE7FF'
                                    }}
                                >
                                    <Typography variant='Content' sx={{ flexGrow: 1, color: '#0D2659' }}>Dealers / Distributor</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => processSignUp('partnership')}
                                    fullWidth
                                    startIcon={<OFPartnership />}
                                    sx={{
                                        // width: '451px',
                                        height: '82px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: 'rgba(176, 198, 255, 0.6)',
                                        },
                                        paddingLeft: '3rem',
                                        gap: 2,
                                        textAlign: 'left',
                                        border: '2px solid #DCE7FF'
                                    }}
                                >
                                    <Typography variant='Content' sx={{ flexGrow: 1, color: '#0D2659' }}>Looking for Partnership</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => processSignUp('sellBusiness')}
                                    fullWidth
                                    startIcon={<OFSellMyBusiness />}
                                    sx={{
                                        // width: '100%',
                                        height: '5rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: 'rgba(176, 198, 255, 0.6)',
                                        },
                                        paddingLeft: '3rem',
                                        gap: 2,
                                        textAlign: 'left',
                                        border: '2px solid #DCE7FF'
                                    }}
                                >
                                    <Typography variant='Content' sx={{ flexGrow: 1, color: '#0D2659' }}>Sell My Business</Typography>
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => processSignUp('investor')}
                                    startIcon={<OFInvestor />}
                                    sx={{
                                        width: '100%',
                                        height: '5rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(233, 241, 255, 0.6)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(176, 198, 255, 0.6)',
                                        },
                                        paddingLeft: '3rem',
                                        paddingRight: '3rem',
                                        gap: 2,
                                        textAlign: 'left'
                                    }}
                                >
                                    <Typography variant='TITLE2' sx={{ flexGrow: 1 }}>Investor</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={handleBusinessOwnerClick}
                                    fullWidth
                                    startIcon={<OFBusinessOwner />}
                                    sx={{
                                        width: '100%',
                                        height: '5rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(233, 241, 255, 0.6)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(176, 198, 255, 0.6)',
                                        },
                                        paddingLeft: '3rem',
                                        paddingRight: '3rem',
                                        gap: 2,
                                        textAlign: 'left'
                                    }}
                                >
                                    <Typography variant='TITLE2' sx={{ flexGrow: 1 }}>Business Owner</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => processSignUp('needSpace')}
                                    fullWidth
                                    startIcon={<OFNeedASpace />}
                                    sx={{
                                        width: '100%',
                                        height: '5rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(233, 241, 255, 0.6)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(176, 198, 255, 0.6)',
                                        },
                                        paddingLeft: '3rem',
                                        paddingRight: '3rem',
                                        gap: 2,
                                        textAlign: 'left'
                                    }}
                                >
                                    <Typography variant='TITLE2' sx={{ flexGrow: 1 }}>Need a Space</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => processSignUp('loanSupport')}
                                    fullWidth
                                    startIcon={<OFLoanSupport />}
                                    sx={{
                                        width: '100%',
                                        height: '5rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(233, 241, 255, 0.6)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(176, 198, 255, 0.6)',
                                        },
                                        paddingLeft: '3rem',
                                        paddingRight: '3rem',
                                        gap: 2,
                                        textAlign: 'left'
                                    }}
                                >
                                    <Typography variant='TITLE2' sx={{ flexGrow: 1 }}>Loan Support</Typography>
                                </Button>
                            </>
                        )}
                    </Stack>
                </Stack>
            </Box>
            <DialogCommonHooks
                show={openDialog}
                handleClose={handleClose}
                body={<LoginForm onSuccess={handleClose} />}
            />
        </Box>



    );
}
