import { Grid, Typography } from '@mui/material';
import React from 'react';

const SuccessStories = () => {
    return (
        <>
            <Grid
                container
                maxWidth="lg"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                paddingY={4}

            >
                <Grid item xs={12} display="flex" justifyContent="center">
                    <Typography variant="h4">Success Stories</Typography>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" textAlign="center">
                    <Typography variant="Body">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default SuccessStories;
