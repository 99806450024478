/**
 * Path definition to route between JavaScript pages.
 * Paths can be accessed through Path's static
 * members, e.g., Path.index etc.
 * @type {string}
 */


const INDEX = "/";
const NOTFOUND = "/notfound";
const SIGNIN = "/signin";
const REGISTRATION = "/signup";
const LANDING = "/landing";
const FORGET = "/forget";



const API_CONTEXT_PATH = "/api";
const API_DOC_PATH = "/api-doc";


const API_SIGNUP_PERSONAL_INFO = `${API_CONTEXT_PATH}/auth/register`;


// Contact & Feedback
const API_POST_CONTACT_FEEDBACK = `${API_CONTEXT_PATH}/pub/lead`

// Interest
const API_POST_INTEREST = `${API_CONTEXT_PATH}/in/interest`


// Franchiser
const API_SIGNUP_FRAN_BUSINESS_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-businessProf`
const API_SIGNUP_FRAN_EXPANSION_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-expansion`
const API_SIGNUP_FRAN_FINANCIAL_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-financial-projection-training`
const API_SIGNUP_FRAN_SPACE_REQUIREMENT_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-space-legal`
const API_SIGNUP_FRAN_APPEARANCE_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-apperance-media`
const API_SIGNUP_PAYMENT_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-paymentDetails`

const API_GET_FRAN_BUSINESS_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-businessProf`
const API_GET_FRAN_EXPANSION_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-expansion`
const API_GET_FRAN_FINANCIAL_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-financial-projection-training`
const API_GET_FRAN_SPACE_REQUIREMENT_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-space-legal`
const API_GET_FRAN_APPEARANCE_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-apperance-media`


// Investor
const API_SIGNUP_INVESTOR_DETAILS = `${API_CONTEXT_PATH}/investor/investor-detail`
const API_GET_INVESTOR_DETAILS = `${API_CONTEXT_PATH}/investor/investor-detail`



// Sell Buisness
const API_SIGNUP_SBUSINESS = `${API_CONTEXT_PATH}/bs/business-seller-ownerInfo`
const API_SIGNUP_SBUSINESS_INFO = `${API_CONTEXT_PATH}/bs/business-seller-businessInfo`
const API_SIGNUP_SBUSINESS_FIN_INFO = `${API_CONTEXT_PATH}/bs/business-seller-financialInfo`
const API_SIGNUP_SBUSINESS_ADV_INFO = `${API_CONTEXT_PATH}/bs/business-seller-req-adv`


// Partner or Business Collaaboration
const API_SIGNUP_PARTNER_COMPANY_INFO = `${API_CONTEXT_PATH}/bc/business-collab-companyInfo`
const API_SIGNUP_PARTNER_FINANCIAL_INFO = `${API_CONTEXT_PATH}/bc/business-collab-financial`
const API_SIGNUP_PARTNER_MENTOR_INFO = `${API_CONTEXT_PATH}/bc/business-collab-mentorship`
const API_SIGNUP_PARTNER_LEGAL_INFO = `${API_CONTEXT_PATH}/bc/business-collab-legalreq`
const API_SIGNUP_PARTNER_APPEARANCE_INFO = `${API_CONTEXT_PATH}/bc/business-collab-appearance`

const API_GET_PARTNER_INFO = `${API_CONTEXT_PATH}/bc/business-collab`


// Dealer
const API_SIGNUP_DEALER_COMPANY_INFO = `${API_CONTEXT_PATH}/dd/dealer-businessInfo`
const API_SIGNUP_DEALER_MARKETING_INFO = `${API_CONTEXT_PATH}/dd/dealer-marketing`
const API_SIGNUP_DEALER_TRAINING_INFO = `${API_CONTEXT_PATH}/dd/dealer-training`
const API_SIGNUP_DEALER_APPEARANCE_INFO = `${API_CONTEXT_PATH}/dd/dealer-appearance`

const API_GET_DEALER_INFO = `${API_CONTEXT_PATH}/dd/dealer`


// Need space
const API_GET_NEED_SPACE = `${API_CONTEXT_PATH}/needSpaces`
const API_CREATE_NEED_SPACE = `${API_CONTEXT_PATH}/pub/needSpace`

// Loan On Property
const API_GET_LOAN_ON_PROPERTY = `${API_CONTEXT_PATH}/loansOnProperty`
const API_CREATE_LOAN_ON_PROPERTY = `${API_CONTEXT_PATH}/pub/loanOnProperty`


const API_GET_SBUSINESS_INFO = `${API_CONTEXT_PATH}/bs/business-seller`


const API_GET_FRAN_SINGLE_CARD_DETAILS = `${API_CONTEXT_PATH}/pub/fran/franchiser`
const API_GET_BSELLER_SINGLE_CARD_DETAILS = `${API_CONTEXT_PATH}/pub/bs/business-seller`
const API_GET_PARTNER_SINGLE_CARD_DETAILS = `${API_CONTEXT_PATH}/pub/bc/business-collab`
const API_GET_DEALER_SINGLE_CARD_DETAILS = `${API_CONTEXT_PATH}/pub/dd/dealer`

const API_PAYMENT_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-paymentDetails`
const API_DOCUMENT_UPLOAD = `${API_DOC_PATH}/documents/upload`

const API_SIGNIN = `${API_CONTEXT_PATH}/auth/authenticate`;

const MASTER_COUNTRIES = `${API_CONTEXT_PATH}/mast/countries`
const MASTER_STATES = `${API_CONTEXT_PATH}/mast/state`
const MASTER_CITY = `${API_CONTEXT_PATH}/mast/city`
const MASTER_INDUSTRIES = `${API_CONTEXT_PATH}/mast/industries`
const MASTER_INDUSTRY_SECTORS = `${API_CONTEXT_PATH}/mast/industry-sector`

const MASTER_RANGE = `${API_CONTEXT_PATH}/mast/range-master?type=NUMBER`

const CARD_LIST = `${API_CONTEXT_PATH}/pub/fran/franchiser-cards`
const BSELLER_CARD_LIST = `${API_CONTEXT_PATH}/pub/bs/business-seller-cards`
const PARTNER_CARD_LIST = `${API_CONTEXT_PATH}/pub/bc/business-collab-cards`
const DEALER_CARD_LIST = `${API_CONTEXT_PATH}/pub/dd/dealer-cards`

const API_GET_SINGLE_IMAGE = `${API_DOC_PATH}/documents`






export class API {

    static get landing() {
        return LANDING;
    }
    static get index() {
        return INDEX;
    }

    static get forget() {
        return FORGET;
    }
    static get signin() {
        return SIGNIN;
    }

    static get signup() {
        return REGISTRATION;
    }

    static get notFound() {
        return NOTFOUND;
    }

    static postContactOrFeedback() {
        return API_POST_CONTACT_FEEDBACK;
    }

    static postInterest() {
        return API_POST_INTEREST;
    }


    static signupPersonalInfo() {
        return API_SIGNUP_PERSONAL_INFO;
    }
    static signupPaymentDetails() {
        return API_SIGNUP_PAYMENT_DETAILS;
    }


    static signupFranBusinessDetails() {
        return API_SIGNUP_FRAN_BUSINESS_DETAILS;
    }

    static signupFranExpansionDetails() {
        return API_SIGNUP_FRAN_EXPANSION_DETAILS;
    }
    static signupFranFinancialDetails() {
        return API_SIGNUP_FRAN_FINANCIAL_DETAILS;
    }
    static signupFranSpaceRequirementDetails() {
        return API_SIGNUP_FRAN_SPACE_REQUIREMENT_DETAILS;
    }
    static signupFranBusinessDetails() {
        return API_SIGNUP_FRAN_BUSINESS_DETAILS;
    }

    static signupFranAppearanceDetails() {
        return API_SIGNUP_FRAN_APPEARANCE_DETAILS;
    }



    static getFranBusinessDetails() {
        return API_GET_FRAN_BUSINESS_DETAILS;
    }

    static getFranExpansionDetails() {
        return API_GET_FRAN_EXPANSION_DETAILS;
    }

    static getFranFinancialDetails() {
        return API_GET_FRAN_FINANCIAL_DETAILS;
    }

    static getFranSpaceRequirementDetails() {
        return API_GET_FRAN_SPACE_REQUIREMENT_DETAILS;
    }
    static getFranAppearanceDetails() {
        return API_GET_FRAN_APPEARANCE_DETAILS;
    }

    static signupInvestorDetails() {
        return API_SIGNUP_INVESTOR_DETAILS;
    }

    static signupBSellerDetails() {
        return API_SIGNUP_SBUSINESS;
    }

    static signupBSellerBusinessInfo() {
        return API_SIGNUP_SBUSINESS_INFO
    }

    static signupBSellerFinancialInfo() {
        return API_SIGNUP_SBUSINESS_FIN_INFO
    }

    static signupBSellerAdvInfo() {
        return API_SIGNUP_SBUSINESS_ADV_INFO
    }

    static signupPartnerCompanyInfo() {
        return API_SIGNUP_PARTNER_COMPANY_INFO
    }
    static signupPartnerFinancialInfo() {
        return API_SIGNUP_PARTNER_FINANCIAL_INFO
    }
    static signupPartnerMentorInfo() {
        return API_SIGNUP_PARTNER_MENTOR_INFO
    }
    static signupPartnerLegalInfo() {
        return API_SIGNUP_PARTNER_LEGAL_INFO
    }
    static signupPartnerAppearanceInfo() {
        return API_SIGNUP_PARTNER_APPEARANCE_INFO
    }

    static getPartnerInfo() {
        return API_GET_PARTNER_INFO
    }

    static getNeedSpace() {
        return API_GET_NEED_SPACE
    }
    static createNeedSpace() {
        return API_CREATE_NEED_SPACE
    }

    static getLoanOnProperty() {
        return API_GET_LOAN_ON_PROPERTY
    }
    static createLoanOnProperty() {
        return API_CREATE_LOAN_ON_PROPERTY
    }

    static getBSellerInfo() {
        return API_GET_SBUSINESS_INFO
    }





    static signupDealerCompanyInfo() {
        return API_SIGNUP_DEALER_COMPANY_INFO
    }
    static signupDealerMarketingInfo() {
        return API_SIGNUP_DEALER_MARKETING_INFO
    }
    static signupDealerTrainingInfo() {
        return API_SIGNUP_DEALER_TRAINING_INFO
    }

    static signupDealerAppearanceInfo() {
        return API_SIGNUP_DEALER_APPEARANCE_INFO
    }

    static getDealerInfo() {
        return API_GET_DEALER_INFO
    }


    static getInvestorDetails() {
        return API_GET_INVESTOR_DETAILS;
    }


    static fileUpload() {
        return API_DOCUMENT_UPLOAD;
    }


    static signin() {
        return API_SIGNIN;
    }


    static getcountries() {
        return MASTER_COUNTRIES;

    }

    static getStates() {
        return MASTER_STATES;

    }

    static getCities() {
        return MASTER_CITY;

    }



    static getIndustries() {
        return MASTER_INDUSTRIES;

    }

    static getIndustrySector() {

        return MASTER_INDUSTRY_SECTORS;
    }

    static getRange() {
        return MASTER_RANGE;

    }

    static getCardList() {
        return CARD_LIST;

    }

    static getBSellerCardList() {
        return BSELLER_CARD_LIST
    }
    static getPartnerCardList() {
        return PARTNER_CARD_LIST
    }
    static getDealerCardList() {
        return DEALER_CARD_LIST
    }

    static getSingleCardDetails() {
        return API_GET_FRAN_SINGLE_CARD_DETAILS;
    }
    static getPartnerSingleCardDetails() {
        return API_GET_PARTNER_SINGLE_CARD_DETAILS;
    }
    static getBSellerSingleCardDetails() {
        return API_GET_BSELLER_SINGLE_CARD_DETAILS;
    }
    static getDealerSingleCardDetails() {
        return API_GET_DEALER_SINGLE_CARD_DETAILS;
    }

    static getSingleImage() {
        return API_GET_SINGLE_IMAGE;
    }


}
