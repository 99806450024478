

import React, { useEffect, useState } from 'react';
import CustomInput from '../../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl } from '@mui/material';
import ProgressStepper from '../progress/ProgressSteper';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomRadioButton from '../../../helpers/CustomRadioButton';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../../helpers/CustomButton';
import { OFAddress, OFBriefcase, OFCalendar, OFCalendar2, OFCastle, OFComment, OFCompany, OFCountry, OFHouse, OFHouse1, OFIndustry, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFRoadLocation, OFRupeeIcon, OFSector, OFStore, OFStore1, OFUser, OFWebDesign, OFWebsiteLink, OFYesNoIcon } from '../../../icons/CustomIcon';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../../helpers/CustomInputPhone';

import { toastError } from '../../../utils/toast-notify';

import { getApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import MultiSelectDropdown from '../../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomDropdownSingle from '../../../helpers/dropdown/CustomDropdownSingle';




const NeedSpaceForm = ({ title, control, errors, setValue, watch, getValues, isBussnessProfile,

    countries,
    cities,
    states,
    industries,
    ranges,
    industrySectors,
    setSelectedCity,
    setSelectedCountry,
    setSelectedIndustry,
    setSelectedSector, setSelectedState,



}) => {


    const [willingToPayForPL, setWillingToPayForPL] = useState(false)





    const FRANCHISE_COMMENCED_YEAR_LIST = [...Array(new Date().getFullYear() - 1900 + 1).keys()].map(year => year + 1900);







    const countryCodeOptions = [
        { label: '+91', value: '91' },
        { label: '+1', value: '1' },
        { label: '+44', value: '+44' },


    ];




    // const outletStates = states.map(state => ({
    //     id: state.id,
    //     name: state.label
    // }));


    return (
        <>
            <Box paddingX={6} paddingY={3} sx={{ backgroundColor: '#E7F0FF' }}>
                <Typography variant="h5">{title}</Typography>
            </Box>



            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                {/* <Box marginBottom={1} marginTop={1}>
                    <Typography variant="TITLE1">Need a space</Typography>

                </Box> */}


                <Grid container spacing={4}>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="contactPersonName"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Contact Person Name "
                                    fullWidth sicon={<OFUser />}
                                    variant="outlined"
                                    error={!!errors.contactPersonName}
                                    helperText={errors.contactPersonName ? errors.contactPersonName.message : ''}
                                    placeholder={'Contact Person Name '}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="emailId"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Email Id "
                                    fullWidth sicon={<OFMailIcon />}
                                    variant="outlined"
                                    error={!!errors.emailId}
                                    helperText={errors.emailId ? errors.emailId.message : ''}
                                    placeholder={'Email Id  *'}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>


                        <Controller
                            name="mobileNo"
                            control={control}
                            render={({ field }) => (
                                <CustomInputPhone
                                    label="Mobile No. "
                                    fullWidth
                                    required
                                    countryCodeOptions={countryCodeOptions}
                                    selectedCountryCode={field.value?.countryCode || ''}
                                    onCountryCodeChange={(newCountryCode) => {
                                        field.onChange({
                                            ...field.value,
                                            countryCode: newCountryCode,
                                        });
                                    }}
                                    value={field.value?.phoneNumber || ''}
                                    onChange={(newPhoneNumber) => {
                                        field.onChange({
                                            ...field.value,
                                            phoneNumber: newPhoneNumber,
                                        });
                                    }}

                                    helperText={errors.mobileNo?.phoneNumber?.message || ''}
                                    error={!!errors.mobileNo}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="businessType"
                            control={control}
                            render={({ field }) => (


                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFBriefcase />}
                                    label="Type of Business "
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.businessType?.name}
                                    helperText={errors.businessType?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                // isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>




                    <Grid item xs={12} md={6}>


                        <Controller
                            name="industry"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFIndustry />}
                                    label="Industry"
                                    option={industries}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedIndustry(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.industry?.name}
                                    helperText={errors.industry?.name?.message || ''}
                                //isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>

                        <Controller
                            name="sector"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFSector />}
                                    label="Sector"
                                    option={industrySectors}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedSector(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.sector}
                                    helperText={errors.sector ? errors.sector.message : ''}
                                />
                            )}
                        />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="location"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Location "
                                    fullWidth sicon={<OFLocation />}
                                    variant="outlined"
                                    error={!!errors.location}
                                    helperText={errors.location ? errors.location.message : ''}
                                    placeholder={'Location  *'}
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>



                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    sicon={<OFCountry />}
                                    label="Country"
                                    option={countries}
                                    defaultValue={field.value}
                                    error={!!errors.country}
                                    helperText={errors.country ? errors.country.message : ''}
                                    changeCallBack={({ id, name }) => {


                                        setSelectedCountry(id);
                                        field.onChange({ id, name });
                                    }}
                                />
                            )}
                        />




                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required sicon={<OFCountry />}
                                    fullWidth
                                    label="State"
                                    option={states}
                                    defaultValue={field.value}
                                    error={!!errors.state?.name}
                                    helperText={errors.state?.name?.message || ''}
                                    placeholder="Select State"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedState(id);
                                        field.onChange({ id, name });
                                    }}
                                // isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    label="City" sicon={<OFCountry />}
                                    option={cities}
                                    defaultValue={field.value}
                                    error={!!errors.city?.name}
                                    helperText={errors.city?.name?.message || ''}
                                    placeholder="Select City"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedCity(id);
                                        field.onChange({ id, name });
                                    }}
                                //  isViewMode={!isEditing}
                                />
                            )}
                        />

                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                        <Controller
                            name="pinCode"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="PIN Code"
                                    fullWidth sicon={<OFCountry />}
                                    variant="outlined"
                                    error={!!errors.pinCode}
                                    helperText={errors.pinCode ? errors.pinCode.message : ''}
                                    placeholder={'Enter PIN Code'}
                                />
                            )}
                        />
                    </Grid> */}








                    <Grid item xs={12} md={6}>
                        <Controller
                            name="desiredSquareFt"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Desired Square Ft "
                                    fullWidth sicon={<OFStore1 />}
                                    variant="outlined"
                                    error={!!errors.desiredSquareFt}
                                    helperText={errors.desiredSquareFt ? errors.desiredSquareFt.message : ''}
                                    placeholder={'Desired Square Ft  *'}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="noOfFloors"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="No of Floors/Units "
                                    fullWidth sicon={<OFHouse1 />}
                                    variant="outlined"
                                    error={!!errors.noOfFloors}
                                    helperText={errors.noOfFloors ? errors.noOfFloors.message : ''}
                                    placeholder={'No of Floors/Units  *'}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="budgetForRent"
                            control={control}
                            render={({ field }) => (


                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFRupeeIcon />}
                                    label="Budget Range for Rent/Purchase "
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.budgetForRent?.name}
                                    helperText={errors.budgetForRent?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                // isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="preferredLeaseTerm"
                            control={control}
                            render={({ field }) => (
                                <CustomCheckbox
                                    {...field} // Spread the field props
                                    required
                                    mainLabel="Preferred Lease Term"
                                    control={control}
                                    options={[
                                        { id: 1, name: 'Short Term' },
                                        { id: 2, name: 'Long Term' },
                                        { id: 3, name: 'Flexible' },

                                    ]}
                                    error={!!errors.preferredLeaseTerm}
                                    helperText={errors.preferredLeaseTerm ? errors.preferredLeaseTerm.message : ''}
                                // isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="willingToPayForPL"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Sales Training  *"
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.willingToPayForPL?.message}
                                    helperText={errors.willingToPayForPL ? errors.willingToPayForPL.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    // isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="howSoonToMove"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="How soon would you like to Move In? md={6}"
                                    fullWidth sicon={<OFCalendar2 />}
                                    variant="outlined"
                                    error={!!errors.howSoonToMove}
                                    helperText={errors.howSoonToMove ? errors.howSoonToMove.message : ''}
                                    placeholder={'No of Days'}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Controller
                            name="additionalRequirements"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Additional Requirements  "
                                    fullWidth sicon={<OFComment />}
                                    variant="outlined"
                                    error={!!errors.additionalRequirements}
                                    helperText={errors.additionalRequirements ? errors.additionalRequirements.message : ''}
                                    placeholder={'Additional Requirements '}
                                />
                            )}
                        />
                    </Grid>

                </Grid>
            </Box>


        </>
    );
};

export default NeedSpaceForm;
