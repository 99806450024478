import React, { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { toastNotify } from "../utils/toast-notify";
// import { toastNotify } from "./alert-msg"; // Assuming you have a toast notification utility

const IdleTimerContainer = ({ props }) => {
    const timeout = 1000 * 60 * parseInt(process.env.REACT_APP_SESSION_TIMEOUT || "15", 10); // Timeout in milliseconds

    // Save the action time to localStorage when user is active
    const handleOnAction = () => {
        const time = new Date();
        localStorage.setItem("time", time);
    };

    // Handle idle state: when user is idle, check the time and perform logout if session timed out
    const handleOnIdle = () => {
        const storedTime = localStorage.getItem("time");
        if (!storedTime) return; // Do nothing if no stored time

        const time = new Date();
        const lastActionTime = new Date(storedTime);
        const idleMinutes = Math.abs(time - lastActionTime) / 60000;

        if (idleMinutes >= parseInt(process.env.REACT_APP_SESSION_TIMEOUT || "15", 10)) {
            if (props.isAuthenticated) {
                toastNotify("error", "Session Timeout");
                console.log("Session timeout triggered");

                props.handleLogout();
            }
        } else {
            reset(); // Reset the idle timer if the session hasn't timed out
        }
    };

    // useIdleTimer hook to manage idle state and actions
    const { reset } = useIdleTimer({
        timeout,
        onAction: handleOnAction,
        onIdle: handleOnIdle,
        debounce: 250,
    });

    // Optionally, store the time when component mounts for initialization
    useEffect(() => {
        const time = new Date();
        localStorage.setItem("time", time);
    }, []);

    return <div>{/* Your app content here */}</div>;
};

export default IdleTimerContainer;


// import React, { useEffect } from "react";
// import { useIdleTimer } from "react-idle-timer";
// import { useNavigate } from "react-router-dom"; // For navigation
// import { toastNotify } from "../utils/toast-notify";

// const IdleTimerContainer = ({ isAuthenticated, handleLogout }) => {
//     const navigate = useNavigate();

//     // Session timeout in milliseconds (e.g., 15 minutes from env variable)
//     const timeout = 1000 * 60 * parseInt(process.env.REACT_APP_SESSION_TIMEOUT || "15", 10);

//     // Idle Timer Hook: Will track activity only if user is authenticated
//     const { reset } = useIdleTimer({
//         timeout,
//         onIdle: handleSessionTimeout,
//         debounce: 250,
//     });

//     // Effect to start/reset the idle timer when the user logs in
//     useEffect(() => {
//         if (isAuthenticated) {
//             reset(); // Start/reset the timer when the user logs in
//         }
//     }, [isAuthenticated, reset]);

//     // Function to handle session timeout
//     const handleSessionTimeout = () => {
//         if (isAuthenticated) {
//             // Notify the user about session timeout
//             toastNotify("error", "Session Expired. Please login again.");

//             // Call the logout function
//             handleLogout();

//             // Redirect the user to the landing page ("/" in this case)
//             navigate("/");
//         }
//     };

//     // Handle user action (mouse move, key press, etc.)
//     const handleOnAction = () => {
//         if (isAuthenticated) {
//             const time = new Date();
//             localStorage.setItem("lastActiveTime", time); // Store the last active time
//         }
//     };

//     // Initialize idle timer for tracking user action
//     useIdleTimer({
//         onAction: handleOnAction,
//         debounce: 250, // Delay between user actions for optimization
//     });

//     return (
//         <div>
//             {/* Your application content */}
//         </div>
//     );
// };

// export default IdleTimerContainer;
