

import React, { useEffect, useState } from 'react';
import CustomInput from '../../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl, IconButton } from '@mui/material';
import ProgressStepper from '../progress/ProgressSteper';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomRadioButton from '../../../helpers/CustomRadioButton';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../../helpers/CustomButton';
import { OFAddress, OFCalendar, OFCastle, OFCompany, OFCountry, OFDescription, OFEditIcon, OFHouse, OFIndustry, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFPhone, OFRoadLocation, OFSector, OFStore, OFUser, OFWebDesign, OFWebsiteLink } from '../../../icons/CustomIcon';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../../helpers/CustomInputPhone';

import { toastError } from '../../../utils/toast-notify';

import { getApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import MultiSelectDropdown from '../../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomDropdownSingle from '../../../helpers/dropdown/CustomDropdownSingle';
import CustomMultiLineInput from '../../../helpers/CustomMultiLineInput';




const BusinessDetailsForm = ({ control, errors, setValue, watch, getValues, isBussnessProfile,

    countries,
    cities,
    states,
    industries,
    outletStates,
    ranges,
    industrySectors,
    setSelectedCity,
    setSelectedCountry,
    setSelectedIndustry,
    setSelectedSector, setSelectedState,
    isDashboardMode,
    setIsEditing,
    isEditing


}) => {


    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));





    const FRANCHISE_COMMENCED_YEAR_LIST = [...Array(new Date().getFullYear() - 1900 + 1).keys()].map(year => year + 1900);







    const countryCodeOptions = [
        { label: '+91', value: '91' },
        { label: '+1', value: '1' },
        { label: '+44', value: '+44' },


    ];





    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>

            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3} sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="TITLE3">Franchisor Registration</Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperFranchisor />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }

            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Business and Professional</Typography>

                    {/* {isDashboardMode &&
                        <IconButton onClick={toggleEdit} style={{ float: 'right' }}>
                            <OFEditIcon />
                        </IconButton>

                    } */}
                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}



                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="companyName"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    defaultValue={field.value}
                                    label="Company Name"
                                    fullWidth sicon={<OFCompany />}
                                    variant="outlined"
                                    error={!!errors.companyName}
                                    helperText={errors.companyName ? errors.companyName.message : ''}
                                    placeholder={'Enter Company name'}
                                    isViewMode={!isEditing}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="ownnerName"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Owner Name"
                                    fullWidth sicon={<OFUser />}
                                    variant="outlined"
                                    error={!!errors.ownnerName}
                                    helperText={errors.ownnerName ? errors.ownnerName.message : ''}
                                    placeholder={'Owner Name'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="ownerEmail"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Owner Email"
                                    type="email"
                                    fullWidth sicon={<OFMailAtSign />}
                                    variant="outlined"
                                    error={!!errors.ownerEmail}
                                    helperText={errors.ownerEmail ? errors.ownerEmail.message : ''}
                                    placeholder={'Owner Email Id'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="alternateEmail"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Alternate Email"
                                    fullWidth sicon={<OFMailAtSign />}
                                    variant="outlined"
                                    error={!!errors.alternateEmail}
                                    helperText={errors.alternateEmail ? errors.alternateEmail.message : ''}
                                    placeholder={'Alternate Email Id'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>


                        <Controller
                            name="ownerMobileNo"
                            control={control}
                            render={({ field }) => (
                                <CustomInputPhone
                                    label="Owner Mobile Number"
                                    fullWidth
                                    sicon={< OFPhone />}
                                    countryCodeOptions={countryCodeOptions}
                                    selectedCountryCode={field.value?.countryCode || ''}
                                    onCountryCodeChange={(newCountryCode) => {
                                        field.onChange({
                                            ...field.value,
                                            countryCode: newCountryCode,
                                        });
                                    }}
                                    value={field.value?.phoneNumber || ''}
                                    onChange={(newPhoneNumber) => {
                                        field.onChange({
                                            ...field.value,
                                            phoneNumber: newPhoneNumber,
                                        });
                                    }}
                                    helperText={
                                        (errors.ownerMobileNo?.countryCode && errors.ownerMobileNo.countryCode.message) ||
                                        (errors.ownerMobileNo?.phoneNumber && errors.ownerMobileNo.phoneNumber.message) ||
                                        ''
                                    }

                                    error={!!errors.ownerMobileNo}

                                    isViewMode={!isEditing}
                                />
                            )}
                        />

                    </Grid>




                    <Grid item xs={12} md={4}>


                        <Controller
                            name="ownerMobileNoNew"
                            control={control}
                            render={({ field }) => (
                                <CustomInputPhone
                                    label="Alternate Mobile No"
                                    fullWidth
                                    sicon={< OFPhone />}
                                    countryCodeOptions={[{ value: '91', label: 'IND(+91)' }, { value: '1', label: 'US(+1)' }]}
                                    selectedCountryCode={field.value?.countryCode || ''}
                                    onCountryCodeChange={(newCountryCode) => {
                                        field.onChange({
                                            ...field.value,
                                            countryCode: newCountryCode,
                                        });
                                    }}
                                    value={field.value?.phoneNumber || ''}
                                    onChange={(newPhoneNumber) => {
                                        field.onChange({
                                            ...field.value,
                                            phoneNumber: newPhoneNumber,
                                        });
                                    }}
                                    helperText={
                                        (errors.ownerMobileNoNew?.countryCode && errors.ownerMobileNoNew.countryCode.message) ||
                                        (errors.ownerMobileNoNew?.phoneNumber && errors.ownerMobileNoNew.phoneNumber.message) ||
                                        ''
                                    }
                                    error={!!errors.ownerMobileNoNew}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />





                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="managerName"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Manager Name"
                                    fullWidth sicon={<OFManager />}
                                    variant="outlined"
                                    error={!!errors.managerName}
                                    helperText={errors.managerName ? errors.managerName.message : ''}
                                    placeholder={'Enter Manager Name'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="addressDetails"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Address Details"
                                    fullWidth sicon={<OFAddress />}
                                    variant="outlined"
                                    error={!!errors.addressDetails}
                                    helperText={errors.addressDetails ? errors.addressDetails.message : ''}
                                    placeholder={'Enter Address Details'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>


                        {/* <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFCountry />}
                                    label="Country"
                                    option={countries}
                                    defaultValue={field.value}
                                    error={!!errors.country}
                                    helperText={errors.country ? errors.country.message : ''}
                                    changeCallBack={({ id, value }) => {

                                        setSelectedCountry(id);
                                        field.onChange({ value: id, label: value });
                                    }}
                                />
                            )}
                        /> */}
                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    sicon={<OFCountry />} // Replace with your actual icon
                                    label="Country"
                                    option={countries}
                                    defaultValue={field.value} // Ensure defaultValue is an object { id, name }

                                    error={!!errors.country?.name}
                                    helperText={errors.country?.name?.message || ''}
                                    changeCallBack={({ id, name }) => {


                                        setSelectedCountry(id);
                                        field.onChange({ id, name }); // Pass { id, name } to form state
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required sicon={<OFCountry />}
                                    fullWidth
                                    label="State"
                                    option={states}
                                    defaultValue={field.value}
                                    error={!!errors.state?.name}
                                    helperText={errors.state?.name?.message || ''}
                                    placeholder="Select State"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedState(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    label="City" sicon={<OFCountry />}
                                    option={cities}
                                    defaultValue={field.value}
                                    error={!!errors.city?.name}
                                    helperText={errors.city?.name?.message || ''}
                                    placeholder="Select City"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedCity(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="pinCode"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="PIN Code"
                                    fullWidth sicon={<OFCountry />}
                                    variant="outlined"
                                    error={!!errors.pinCode}
                                    helperText={errors.pinCode ? errors.pinCode.message : ''}
                                    placeholder={'Enter PIN Code'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>



                    <Grid item xs={12} md={4}>


                        <Controller
                            name="industry"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFIndustry />}
                                    label="Industry"
                                    option={industries}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedIndustry(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.industry?.name}
                                    helperText={errors.industry?.name?.message || ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {/* <Controller
                            name="sector"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field} required
                                    // field={field}
                                    fullWidth sicon={<OFSector />}
                                    label={'Sector'}
                                    variant="outlined"
                                    option={industrySectors}
                                    defaultValue={field.value}
                                    error={!!errors.city}
                                    helperText={errors.sector ? errors.sector.message : ''}
                                    changeCallBack={({ id, name }) => {
                                        //  console.log('Sector selected:', value);
                                        setSelectedSector(id);
                                        field.onChange(id, name);
                                    }}
                                />

                            )}
                        /> */}
                        <Controller
                            name="sector"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFSector />}
                                    label="Sector"
                                    option={industrySectors}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedSector(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.sector?.name}
                                    helperText={errors.sector?.name?.message || ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>


                        <Controller
                            name="yearCommencedFranchising"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CustomDropdownSingle
                                    {...field}
                                    fullWidth sicon={<OFCalendar />}
                                    required
                                    label="Year of Commencement"
                                    option={["1900", "1915", "2020"]}
                                    defaultValue={field.value}
                                    changeCallBack={(value) => {
                                        field.onChange(value);
                                    }}
                                    error={!!errors.yearCommencedFranchising}
                                    helperText={errors.yearCommencedFranchising?.message || ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />


                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="noOfFranchiseOutlets"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field} required
                                    field={field}
                                    fullWidth sicon={<OFStore />}
                                    label="No of Franchise Outlets"
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty

                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    error={!!errors.noOfFranchiseOutlets?.name}
                                    helperText={errors.noOfFranchiseOutlets?.name?.message || ''}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="websiteLink"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Website Link"
                                    fullWidth sicon={<OFWebsiteLink />}
                                    variant="outlined"
                                    error={!!errors.websiteLink}
                                    helperText={errors.websiteLink ? errors.websiteLink.message : ''}
                                    placeholder={'eg www.website.com'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="outletsLocationState"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <MultipleSelectChip
                                    options={outletStates}
                                    label="Outlets are located at State(s)"
                                    value={value} // Pass array of objects with id and label
                                    onChange={onChange}
                                    required
                                    error={errors?.outletsLocationState?.message}
                                    helperText={errors.outletsLocationState ? errors.outletsLocationState.message : ''}
                                />
                            )}
                        />

                    </Grid>

                    {/* <Grid item xs={12} md={2}>

                        <Controller
                            name="isMarketingKitAvailable"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Marketing kits Available?"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.isMarketingKitAvailable?.message}
                                    helperText={errors.isMarketingKitAvailable ? errors.isMarketingKitAvailable.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        setIsMarketingKitAvailable(e.target.value); 
                                    }}
                                />
                            )}
                        />

                    </Grid> 

                    {isMarketingKitAvailable === 'Yes' && (
                        <Grid item xs={12} md={6}>

                            <Controller
                                name="marketingKitDetails"
                                control={control}
                                render={({ field }) => (
                                    <CustomCheckbox
                                        name="marketingKitDetails"
                                        control={control}
                                        options={checkboxOptions}
                                        mainLabel="If yes, Please Specify (Franchise Kit, Brochures, Company Profile, Others)"

                                    />
                                )}
                            />



                        </Grid>)}*/}
                    <Grid item xs={12} md={12}>


                        {/* <Controller
                            name="businessDescription"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field} required
                                    sx={{ marginTop: 0.5 }}
                                    fullWidth sicon={<OFMailIcon />}
                                    size="large"
                                    placeholder="Enter Describe your Business"
                                    multiline
                                    rows={4}
                                    inputProps={{ maxLength: 50 }}
                                    error={!!errors.businessDescription}
                                    helperText={errors.businessDescription ? errors.businessDescription.message : ''}
                                    FormHelperTextProps={{
                                        sx: { fontSize: '14px', color: 'black', marginLeft: 0, marginTop: 0.5 }
                                    }}

                                />
                            )}
                        /> */}
                        <Controller
                            name="businessDescription"
                            control={control}
                            render={({ field }) => (
                                <CustomMultiLineInput
                                    field={field}
                                    label="Describe your Business"
                                    error={errors.businessDescription}
                                    sicon={<OFDescription />}
                                    helperText={errors.businessDescription ? errors.businessDescription.message : ''}
                                    placeholder="Enter Describe your Business"
                                    rows={4}
                                    maxLength={50}
                                    required
                                    isViewMode={!isEditing} // Pass view mode prop
                                />
                            )}
                        />


                    </Grid>
                </Grid>
            </Box>



        </>);
};

export default BusinessDetailsForm;
