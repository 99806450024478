import { Box, Container, useMediaQuery } from '@mui/material'
import React from 'react'
import { oneFranHubtheme } from '../../theme/theme';

const FormContainer = ({ customComponent }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    return (





        <Box
            paddingY={4}
            sx={{
                backgroundColor: '#F1F5F9',
                boxSizing: 'border-box',
                // paddingX: isMobile ? 2 : isTablet ? 5 : 25,

            }}
        >
            <Container maxWidth={'xl'}>
                {customComponent}

            </Container>



        </Box>
    )
}

export default FormContainer