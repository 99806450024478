

import React, { useState, useEffect, useContext } from 'react';
import {
    AppBar as MuiAppBar, Grid, Toolbar, IconButton, Button, Box, ListItemIcon, styled, Menu, MenuItem, useMediaQuery, DialogActions, Dialog, Avatar, Typography
} from '@mui/material';
import CompanyLogo from '../../assets/CompanyLogoHeader.svg';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { oneFranHubtheme } from "../../theme/theme";
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import CloseButton from "../../assets/icons/CloseButton.svg";
import { useNavigate } from 'react-router-dom';
import Popup from './Popup';
import LoginForm from '../../pages/signin/LoginForm';
import { AuthContext } from '../../context/AuthContext';
import { isLoggedInState, perInfoState } from '../../recoil/profile-atom';
import { useRecoilState } from 'recoil';
import { toProper } from '../../utils/UtilFunctions';
import SettingsIcon from '@mui/icons-material/Settings';
import { OFDashboardIcon, OFHeartIcon, OFLogoutIcon, OFMyProfileIcon } from '../../icons/CustomIcon';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
}));

const NavButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontSize: '18px',
    color: 'white',
    lineHeight: '22.68px',
    // [theme.breakpoints.up('sm')]: {
    //     fontSize: '1rem', // Adjust font size for small screens and up
    // },
    // [theme.breakpoints.up('md')]: {
    //     fontSize: '0.7rem', // Adjust font size for medium screens and up
    // },
    // [theme.breakpoints.up('lg')]: {
    //     fontSize: '0.7rem', // Adjust font size for large screens and up
    // },
    // [theme.breakpoints.up('xl')]: {
    //     fontSize: '1.1rem', // Adjust font size for medium screens and up
    // },
    '&.active': {
        color: theme.palette.primary.main,
    },
}));

export default function CustomNavBar() {
    const { logout } = useContext(AuthContext);
    const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
    const navigate = useNavigate();
    const [userInfo] = useRecoilState(perInfoState);
    const [open, setOpen] = useState(false);
    const [anchorNav, setAnchorNav] = useState(null);
    const [activeMenu, setActiveMenu] = useState('home');
    const [openPopup, setOpenPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));

    console.log("userInfo" + JSON.stringify(userInfo, null, 2))

    useEffect(() => {
        const storedActiveMenu = localStorage.getItem('activeMenu');
        if (storedActiveMenu) {
            setActiveMenu(storedActiveMenu);
        }
    }, []);

    const openMenu = (event) => {
        setAnchorNav(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorNav(null);
    };

    const handleNavigate = (section) => {
        setActiveMenu(section);
        localStorage.setItem('activeMenu', section);
        closeMenu();
    };

    const handleLogin = () => {
        setOpen(true);
        closeMenu();
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenPopup = () => {
        setOpenPopup(true);
        closeMenu();
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (path) => {
        handleMenuClose();
        navigate(path);
    };

    const ResponsiveNavButton = ({ children, ...props }) => (
        <NavButton {...props}>
            <Typography variant="body1" sx={{
                fontSize: {
                    xs: '0.8rem', // Smallest font size for extra-small screens
                    sm: '1rem',  // Slightly larger font size for small screens
                    md: '1.2rem', // Larger font size for medium screens
                    lg: '1.4rem'  // Largest font size for large screens and up
                },
            }}>
                {children}
            </Typography>
        </NavButton>
    );



    return (
        <div>
            <Grid
                container
                justifyContent="center"
                sx={{
                    backgroundColor: 'rgba(13, 38, 89, 3)',
                    paddingY: 2,
                    // marginTop: 4,
                    //marginBottom: 4,
                }}
            >
                <Box
                    maxWidth={{ xs: '100%', sm: '90%', md: '1440px', lg: '1440px' }}
                    sx={{
                        width: '100%',
                        paddingX: { xs: 1, sm: 2, md: 3 },
                    }}
                >
                    <AppBar position="static">
                        <Toolbar
                            sx={{
                                backgroundColor: 'rgba(13, 38, 89, 3)',

                            }}
                        >
                            <Box sx={{
                                display: 'flex', justifyContent: 'space-between',
                                width: '100%',
                                alignItems: 'center'
                            }}>
                                <Box mr={4}>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="logo"
                                        onClick={() => navigate('/')}
                                    >
                                        <img src={CompanyLogo} alt="Company Logo" style={{ height: '2.5rem', width: 'auto' }} />
                                    </IconButton>

                                </Box>




                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, gap: 0.5, justifyContent: 'center' }}>
                                    <Box sx={{
                                        display: 'flex', justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',
                                        ml: 6

                                    }}>
                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                            <NavButton
                                                href="#home"
                                                className={activeMenu === 'home' ? 'active' : ''}
                                                onClick={() => handleNavigate('home')}
                                            >
                                                Home
                                            </NavButton>
                                            <NavButton
                                                href="#products"
                                                className={activeMenu === 'products' ? 'active' : ''}
                                                onClick={() => handleNavigate('products')}
                                            >
                                                Products
                                            </NavButton>
                                            <NavButton
                                                href="#need-space"
                                                className={activeMenu === 'need-space' ? 'active' : ''}
                                                onClick={() => handleNavigate('need-space')}
                                            >
                                                Need a Space
                                            </NavButton>
                                            <NavButton
                                                href="#loan-property"
                                                className={activeMenu === 'loan-property' ? 'active' : ''}
                                                onClick={() => handleNavigate('loan-property')}
                                            >
                                                Loan on Property
                                            </NavButton>
                                            <NavButton
                                                href="#about-us"
                                                className={activeMenu === 'about-us' ? 'active' : ''}
                                                onClick={() => handleNavigate('about-us')}
                                            >
                                                About Us
                                            </NavButton>
                                            <NavButton
                                                href="#contact-us"
                                                className={activeMenu === 'contact-us' ? 'active' : ''}
                                                onClick={() => handleNavigate('contact-us')}
                                            >
                                                Contact Us
                                            </NavButton>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <NavButton href="#search">
                                                <SearchIcon />
                                            </NavButton>
                                            <NavButton onClick={() => isLoggedIn ? handleLogout() : handleLogin()}>
                                                {isLoggedIn ? 'Logout' : 'Login'}
                                            </NavButton>
                                            {!isLoggedIn && (
                                                <NavButton onClick={() => handleOpenPopup()}>
                                                    Register
                                                </NavButton>
                                            )}
                                            {isLoggedIn && (
                                                <Box display="flex" alignItems="center" gap={1} onClick={handleMenuOpen} sx={{ cursor: 'pointer' }}>
                                                    <IconButton color="inherit">
                                                        <Avatar alt="User Avatar" src="/images/avatar1.png" />
                                                    </IconButton>
                                                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                                                        <Typography variant="body1" sx={{ fontSize: '18px', color: 'white' }}>
                                                            {`${userInfo.firstName} ${userInfo.lastName}`}
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ fontSize: '14px', color: 'blue' }}>
                                                            {userInfo.roles[0]}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>


                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    >
                                        <MenuItem onClick={() => handleMenuClick('/dashboard')}>
                                            <ListItemIcon>
                                                <OFDashboardIcon fontSize="small" />
                                            </ListItemIcon>
                                            Dashboard
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuClick('/profile')}>
                                            <ListItemIcon>
                                                <OFMyProfileIcon fontSize="small" />
                                            </ListItemIcon>
                                            My Profile
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuClick('/wishlist')}>
                                            <ListItemIcon>
                                                <OFHeartIcon fontSize="small" />
                                            </ListItemIcon>
                                            Wishlist
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuClick('/settings')}>
                                            <ListItemIcon>
                                                <SettingsIcon fontSize="small" />
                                            </ListItemIcon>
                                            Settings
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleMenuClose();
                                                logout();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <OFLogoutIcon fontSize="small" />
                                            </ListItemIcon>
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </Box>


                                <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
                                    <IconButton size="large" edge="end" onClick={openMenu} sx={{ color: 'white', ml: 'auto' }}>
                                        <MenuIcon />
                                    </IconButton>
                                </Box>

                                <Menu
                                    anchorEl={anchorNav}
                                    open={Boolean(anchorNav)}
                                    onClose={closeMenu}
                                    PaperProps={{
                                        sx: {
                                            //   width: 'auto',
                                            marginTop: '10px',
                                        },
                                    }}
                                >
                                    <MenuItem onClick={() => handleNavigate('home')}>Home</MenuItem>
                                    <MenuItem onClick={() => handleNavigate('products')}>Products</MenuItem>
                                    <MenuItem onClick={() => handleNavigate('need-space')}>Need a Space</MenuItem>
                                    <MenuItem onClick={() => handleNavigate('loan-property')}>Loan on Property</MenuItem>
                                    <MenuItem onClick={() => handleNavigate('about-us')}>About Us</MenuItem>
                                    <MenuItem onClick={() => handleNavigate('contact-us')}>Contact Us</MenuItem>
                                    <MenuItem onClick={isLoggedIn ? handleLogout : handleLogin}>
                                        {isLoggedIn ? 'Logout' : 'Login'}
                                    </MenuItem>
                                    {!isLoggedIn && (
                                        <MenuItem onClick={handleOpenPopup}>
                                            Signup
                                        </MenuItem>
                                    )}
                                    {isLoggedIn && (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap={1}
                                            onClick={handleMenuOpen}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <IconButton color="inherit">
                                                <Avatar alt="User Avatar" src="/images/avatar1.png" />
                                            </IconButton>
                                            <Box display="flex" flexDirection="column" alignItems="flex-start">
                                                <Typography variant="body1" sx={{ fontSize: '18px', color: 'white' }}>
                                                    {toProper(`${userInfo.firstName} ${userInfo.lastName}`)}
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '14px', color: 'blue' }}>
                                                    {toProper(userInfo.roles[0])}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Menu>
                            </Box>
                        </Toolbar>
                    </AppBar>

                </Box>
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        background: 'transparent',
                        boxShadow: 'none',
                        width: { xs: '90vw', sm: '60vw', md: '40vw', lg: '30vw' },
                    },
                }}
            >
                <Box display="flex" justifyContent="center" alignItems="center" position="relative" p={1}>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            zIndex: 1,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
                    <Button onClick={handleClose} color="primary" sx={{ padding: 0 }}>
                        <img src={CloseButton} width="80px" height="auto" alt="Close" />
                    </Button>
                </DialogActions>

                <DialogContent sx={{ background: 'white', borderRadius: 5, padding: 2 }}>
                    <LoginForm onSuccess={handleClose} />
                </DialogContent>
            </Dialog>

            {openPopup && <Popup handleClose={handleClosePopup} />}
        </div>
    );
}
