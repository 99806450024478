
import React from 'react';
import { Button } from '@mui/material';

const CustomButton = ({ onClick, label, type = 'button', width = '100%', height = '3.875rem' }) => {
    return (
        <Button
            onClick={onClick}
            type={type}
            sx={{
                width: width,
                position: 'relative',
                borderRadius: '37px',
                // background: 'linear-gradient(90deg, #042f85, #0d51d9)',
                background: '#0D2659',
                height: height,
                fontSize: '1.125rem',
                color: '#fff',
                textTransform: 'none', // Prevents automatic capitalization

                '&:hover': {
                    background: 'linear-gradient(90deg, #042f85, #0d51d9)',
                },
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    whiteSpace: 'nowrap', // Ensures text doesn't wrap
                    fontSize: '18px',
                    fontWeight: '400',
                    lineHeight: '22.68px',
                    textTransform: 'none', // Prevents automatic capitalization
                }}
            >
                {label}
            </div>
        </Button>
    );
};

export default CustomButton;
