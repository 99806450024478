
import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-icon': {
        marginRight: '10px',
    },
}));

export default function CustomDropDown({
    option = [], // Default to empty array if option is null
    defaultValue = {},
    changeCallBack = () => { },
    disabled,
    id,
    justifyContent = 'center',
    state,
    style,
    label,
    required = false,
    field,
    colorDisabled,
    limitWidth,
    helperText,
    labelComponent,
    backgroundColor,
    labelRightComponent,
    error = null,
    IconComponent = KeyboardArrowDownIcon,
    sicon,
    eicon,
    isViewMode = false, // Add isViewMode prop
}) {
    const [status, setStatus] = useState(defaultValue?.id || ""); // Initialize with id or empty string

    useEffect(() => {
        setStatus(defaultValue?.id || "");
    }, [defaultValue]);

    const handleChange = (event) => {
        const newValue = event.target.value;

        // Find the selected option by its id
        const selectedOption = option.find(item => item.id === newValue);

        if (selectedOption) {
            setStatus(newValue);

            // Pass the selected object { id, name }
            if (changeCallBack) {
                changeCallBack({ id: selectedOption.id, name: selectedOption.name });
            }

            if (state?.saveValue) {
                state.saveValue(newValue);
            }

            if (field?.onChange) {
                field.onChange({ id: selectedOption.id, name: selectedOption.name });
            }
        } else {
            console.warn('Selected value is not valid');
        }
    };

    const renderSelectValue = (selected) => {
        if (selected === "" || !option.some(item => item.id === selected)) {
            return <>Select Option</>;
        }
        const selectedOption = option.find((item) => item.id === selected);
        return selectedOption ? selectedOption.name : "Select Option";
    };

    if (isViewMode) {
        const selectedOption = option.find((item) => item.id === status);
        return (
            <Box justifyContent={justifyContent} mt={0} mb={1}>
                {labelComponent ? labelComponent : (
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <InputLabel style={{ color: '#000000', marginBottom: '0.5rem' }}>
                            {isViewMode ? (
                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'grey' }}>
                                    {sicon && <InputAdornment position="start">{sicon}</InputAdornment>}
                                    <Box sx={{ ml: sicon ? 1 : 0 }}>{label}</Box>
                                </Typography>
                            ) : (
                                label
                            )}
                            {required && !isViewMode && (
                                <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
                                    *
                                </Typography>
                            )}
                        </InputLabel>
                        <InputLabel>{labelRightComponent}</InputLabel>
                    </Stack>
                )}
                <Typography
                    style={{
                        ...style,
                        paddingLeft: sicon ? 40 : 16,
                        paddingRight: eicon ? 40 : 16,
                        backgroundColor: '#FBFCFF',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {selectedOption ? selectedOption.name : ''}
                </Typography>
                {helperText && (
                    <Typography variant="body2" color="error" style={{ fontSize: 14, marginTop: '0.5rem' }}>
                        {helperText}
                    </Typography>
                )}
            </Box>
        );
    }

    return (
        <Box justifyContent={justifyContent} mt={0} mb={1}>
            {labelComponent ? labelComponent : (
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <InputLabel style={{ color: '#000000', marginBottom: '0.5rem' }}>
                        {label}
                        {required && <Typography component={'span'} color={'error.main'} sx={{ marginLeft: 1 }}>*</Typography>}
                    </InputLabel>
                    <InputLabel>{labelRightComponent}</InputLabel>
                </Stack>
            )}
            <FormControl style={limitWidth ? { minWidth: '50%' } : {}} fullWidth={!limitWidth}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    {sicon && (
                        <IconButton style={{ position: 'absolute', left: 6, zIndex: 1 }}>
                            {sicon}
                        </IconButton>
                    )}
                    <CustomSelect
                        style={{
                            ...style,
                            paddingLeft: sicon ? 40 : 16,
                            paddingRight: eicon ? 40 : 16,
                            backgroundColor: '#FBFCFF',
                            width: '100%',
                            position: 'relative',
                        }}
                        disabled={disabled}
                        displayEmpty
                        value={status}
                        onChange={handleChange}
                        IconComponent={IconComponent}
                        renderValue={renderSelectValue}
                    >
                        {option?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                    {eicon && (
                        <IconButton style={{ position: 'absolute', right: 0 }}>
                            {eicon}
                        </IconButton>
                    )}
                </Box>
            </FormControl>
            {helperText && (
                <Typography variant="body2" color="error" style={{ fontSize: 14, marginTop: '0.5rem' }}>
                    {helperText}
                </Typography>
            )}
        </Box>
    );
}
