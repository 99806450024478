// paths.js
const PATH_SIGN_IN = '/signin';
const PATH_DASHBOARD = '/dashboard';
const PATH_INVESTOR = "/investor";
const PATH_NEED_SPACE = "/need-space";
const PATH_LOAN_SUPPORT = "/loan-support";
const PATH_BUSINESS_OWNER = "/business-owner";
const PATH_FRANCHISE = `${PATH_BUSINESS_OWNER}/franchise`;
const PATH_DEALER = `${PATH_BUSINESS_OWNER}/dealers`;
const PATH_PARTNERSHIP = `${PATH_BUSINESS_OWNER}/partnership`;
const PATH_SELL_BUSINESS = `${PATH_BUSINESS_OWNER}/sell-business`;




const Path = {
    signin: PATH_SIGN_IN,
    dashboard: PATH_DASHBOARD,
    investor: PATH_INVESTOR,
    needSpace: PATH_NEED_SPACE,
    loanSupport: PATH_LOAN_SUPPORT,
    businessOwner: PATH_BUSINESS_OWNER,
    franchise: PATH_FRANCHISE,
    dealer: PATH_DEALER,
    partnership: PATH_PARTNERSHIP,
    sellBusiness: PATH_SELL_BUSINESS,
};

export default Path;
