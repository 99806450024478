

import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import CustomFooter from './CustomFooter'


const OFFooter = () => {
    return (
        <Box sx={{ backgroundColor: '#E7F0FF' }}>
            <Container maxWidth="xl">
                <CustomFooter />
            </Container>


        </Box>

    )
}

export default OFFooter