
import React from 'react';
import { useController } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Typography } from '@mui/material';

const CustomCheckbox = ({ sicon, name, control, options, mainLabel, isViewMode = false, required }) => {
    const { field } = useController({ name, control, defaultValue: [] });

    // Normalize field value to ensure it's always an array of objects
    const normalizeFieldValue = () => {
        const value = Array.isArray(field?.value) ? field.value : []; // Ensure value is an array
        return value.map(item => {
            const itemId = Number(item);
            const matchedOption = options.find(option => Number(option.id) === itemId);
            return typeof item === 'object' && item !== null
                ? item
                : { id: itemId, name: matchedOption ? matchedOption.name : itemId };
        });
    };

    // Get selected options as objects with id and name, excluding strings
    const getSelectedOptions = () => {
        const normalizedValue = normalizeFieldValue(); // No need to provide default array here
        return options.filter(option =>
            normalizedValue.some(selected => Number(selected.id) === Number(option.id))
        );
    };

    // Handle changes in checkboxes
    const handleChange = (event) => {
        const { value, checked } = event.target;
        const itemId = Number(value);
        const matchedOption = options.find(option => Number(option.id) === itemId);
        if (!matchedOption) return; // Ignore if the option doesn't exist

        const currentValues = normalizeFieldValue();
        const updatedValue = checked
            ? [...currentValues, { id: itemId, name: matchedOption.name }]
            : currentValues.filter(item => Number(item.id) !== itemId);

        // Update the field with the new array of objects
        field.onChange(updatedValue);
        console.log('Selected Options:', updatedValue);
    };

    return (
        <div style={{ marginBottom: 8, marginTop: 16 }}>
            {mainLabel && (
                <FormLabel
                    component="legend"
                    style={{ color: 'grey', marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}
                >
                    {isViewMode && sicon && (
                        <span style={{ marginRight: '0.5rem' }}>
                            {React.isValidElement(sicon) ? sicon : typeof sicon === 'function' ? sicon(14, 14) : <span>{sicon}</span>}
                        </span>
                    )}
                    {mainLabel}
                    {required && !isViewMode && (
                        <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
                            *
                        </Typography>
                    )}
                </FormLabel>
            )}

            {isViewMode ? (
                <Typography style={{ marginLeft: '1rem' }}>
                    {getSelectedOptions()
                        .map(option => option.name)
                        .join(', ')}
                </Typography>
            ) : (
                <FormGroup row>
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.id}
                            control={
                                <Checkbox
                                    value={option.id}
                                    checked={normalizeFieldValue().some(selected => Number(selected.id) === Number(option.id))}
                                    onChange={handleChange}
                                />
                            }
                            label={option.name}
                            style={{ marginRight: '2rem', marginLeft: '1rem' }}
                        />
                    ))}
                </FormGroup>
            )}
        </div>
    );
};

export default CustomCheckbox;
