

import React, { useEffect, useState } from 'react';
import CustomInput from '../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl, IconButton } from '@mui/material';
import { Tabs, Tab, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../helpers/CustomRadioButton';
import CustomRadioButton from '../../helpers/CustomRadioButton';
import CustomCheckbox from '../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../helpers/CustomButton';
import { OFAddress, OFCalendar, OFCastle, OFCompany, OFCountry, OFDescription, OFEditIcon, OFHouse, OFIndustry, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFPhone, OFRoadLocation, OFSector, OFStore, OFUser, OFWebDesign, OFWebsiteLink } from '../../icons/CustomIcon';
import CustomDropDown from '../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../helpers/CustomInputPhone';

import { toastError } from '../../utils/toast-notify';

import { getApiCall } from '../../axios/axios-methods';
import { API } from '../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../axios/api-service';
import MultiSelectDropdown from '../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../helpers/dropdown/MultiSelectChip';
import CustomDropdownSingle from '../../helpers/dropdown/CustomDropdownSingle';
import CustomMultiLineInput from '../../helpers/CustomMultiLineInput';
import ProgressSteperFranchisor from './progress/ProgressSteperFranchisor';
import ProgressSteperBusinessCollaboration from './progress/ProgressSteperBusinessCollaboration';
import ProgressSteperDealer from './progress/ProgressSteperDealer';
import ProgressSteperInvestor from './progress/ProgressSteperInvestor';
import ProgressSteperSellMyBusiness from './progress/ProgressSteperSellMyBusiness';
import { perInfoState } from '../../recoil/profile-atom';
import { useRecoilState } from 'recoil';




const PaymentInfoForm = ({ control, errors, setValue, watch, getValues, isBussnessProfile,

    countries,
    cities,
    states,
    industries,
    ranges,
    industrySectors,
    setSelectedCity,
    setSelectedCountry,
    setSelectedIndustry,
    setSelectedSector, setSelectedState,
    isDashboardMode,
    setIsEditing,
    isEditing


}) => {


    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const [userInfo] = useRecoilState(perInfoState);
    const role = userInfo?.roles[0]

    console.log(" role payment " + JSON.stringify(role, null, 2))

    const FRANCHISE_COMMENCED_YEAR_LIST = [...Array(new Date().getFullYear() - 1900 + 1).keys()].map(year => year + 1900);







    const countryCodeOptions = [
        { label: '+91', value: '91' },
        { label: '+1', value: '1' },
        { label: '+44', value: '+44' },


    ];





    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const [selectedTab, setSelectedTab] = useState(0);

    // Handler for tab change
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const planSets = [
        [
            {
                name: 'Basic',
                price: '29.99/3 ',
                details: 'Basic plan for 3 months with limited features.',
            },
            {
                name: 'Standard',
                price: '49.99/3 ',
                details: 'Standard plan for 3 months with more features.',
            },
            {
                name: 'Unlimited',
                price: '69.99/3 ',
                details: 'Unlimited plan for 3 months with all features.',
            },
        ],
        [
            {
                name: 'Basic',
                price: '54.99/6 ',
                details: 'Basic plan for 6 months with limited features.',
            },
            {
                name: 'Standard',
                price: '94.99/6 ',
                details: 'Standard plan for 6 months with more features.',
            },
            {
                name: 'Unlimited',
                price: '134.99/6 ',
                details: 'Unlimited plan for 6 months with all features.',
            },
        ],
        [
            {
                name: 'Basic',
                price: '99.99/12 ',
                details: 'Basic plan for 12 months with limited features.',
            },
            {
                name: 'Standard',
                price: '179.99/12 ',
                details: 'Standard plan for 12 months with more features.',
            },
            {
                name: 'Unlimited',
                price: '249.99/12 ',
                details: 'Unlimited plan for 12 months with all features.',
            },
        ],
    ];

    const plandetails = [
        "30h Fast generations",
        "Unlimited Relaxed generations",
        "General commercial terms",
        "Access to member gallery",
        "Optional credit top ups",
        "3 concurrent fast jobs",
        "12 concurrent fast jobs"
    ];

    const CheckIcon = ({ width = 24, height = 24, color = 'currentColor' }) => (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#EBEFF0" />
            <g clip-path="url(#clip0_224_11250)">
                <path d="M11.8463 5.42389L6.92781 11.326L4.18945 8.50944L5.14545 7.58L6.85153 9.33484L10.822 4.57031L11.8463 5.42389Z" fill="#B9BEC1" />
            </g>
            <defs>
                <clipPath id="clip0_224_11250">
                    <rect width="8" height="8" fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>
    )






    return (
        <>

            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3} sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="TITLE3">
                            {role === 'FRANCHISER' && 'Franchisor Registration'}
                            {role === 'BCOLLAB' && 'Business Collaboration Registration'}
                            {role === 'DEALER' && 'Dealer Registration'}
                            {role === 'INVESTOR' && 'Investor Registration'}
                            {role === 'BSELLER' && 'Business Seller Registration'}
                        </Typography>
                    </Box>

                    <Box ml={2} mr={2}>
                        {!isMobile && (
                            <>
                                {role === 'FRANCHISER' && <ProgressSteperFranchisor />}
                                {role === 'BCOLLAB' && <ProgressSteperBusinessCollaboration />}
                                {role === 'DEALER' && <ProgressSteperDealer />}
                                {role === 'INVESTOR' && <ProgressSteperInvestor />}
                                {role === 'BSELLER' && <ProgressSteperSellMyBusiness />}
                                <Divider />
                            </>
                        )}
                    </Box>




                </>
            }

            <Box
                marginBottom={1}
                marginTop={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h4" paragraph>
                    Payment Plans
                </Typography>

                <Typography variant="body1" paragraph>
                    Choose the plan that works for you.
                </Typography>


                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    centered
                    sx={{ marginBottom: 2 }}
                >
                    <Tab label="3 Months" />
                    <Tab label="6 Months" />
                    <Tab label="12 Months" />
                </Tabs>


                <Grid container spacing={2} justifyContent="center">
                    {planSets[selectedTab].map((plan, index) => (
                        <Grid item xs={12} sm={6} md={2} key={index}>


                            <Paper
                                elevation={3}
                                sx={{
                                    padding: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    '&:hover': { borderColor: 'primary.main', boxShadow: 6 },
                                }}
                            >
                                <Typography variant="Content" sx={{ marginBottom: 1 }}>
                                    {plan.name}
                                </Typography>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        gap: 1,
                                    }}
                                >
                                    <Box sx={{ width: '50%' }}>
                                        <Typography variant='Content'
                                            sx={{
                                                fontSize: '24px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {plan.price}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ width: '50%', ml: 1 }}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '14px',
                                                wordWrap: 'break-word',       // Allows text to wrap within words if needed
                                                overflowWrap: 'break-word',   // Ensures text wraps properly within the box
                                                textAlign: 'left',            // Ensures text is aligned to the left
                                                lineHeight: 1.2,              // Adjusts line height for readability
                                                margin: 0,                    // Removes any default margin that may affect layout
                                                // color: '#B9BEC1'
                                                color: 'grey'
                                            }}
                                        >
                                            per editor/month billed monthly
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start', // Align items to the left
                                        marginTop: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            marginTop: 1,
                                        }}
                                    >
                                        {plandetails.map((detail, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: 0.5, // Space between rows
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginRight: 1, // Space between icon and text
                                                    }}
                                                >
                                                    <CheckIcon width={16} height={16} color="#B9BEC1" />
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        fontSize: '14px',
                                                        wordWrap: 'break-word',
                                                        margin: 0,
                                                    }}
                                                >
                                                    {detail}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>

                                </Box>



                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ marginTop: 2 }}
                                >
                                    Choose Plan
                                </Button>
                            </Paper>

                        </Grid>
                    ))}
                </Grid>
                {/* <Grid item xs={12} md={6}>
                    <Controller
                        name="ownnerName"
                        control={control}
                        render={({ field }) => (
                            <CustomInput
                                {...field} required
                                label="Owner Name"
                                fullWidth sicon={<OFUser />}
                                variant="outlined"
                                error={!!errors.ownnerName}
                                helperText={errors.ownnerName ? errors.ownnerName.message : ''}
                                placeholder={'Owner Name'}
                                isViewMode={!isEditing}
                            />
                        )}
                    />
                </Grid>
                <Grid item sx={12} md={6}>
                    <Controller
                        name="ownnerName"
                        control={control}
                        render={({ field }) => (
                            <CustomInput
                                {...field} required
                                label="Owner Name"
                                fullWidth sicon={<OFUser />}
                                variant="outlined"
                                error={!!errors.ownnerName}
                                helperText={errors.ownnerName ? errors.ownnerName.message : ''}
                                placeholder={'Owner Name'}
                                isViewMode={!isEditing}
                            />
                        )}
                    />


                </Grid> */}
            </Box>
            {/* <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Grid container spacing={2} alignItems={'center'} justifyContent="center">
                    <Grid item sx={12} md={12} >
                        <Typography variant="TITLE1" paragraph>
                            Payment Plans
                        </Typography>

                        <Typography variant="Content" paragraph>
                            Choose the plan that works for you.
                        </Typography>
                    </Grid>


                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        centered
                        sx={{ marginBottom: 2 }}
                    >
                        <Tab label="3 Months" />
                        <Tab label="6 Months" />
                        <Tab label="12 Months" />
                    </Tabs>

                   
                    <Grid item xs={12} sm={6} md={3}  >
                        {planSets[selectedTab].map((plan, index) => (

                            <Paper
                                elevation={3}
                                sx={{
                                    padding: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    '&:hover': { borderColor: 'primary.main', boxShadow: 6 },
                                }}
                            >
                                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                    {plan.name}
                                </Typography>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center', // Center the price and details text
                                        gap: 1,
                                        marginBottom: 2,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        {plan.price}
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: '14px',
                                            textAlign: 'left',
                                            color: 'grey',
                                        }}
                                    >
                                        per editor/month billed monthly
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        marginTop: 1,
                                    }}
                                >
                                    {plandetails.map((detail, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: 0.5,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginRight: 1,
                                                }}
                                            >
                                                <CheckIcon width={16} height={16} color="#B9BEC1" />
                                            </Box>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '14px',
                                                    margin: 0,
                                                }}
                                            >
                                                {detail}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ marginTop: 2 }}
                                >
                                    Choose Plan
                                </Button>
                            </Paper>
                        ))}
                    </Grid>



                    <Grid container spacing={2} mt={2} md={10}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="ownerName" // Ensure this matches your form field name
                                control={control}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        required
                                        label="Owner Name"
                                        fullWidth
                                        sicon={<OFUser />}
                                        variant="outlined"
                                        error={!!errors.ownerName}
                                        helperText={errors.ownerName ? errors.ownerName.message : ''}
                                        placeholder="Owner Name"
                                        isViewMode={!isEditing}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="ownerName" // Ensure this matches your form field name
                                control={control}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        required
                                        label="Owner Name"
                                        fullWidth
                                        sicon={<OFUser />}
                                        variant="outlined"
                                        error={!!errors.ownerName}
                                        helperText={errors.ownerName ? errors.ownerName.message : ''}
                                        placeholder="Owner Name"
                                        isViewMode={!isEditing}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box> */}

        </>);
};

export default PaymentInfoForm;
