import { Box, Grid, Paper } from "@mui/material";



export const OFIdeaCard = ({
    width = 245,
    height = 250,
    color = 'blue',
    title,
    contentBGColor,
    titleBgColor = 'red',
    content, // Adding children prop to include content inside the card
}) => (

    <Grid item xs={12} sm={12} md={3} mt={2} mb={4} >


        <Grid container>
            <Grid item md={3}>

                <Box style={{ position: 'relative', width: `${width}px`, height: `${height + 100}px` }}>
                    {/* Title */}
                    <div
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            backgroundColor: titleBgColor,
                            textAlign: 'center',
                            padding: '20px 0',
                            zIndex: 1,
                        }}
                    >
                        <h2 style={{ margin: 0, fontSize: '16px', color: color }}>{title}</h2>
                    </div>

                    {/* SVG Content */}
                    <div style={{ position: 'relative', top: '0px' }}>
                        <OFShadow width={width} height={height} color={color} style={{ position: 'absolute', top: '10', left: '0', zIndex: -1 }} />
                        <OFIdeaCardSVG width={width} height={height} color={contentBGColor} style={{ position: 'relative' }} />

                        {/* Main Content Area */}
                        <div
                            style={{
                                position: 'absolute',
                                top: '30px', // Adjust position as needed
                                left: '0',
                                width: '100%',
                                padding: '10px',
                                boxSizing: 'border-box',
                                zIndex: 2,
                                backgroundColor: contentBGColor
                            }}
                        >
                            {content}
                        </div>
                    </div>

                </Box>
            </Grid>
        </Grid>


    </Grid>
);

// const OFIdeaCardSVG = ({ width, height, color, style }) => (
//     <svg width={width} height={height} viewBox="0 0 233 233" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
//         <path d="M105.212 232.223H105.817C108.296 230.152 113.118 225.949 114.857 230.575C114.963 227.174 119.558 225.345 122.037 227.688C123.549 224.906 127.388 219.933 130.351 224.559C133.601 220.704 141.341 217.832 145.438 222.215C148.733 216.653 160.298 218.451 163.82 223.107C165.135 217.484 171.393 216.834 175.837 219.54C176.624 212.768 182.867 212.692 186.465 215.065C184.832 210.485 202.171 202.549 202.503 209.638C204.65 204.605 212.193 207.976 214.657 210.606C216.244 205.27 221.369 204.589 226.176 205.028C227.007 203.38 229.426 201.339 232.208 199.888V0H0V232.223H101.116C101.751 230.197 103.141 229.184 105.212 232.223Z" fill="#FEE882" />
//     </svg>
// );

const OFIdeaCardSVG = ({ width, height, color, style }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 233 233"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
    >
        <path
            d="M105.212 232.223H105.817C108.296 230.152 113.118 225.949 114.857 230.575C114.963 227.174 119.558 225.345 122.037 227.688C123.549 224.906 127.388 219.933 130.351 224.559C133.601 220.704 141.341 217.832 145.438 222.215C148.733 216.653 160.298 218.451 163.82 223.107C165.135 217.484 171.393 216.834 175.837 219.54C176.624 212.768 182.867 212.692 186.465 215.065C184.832 210.485 202.171 202.549 202.503 209.638C204.65 204.605 212.193 207.976 214.657 210.606C216.244 205.27 221.369 204.589 226.176 205.028C227.007 203.38 229.426 201.339 232.208 199.888V0H0V232.223H101.116C101.751 230.197 103.141 229.184 105.212 232.223Z"
            fill={color} // Applying the color prop here
        />
    </svg>
);


const OFShadow = ({ width, height, color = 'green', style }) => (
    <svg width={width + 20} height={height + 20} viewBox="0 0 246 197" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path d="M108.22 196.997H108.87C111.576 194.593 116.837 189.726 118.727 195.092C118.848 191.162 123.851 189.031 126.542 191.752C128.175 188.532 132.332 182.802 135.582 188.139C139.074 183.679 147.448 180.399 151.953 185.448C155.46 179.054 168.022 181.109 171.937 186.476C173.253 180.006 180.025 179.265 184.907 182.364C185.588 174.594 192.33 174.503 196.305 177.224C194.401 171.979 212.873 162.939 213.478 171.027C215.61 165.297 223.878 169.122 226.645 172.13C228.141 166.053 233.644 165.267 238.844 165.781C239.675 163.907 242.185 161.594 245.102 159.946L232.238 0.8125H0L10.1433 192.553L103.761 197.012C104.472 194.669 105.983 193.49 108.22 197.012V196.997Z" fill="#EDEDED" />
        fill={color}  </svg>
);
