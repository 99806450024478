import { useMediaQuery } from '@mui/material'
import React from 'react'
import { oneFranHubtheme } from '../../theme/theme';
import { Container, Typography, Button, Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        // Logic to navigate back or to a different page

        navigate("/")
    };
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    return (
        <Box sx={{ bgcolor: '#FFFFFF', boxSizing: 'border-box', paddingX: isMobile ? 2 : isTablet ? 5 : 40, paddingY: 2 }}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                p={4}
                border="1px solid #ccc"
                borderRadius="8px"
            >
                <LockIcon style={{ fontSize: 60, color: '#f44336' }} />
                <Typography variant="h3" color="textPrimary" gutterBottom>
                    403 Forbidden
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    You do not have permission to access this page.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleGoBack}>
                    Go Back
                </Button>
            </Box>
        </Box>
    )
}

export default NotFoundPage