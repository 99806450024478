// import React, { useRef, useState, useEffect } from 'react';
// import { Controller } from 'react-hook-form';
// import { TextField, InputAdornment, IconButton, Typography, Box, Stack, InputLabel } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import styled from '@emotion/styled';
// import { OFImageIcon, OFUploadIcon } from '../icons/CustomIcon';

// const StyledTextField = styled(TextField)(({ theme }) => ({
//     '& .MuiOutlinedInput-root': {
//         '& fieldset': {
//             borderColor: theme.palette.grey[400],
//         },
//         '&:hover fieldset': {
//             borderColor: theme.palette.primary.main,
//         },
//         '&.Mui-focused fieldset': {
//             borderColor: theme.palette.primary.main,
//         },
//     },
// }));

// function MediaUpload({
//     label,
//     labelRightComponent,
//     description = null,
//     size = 'small',
//     accept = '*/*',
//     iconStart = true,
//     iconEnd = true,
//     placeholder = 'Choose file',
//     onChange,
//     control,
//     name,
//     resetFile,
//     isViewMode = false,
//     externalPreviewUrl,
//     required,
//     ...props
// }) {
//     const [fileName, setFileName] = useState('');
//     const inputRef = useRef(null);

//     const handleFileChange = (event, onChange) => {
//         const file = event.target.files[0];
//         if (file) {
//             onChange(file); // Send the selected file back to the form control
//             setFileName(file.name); // Update the file name in the state
//         }
//     };

//     const handleClearFile = () => {
//         setFileName(''); // Clear the file name
//         if (inputRef.current) {
//             inputRef.current.value = ''; // Reset the file input
//         }
//     };

//     useEffect(() => {
//         if (resetFile) {
//             handleClearFile(); // Reset file input when resetFile prop changes
//         }
//     }, [resetFile]);

//     if (isViewMode) {
//         return (
//             <Box {...props}>
//                 <Stack direction="row" justifyContent="space-between" alignItems="center">
//                     <InputLabel style={{ fontSize: size, color: '#000000', marginBottom: '0.5rem' }}>
//                         {label}
//                     </InputLabel>
//                     {required && (
//                         <Typography variant="body2" color="error" sx={{ marginLeft: 1 }}>
//                             *
//                         </Typography>
//                     )}
//                     <InputLabel>{labelRightComponent}</InputLabel>
//                 </Stack>
//                 {description && <Typography>{description}</Typography>}
//                 {externalPreviewUrl && (
//                     <Box mt={2}>
//                         <img
//                             src={externalPreviewUrl}
//                             alt="Preview"
//                             style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }}
//                         />
//                     </Box>
//                 )}
//             </Box>
//         );
//     }

//     return (
//         <Box {...props}>
//             <Stack direction="row" justifyContent="space-between" alignItems="center">
//                 <InputLabel style={{ fontSize: size, color: '#000000', marginBottom: '0.5rem' }}>
//                     {label}
//                 </InputLabel>
//                 <InputLabel>{labelRightComponent}</InputLabel>
//             </Stack>
//             {description && <Typography>{description}</Typography>}
//             <Controller
//                 name={name}
//                 control={control}
//                 defaultValue=""
//                 render={({ field: { onChange, ...field } }) => (
//                     <StyledTextField
//                         {...field}
//                         {...props}
//                         value={fileName}
//                         placeholder={placeholder}
//                         fullWidth
//                         InputProps={{
//                             startAdornment: iconStart && (
//                                 <InputAdornment position="start">
//                                     <IconButton component="span">
//                                         <OFImageIcon />
//                                     </IconButton>
//                                 </InputAdornment>
//                             ),
//                             endAdornment: iconEnd && (
//                                 <InputAdornment position="end">
//                                     <>
//                                         <input
//                                             ref={inputRef}
//                                             type="file"
//                                             accept={accept}
//                                             style={{ display: 'none' }}
//                                             onChange={(e) => handleFileChange(e, onChange)}
//                                         />
//                                         <IconButton
//                                             component="label"
//                                             onClick={() => inputRef.current && inputRef.current.click()}
//                                         >
//                                             <OFUploadIcon />
//                                         </IconButton>
//                                         {fileName && (
//                                             <IconButton
//                                                 onClick={handleClearFile}
//                                                 color="error"
//                                             >
//                                                 <CloseIcon />
//                                             </IconButton>
//                                         )}
//                                     </>
//                                 </InputAdornment>
//                             ),
//                         }}
//                     />
//                 )}
//             />
//         </Box>
//     );
// }

// export default MediaUpload;
import React, { useRef, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, InputAdornment, IconButton, Typography, Box, Stack, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { OFImageIcon, OFUploadIcon } from '../icons/CustomIcon';

function MediaUpload({
    label,
    accept,
    control,
    name,
    required,
    size,
    labelRightComponent,
    onFileSelect, // Select
    onViewSelect,  // View
    isViewMode = false,
    externalPreviewUrl,
    initialFileName,
    onClearMedia,
    helperText,
    ...props
}) {
    const [fileName, setFileName] = useState(initialFileName || '');

    useEffect(() => {
        setFileName(initialFileName || '');
    }, [initialFileName]);

    const inputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name); // Update file name display
            onFileSelect(file); // Call onFileSelect when file is selected
        }
    };

    return (
        <Box {...props}>
            <Stack direction="row" alignItems="center">

                <InputLabel style={{ fontSize: size, color: '#000000', marginBottom: '0.5rem' }}>
                    {label}
                </InputLabel>
                {required && (
                    <Typography variant="body2" color="error" sx={{ marginLeft: '0.25rem' }}>
                        *
                    </Typography>
                )}
                {labelRightComponent && (
                    <InputLabel sx={{ marginLeft: '0.5rem' }}>
                        {labelRightComponent}
                    </InputLabel>
                )}


            </Stack>
            {isViewMode ? (
                // <Box mt={2}>
                //     <img
                //         src={externalPreviewUrl}
                //         alt="Preview"
                //         style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }}
                //     />
                // </Box>

                <Typography
                    component="span"
                    onClick={() => onViewSelect(fileName)}
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                >
                    {fileName}
                </Typography>

            ) : (
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <TextField
                            {...field}
                            value={fileName}
                            placeholder="Choose file"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton component="span">
                                            <OFImageIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <input
                                            ref={inputRef}
                                            type="file"
                                            accept={accept}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                        <IconButton
                                            component="label"
                                            onClick={() => inputRef.current && inputRef.current.click()}
                                        >
                                            <OFUploadIcon />
                                        </IconButton>
                                        {fileName &&
                                            <Typography
                                                component="span"
                                                onClick={() => onViewSelect(fileName)}
                                                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                            >
                                                View Image
                                            </Typography>
                                        }
                                        {fileName && (
                                            <IconButton color="error"

                                                onClick={() => {
                                                    setFileName('');
                                                    onClearMedia();
                                                }}>
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />

                    )}
                />

            )}
            {helperText && (
                <Typography variant="body2" color="error" style={{ fontSize: '14px', marginTop: '0.5rem' }}>
                    {helperText}
                </Typography>
            )}
        </Box>

    );
}

export default MediaUpload;
