import { useRecoilState } from "recoil";
import { API } from "../axios/api-path";
import { fetchFormInfo } from "../axios/api-service";
import { dealerRegistrationState } from "../recoil/profile-atom";

export const toProper = (message) => {
    if (!message) return "";


    message = message.replace(/_/g, " ");

    return message
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
};



export const formatInvestment = (value) => {
    if (value >= 10000000) {
        return `${(value / 10000000).toFixed(1)} C`;
    } else if (value >= 100000) {
        return `${(value / 100000).toFixed(1)} L`;
    } else if (value >= 1000) {
        return `${(value / 1000).toFixed(1)} K`;
    } else {
        return value?.toString();
    }
};


// bulletStyles.js
export const bulletStyle = {
    fontWeight: 'bold',
    position: 'relative',
    paddingLeft: '1rem',
    '&::before': {
        content: '""',
        display: 'inline-block',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: '#000',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
};

export function extractParts(ownerMobileNo) {
    if (ownerMobileNo != null) {

        const [countryCode, phoneNumber] = ownerMobileNo.split('-');


        return {
            countryCode: countryCode || null,
            phoneNumber: phoneNumber || null
        };
    } else {

        return {
            countryCode: null,
            phoneNumber: null
        };
    }
}



export function getDetailsAsText(data) {
    if (!Array.isArray(data)) {
        return '';
    }

    return data.map(item => item.name).join(', ');
}


// export const fetchRegistrationProgress = async () => {
//     const [dealerState, setDealerRegistrationState] = useRecoilState(dealerRegistrationState);
//     try {
//         // Define the API endpoint path
//         const path = API.getDealerInfo();

//         try {
//             // Fetch data from the API
//             const response = await fetchFormInfo(path);

//             if (response.data) {

//                 // Update Recoil state with the fetched data
//                 setDealerRegistrationState((prevState) => ({
//                     ...prevState, // Preserve the previous state values
//                     businessInfoStatus: response.data.businessInfoStatus,
//                     marketingStatus: response.data.marketingStatus,
//                     trainingStatus: response.data.trainingStatus,
//                     appearanceStatus: response.data.appearanceStatus,
//                 }));
//             } else {
//                 console.error('No data found in response.');
//             }

//         } catch (err) {
//             // Log or handle API fetch errors
//             console.error('Error fetching registration progress:', err);
//         }
//     } catch (err) {
//         // Handle any top-level errors
//         console.error('Error in fetchRegistrationProgress:', err);
//     }
// };

