
import React, { useEffect, useState } from 'react';
import { Paper, Stack } from '@mui/material';
import CustomButton from '../../../helpers/CustomButton';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { API } from '../../../axios/api-path';
import { postApiCallWithCred, postApiFileUpload } from '../../../axios/axios-methods';
import { toastError, toastSuccess } from '../../../utils/toast-notify';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import AppearanceDetailsForm from './AppearanceDetailsForm';
import { perInfoState } from '../../../recoil/profile-atom';
import { useRecoilState } from 'recoil';
import { fetchAppearanceInfo, fetchFFinancialInfo, fetchFormInfo, fetchSingleImage } from '../../../axios/api-service';
import AppearanceAndMediaForm from './AppearanceAndMediaForm';
import { uploadMedia, viewMedia } from '../../../utils/MediaOperatiosns';

const AppearanceAndMedia = ({ isDashboardMode }) => {
    //     const [userInfo, setUserInfo] = useRecoilState(perInfoState);
    //     const [resetFiles, setResetFiles] = useState({});

    //     const navigate = useNavigate();
    //     const location = useLocation();

    //     const [appearanceInfo, setAppearanceInfo] = useState(null)
    //     const [isAppearanceInfo, setIsAppearanceInfo] = useState(false)


    //     useEffect(() => {
    //         const fetchData = async () => {
    //             try {
    //                 const response = await fetchAppearanceInfo();


    //                 if (response.status === "success") {
    //                     setAppearanceInfo(response.data);

    //                     setIsAppearanceInfo(true);
    //                 } else {
    //                     setIsAppearanceInfo(false);
    //                 }
    //             } catch (err) {
    //                 // Handle error
    //             }
    //         };

    //         fetchData();
    //     }, []);





    //     const validationSchema = yup.object({
    //         // brandLogo: yup
    //         //     .mixed()
    //         //     .required('Brand logo is required')
    //         //     .test('fileType', 'Only PNG files are allowed', (value) => {
    //         //         return value
    //         //         && value[0]?.type === 'image/png'; 
    //         //     }),
    //         brandLogo: yup
    //             .mixed()
    //             .required('Brand logo is required'),

    //     });
















    //     const { control, handleSubmit, setValue, getValues, formState: { errors }, reset } = useForm({
    //         defaultValues: {
    //             image1: null,
    //             image2: null,
    //             image3: null,
    //             image4: null,
    //             brandLogo: null,
    //             videoLink: null
    //         },
    //         resolver: yupResolver(validationSchema),
    //     });



    //     const [previewUrls, setPreviewUrls] = useState({
    //         image1: '',
    //         image2: '',
    //         image3: '',
    //         image4: '',
    //         brandLogo: '',
    //     });


    //     useEffect(() => {
    //         const fetchImageUrl = async (id) => {
    //             if (!id) return null;

    //             try {
    //                 const blob = await fetchSingleImage(API.getSingleImage(), id);
    //                 return blob;
    //             } catch (error) {
    //                 console.error('Error fetching image:', error);
    //                 return null; // Return null if there's an error
    //             }
    //         };

    //         const fetchAndSetPreviewUrls = async () => {
    //             try {
    //                 if (appearanceInfo) {
    //                     const blobs = await Promise.all([
    //                         fetchImageUrl(appearanceInfo.image1?.id),
    //                         fetchImageUrl(appearanceInfo.image2?.id),
    //                         fetchImageUrl(appearanceInfo.image3?.id),
    //                         fetchImageUrl(appearanceInfo.image4?.id),
    //                         fetchImageUrl(appearanceInfo.brandLogo?.id),
    //                     ]);



    //                     setPreviewUrls({
    //                         image1: blobs[0],
    //                         image2: blobs[1],
    //                         image3: blobs[2],
    //                         image4: blobs[3],
    //                         brandLogo: blobs[4],
    //                     });

    //                     reset({
    //                         image1: blobs[0],
    //                         image2: blobs[1],
    //                         image3: blobs[2],
    //                         image4: blobs[3],
    //                         brandLogo: blobs[4],
    //                         videoLink: appearanceInfo.videoLink,
    //                     });
    //                 }
    //             } catch (error) {
    //                 console.error('Error fetching images:', error);
    //             }
    //         };

    //         fetchAndSetPreviewUrls();
    //     }, [appearanceInfo, reset]);





    //     const onSubmit = async (dataUpload) => {


    //         const path = API.fileUpload();
    //         const files = [
    //             { key: 'image1', file: dataUpload.image1, category: 'image/jpg' },
    //             { key: 'image2', file: dataUpload.image2, category: 'image/jpg' },
    //             { key: 'image3', file: dataUpload.image3, category: 'image/jpg' },
    //             { key: 'image4', file: dataUpload.image4, category: 'image/jpg' },
    //             { key: 'brandLogo', file: dataUpload.brandLogo, category: 'image/png' },
    //         ];

    //         // Filter out any null or undefined files
    //         const validFiles = files.filter(fileObj => fileObj.file);


    //         const uploadResults = {};


    //         const uploadPromises = validFiles.map(async ({ key, file, category }) => {
    //             const formData = new FormData();
    //             formData.append('name', file.name);
    //             formData.append('file', file);
    //             formData.append('category', category);
    //             formData.append('userCode', userInfo.userCode);

    //             try {
    //                 const response = await postApiFileUpload({
    //                     data: formData,
    //                     path: path,
    //                 });


    //                 uploadResults[key] = {
    //                     id: response.id,
    //                     name: response.name,
    //                     type: response.type,
    //                     path: response.path,
    //                     createdAt: response.createdAt,
    //                 };


    //                 toastSuccess(`File successfully uploaded: ${response.name}`);
    //             } catch (error) {
    //                 console.error(`File upload error (${key}):`, error);
    //                 toastError(`File upload failed: ${error.message}`);
    //             }
    //         });


    //         try {
    //             await Promise.all(uploadPromises);





    //             // setResetFiles({
    //             //     image1: true,
    //             //     image2: true,
    //             //     image3: true,
    //             //     image4: true,
    //             //     brandLogo: true,

    //             // });


    //             // setValue('image1', null);
    //             // setValue('image2', null);
    //             // setValue('image3', null);
    //             // setValue('image4', null);
    //             // setValue('brandLogo', null);
    //             // reset();

    //         } catch (error) {
    //             console.error('An error occurred while uploading files:', error);
    //             toastError('An error occurred while uploading files. Please try again.');
    //         }

    //         await submitAppearanceDetails(uploadResults, dataUpload?.videoLink);

    //     };


    //     const submitAppearanceDetails = async (uploadResults, videoLink) => {

    //         const path = API.signupFranAppearanceDetails();


    //         const apiCallData = {
    //             ...uploadResults,
    //             videoLink: videoLink
    //         };


    //         try {
    //             await postApiCallWithCred({
    //                 data: apiCallData,
    //                 path: path,
    //                 onSuccess: (res) => {
    //                     if (isDashboardMode) {
    //                         setIsEditing(false);
    //                     } else {
    //                         handleNext();
    //                     }
    //                 },
    //                 onError: (error) => {
    //                     toastError(error);
    //                 }
    //             });
    //         } catch (error) {
    //             console.error('Unexpected error:', error);
    //         }
    //     };


    //     const handleNext = () => {
    //         navigate(location.pathname.replace(/[^/]*$/, '7'));
    //     };

    //     const handleBack = () => {
    //         navigate(location.pathname.replace(/[^/]*$/, '5'));
    //     };
    //     const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    //     return (
    //         <>
    //             <ToastContainer />
    //             <Paper elevation={2} sx={{
    //                 display: 'flex', flexDirection: 'column', gap: 2, borderRadius: 4, paddingBottom: 2,
    //                 margin: 4,
    //             }}>
    //                 <AppearanceAndMediaForm
    //                     control={control}
    //                     errors={errors}
    //                     setValue={setValue}
    //                     getValues={getValues}
    //                     resetFiles={resetFiles}
    //                     isDashboardMode={isDashboardMode}
    //                     isEditing={isEditing}
    //                     setIsEditing={setIsEditing}
    //                     previewUrls={previewUrls}
    //                 />
    //                 {!isDashboardMode ? (
    //                     <Stack
    //                         direction={{ xs: 'column', sm: 'row' }}
    //                         spacing={2}
    //                         justifyContent="center"
    //                         alignItems="center"
    //                         sx={{ width: '100%', p: 2 }}
    //                     >
    //                         <CustomButton
    //                             type="button"
    //                             width={{ xs: '75%', sm: '10rem' }}
    //                             variant="contained"
    //                             label="Back"
    //                             onClick={handleBack}
    //                         />

    //                         <CustomButton
    //                             type="submit"
    //                             width={{ xs: '75%', sm: '14rem' }}
    //                             variant="contained"
    //                             label={"Save and Next"}
    //                             onClick={handleSubmit(onSubmit)}
    //                         />
    //                     </Stack>
    //                 ) : (
    //                     isEditing && (
    //                         <Stack
    //                             direction={{ xs: 'column', sm: 'row' }}
    //                             spacing={2}
    //                             justifyContent="center"
    //                             alignItems="center"
    //                             sx={{ width: '100%', p: 2 }}
    //                         >
    //                             <CustomButton
    //                                 type="submit"
    //                                 width={{ xs: '75%', sm: '14rem' }}
    //                                 variant="contained"
    //                                 label="Update"
    //                                 onClick={handleSubmit(onSubmit)}
    //                             />
    //                         </Stack>
    //                     )
    //                 )}
    //             </Paper>
    //         </>
    //     );
    // };


    const [userInfo, setUserInfo] = useRecoilState(perInfoState);
    const [resetFiles, setResetFiles] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    const [appearanceInfo, setAppearanceInfo] = useState(null)
    const [isAppearanceInfo, setIsAppearanceInfo] = useState(false)
    const [formData, setFormData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const path = API.getFranAppearanceDetails();

            try {
                const response = await fetchFormInfo(path);

                if (response.status === "success") {
                    setFormData(response.data);
                    setBusinessPhotos(response.data)
                    setIsAppearanceInfo(true);
                } else {
                    setIsAppearanceInfo(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);


    const validationSchema = yup.object({
        brandLogo: yup
            .mixed()
            .required('Brand logo is required'),




    });




    const [businessPhotos, setBusinessPhotos] = useState(formData);




    const handleFileUpload = async (file, name) => {
        const uploadData = { file, category: 'image/jpg', userCode: userInfo?.userCode };
        const response = await uploadMedia(uploadData);
        console.log("Media uploaded:", JSON.stringify(response, null, 2));

        if (response) {
            const responseData = {
                id: response.id,
                name: response.name,
                type: response.type,
                path: response.path,
                createdAt: response.createdAt,
            };



            setBusinessPhotos((prevPhotos) => ({
                ...prevPhotos,
                [name]: responseData, // Overwrite
            }));
        }
    };


    const handleFileClear = async (file, name) => {

        setBusinessPhotos((prevState) => ({
            ...prevState,
            [name]: null,
        }));
    }

    console.log(" All Media uploaded " + JSON.stringify(businessPhotos, null, 2))





    const handleFileView = async (file, name) => {
        console.log("View file " + name);
        const fetchedMedia = formData[name] || businessPhotos[name];

        if (fetchedMedia?.id) {
            try {
                const fileUrl = await viewMedia(fetchedMedia.id);

                if (fileUrl) {

                    window.open(fileUrl, '_blank');
                } else {
                    console.error('Failed to fetch the file URL.');
                }
            } catch (error) {
                console.error('Error viewing file:', error);
            }
        }
    };







    const { control, handleSubmit, setValue, getValues, formState: { errors }, reset } = useForm({
        defaultValues: {

        },
        resolver: yupResolver(validationSchema),
    });




    const onSubmit = async (data) => {




        const path = API.signupFranAppearanceDetails();


        const apiCallData = {
            ...businessPhotos,
            "video1": data?.video1,

        };


        try {
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {
                    if (isDashboardMode) {
                        setIsEditing(false);
                    } else {
                        handleNext();
                    }
                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };


    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '7'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '5'));
    };





    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);











    useEffect(() => {
        if (formData) {

            reset({

                "video1": formData?.video1,


            });
        }
    }, [formData, reset]);




    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <AppearanceAndMediaForm control={control} errors={errors}
                    //  watch={watch}
                    setValue={setValue} getValues={getValues}


                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    handleFileView={handleFileView}
                    handleFileUpload={handleFileUpload}
                    handleFileClear={handleFileClear}
                    formData={formData}

                />


                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}



            </Paper>

        </>
    )
}


export default AppearanceAndMedia;
