

import React, { useEffect, useState } from 'react';
import CustomInput from '../../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import ProgressStepper from '../progress/ProgressSteper';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomRadioButton from '../../../helpers/CustomRadioButton';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../../helpers/CustomButton';
import { OFAddress, OFAppleReminder, OFBackpack1, OFBriefcase, OFCalendar, OFCalendar2, OFCastle, OFCompany, OFCountry, OFDescription, OFEditIcon, OFHouse, OFHouse4, OFIndustry, OFLegalDocument1, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFPhone, OFRoadLocation, OFRupeeIcon, OFSector, OFStore, OFUser, OFWebDesign, OFWebsiteLink, OFYesNoIcon } from '../../../icons/CustomIcon';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../../helpers/CustomInputPhone';

import { toastError } from '../../../utils/toast-notify';

import { getApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import MultiSelectDropdown from '../../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomDropdownSingle from '../../../helpers/dropdown/CustomDropdownSingle';
import CustomMultiLineInput from '../../../helpers/CustomMultiLineInput';

import ProgressStepperInvestor from '../progress/ProgressSteperInvestor';
import ProgressSteperSellMyBusiness from '../progress/ProgressSteperSellMyBusiness';




const SFinancialDetailsForm = ({ control, errors, setValue, watch, getValues, isBussnessProfile,

    countries,
    cities,
    states,
    industries,
    ranges,
    industrySectors,
    setSelectedCity,
    setSelectedCountry,
    setSelectedIndustry,
    setSelectedSector, setSelectedState,
    isDashboardMode,
    setIsEditing,
    isEditing,
    loanSupportRequired


}) => {


    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));





    const FRANCHISE_COMMENCED_YEAR_LIST = [...Array(new Date().getFullYear() - 1900 + 1).keys()].map(year => year + 1900);



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    const countryCodeOptions = [
        { label: '+91', value: '91' },
        { label: '+1', value: '1' },
        { label: '+44', value: '+44' },


    ];





    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>

            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3}
                        sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="h5" sx={{ color: "#0D2659" }}>Business on Sales</Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperSellMyBusiness />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }

            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Financial Details</Typography>

                    {/* {isDashboardMode &&
                        <IconButton onClick={toggleEdit} style={{ float: 'right' }}>
                            <OFEditIcon />
                        </IconButton>

                    } */}
                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}



                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={4}>



                    <Grid item xs={12} md={4}>
                        <Controller
                            name="availableGoodsValue"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Available Goods Value"
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.availableGoodsValue}
                                    helperText={errors.availableGoodsValue ? errors.availableGoodsValue.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <Controller
                            name="operationalExpanse"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Operational Expenses Including rentals"
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.operationalExpanse}
                                    helperText={errors.operationalExpanse ? errors.operationalExpanse.message : ''}
                                    placeholder={'eg 99999.9'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <Controller
                            name="employeesSalaryMonthly"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Employee Salaries per month *"
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.employeesSalaryMonthly}
                                    helperText={errors.employeesSalaryMonthly ? errors.employeesSalaryMonthly.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>



                    <Grid item xs={12} md={4}>
                        <Controller
                            name="grossIncome"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Gross Income"

                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.grossIncome}
                                    helperText={errors.grossIncome ? errors.grossIncome.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <Controller
                            name="ebitda"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="EBITDA"
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.ebitda}
                                    helperText={errors.ebitda ? errors.ebitda.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="approxNetProfit"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Approx Net Profit After Tax"

                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.approxNetProfit}
                                    helperText={errors.approxNetProfit ? errors.approxNetProfit.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="debtObligation"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Debt Obligations "
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.debtObligation}
                                    helperText={errors.debtObligation ? errors.debtObligation.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="revenueStreams"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFAppleReminder />}
                                    label="Revenue Streams"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.revenueStreams?.name}
                                    helperText={errors.revenueStreams ? errors.revenueStreams?.name?.message : ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="previousFPyearOne"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Previous Financial Revenue Projections (Year-1) * "

                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.previousFPyearOne}
                                    helperText={errors.previousFPyearOne ? errors.previousFPyearOne.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>



                    <Grid item xs={12} md={4}>
                        <Controller
                            name="previousFPyearTwo"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Previous Financial Revenue Projections (Year-2) *"
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.previousFPyearTwo}
                                    helperText={errors.previousFPyearTwo ? errors.previousFPyearTwo.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="previousFPyearThree"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Previous Financial Revenue Projections (Year-3) *"
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.previousFPyearThree}
                                    helperText={errors.previousFPyearThree ? errors.previousFPyearThree.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>










                </Grid>
            </Box>



        </>);
};

export default SFinancialDetailsForm;
