// // import React from 'react';
// // import { Tooltip } from '@mui/material';

// // // Custom tooltip component
// // function CustomToolTip({ title, children }) {
// //     return (
// //         <Tooltip
// //             title={title}
// //             arrow
// //             componentsProps={{
// //                 tooltip: {
// //                     sx: {
// //                         backgroundColor: 'yellow', // Tooltip background color
// //                         color: 'black', // Tooltip text color
// //                         fontSize: '0.875rem', // Customize as needed
// //                     },
// //                 },
// //             }}
// //         >
// //             {children}
// //         </Tooltip>
// //     );
// // }

// // export default CustomToolTip;
// import React from 'react';
// import { Tooltip } from '@mui/material';

// // Custom tooltip component
// function CustomToolTip({ title, children }) {
//     return (
//         <Tooltip
//             title={title}
//             arrow
//             disableFocusListener // Disable showing tooltip on focus if not needed
//             disableTouchListener // Disable showing tooltip on touch if not needed
//             componentsProps={{
//                 tooltip: {
//                     sx: {
//                         backgroundColor: 'yellow', // Tooltip background color
//                         color: 'black', // Tooltip text color
//                         fontSize: '0.875rem', // Customize as needed
//                     },
//                 },
//             }}
//         >
//             {children}
//         </Tooltip>
//     );
// }

// export default CustomToolTip;

import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        arrow
        PopperProps={{
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 10],
                    },
                },
            ],
        }}
        disableInteractive={false} // Ensures tooltip can be interacted with on touch devices
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#F5F5F5',
        color: '#333333',
        boxShadow: theme.shadows[1],
        fontSize: 15,
        padding: '8px 12px', // Increase padding for better touch interaction
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#F5F5F5',
    },
}));

export default CustomTooltip;
