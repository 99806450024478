
import React from 'react';
import { Box, Typography, OutlinedInput, InputAdornment, useMediaQuery } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../helpers/CustomButton';
import User from "../../assets/icons/User.svg";
import Mail from "../../assets/icons/Mail.svg";
import Call from "../../assets/icons/Call.svg";
import Interest from "../../assets/icons/Interest.svg";
import { API } from '../../axios/api-path';
import { postApiCall } from '../../axios/axios-methods';
import { toast, ToastContainer } from 'react-toastify';

function ExpertSearchForm() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { handleSubmit, control, formState: { errors }, reset } = useForm();


    const indianMobilePattern = /^[6-9]\d{9}$/;

    const onSubmit = async (data) => {
        const apiCallData = {
            name: data.name,
            emailId: data.email,
            contactNo: data.contactNo,
            interestFeedback: data.interest,
            type: "LEAD"
        };

        const path = API.postContactOrFeedback();
        try {
            await postApiCall({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {
                    toast.success("Successfully Sent");
                    reset(); // Reset fields after successful submission
                },
                onError: (error) => {
                    console.error('Submission error:', error);
                },
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                width: '100%',
                maxWidth: '35rem',
                mx: 'auto',
                my: '1rem',
                padding: '3rem',
                border: '1px solid black',
                borderRadius: '1.563rem',
                position: 'relative',
            }}
        >
            <ToastContainer position="top-right" autoClose={5000} />
            <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.875rem', lineHeight: '2.3625rem' }}>
                What are you looking for?
            </Typography>
            {/* <Typography sx={{ fontWeight: 400, fontSize: '1rem', lineHeight: '1.26rem', mt: '0.5rem', mb: '0.5rem' }}>
                Lorem Ipsum is simply dummy text of the printing.
            </Typography> */}

            <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: 'Name is required' }}
                render={({ field }) => (
                    <OutlinedInput
                        {...field}
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={User} alt="User Icon" />
                            </InputAdornment>
                        }
                        placeholder="Name"
                        error={!!errors.name}
                    />
                )}
            />
            {errors.name && <Typography color="error">{errors.name.message}</Typography>}

            <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                    required: 'Email is required',
                    pattern: { value: /^\S+@\S+\.\S+$/, message: 'Invalid email format' }
                }}
                render={({ field }) => (
                    <OutlinedInput
                        {...field}
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={Mail} alt="Mail Icon" />
                            </InputAdornment>
                        }
                        placeholder="Email ID"
                        error={!!errors.email}
                    />
                )}
            />
            {errors.email && <Typography color="error">{errors.email.message}</Typography>}

            <Controller
                name="contactNo"
                control={control}
                defaultValue=""
                rules={{
                    required: 'Contact No is required',
                    pattern: {
                        value: indianMobilePattern,
                        message: 'Phone number is not valid'
                    }
                }}
                render={({ field }) => (
                    <OutlinedInput
                        {...field}
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={Call} alt="Call Icon" />
                            </InputAdornment>
                        }
                        placeholder="Contact No"
                        error={!!errors.contactNo}
                    />
                )}
            />
            {errors.contactNo && <Typography color="error">{errors.contactNo.message}</Typography>}

            <Controller
                name="interest"
                control={control}
                defaultValue=""
                rules={{ required: 'Interest is required' }}
                render={({ field }) => (
                    <OutlinedInput
                        {...field}
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={Interest} alt="Interest Icon" />
                            </InputAdornment>
                        }
                        placeholder="What are you looking for"
                        error={!!errors.interest}
                    />
                )}
            />
            {errors.interest && <Typography color="error">{errors.interest.message}</Typography>}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                    top: isMobile ? '2rem' : '0',
                }}
            >
                <CustomButton
                    type="submit"
                    width={{ xs: '75%', sm: '14rem' }}
                    variant="contained"
                    label="Submit"
                />
            </Box>
        </Box>
    );
}

export default ExpertSearchForm;
