
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(option, selected, theme) {
    return {
        fontWeight:
            selected.indexOf(option) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const MultiSelectChip = ({ options, selectedValues, onChange, label }) => {
    const theme = useTheme();
    const [selected, setSelected] = React.useState(selectedValues || []);

    React.useEffect(() => {
        setSelected(selectedValues);
    }, [selectedValues]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        const updatedSelected = typeof value === 'string' ? value.split(',') : value;
        setSelected(updatedSelected);
        onChange(updatedSelected);
    };

    return (
        <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                value={selected}
                onChange={handleChange}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        value={option}
                        style={getStyles(option, selected, theme)}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiSelectChip;
