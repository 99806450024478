
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatMessage, toProper } from './UtilFunctions';

// Utility function to show toast notifications
export const toastNotify = (type, msg) => {
    toast[type](msg, {
        //autoClose: false,

        autoClose: 3000,
        closeOnClick: true,

        theme: "colored"
    });
};




// Utility function to show error toast notifications
export const toastError = (error) => {
    const errorMsg =
        error?.response?.data?.errorMessage || error?.response?.data?.message || error?.response?.message || "Some error occured";

    const errorMsgNew = toProper(errorMsg);

    toastNotify("error", errorMsgNew);
};

// Utility function to show success toast notifications
export const toastSuccess = (status) => {
    let msg;
    switch (status) {
        case 201:
            msg = "Successfully Created";
            break;
        case 200:
            msg = "Successfully Updated";
            break;
        case 204:
            msg = "Successfully Deleted";
            break;
        default:
            msg = "Successfully uploaded";
    }
    toastNotify("success", msg);
};
