import React, { createContext, useState } from 'react';
import { isLoggedInState, perInfoState } from '../recoil/profile-atom';
import { useSetRecoilState } from 'recoil';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const setIsLoggedIn = useSetRecoilState(isLoggedInState);
    const setUserInfo = useSetRecoilState(perInfoState);

    const [auth, setAuth] = useState(null);

    const login = (responseData) => {
        setAuth(responseData);
        // sessionStorage.setItem('token', responseData.data.token);

        localStorage.setItem('token', responseData.data.token);
        setIsLoggedIn(true);
        setUserInfo(responseData.data.user);

    };

    const logout = () => {
        setAuth(null);
        // sessionStorage.removeItem('authToken');
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setUserInfo(null);
    };

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
