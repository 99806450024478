// // import React, { useState } from 'react';
// // import {
// //     InputAdornment,
// //     InputLabel,
// //     Stack,
// //     TextField,
// //     Typography,
// //     Box
// // } from '@mui/material';
// // import styled from '@emotion/styled';
// // import ErrorIcon from '@mui/icons-material/Error';
// // import Visibility from '@mui/icons-material/Visibility';
// // import VisibilityOff from '@mui/icons-material/VisibilityOff';

// // const StyledTextField = styled(TextField)(({ theme }) => ({
// //     '& .Mui-disabled': {
// //         background: '#dddddd70',
// //     },
// //     '& input:disabled': {
// //         WebkitTextFillColor: 'black',
// //         cursor: 'not-allowed',
// //     },
// //     '& .MuiOutlinedInput-root': {
// //         borderWidth: '1px',
// //         borderColor: theme.palette.grey[400],
// //         '&:hover fieldset': {
// //             borderWidth: '1px',
// //             borderColor: theme.palette.primary.main,
// //         },
// //         '&.Mui-focused fieldset': {
// //             borderWidth: '1px',
// //             borderColor: theme.palette.primary.main,
// //         },
// //     },
// // }));

// // function CustomInputPassword({
// //     isViewMode,
// //     label,
// //     labelRightComponent,
// //     sicon, // prefix Icon
// //     description = null,
// //     onChange,
// //     placeholder,
// //     size = 'small',
// //     labelSize,
// //     helperText,
// //     error = null,
// //     disabled = false,
// //     readOnly = false,
// //     maxLength = 500,
// //     boxProps,
// //     required,
// //     marginBottom = 0,
// //     marginTop = 0,
// //     onNextLine,
// //     value,
// //     ...props
// // }) {
// //     const [showPassword, setShowPassword] = useState(false);

// //     const togglePasswordVisibility = () => {
// //         setShowPassword((prev) => !prev);
// //     };

// //     return (
// //         <Box {...boxProps} mb={marginBottom} mt={marginTop}>
// //             <Stack direction={onNextLine ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
// //                 <InputLabel style={{ fontSize: labelSize, color: '#000000', marginBottom: isViewMode ? 2 : 4 }}>
// //                     {label}
// //                     {required && !isViewMode && (
// //                         <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
// //                             *
// //                         </Typography>
// //                     )}
// //                 </InputLabel>
// //                 <InputLabel>{labelRightComponent}</InputLabel>
// //             </Stack>
// //             {description && <Typography>{description}</Typography>}
// //             {isViewMode ? (
// //                 <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
// //                     <Box sx={{ ml: 5 }}>{value}</Box>
// //                 </Typography>
// //             ) : (
// //                 <StyledTextField
// //                     {...props}
// //                     value={value}
// //                     onChange={onChange}
// //                     disabled={disabled}
// //                     type={showPassword ? 'text' : 'password'}
// //                     size={size}
// //                     fullWidth
// //                     placeholder={placeholder}
// //                     sx={{
// //                         backgroundColor: '#FBFCFF',
// //                     }}
// //                     InputProps={{
// //                         inputProps: {
// //                             maxLength,
// //                         },
// //                         readOnly,
// //                         endAdornment: (
// //                             <InputAdornment position="end">
// //                                 {showPassword ? (
// //                                     <VisibilityOff onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
// //                                 ) : (
// //                                     <Visibility onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
// //                                 )}
// //                             </InputAdornment>
// //                         ),
// //                         startAdornment: sicon ? (
// //                             <InputAdornment position="start">{sicon}</InputAdornment>
// //                         ) : null,
// //                     }}
// //                     error={!!error}
// //                 />
// //             )}
// //             {helperText && (
// //                 <Typography variant="body2" color="error" style={{ fontSize: 14, marginTop: '0.5rem' }}>
// //                     {helperText}
// //                 </Typography>
// //             )}
// //         </Box>
// //     );
// // }

// // export default CustomInputPassword;
// import React, { useState } from 'react';
// import {
//     InputAdornment,
//     InputLabel,
//     Stack,
//     TextField,
//     Typography,
//     Box,
//     Tooltip,
//     IconButton,
// } from '@mui/material';
// import styled from '@emotion/styled';
// import ErrorIcon from '@mui/icons-material/Error';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import InfoIcon from '@mui/icons-material/Info';

// const StyledTextField = styled(TextField)(({ theme }) => ({
//     '& .Mui-disabled': {
//         background: '#dddddd70',
//     },
//     '& input:disabled': {
//         WebkitTextFillColor: 'black',
//         cursor: 'not-allowed',
//     },
//     '& .MuiOutlinedInput-root': {
//         borderWidth: '1px',
//         borderColor: theme.palette.grey[400],
//         '&:hover fieldset': {
//             borderWidth: '1px',
//             borderColor: theme.palette.primary.main,
//         },
//         '&.Mui-focused fieldset': {
//             borderWidth: '1px',
//             borderColor: theme.palette.primary.main,
//         },
//     },
// }));

// function CustomInputPassword({
//     isViewMode,
//     label,
//     labelRightComponent,
//     sicon, // Prefix Icon
//     description = null,
//     onChange,
//     placeholder,
//     size = 'small',
//     labelSize,
//     helperText,
//     error = null,
//     disabled = false,
//     readOnly = false,
//     maxLength = 500,
//     boxProps,
//     required,
//     marginBottom = 0,
//     marginTop = 0,
//     onNextLine,
//     value,
//     infoTooltipText, // New prop for tooltip text
//     ...props
// }) {
//     const [showPassword, setShowPassword] = useState(false);

//     const togglePasswordVisibility = () => {
//         setShowPassword((prev) => !prev);
//     };

//     return (
//         <Box {...boxProps} mb={marginBottom} mt={marginTop}>
//             <Stack direction={onNextLine ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
//                 <Box display="flex" alignItems="center">
//                     <InputLabel
//                         style={{
//                             fontSize: labelSize,
//                             color: '#000000',
//                             marginBottom: isViewMode ? 2 : 4,
//                             marginRight: infoTooltipText ? 8 : 0,
//                         }}
//                     >
//                         {label}
//                         {required && !isViewMode && (
//                             <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
//                                 *
//                             </Typography>
//                         )}
//                         {infoTooltipText && (
//                             <Tooltip title={infoTooltipText} placement="top">
//                                 <IconButton>
//                                     <InfoIcon fontSize="small" />
//                                 </IconButton>
//                             </Tooltip>
//                         )}
//                     </InputLabel>

//                 </Box>
//                 <InputLabel>{labelRightComponent}</InputLabel>
//             </Stack>
//             {description && <Typography>{description}</Typography>}
//             {isViewMode ? (
//                 <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
//                     <Box sx={{ ml: 5 }}>{value}</Box>
//                 </Typography>
//             ) : (
//                 <StyledTextField
//                     {...props}
//                     value={value}
//                     onChange={onChange}
//                     disabled={disabled}
//                     type={showPassword ? 'text' : 'password'}
//                     size={size}
//                     fullWidth
//                     placeholder={placeholder}
//                     sx={{
//                         backgroundColor: '#FBFCFF',
//                     }}
//                     InputProps={{
//                         inputProps: {
//                             maxLength,
//                         },
//                         readOnly,
//                         endAdornment: (
//                             <InputAdornment position="end">
//                                 {showPassword ? (
//                                     <VisibilityOff onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
//                                 ) : (
//                                     <Visibility onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
//                                 )}
//                             </InputAdornment>
//                         ),
//                         startAdornment: sicon ? (
//                             <InputAdornment position="start">{sicon}</InputAdornment>
//                         ) : null,
//                     }}
//                     error={!!error}
//                 />
//             )}
//             {helperText && (
//                 <Typography variant="body2" color="error" style={{ fontSize: 14, marginTop: '0.5rem' }}>
//                     {helperText}
//                 </Typography>
//             )}
//         </Box>
//     );
// }

// export default CustomInputPassword;
import React, { useState } from 'react';
import {
    InputAdornment,
    InputLabel,
    Stack,
    TextField,
    Typography,
    Box,
    Tooltip,
    IconButton,
    List,
    ListItem,
} from '@mui/material';
import styled from '@emotion/styled';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InfoIcon from '@mui/icons-material/Info';
import CustomTooltip from './CustomTooltip';

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .Mui-disabled': {
        background: '#dddddd70',
    },
    '& input:disabled': {
        WebkitTextFillColor: 'black',
        cursor: 'not-allowed',
    },
    '& .MuiOutlinedInput-root': {
        borderWidth: '1px',
        borderColor: theme.palette.grey[400],
        '&:hover fieldset': {
            borderWidth: '1px',
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
            borderWidth: '1px',
            borderColor: theme.palette.primary.main,
        },
    },
}));

function CustomInputPassword({
    isViewMode,
    label,
    labelRightComponent,
    sicon, // Prefix Icon
    description = null,
    onChange,
    placeholder,
    size = 'small',
    labelSize,
    helperText,
    error = null,
    disabled = false,
    readOnly = false,
    maxLength = 500,
    boxProps,
    required,
    marginBottom = 0,
    marginTop = 0,
    onNextLine,
    value,
    infoTooltipContent, // Generic tooltip content
    ...props
}) {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    // Render tooltip content dynamically based on its type
    const renderTooltipContent = (content) => {
        if (Array.isArray(content)) {
            return (
                <Box>
                    <Typography>Your Password should be:</Typography>
                    <List>
                        {content.map((item, index) => (
                            <ListItem key={index} style={{ display: 'list-item', padding: 0, marginLeft: '1rem' }}>
                                <Typography variant="body2">{item}</Typography>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            );
        }
        return <Typography variant="body2">{content}</Typography>;
    };



    return (
        <Box {...boxProps} mb={marginBottom} mt={marginTop}>
            <Stack direction={onNextLine ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <InputLabel
                        style={{
                            fontSize: labelSize,
                            color: '#000000',
                            marginBottom: isViewMode ? 2 : 4,
                            marginRight: infoTooltipContent ? 8 : 0,
                        }}
                    >
                        {label}
                        {required && !isViewMode && (
                            <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
                                *
                            </Typography>
                        )}
                        {infoTooltipContent && (
                            <CustomTooltip
                                title={renderTooltipContent(infoTooltipContent)}
                                placement="top"
                                arrow
                            >
                                <IconButton>
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                            </CustomTooltip>
                        )}
                    </InputLabel>

                </Box>
                <InputLabel>{labelRightComponent}</InputLabel>
            </Stack>
            {description && <Typography>{description}</Typography>}
            {isViewMode ? (
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ ml: 5 }}>{value}</Box>
                </Typography>
            ) : (
                <StyledTextField
                    {...props}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    type={showPassword ? 'text' : 'password'}
                    size={size}
                    fullWidth
                    placeholder={placeholder}
                    sx={{
                        backgroundColor: '#FBFCFF',
                    }}
                    InputProps={{
                        inputProps: {
                            maxLength,
                        },
                        readOnly,
                        endAdornment: (
                            <InputAdornment position="end">
                                {showPassword ? (
                                    <VisibilityOff onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                                ) : (
                                    <Visibility onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} />
                                )}
                            </InputAdornment>
                        ),
                        startAdornment: sicon ? (
                            <InputAdornment position="start">{sicon}</InputAdornment>
                        ) : null,
                    }}
                    error={!!error}
                />
            )}
            {helperText && (
                <Typography variant="body2" color="error" style={{ fontSize: 14, marginTop: '0.5rem' }}>
                    {helperText}
                </Typography>
            )}
        </Box>
    );
}

export default CustomInputPassword;
