
import React, { useState } from 'react';
import { Typography, Box, TextField, Grid, Divider, Link, IconButton, Button, InputAdornment, useMediaQuery } from '@mui/material';
import CompanyLogo from '../../assets/CompanyLogo.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import { oneFranHubtheme } from "../../theme/theme";
import { OFMail2 } from '../../icons/CustomIcon';
import { useNavigate } from 'react-router-dom';

const footerTitleStyle = {
    opacity: 0.7,
    color: '#555555',
    fontSize: '1rem',
    fontWeight: 400,
    marginBottom: '1.5rem',
};

const footerContentStyle = {
    color: '#0D2659',
    fontSize: '1rem',
    fontWeight: 400,
    marginBottom: '0.75rem',
};

const dividerStyle = {
    borderTop: '1px solid #CCCCCC',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
};

const CustomFooter = () => {

    const navigate = useNavigate();
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    const [email, setEmail] = useState('');

    const handleSubmit = () => {
        alert('Under development', email);
    };

    return (

        <Box
            paddingBottom={2} paddingTop={10}
            sx={{
                // width: '100%',
                // paddingX: { xs: 1, sm: 2, md: 3 },
                // paddingTop: { xs: 1, sm: 2, md: 8 }

            }}
        >
            {/* Subscription Section */}
            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ paddingY: '0.75rem' }}>
                        <Typography
                            variant="TITLE1"
                            sx={{
                                color: '#0D2659',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textAlign: isMobile ? 'center' : 'left',
                                marginBottom: isMobile ? '1rem' : '0',
                                marginLeft: isMobile ? '1rem' : '19rem',
                                marginRight: isMobile ? '1rem' : '0',
                                padding: isMobile ? '0 1rem' : isTablet ? '0 1rem' : '0 3rem 0 5rem',
                            }}
                        >
                            Subscribe to Our Newsletter
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Box
                        sx={{
                            position: 'relative',
                            maxWidth: isMobile ? '90%' : isTablet ? '40%' : '83.33%',
                            display: 'flex',
                            p: '0.5rem',
                            backgroundColor: '#fff',
                            borderRadius: '100px',
                            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.20)',
                            border: '1.5px solid #505050',
                        }}
                    >
                        <TextField
                            type="text"
                            placeholder="Enter Your Email Id"
                            variant="outlined"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '100px',
                                    paddingRight: '8.5rem',
                                    height: '2.2rem',
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                                '& input': {
                                    paddingLeft: '0.5rem',
                                    height: '2.2rem',
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <OFMail2 sx={{ color: '#505050' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{
                                position: 'absolute',
                                right: '0.3rem',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                backgroundColor: '#0D2659',
                                borderColor: '#FFFFFF',
                                color: '#FFFFFF',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                width: '117px',
                                height: '2.8rem',
                                borderRadius: '50px',
                                '&:hover': {
                                    backgroundColor: '#0A1E47',
                                    borderColor: '#FFFFFF',
                                },
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {/* Main Footer Content */}
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={3.5}>
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <img src={CompanyLogo} alt="Company Logo" width="255px" height="54px" />
                        <Box>
                            <Typography sx={{ textAlign: 'left', opacity: 0.7, color: '#555555', fontSize: '1rem', fontWeight: 400, mt: 2 }}>
                                With lots of unique blocks, you can easily <br /> build a page without coding. Build your next <br /> landing page.
                            </Typography>
                        </Box>
                        <Typography sx={{ ...footerTitleStyle, mt: 2, mb: 0, textAlign: 'left' }}>Follow us</Typography>
                        <Box display="flex" alignItems="center" justifyContent="flex-start">
                            <IconButton component="a" href="https://www.facebook.com" target="_blank" color="primary" size="large">
                                <FacebookIcon />
                            </IconButton>
                            <IconButton component="a" href="https://twitter.com" target="_blank" color="info" size="large">
                                <TwitterIcon />
                            </IconButton>
                            <IconButton component="a" href="https://www.instagram.com" target="_blank" color="error" size="large">
                                <InstagramIcon />
                            </IconButton>
                            <IconButton component="a" href="https://www.linkedin.com" target="_blank" color="primary" size="large">
                                <LinkedInIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={1.5} sx={{ textAlign: 'left' }}>
                    <Typography variant="h6" sx={footerTitleStyle}>Company</Typography>
                    <Typography onClick={() => navigate('/about-us')} sx={footerContentStyle} style={{ cursor: 'pointer' }} >About us</Typography>
                    <Typography onClick={() => navigate('/contact-us')} sx={footerContentStyle} style={{ cursor: 'pointer' }} >Contact us</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }} >Sign Up/Login</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={2.1} sx={{ textAlign: 'left' }}>
                    <Typography variant="h6" sx={footerTitleStyle}>Product</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Franchise</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Partnership</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Dealerships and Distributors</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Sell my business</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={1.5} sx={{ textAlign: 'left' }}>
                    <Typography variant="h6" sx={footerTitleStyle}>Business</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Business Ideas</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Inventory</Typography>
                    <Typography onClick={() => navigate('/need-space')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Need a space</Typography>
                    <Typography onClick={() => navigate('/loan-support')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Loan on property</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={1.5} sx={{ textAlign: 'left' }}>
                    <Typography variant="h6" sx={footerTitleStyle}>Support</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Helpdesk</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Suggestions</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={1.5} sx={{ textAlign: 'left' }}>
                    <Typography variant="h6" sx={footerTitleStyle}>Knowledge</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Article</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Testimonials</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>FAQs</Typography>
                    <Typography onClick={() => navigate('/')} sx={footerContentStyle} style={{ cursor: 'pointer' }}>Terms of Service</Typography>
                </Grid>
            </Grid>

            <Divider sx={dividerStyle} />


            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
                    <Typography sx={footerContentStyle} style={{ cursor: 'pointer' }}>
                        All rights reserved © 2024 Infosocius LLP.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
                    <Typography sx={footerContentStyle} style={{ cursor: 'pointer' }}>
                        <Link href="/privacy-policy" underline="none" sx={{ color: 'inherit', mx: 2 }}>
                            Privacy Policy
                        </Link>
                        <Link href="/terms" underline="none" sx={{ color: 'inherit', mx: 2 }}>
                            Terms & Conditions
                        </Link>
                    </Typography>
                </Grid>
            </Grid>


        </Box>

    );
};

export default CustomFooter;
