
// import React from 'react';
// import { Box, Button, InputAdornment, Typography, OutlinedInput, useMediaQuery } from '@mui/material';
// import User from "../../assets/icons/User.svg";
// import Mail from "../../assets/icons/Mail.svg";
// import Call from "../../assets/icons/Call.svg";
// import { useTheme } from '@mui/material/styles';
// import CustomButton from '../../helpers/CustomButton';

// function FeedbackForm() {
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         alert('Thanks for contacting us!');
//     };

//     return (
//         <Box
//             component="form"
//             onSubmit={handleSubmit}
//             sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 gap: '1rem',
//                 width: '60%',
//                 maxWidth: '35rem',
//                 mx: 'auto',
//                 my: '1rem',
//                 padding: '3rem',
//                 border: '1px solid black',
//                 borderRadius: '1.563rem',
//                 position: 'relative',
//             }}
//         >
//             <Typography
//                 variant="h6"
//                 sx={{

//                 }}
//             >
//                 Send Us Your Questions and Feedback
//             </Typography>
//             <Typography
//                 sx={{
//                     fontFamily: 'Outfit, sans-serif',
//                     fontWeight: 400,
//                     fontSize: '1rem', // Approximately 16px
//                     lineHeight: '1.26rem', // Approximately 20.16px
//                     mt: '0.5rem',
//                     mb: '0.5rem',
//                 }}
//             >
//                 Lorem Ipsum is simply dummy text of the printing
//             </Typography>
//             <OutlinedInput
//                 startAdornment={
//                     <InputAdornment position="start">
//                         <img src={User} alt="User Icon" />
//                     </InputAdornment>
//                 }
//                 inputProps={{
//                     'aria-label': 'Name',
//                     placeholder: 'Name',
//                 }}
//                 sx={{ mb: '1rem' }}
//             />
//             <OutlinedInput
//                 startAdornment={
//                     <InputAdornment position="start">
//                         <img src={Mail} alt="Mail Icon" />
//                     </InputAdornment>
//                 }
//                 inputProps={{
//                     'aria-label': 'Email',
//                     placeholder: 'Email ID',
//                 }}
//                 sx={{ mb: '1rem' }}
//             />
//             <OutlinedInput
//                 startAdornment={
//                     <InputAdornment position="start">
//                         <img src={Call} alt="Call Icon" />
//                     </InputAdornment>
//                 }
//                 inputProps={{
//                     'aria-label': 'Contact No',
//                     placeholder: 'Contact No',
//                 }}
//                 sx={{ mb: '1rem' }}
//             />
//             <Box
//                 sx={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     // mt: '1rem',
//                     position: 'relative', // To keep the button within the form’s border
//                     top: isMobile ? '2rem' : '0', // Adjust button position based on screen size
//                 }}
//             >

//                 <CustomButton
//                     type="submit"
//                     width={{ xs: '75%', sm: '14rem' }}
//                     variant="contained"
//                     label="Submit"
//                 //onClick={handleSubmit(onSubmit)}
//                 />
//                 {/* <Button
//                     type="submit"
//                     variant="contained"
//                     sx={{
//                         backgroundColor: '#0D2659',
//                         color: '#FFFFFF',
//                         borderWidth: '2px',
//                         borderStyle: 'solid',
//                         width: '7.3125rem', // Approximately 117px
//                         height: '2.8rem',
//                         borderRadius: '3.125rem', // Approximately 50px
//                         '&:hover': {
//                             backgroundColor: '#0A1E47',
//                         },
//                     }}
//                 >
//                     Submit
//                 </Button> */}
//             </Box>
//         </Box>
//     );
// }

// export default FeedbackForm;

import React from 'react';
import { Box, Typography, OutlinedInput, InputAdornment, useMediaQuery } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../helpers/CustomButton';
import User from "../../assets/icons/User.svg";
import Mail from "../../assets/icons/Mail.svg";
import Call from "../../assets/icons/Call.svg";
import Interest from "../../assets/icons/Interest.svg";
import { API } from '../../axios/api-path';
import { postApiCall } from '../../axios/axios-methods';
import { toast, ToastContainer } from 'react-toastify';

function FeedbackForm() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { handleSubmit, control, formState: { errors }, reset } = useForm();


    const indianMobilePattern = /^[6-9]\d{9}$/;

    const onSubmit = async (data) => {
        const apiCallData = {
            name: data.name,
            emailId: data.email,
            contactNo: data.contactNo,
            interestFeedback: data.interest,
            type: "FEEDBACK"
        };

        const path = API.postContactOrFeedback();
        try {
            await postApiCall({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {
                    toast.success("Successfully Sent");
                    reset(); // Reset fields after successful submission
                },
                onError: (error) => {
                    console.error('Submission error:', error);
                },
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                width: '100%',
                maxWidth: '35rem',
                mx: 'auto',
                my: '1rem',
                padding: '3rem',
                border: '1px solid black',
                borderRadius: '1.563rem',
                position: 'relative',
            }}
        >
            <ToastContainer position="top-right" autoClose={5000} />
            <Typography
                variant="h6"
                sx={{

                }}
            >
                We’re Listening – Share Your Ideas/Suggestions
            </Typography>
            <Typography
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    fontSize: '1rem', // Approximately 16px
                    lineHeight: '1.26rem', // Approximately 20.16px
                    mt: '0.5rem',
                    mb: '0.5rem',
                }}
            >
                Questions, Ideas, or suggestions - We’re listening
                Simply fill in the form and we’ll be in touch with you shortly.
            </Typography>
            <Typography>Full Name</Typography>
            <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: 'Name is required' }}
                render={({ field }) => (
                    <OutlinedInput
                        {...field}
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={User} alt="User Icon" />
                            </InputAdornment>
                        }
                        placeholder="Name"
                        error={!!errors.name}
                    />
                )}
            />
            {errors.name && <Typography color="error">{errors.name.message}</Typography>}
            <Typography>Email Id</Typography>
            <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                    required: 'Email is required',
                    pattern: { value: /^\S+@\S+\.\S+$/, message: 'Invalid email format' }
                }}
                render={({ field }) => (
                    <OutlinedInput
                        {...field}
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={Mail} alt="Mail Icon" />
                            </InputAdornment>
                        }
                        placeholder="Email ID"
                        error={!!errors.email}
                    />
                )}
            />
            {errors.email && <Typography color="error">{errors.email.message}</Typography>}
            <Typography>Mobile No.</Typography>
            <Controller
                name="contactNo"
                control={control}
                defaultValue=""
                rules={{
                    required: 'Contact No is required',
                    pattern: {
                        value: indianMobilePattern,
                        message: 'Mobile number is not valid'
                    }
                }}
                render={({ field }) => (
                    <OutlinedInput
                        {...field}
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={Call} alt="Call Icon" />
                            </InputAdornment>
                        }
                        placeholder="Contact No"
                        error={!!errors.contactNo}
                    />
                )}
            />
            {errors.contactNo && <Typography color="error">{errors.contactNo.message}</Typography>}
            <Typography>Feedback/Suggestions</Typography>
            <Controller
                name="interest"
                control={control}
                defaultValue=""
                rules={{ required: 'Feedback/Suggestions is required' }}
                render={({ field }) => (
                    <OutlinedInput
                        {...field}
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={Interest} alt="Interest Icon" />
                            </InputAdornment>
                        }
                        placeholder="Enter your message"
                        error={!!errors.interest}
                    />
                )}
            />
            {errors.interest && <Typography color="error">{errors.interest.message}</Typography>}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                    top: isMobile ? '2rem' : '0',
                }}
            >
                <CustomButton
                    type="submit"
                    width={{ xs: '75%', sm: '14rem' }}
                    variant="contained"
                    label="Submit"
                />
            </Box>
        </Box>
    );
}

export default FeedbackForm;
