import { Container, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { oneFranHubtheme } from '../../theme/theme';
import { Box } from '@mui/system';
import AboutUsImg from "./../../assets/about-us.svg"

const AboutUs = () => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    return (
        <Box>
            <Container maxWidth="xl">

                <Box paddingY={2} >

                    <Grid container justifyContent="center" alignItems="center">


                        <Grid item xs={12} md={12}>
                            <Typography variant='h4' sx={{ color: '#002659', py: 2 }}>About us</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} paddingY={0}>

                            <Box sx={{ width: '100%' }}>

                                {/* <Typography variant='TITLE2' sx={{ color: '#002659' }} paragraph>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Typography>*/}
                                <Typography variant='Content' sx={{ color: '#666666', lineHeight: '30px' }} paragraph>Onefranhub.com is more than just a platform; it's a vibrant hub designed to connect businesses and investors in a mutually beneficial ecosystem. Our goal is to simplify the complex landscape of business development by providing a comprehensive suite of services. Whether you're looking to franchise your brand, explore dealership or distributorship opportunities, seek strategic partnerships, or buy or sell a business, we offer an all-inclusive solution tailored to meet your needs. Our mission is to be your go-to resource for every aspect of business growth, from loan support through our network of banks and NBFCs, to legal assistance and space solutions that facilitate expansion.</Typography>
                                <Typography variant='Content' sx={{ color: '#666666', lineHeight: '30px' }} paragraph>Built on a foundation of deep industry expertise, Onefranhub.com stands out as a leading force in the business solutions arena. Our platform is thoughtfully designed to provide business owners and investors with seamless access to valuable investment opportunities, strategic alliances, and the essential tools needed to elevate their ventures. Uniquely, we enable business owners to become investors themselves, fostering a dynamic environment where mutual growth and collaboration thrive.</Typography>



                            </Box>



                        </Grid>
                        <Grid item xs={12} md={6} paddingY={1} >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <img src={AboutUsImg} alt="About Us" style={{ width: '75%', height: 'auto' }} ></img>

                            </Box>


                        </Grid>
                        <Box paddingY={4}>
                            <Paper elevation={3} sx={{ p: 8, borderRadius: '18px' }}>
                                <Grid container spacing={2} alignItems="center">

                                    <Grid item xs={12} md={5} >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'left', // Centers content horizontally
                                                alignItems: 'center', // Centers content vertically
                                                paddingRight: 6

                                            }}
                                        >
                                            <img src={AboutUsImg} alt="About Us" style={{ width: '80%', height: 'auto' }} ></img>

                                        </Box>


                                    </Grid>
                                    <Grid item xs={12} md={7} >

                                        <Box sx={{ maxWidth: '100%' }}>

                                            {/* <Typography variant='TITLE2' sx={{ color: '#002659' }} paragraph>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Typography> */}
                                            <Typography variant='Content' sx={{ color: '#666666', lineHeight: '30px' }} paragraph>Our core values—trust, collaboration, and growth—are central to everything we do. We are committed to understanding and prioritizing the needs of both investors and business owners, ensuring that every interaction is grounded in genuine support and innovative solutions. Whether you're a startup taking your first steps or an established enterprise seeking new avenues for growth, our people-centric approach and forward-thinking strategies are designed to empower you at every stage of your journey.</Typography>
                                            <Typography variant='Content' sx={{ color: '#666666', lineHeight: '30px' }} paragraph>As we solidify our presence in India, we are excited to expand our reach to Asia and beyond, bringing our comprehensive platform to a global audience. At Onefranhub.com, we believe in transforming ideas into tangible success. Our services go beyond mere listings; we offer actionable solutions for securing financing, finding the right real estate, and navigating legal complexities. </Typography>
                                            <Typography variant='Content' sx={{ color: '#666666', lineHeight: '30px' }} paragraph>Join us in turning your business aspirations into reality, and experience how our platform can drive your success—together. </Typography>
                                        </Box>



                                    </Grid>
                                </Grid>
                            </Paper>

                        </Box>



                    </Grid>

                </Box>


            </Container>



        </Box>


    )
}

export default AboutUs