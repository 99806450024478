import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import TopFrCard from '../../components/card/TopFrCard';

const CardDisplay = ({ filteredItems }) => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {filteredItems.length > 0 ? (
                filteredItems.map(card => (

                    <TopFrCard
                        key={card.id}
                        entityCode={card.entityCode}
                        imageId={card.logo?.id}
                        category={card.industryName}
                        location={card.state}
                        description={card.businessName}
                        investmentRange={card.investmentRange}
                        profitMargin={card.profitMarginPerYear}
                        outlets={card.franchiseOutlets}
                        noOfCards={4}

                    />
                ))
            ) : (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 2 }}>
                    <Typography>No results found.</Typography>
                </Box>
            )}
        </Box>
    );
};

export default CardDisplay;
