
import React from 'react';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    TextField,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    Stack,
    InputLabel,
    Typography,
} from '@mui/material';
import styled from '@emotion/styled';

const StyledSelect = styled(Select)(({ theme }) => ({
    '.MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        paddingLeft: '16px',
        paddingRight: '40px', // Ensure space for the dropdown icon
    },
    '& .MuiOutlinedInput-root': {
        height: '56px', // Match the height with TextField
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    '& .MuiSelect-icon': {
        right: '12px', // Adjusted right position for better alignment
    },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        height: '56px', // Match the height with Select
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: 'none', // Remove the left border to connect with Select
    },
    '& fieldset': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
}));

const DropdownMenuItem = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',
}));

function CustomInputFee({
    label,
    labelRightComponent,
    sicon, // prefix Icon
    eicon, // postfix Icon
    description = null,
    options = [],
    selectedType = '',
    onTypeChange,
    value = '',
    onValueChange,
    placeholder = 'Enter value',
    helperText,
    error,
    required = false,
    disabled = false,
    isViewMode = false,
    onNextLine,
    labelSize,
    marginBottom,
    marginTop,
    boxProps
}) {
    // Determine the selected icon
    const selectedIcon = options.find(option => option.value === selectedType)?.icon;
    const suffix = selectedType === 'percentage' ? '%' : selectedType === 'fixedAmount' ? '' : '';

    return (
        <Box {...boxProps} mb={marginBottom} mt={marginTop}>
            <Stack direction={onNextLine ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
                <InputLabel style={{ fontSize: labelSize, color: '#000000', marginBottom: isViewMode ? 2 : 4 }}>
                    {isViewMode ? (
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'grey' }}>
                            {selectedIcon && <InputAdornment position="start">{selectedIcon}</InputAdornment>}
                            <Box sx={{ ml: selectedIcon ? 1 : 0 }}>{label}</Box>
                            {/* <Typography variant="body1" sx={{ ml: 1 }}>
                                {suffix}
                            </Typography> */}
                        </Typography>
                    ) : (
                        label
                    )}
                    {required && !isViewMode && (
                        <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
                            *
                        </Typography>
                    )}
                </InputLabel>
                <InputLabel>{labelRightComponent}</InputLabel>
            </Stack>
            {description && <Typography>{description}</Typography>}
            {isViewMode ? (
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* {selectedIcon && <InputAdornment position="start">{selectedIcon}</InputAdornment>} */}
                    <Box sx={{ ml: 5 }}>{value} {suffix}</Box>
                </Typography>
            ) : (
                <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row' }}>



                    <StyledSelect
                        value={selectedType}
                        onChange={(e) => onTypeChange(e.target.value)}
                        variant="outlined"
                        disabled={disabled || isViewMode}
                        displayEmpty
                        renderValue={(selected) => (
                            <Box sx={{ alignItems: 'center' }}>
                                {options.find(option => option.value === selected)?.icon}
                            </Box>
                        )}
                    >
                        {options.map(option => (
                            <DropdownMenuItem key={option.value} value={option.value}>
                                <ListItemIcon>
                                    {option.icon}
                                </ListItemIcon>
                                {/* <ListItemText primary={option.label} /> */}
                            </DropdownMenuItem>
                        ))}
                    </StyledSelect>

                    <Box>



                        <StyledTextField
                            value={value}
                            onChange={(e) => onValueChange(e.target.value)}
                            placeholder={placeholder}
                            error={error}
                            helperText={helperText}
                            disabled={disabled || isViewMode}
                            required={required}
                            variant="outlined"
                            InputProps={{
                                endAdornment: eicon ? <InputAdornment position="end">{eicon}</InputAdornment> : null,
                            }}

                            FormHelperTextProps={{
                                sx: { fontSize: '14px' },  // Increase font size for helper text
                            }}
                        />
                    </Box>
                </FormControl>
            )}
        </Box>
    );
}

export default CustomInputFee;
