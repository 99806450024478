import { Box, Grid, Paper } from '@mui/material'
import React from 'react'

const SecondAdvertisement = () => {
    const radius1 = '18px'
    return (
        <Grid item xs={12} md={3}>
            <Box mb={2}>


                <Paper elevation={3} style={{ height: 'auto', borderRadius: radius1, overflow: 'hidden' }}  >
                    <img
                        src="/images/Adv1.svg"
                        alt="advertisement"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />

                </Paper>
            </Box>
            <Box mb={2}>
                <Paper elevation={3} style={{ height: 'auto', overflowY: 'auto', borderRadius: radius1 }} mt={1} >
                    <img
                        src="/images/Adv3.svg"
                        alt="advertisement"
                    // style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />

                </Paper>
            </Box>

        </Grid>
    )
}

export default SecondAdvertisement