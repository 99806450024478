
import React from 'react';
import { Box, Typography, IconButton, Button, useMediaQuery, InputBase, Paper, Grid } from '@mui/material';
import LocationIcon from '@mui/icons-material/LocationOn';
import DropdownIcon from '@mui/icons-material/ArrowDropDown';
import CategoryIcon from '@mui/icons-material/Category';
import { oneFranHubtheme } from "../../theme/theme";
import CustomButton from '../../helpers/CustomButton';


const Banner = () => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    return (
        <Grid container maxWidth={'xl'} >
            <Grid item
                sx={{
                    boxSizing: 'border-box',
                    //    paddingX: isMobile ? 2 : isTablet ? 5 : 40,
                    // paddingY: isMobile ? 0 : 2,
                    // border: '1px solid green',
                }}
            >

                <Box
                    pt={isMobile ? 0 : 3}
                    pb={isMobile ? 0 : 10}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: isMobile ? 'center' : 'flex-start', // Center items on mobile

                    }}
                >
                    <Typography
                        variant={isMobile ? 'h6' : 'h1'}
                        sx={{


                            color: '#fff',
                            textAlign: isMobile ? 'center' : 'left',
                            lineHeight: 1.2,
                            marginBottom: '0.5rem',
                        }}
                    >
                        The power behind the type
                    </Typography>
                    <Typography
                        variant={isMobile ? 'h6' : 'h1'}
                        sx={{

                            color: '#fff',
                            textAlign: isMobile ? 'center' : 'left',
                            lineHeight: 1.2,
                            marginBottom: '2rem',
                        }}
                    >
                        of customer your brands
                    </Typography>
                    <Typography
                        sx={{
                            width: isMobile ? '90%' : '80%', // Adjust width for mobile
                            fontSize: '24px',
                            lineHeight: '40px',
                            color: '#fff',
                            textAlign: isMobile ? 'center' : 'left', // Center text on mobile
                            marginBottom: isMobile ? '2rem' : '5rem',
                        }}
                    >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                    </Typography>

                    <Box
                    // sx={{ paddingX: isMobile ? 2 : isTablet ? 5 : 10, marginTop: isMobile ? '1rem' : '0', paddingY: 4 }}
                    >
                        {isMobile ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    width: '100%',
                                    backgroundColor: '#fff',
                                    padding: '1rem',
                                    borderRadius: '10px',
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
                                    border: '1px solid #d9b3da',
                                }}
                            >
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        padding: '0.5rem',
                                        borderRadius: '5px',
                                        boxShadow: 'none',
                                        border: '1px solid #d9b3da',
                                    }}
                                >
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <LocationIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    </IconButton>
                                    <InputBase
                                        placeholder="Search by Location"
                                        sx={{ ml: 1, flex: 1 }}
                                    />
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <DropdownIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </IconButton>
                                </Paper>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        padding: '0.5rem',
                                        borderRadius: '5px',
                                        boxShadow: 'none',
                                        border: '1px solid #d9b3da',
                                    }}
                                >
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <CategoryIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    </IconButton>
                                    <InputBase
                                        placeholder="Industry"
                                        sx={{ ml: 1, flex: 1 }}
                                    />
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <DropdownIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </IconButton>
                                </Paper>
                                <CustomButton
                                    type="submit"
                                    width={{ xs: '75%', sm: '14rem' }}
                                    variant="contained"
                                    label="Submit"
                                //onClick={handleSubmit(onSubmit)}
                                />
                                {/* <Button
                                    variant="contained"
                                    sx={{
                                        width: '100%',
                                        height: '3.875rem',
                                        borderRadius: '37px',
                                        background: 'linear-gradient(90deg, #042f85, #0d51d9)',
                                        fontSize: '1.125rem',
                                        color: '#fff',
                                    }}
                                >
                                    Search
                                </Button> */}
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    width: isTablet ? '100%' : '100%',
                                    height: '4.5rem',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0.312rem 0.312rem 0.312rem 1.5rem',
                                    borderRadius: '100px',
                                    backgroundColor: '#fff',
                                    border: '1px solid #d9b3da',
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
                                    boxSizing: 'border-box',
                                    fontSize: '1rem',
                                    color: '#0d2659',
                                    fontFamily: 'Outfit',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '0.75rem',
                                    }}
                                >
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <LocationIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    </IconButton>
                                    <Typography variant="body2">Search by Location</Typography>
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <DropdownIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '0.75rem',
                                    }}
                                >
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <CategoryIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    </IconButton>
                                    <Typography variant="body2">Industry</Typography>
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <DropdownIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </IconButton>
                                </Box>
                                {/* <CustomButton
                                    type="submit"
                                    width={{ xs: '75%', sm: '14rem' }}
                                    variant="contained"
                                    label="Submit"
                                //onClick={handleSubmit(onSubmit)}
                                /> */}
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '10rem',
                                        height: '3.875rem',
                                        borderRadius: '37px',
                                        background: 'linear-gradient(90deg, #042f85, #0d51d9)',
                                        fontSize: '1.125rem',
                                        color: '#fff',
                                        position: 'relative',
                                    }}
                                >
                                    Search
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>

            </Grid >
        </Grid>
    );
};

export default Banner;
