import React from 'react'
import CardDisplay from './CardDisplay'
import { useMediaQuery } from '@mui/material';
import { oneFranHubtheme } from "../../theme/theme";
import { Box } from '@mui/system';

const CardContainer = ({ colour, title, description, noOfCards, defaultCard, cardData }) => {

    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    return (


        <CardDisplay
            title={title}
            description={description}
            defaultCard={defaultCard}
            noOfCards={noOfCards}
            cardData={cardData}
        />



    )
}

export default CardContainer