



// import React from 'react'
// import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
// import { oneFranHubtheme } from "../../theme/theme";
// import { Box } from '@mui/system';
// import Investor from "../../assets/Investor.svg"
// import checkmarkBadge from "../../assets/icons/checkmarkBadge.svg"
// import { bulletStyle } from '../../utils/UtilFunctions';


// const InvestorBanner = ({ }) => {

//     const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
//     const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
//     return (
//         <Box padding={2}>

//             <Container maxWidth={'xl'}>
//                 <Grid container>


//                     <Grid item xs={12} md={12} padding={1}>
//                         <Typography
//                             variant="h4"
//                             sx={{

//                                 lineHeight: '60.48px',
//                             }}
//                         >
//                             <b>Investors and Business Owners</b>
//                         </Typography>

//                     </Grid>
//                     <Grid item xs={12} md={8} padding={1}>
//                         <Typography
//                             variant='Content'
//                             sx={{

//                                 lineHeight: '30px',
//                                 color: '#666666',

//                             }}
//                         >
//                             Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
//                             the industry's standard dummy text ever since the 1500s
//                         </Typography>

//                     </Grid>



//                     <Grid item xs={12} md={6} padding={1} >
//                         <Box mt={2} mb={2} sx={{ borderRadius: '10px', overflow: 'hidden', position: 'relative', height: 'auto' }}>
//                             <img
//                                 src={Investor}
//                                 style={{
//                                     width: '100%',
//                                     height: 'auto%',
//                                     // objectFit: 'cover',
//                                     display: 'block'
//                                 }}
//                                 alt="Investor"
//                             />
//                         </Box>
//                     </Grid>
//                     <Grid item xs={12} md={6} padding={1} >

//                         <div style={{ display: 'flex', alignItems: 'flex-start' }}>
//                             <div>
//                                 <img src={checkmarkBadge} alt="Step Progress" style={{ marginBottom: 1, marginRight: 1, alignSelf: 'flex-start' }} />
//                             </div>
//                             <div style={{ display: 'flex', flexDirection: 'column' }}>
//                                 <Typography marginBottom={1} sx={{ color: '#690067' }} component="h1" fontWeight={400} fontSize={{ xs: "15px", md: "24px" }} lineHeight="20.83px" textAlign="justify">
//                                     Investor
//                                 </Typography>
//                                 <Typography variant="body1" mb={5} fontWeight={400} fontSize="14px" lineHeight="18.23px" textAlign="justify">
//                                     Find investors ready to support your business growth and expansion
//                                 </Typography>
//                             </div>
//                         </div>

//                         <div style={{ display: 'flex', alignItems: 'flex-start' }}>
//                             <div>
//                                 <img src={checkmarkBadge} alt="Step Progress" style={{ marginBottom: '4px', marginRight: '16px', alignSelf: 'flex-start' }} />
//                             </div>
//                             <div style={{ display: 'flex', flexDirection: 'column' }}>
//                                 <Typography marginBottom={1} sx={{ color: '#690067' }} component="h1" fontWeight={400} fontSize={{ xs: "15px", md: "24px" }} lineHeight="20.83px" textAlign="justify">
//                                     Business Owner
//                                 </Typography>
//                                 <Typography variant="body1" mb={5} fontWeight={400} fontSize="14px" lineHeight="18.23px" textAlign="justify">
//                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
//                                 </Typography>
//                             </div>
//                         </div>

//                         <Box
//                             display="flex"
//                             alignItems="flex-start"
//                             marginLeft={{ xs: 0, md: 5 }}
//                             maxWidth="100%" // Ensure it stays within the parent container's width
//                             overflow="hidden" // Prevents content overflow
//                         >
//                             <Box
//                                 display="grid"
//                                 gridTemplateColumns="1fr 1fr"
//                                 gap={1}
//                                 maxWidth="100%" // Keeps the grid inside the container
//                             >
//                                 <Typography variant="h6" sx={bulletStyle}>
//                                     Franchise
//                                 </Typography>
//                                 <Typography variant="h6" sx={bulletStyle}>
//                                     Dealership/Distributorship
//                                 </Typography>
//                                 <Typography variant="h6" sx={bulletStyle}>
//                                     Partnerships
//                                 </Typography>
//                                 <Typography variant="h6" sx={bulletStyle}>
//                                     Sell your business
//                                 </Typography>
//                             </Box>
//                         </Box>


//                     </Grid>




//                 </Grid>


//             </Container>
//         </Box >
//     )
// }

// export default InvestorBanner
import React from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { oneFranHubtheme } from '../../theme/theme';
import { Box } from '@mui/system';
import Investor from '../../assets/Investor.svg';
import checkmarkBadge from '../../assets/icons/checkmarkBadge.svg';
import { bulletStyle } from '../../utils/UtilFunctions';

const InvestorBanner = () => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    return (
        <Box padding={1}>

            <Grid container sx={{ padding: 0, margin: 0 }}>
                <Grid item xs={12} md={12} mb={2}>
                    <Typography
                        variant="h4"
                        sx={{
                            lineHeight: isMobile ? '60.48px' : '60.48px',

                            textAlign: isMobile ? 'center' : 'left',
                        }}
                    >
                        <b>Investors and Business Owners</b>
                    </Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                    <Typography
                        variant="Content"
                        sx={{
                            lineHeight: '30px',
                            color: '#666666',

                            textAlign: isMobile ? 'center' : 'left',
                        }}
                    >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box
                        mt={2}
                        mb={2}
                        sx={{
                            borderRadius: '10px',
                            overflow: 'hidden',
                            position: 'relative',
                            height: 'auto',
                        }}
                    >
                        <img
                            src={Investor}
                            style={{
                                width: '100%',
                                height: 'auto',
                                display: 'block',
                            }}
                            alt="Investor"
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} padding={2}>
                    {/* First Section */}
                    <Box display="flex" alignItems="flex-start" mb={2} pl={2}>
                        <img
                            src={checkmarkBadge}
                            alt="Step Progress"
                            style={{ marginBottom: '4px', marginRight: '8px', alignSelf: 'flex-start' }}
                        />
                        <Box display="flex" flexDirection="column" >
                            <Typography variant='h4'
                                sx={{
                                    color: '#690067',
                                    fontWeight: 400,
                                    fontSize: { xs: '15px', md: '24px' },
                                    lineHeight: '20.83px',
                                    textAlign: 'justify',
                                    mb: 1,
                                }}
                            >
                                Investor
                            </Typography>
                            <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="18.23px"
                                textAlign="justify"
                                mb={5}
                            >
                                Find investors ready to support your business growth and expansion
                            </Typography>
                        </Box>
                    </Box>

                    {/* Second Section */}
                    <Box display="flex" alignItems="flex-start" mb={2} pl={2}>
                        <img
                            src={checkmarkBadge}
                            alt="Step Progress"
                            style={{ marginBottom: '4px', marginRight: '8px', alignSelf: 'flex-start' }}
                        />
                        <Box display="flex" flexDirection="column">
                            <Typography
                                sx={{
                                    color: '#690067',
                                    fontWeight: 400,
                                    fontSize: { xs: '15px', md: '24px' },
                                    lineHeight: '20.83px',
                                    textAlign: 'justify',
                                    mb: 1,
                                }}
                            >
                                Business Owner
                            </Typography>
                            <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="18.23px"
                                textAlign="justify"
                                mb={5}
                            >
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                industry's standard dummy text ever since the 1500s.
                            </Typography>
                        </Box>
                    </Box>

                    {/* Bullet Points Section */}
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        maxWidth="100%"
                        overflow="hidden"
                        marginLeft={isTablet ? 0 : { xs: 0, md: 5 }}
                    >
                        <Box
                            display="grid"
                            gridTemplateColumns="1fr 1fr"
                            gap={1}
                            maxWidth="100%" // Keeps the grid inside the container
                        >
                            <Typography variant="TITLE3" sx={bulletStyle}>
                                Franchise
                            </Typography>
                            <Typography variant="TITLE3" sx={bulletStyle}>
                                Dealership/Distributorship
                            </Typography>
                            <Typography variant="TITLE3" sx={bulletStyle}>
                                Partnerships
                            </Typography>
                            <Typography variant="TITLE3" sx={bulletStyle}>
                                Sell your business
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    );
};

export default InvestorBanner;
