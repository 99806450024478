
const industryColors = {
    "Automotive": '#003366',
    "Beauty, Health & Wellness": '#FF69B4',
    "Building Construction": '#808080',
    "Business Services": '#87CEEB',
    "Education": '#FFA500',
    "Energy & Environment": '#008000',
    "Fast-Moving Consumer Goods (FMCG)": '#FF0000',
    "Fashion": '#800080',
    "Banking & Finance": '#FFD700',
    "Food & Beverage": '#8B4513',
    "Health": '#008080',
    "Industrial Machinery & Equipment": '#A9A9A9',
    "Leisure & Entertainment": '#FF00FF',
    "Retail": '#00FFFF',
    "Software & IT Services": '#1E90FF',
    "Travel & Tourism": '#40E0D0',
    default: 'gray',
};

export default industryColors;
