
import React, { useRef, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, InputAdornment, IconButton, Typography, Box, Stack, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { OFImageIcon, OFUploadIcon } from '../icons/CustomIcon';

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.grey[400],
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
        },
    },
}));

function CustomFileUpload({
    label,
    labelRightComponent,
    description = null,
    size = 'small',
    accept = '*/*',
    iconStart = true,
    iconEnd = true,
    placeholder = 'Choose file',
    onChange,
    control,
    name,
    resetFile,
    isViewMode = false,
    externalPreviewUrl,
    required,
    ...props
}) {
    const [fileName, setFileName] = useState('');
    const inputRef = useRef(null);

    const handleFileChange = (event, onChange) => {
        const file = event.target.files[0];
        if (file) {
            onChange(file);
            setFileName(file.name);
        }
    };

    const handleClearFile = () => {
        setFileName('');
        if (inputRef.current) {
            inputRef.current.value = ''; // Reset the file input
        }
    };

    // Reset file input if resetFile prop changes
    useEffect(() => {
        if (resetFile) {
            handleClearFile();
        }
    }, [resetFile]);

    if (isViewMode) {
        return (
            <Box {...props}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <InputLabel style={{ fontSize: size, color: '#000000', marginBottom: '0.5rem' }}>
                        {label}
                    </InputLabel>
                    {required && !isViewMode && (
                        <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
                            *
                        </Typography>
                    )}
                    <InputLabel>{labelRightComponent}</InputLabel>
                </Stack>
                {description && <Typography>{description}</Typography>}
                {externalPreviewUrl && (
                    <Box mt={2}>
                        <img
                            src={externalPreviewUrl}
                            alt="Preview"
                            style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }}
                        />
                    </Box>
                )}
            </Box>
        );
    }

    return (
        <Box {...props}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <InputLabel style={{ fontSize: size, color: '#000000', marginBottom: '0.5rem' }}>
                    {label}
                </InputLabel>
                <InputLabel>{labelRightComponent}</InputLabel>
            </Stack>
            {description && <Typography>{description}</Typography>}
            <Controller
                name={name}
                control={control}
                defaultValue=""
                render={({ field: { onChange, ...field } }) => (
                    <StyledTextField
                        {...field}
                        {...props}
                        value={fileName}
                        placeholder={placeholder}
                        fullWidth
                        InputProps={{
                            startAdornment: iconStart && (
                                <InputAdornment position="start">
                                    <IconButton component="span">
                                        <OFImageIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: iconEnd && (
                                <InputAdornment position="end">
                                    <>
                                        <input
                                            ref={inputRef}
                                            type="file"
                                            accept={accept}
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileChange(e, onChange)}
                                        />
                                        <IconButton
                                            component="label"
                                            onClick={() => inputRef.current && inputRef.current.click()}
                                        >
                                            <OFUploadIcon />
                                        </IconButton>
                                        {fileName && (
                                            <IconButton
                                                onClick={handleClearFile}
                                                color="error"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                    </>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
        </Box>
    );
}

export default CustomFileUpload;
