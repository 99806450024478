

import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneFranHubtheme } from '../../../theme/theme';
import { API } from '../../../axios/api-path';
import CustomButton from '../../../helpers/CustomButton';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError, toastSuccess } from '../../../utils/toast-notify';
import { fetchBusinessProfile, fetchCities, fetchCountries, fetchFormInfo, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';

import { booleanValidationNormal, cityValidation, countryValidation, emailValidation, numericValidation, phoneValidation, pincodeValidation, stateValidation, textValidation } from '../../../utils/validations';
import DealerTrainingAndOperationForm from './DealerTrainingAndOperationForm';
import { dealerRegistrationState } from '../../../recoil/profile-atom';
import { useRecoilState } from 'recoil';

const DealerTrainingAndOperation = ({ isDashboardMode }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState(null);
    const [isFormData, setIsFormData] = useState(false);
    // const [outletStates, setOutletStates] = useState([])

    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);
    const [dealerState, setDealerRegistrationState] = useRecoilState(dealerRegistrationState);

    useEffect(() => {
        fetchCountries(setCountries);

        const fetchData = async () => {
            const path = API.getDealerInfo();

            try {
                const response = await fetchFormInfo(path);

                if (response.data.trainingStatus === 'COMPLETE') {
                    setFormData(response.data.training);

                    setIsFormData(true);
                } else {
                    setIsFormData(false);
                }
                setDealerRegistrationState((prevState) => ({
                    //   ...prevState,
                    businessInfoStatus: response.data.businessInfoStatus,
                    marketingStatus: response.data.marketingStatus,
                    trainingStatus: response.data.trainingStatus,
                    appearanceStatus: response.data.appearanceStatus,
                }));
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);



    const validationSchema = yup.object({



        "initialTrainingDays": numericValidation,
        // "locationOfTraining": formData?.locationOfTraining,
        "ongoingTraining": booleanValidationNormal('ongoingTraining'),
        "customerSupport": booleanValidationNormal('customerSupport'),
        "operationalSupport": booleanValidationNormal('operationalSupport'),
        "logisticSupport": booleanValidationNormal('logisticSupport'),
        "stockManagementGuidance": booleanValidationNormal('stockManagementGuidance'),
        "orderFulfillmentProcess": textValidation({ message: 'Rrequired' }),
        "reportingRequirements": textValidation({ message: 'Rrequired' }),
        "contract": textValidation({ message: 'Rrequired' }),
        "licensesPermits": textValidation({ message: 'Rrequired' }),
        "compliance": textValidation({ message: 'Rrequired' }),

    });




    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue, getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {




        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        country,
        city,
        state,
        loanSupportRequired,
    }
        = watch({ control });




    useEffect(() => {

        if (country) {
            fetchStates(setStates, country.id);

        }
    }, [country, setValue]);
    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);



    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);


    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');



    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);



    useEffect(() => {

        if (selectedCountry) {

            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);



    function extractParts(ownerMobileNo) {
        if (ownerMobileNo != null) {

            const [countryCode, phoneNumber] = ownerMobileNo.split('-');


            return {
                countryCode: countryCode || null,
                phoneNumber: phoneNumber || null
            };
        } else {

            return {
                countryCode: null,
                phoneNumber: null
            };
        }
    }


    useEffect(() => {
        if (formData) {

            reset({



                "initialTrainingDays": formData?.initialTrainingDays,
                "locationOfTraining": formData?.locationOfTraining,
                "ongoingTraining": formData?.ongoingTraining,
                "customerSupport": formData?.customerSupport,
                "operationalSupport": formData?.operationalSupport,
                "logisticSupport": formData?.logisticSupport,
                "stockManagementGuidance": formData?.stockManagementGuidance,
                "orderFulfillmentProcess": formData?.orderFulfillmentProcess,
                "reportingRequirements": formData?.reportingRequirements,
                "contract": formData?.contract,
                "licensesPermits": formData?.licensesPermits,
                "compliance": formData?.compliance
















            });
        }
    }, [formData, reset]);

    const onSubmit = async (data) => {

        const apiCallData = {



            "initialTrainingDays": data?.initialTrainingDays,
            "locationOfTraining": data?.locationOfTraining,
            "ongoingTraining": data?.ongoingTraining,
            "customerSupport": data?.customerSupport,
            "operationalSupport": data?.operationalSupport,
            "logisticSupport": data?.logisticSupport,
            "stockManagementGuidance": data?.stockManagementGuidance,
            "orderFulfillmentProcess": data?.orderFulfillmentProcess,
            "reportingRequirements": data?.reportingRequirements,
            "contract": data?.contract,
            "licensesPermits": data?.licensesPermits,
            "compliance": data?.compliance












        };


        const path = API.signupDealerTrainingInfo();
        try {

            console.log(" Submit data " + JSON.stringify(apiCallData, null, 2))
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {

                    if (isDashboardMode) {
                        setIsEditing(false)

                    }
                    else {
                        handleNext();


                    }



                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '5'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '3'));
    };


    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <DealerTrainingAndOperationForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

                    countries={countries}
                    cities={cities}
                    states={states}

                    setSelectedCity={setSelectedCity}
                    setSelectedCountry={setSelectedCountry}


                    setSelectedState={setSelectedState}
                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}


                />


                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}



            </Paper>

        </>
    )
}

export default DealerTrainingAndOperation