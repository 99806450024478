
import {
    api,
    apiDownloadFile,
    apiFileUpload,
    //apiWithCred
} from "./axios-configuration";


export const postApiCall = async ({ path, data, onSuccess, onError }) => {
    try {
        const response = await api.post(path, data); // Use the `api` instance
        if (onSuccess) onSuccess(response.data);
        return response.data;
    } catch (error) {
        if (onError) onError(error);
        throw error; // Rethrow the error for component-level handling
    }
};


export const postApiCallWithCred = async ({ path, data, onSuccess, onError }) => {
    try {
        const response = await api.post(path, data); // Use the `api` instance
        if (onSuccess) onSuccess(response.data);
        return response.data;
    } catch (error) {
        if (onError) onError(error);
        throw error; // Rethrow the error for component-level handling
    }
};

export const postApiFileUpload = async ({ path, data, onSuccess, onError }) => {
    try {
        const response = await apiFileUpload.post(path, data); // Use the `api` instance
        if (onSuccess) onSuccess(response.data);
        return response.data;
    } catch (error) {
        if (onError) onError(error);
        throw error; // Rethrow the error for component-level handling
    }
};


// export const getApiCall = async ({ path, onSuccess, onError }) => {
//     try {
//         const response = await api.get(path);
//         if (onSuccess) onSuccess(response.data);
//         return response.data;
//     } catch (error) {
//         if (onError) onError(error);
//         throw error; // Rethrow the error for component-level handling
//     }
// };

export const getApiCall = async ({ path, params = {}, onSuccess, onError }) => {
    try {
        const response = await api.get(path, { params });
        if (onSuccess) onSuccess(response.data);
        return response.data;
    } catch (error) {
        if (onError) onError(error);
        throw error; // Rethrow the error for component-level handling
    }
};


export const getApiDownloadFile = async (fileUrl, onSuccess, onError) => {
    try {
        const response = await apiDownloadFile.get(fileUrl, {
            responseType: 'blob', // Important for handling binary data
        });


        const fileUrlBlob = URL.createObjectURL(response.data);


        if (onSuccess) onSuccess(fileUrlBlob);
        return fileUrlBlob;
    } catch (error) {
        console.error('Error downloading the file:', error);
        if (onError) onError(error);
        throw error;
    }
};