

// import React, { useState, useEffect, useContext } from 'react';
// import {
//     AppBar as MuiAppBar, Grid, Toolbar, IconButton, Button, Box, ListItemIcon, styled, Menu, MenuItem, useMediaQuery, DialogActions, Dialog, Avatar, Typography,
//     AppBar
// } from '@mui/material';
// import CompanyLogo from '../../assets/CompanyLogoHeader.svg';
// import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
// import { oneFranHubtheme } from "../../theme/theme";
// import DialogContent from '@mui/material/DialogContent';
// import CloseIcon from '@mui/icons-material/Close';
// import CloseButton from "../../assets/icons/CloseButton.svg";
// import { useNavigate } from 'react-router-dom';
// import Popup from './Popup';
// import LoginForm from '../../pages/signin/LoginForm';
// import { AuthContext } from '../../context/AuthContext';
// import { isLoggedInState, perInfoState } from '../../recoil/profile-atom';
// import { useRecoilState } from 'recoil';
// import { toProper } from '../../utils/UtilFunctions';
// import SettingsIcon from '@mui/icons-material/Settings';
// import { OFDashboardIcon, OFHeartIcon, OFLogoutIcon, OFMyProfileIcon } from '../../icons/CustomIcon';
// import { useTheme } from '@emotion/react';

// // const AppBar = styled(MuiAppBar)(({ theme }) => ({
// //     backgroundColor: '#FFFFFF',
// //     boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
// // }));

// const NavButton = styled(Button)(({ theme }) => ({
//     textTransform: 'none',
//     fontSize: '18px',
//     fontWeight: 400,
//     color: 'white',
//     lineHeight: '22.68px',

//     '&.active': {
//         color: theme.palette.primary.main,
//     },
// }));

// const OFNavbar = () => {
//     const { logout } = useContext(AuthContext);
//     const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
//     const navigate = useNavigate();
//     const [userInfo] = useRecoilState(perInfoState);
//     const [open, setOpen] = useState(false);
//     const [anchorNav, setAnchorNav] = useState(null);
//     const [activeMenu, setActiveMenu] = useState('home');
//     const [openPopup, setOpenPopup] = useState(false);
//     const [anchorEl, setAnchorEl] = useState(null);

//     const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
//     const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));

//     useEffect(() => {
//         const storedActiveMenu = localStorage.getItem('activeMenu');
//         if (storedActiveMenu) {
//             setActiveMenu(storedActiveMenu);
//         }
//     }, []);

//     const openMenu = (event) => {
//         setAnchorNav(event.currentTarget);
//     };

//     const closeMenu = () => {
//         setAnchorNav(null);
//     };

//     const handleNavigate = (section) => {
//         setActiveMenu(section);
//         localStorage.setItem('activeMenu', section);
//         closeMenu();
//     };

//     const handleLogin = () => {
//         setOpen(true);
//         closeMenu();
//     };

//     const handleLogout = () => {
//         logout();
//         navigate('/');
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const handleOpenPopup = () => {
//         setOpenPopup(true);
//         closeMenu();
//     };

//     const handleClosePopup = () => {
//         setOpenPopup(false);
//     };

//     const handleMenuOpen = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleMenuClose = () => {
//         setAnchorEl(null);
//     };

//     const handleMenuClick = (path) => {
//         handleMenuClose();
//         navigate(path);
//     };

//     const ResponsiveNavButton = ({ children, ...props }) => (
//         <NavButton {...props}>
//             <Typography variant="body1" sx={{
//                 fontSize: '18px'
//             }}>
//                 {children}
//             </Typography>
//         </NavButton>
//     );


//     return (

//         <>

//             <AppBar position="static" sx={{ backgroundColor: '#1976d2' }}>
//                 <Toolbar>
//                     <Grid container alignItems="center" spacing={0} sx={{ border: '1px solid white' }}>
//                         {/* Left Section - Hidden on Mobile */}
//                         <Grid item xs={12} sm={10} md={1.5} lg={1.5} sx={{ display: { xs: 'none', md: 'block' } }}>
//                             <Typography variant="body1" sx={{ color: '#fff', padding: 0 }}>
//                                 Hello
//                             </Typography>
//                         </Grid>

//                         {/* Central Section */}
//                         <Grid item xs={12} sm={10} md={9} lg={9} sx={{ border: '1px solid white', p: 1 }}>
//                             <Box
//                                 sx={{
//                                     display: 'flex',
//                                     flexWrap: 'wrap',
//                                     justifyContent: isMobile ? 'center' : 'space-between',
//                                     alignItems: 'center',
//                                     color: '#fff',
//                                     gap: 1, // Adjust the spacing between items
//                                 }}
//                             >
//                                 {/* Menu Icon for Mobile */}
//                                 <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
//                                     <IconButton size="large" edge="end" onClick={openMenu} sx={{ color: 'white' }}>
//                                         <MenuIcon />
//                                     </IconButton>
//                                 </Box>

//                                 {/* Logo */}
//                                 <IconButton
//                                     edge="start"
//                                     color="inherit"
//                                     aria-label="logo"
//                                     onClick={() => navigate('/')}
//                                 >
//                                     <img src={CompanyLogo} alt="Company Logo" style={{ height: '2.5rem', width: 'auto' }} />
//                                 </IconButton>

//                                 {/* Menu Items for Desktop */}
//                                 <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2, flexGrow: 1, justifyContent: 'space-between' }}>
//                                     <NavButton
//                                         href="#home"
//                                         className={activeMenu === 'home' ? 'active' : ''}
//                                         onClick={() => handleNavigate('home')}
//                                     >
//                                         Home
//                                     </NavButton>
//                                     <NavButton
//                                         href="#products"
//                                         className={activeMenu === 'products' ? 'active' : ''}
//                                         onClick={() => handleNavigate('products')}
//                                     >
//                                         Products
//                                     </NavButton>
//                                     <NavButton
//                                         href="#need-space"
//                                         className={activeMenu === 'need-space' ? 'active' : ''}
//                                         onClick={() => handleNavigate('need-space')}
//                                     >
//                                         Need a Space
//                                     </NavButton>
//                                     <NavButton
//                                         href="#loan-property"
//                                         className={activeMenu === 'loan-property' ? 'active' : ''}
//                                         onClick={() => handleNavigate('loan-property')}
//                                     >
//                                         Loan on Property
//                                     </NavButton>
//                                     <NavButton
//                                         href="#about-us"
//                                         className={activeMenu === 'about-us' ? 'active' : ''}
//                                         onClick={() => handleNavigate('about-us')}
//                                     >
//                                         About Us
//                                     </NavButton>
//                                     <NavButton
//                                         href="#contact-us"
//                                         className={activeMenu === 'contact-us' ? 'active' : ''}
//                                         onClick={() => handleNavigate('contact-us')}
//                                     >
//                                         Contact Us
//                                     </NavButton>

//                                     <NavButton href="#search">
//                                         <SearchIcon />
//                                     </NavButton>
//                                     <NavButton onClick={() => isLoggedIn ? handleLogout() : handleLogin()}>
//                                         {isLoggedIn ? 'Logout' : 'Login'}
//                                     </NavButton>
//                                     {!isLoggedIn && (
//                                         <NavButton onClick={() => handleOpenPopup()}>
//                                             Register
//                                         </NavButton>
//                                     )}
//                                     {isLoggedIn && (
//                                         <Box display="flex" alignItems="center" gap={1} onClick={handleMenuOpen} sx={{ cursor: 'pointer' }}>
//                                             <IconButton color="inherit">
//                                                 <Avatar alt="User Avatar" src="/images/avatar1.png" />
//                                             </IconButton>
//                                             <Box display="flex" flexDirection="column" alignItems="flex-start">
//                                                 <Typography variant="body1" sx={{ fontSize: '18px', color: 'white' }}>
//                                                     {`${userInfo.firstName} ${userInfo.lastName}`}
//                                                 </Typography>
//                                                 <Typography variant="body2" sx={{ fontSize: '14px', color: 'blue' }}>
//                                                     {userInfo.roles[0]}
//                                                 </Typography>
//                                             </Box>
//                                         </Box>
//                                     )}
//                                 </Box>

//                                 {/* Menu for Mobile */}
//                                 <Menu
//                                     anchorEl={anchorNav}
//                                     open={Boolean(anchorNav)}
//                                     onClose={closeMenu}
//                                     PaperProps={{
//                                         sx: {
//                                             marginTop: '10px',
//                                             maxHeight: 'calc(100vh - 64px)', // Adjust based on the header height
//                                         },
//                                     }}
//                                 >
//                                     <MenuItem onClick={() => handleNavigate('home')}>Home</MenuItem>
//                                     <MenuItem onClick={() => handleNavigate('products')}>Products</MenuItem>
//                                     <MenuItem onClick={() => handleNavigate('need-space')}>Need a Space</MenuItem>
//                                     <MenuItem onClick={() => handleNavigate('loan-property')}>Loan on Property</MenuItem>
//                                     <MenuItem onClick={() => handleNavigate('about-us')}>About Us</MenuItem>
//                                     <MenuItem onClick={() => handleNavigate('contact-us')}>Contact Us</MenuItem>
//                                     <MenuItem onClick={isLoggedIn ? handleLogout : handleLogin}>
//                                         {isLoggedIn ? 'Logout' : 'Login'}
//                                     </MenuItem>
//                                     {!isLoggedIn && (
//                                         <MenuItem onClick={handleOpenPopup}>
//                                             Signup
//                                         </MenuItem>
//                                     )}
//                                     {isLoggedIn && (
//                                         <Box
//                                             display="flex"
//                                             alignItems="center"
//                                             gap={1}
//                                             onClick={handleMenuOpen}
//                                             sx={{ cursor: 'pointer' }}
//                                         >
//                                             <IconButton color="inherit">
//                                                 <Avatar alt="User Avatar" src="/images/avatar1.png" />
//                                             </IconButton>
//                                             <Box display="flex" flexDirection="column" alignItems="flex-start">
//                                                 <Typography variant="body1" sx={{ fontSize: '18px', color: 'white' }}>
//                                                     {`${userInfo.firstName} ${userInfo.lastName}`}
//                                                 </Typography>
//                                                 <Typography variant="body2" sx={{ fontSize: '14px', color: 'blue' }}>
//                                                     {userInfo.roles[0]}
//                                                 </Typography>
//                                             </Box>
//                                         </Box>
//                                     )}
//                                 </Menu>
//                             </Box>
//                         </Grid>

//                         {/* Right Section - Hidden on Mobile */}
//                         <Grid item xs={12} sm={10} md={1.5} lg={1.5} sx={{ display: { xs: 'none', md: 'block' }, border: '1px solid white' }}>
//                             <Typography variant="body1" sx={{ color: '#fff', textAlign: 'right', padding: 0 }}>
//                                 World
//                             </Typography>
//                         </Grid>
//                     </Grid>
//                 </Toolbar>
//             </AppBar>

//             <Dialog
//                 open={open}
//                 onClose={handleClose}
//                 PaperProps={{
//                     sx: {
//                         borderRadius: 2,
//                         background: 'transparent',
//                         boxShadow: 'none',
//                         width: { xs: '90vw', sm: '60vw', md: '40vw', lg: '30vw' },
//                     },
//                 }}
//             >
//                 <Box display="flex" justifyContent="center" alignItems="center" position="relative" p={1}>
//                     <IconButton
//                         onClick={handleClose}
//                         sx={{
//                             position: 'absolute',
//                             right: 0,
//                             top: 0,
//                             zIndex: 1,
//                         }}
//                     >
//                         <CloseIcon />
//                     </IconButton>
//                 </Box>

//                 <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
//                     <Button onClick={handleClose} color="primary" sx={{ padding: 0 }}>
//                         <img src={CloseButton} width="80px" height="auto" alt="Close" />
//                     </Button>
//                 </DialogActions>

//                 <DialogContent sx={{ background: 'white', borderRadius: 5, padding: 2 }}>
//                     <LoginForm onSuccess={handleClose} />
//                 </DialogContent>
//             </Dialog>

//             {openPopup && <Popup handleClose={handleClosePopup} />}

//         </>








//     )
// }

// export default OFNavbar

import React, { useState, useEffect, useContext } from 'react';
import {
    AppBar, Grid, Toolbar, Stack, IconButton, Button, Box, ListItemIcon, Menu, MenuItem, useMediaQuery, DialogActions, Dialog, Avatar, Typography,
    Container,
    makeStyles
} from '@mui/material';
import CompanyLogo from '../../assets/CompanyLogoHeader.svg';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { oneFranHubtheme } from "../../theme/theme";
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import CloseButton from "../../assets/icons/CloseButton.svg";
import { Link, useNavigate } from 'react-router-dom';
import Popup from './Popup';
import LoginForm from '../../pages/signin/LoginForm';
import { AuthContext } from '../../context/AuthContext';
import { isLoggedInState, perInfoState } from '../../recoil/profile-atom';
import { useRecoilState } from 'recoil';
import { toProper } from '../../utils/UtilFunctions';
import SettingsIcon from '@mui/icons-material/Settings';
import { OFCompany, OFCompanyLogo, OFDashboardIcon, OFHeartIcon, OFLogoutIcon, OFMyProfileIcon } from '../../icons/CustomIcon';
import { styled } from '@mui/system';

// Define styled components
const StyledTypography = styled(Typography)({
    color: '#222222',
});

const LogoAll = styled(Box)({
    alignItems: 'center',
    textDecoration: 'none',
});

const MenuIconStyled = styled(MenuIcon)({
    color: '#FFFFFF',
});

const MenuItemTypography = styled(Typography)({
    color: '#FFFFFF',
});



const OFNavbar = () => {

    const { logout } = useContext(AuthContext);
    const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
    const navigate = useNavigate();
    const [userInfo] = useRecoilState(perInfoState);
    const [open, setOpen] = useState(false);
    const [anchorNav, setAnchorNav] = useState(null);
    const [activeMenu, setActiveMenu] = useState('home');
    const [openPopup, setOpenPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));

    useEffect(() => {
        const storedActiveMenu = localStorage.getItem('activeMenu');
        if (storedActiveMenu) {
            setActiveMenu(storedActiveMenu);
        }
    }, []);

    const openMenu = (event) => {
        setAnchorNav(event.currentTarget);
    };

    const [openContactModal, setContactModalOpen] = useState(false);
    const handleContactModalOpen = () => {
        setContactModalOpen(true);
    };

    const handleContactModalClose = () => {
        setContactModalOpen(false);
    };

    const closeMenu = () => {
        setAnchorNav(null);
    };

    const handleNavigate = (section) => {
        setActiveMenu(section);
        localStorage.setItem('activeMenu', section);
        closeMenu();
    };

    const handleLogin = () => {
        setOpen(true);
        closeMenu();
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenPopup = () => {
        setOpenPopup(true);
        closeMenu();
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (path) => {
        handleMenuClose();
        navigate(path);
    };

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    //const classes = useStyles();
    return (
        <>


            <AppBar position="static" sx={{ backgroundColor: 'rgba(13, 38, 89, 3)', fontcolor: 'white', py: 2 }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <LogoAll
                            component="a"
                            href="/"
                        >
                            <img src={CompanyLogo} width="75%" alt="Company Logo" />

                        </LogoAll>



                        {/* Desktop Menu */}
                        <Box
                            sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}
                        >
                            <Button></Button>

                            <MenuItem component={Link} to="#product">
                                <MenuItemTypography>Home</MenuItemTypography>
                            </MenuItem>
                            <MenuItem component={Link} to="#about-us">
                                <MenuItemTypography>Products</MenuItemTypography>
                            </MenuItem>
                            <MenuItem component={Link} to="/signin">
                                <MenuItemTypography>Need a space</MenuItemTypography>
                            </MenuItem>
                            <MenuItem component={Link} to="/signin">
                                <MenuItemTypography>Loan on property</MenuItemTypography>
                            </MenuItem>
                            <MenuItem component={Link} to="/signin">
                                <MenuItemTypography>About us</MenuItemTypography>
                            </MenuItem>
                            <MenuItem component={Link} to="/signin" className={activeMenu === 'contact-us' ? 'active' : ''}>
                                <MenuItemTypography>Contact us</MenuItemTypography>
                            </MenuItem>
                            <MenuItem component={Link} to="/signin">
                                <SearchIcon />
                            </MenuItem>
                            {!isLoggedIn && (
                                <MenuItem
                                    button
                                    onClick={() => isLoggedIn ? handleLogout() : handleLogin()}
                                >
                                    <MenuItemTypography>Login</MenuItemTypography>
                                </MenuItem>
                            )}
                            {!isLoggedIn && (
                                <MenuItem
                                    button
                                    onClick={() => handleOpenPopup()}
                                >
                                    <MenuItemTypography>Register</MenuItemTypography>
                                </MenuItem>
                            )}

                            <Stack direction="row" spacing={2} ml={1}></Stack>
                            {isLoggedIn && (
                                <Box display="flex" alignItems="center" gap={1} onClick={handleMenuOpen} sx={{ cursor: 'pointer' }}>
                                    <IconButton color="inherit">
                                        <Avatar alt="User Avatar" src="/images/avatar1.png" />
                                    </IconButton>
                                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                                        <Typography variant="body1" sx={{ fontSize: '18px', color: '#FFFFFF' }}>
                                            {`${userInfo?.firstName} ${userInfo?.lastName}`}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '14px', color: 'blue' }}>
                                            {userInfo?.roles[0]}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <MenuItem onClick={() => handleMenuClick('/dashboard')}>
                                    <ListItemIcon>
                                        <OFDashboardIcon fontSize="small" />
                                    </ListItemIcon>
                                    Dashboard
                                </MenuItem>
                                <MenuItem onClick={() => handleMenuClick('/profile')}>
                                    <ListItemIcon>
                                        <OFMyProfileIcon fontSize="small" />
                                    </ListItemIcon>
                                    My Profile
                                </MenuItem>
                                <MenuItem onClick={() => handleMenuClick('/wishlist')}>
                                    <ListItemIcon>
                                        <OFHeartIcon fontSize="small" />
                                    </ListItemIcon>
                                    Wishlist
                                </MenuItem>
                                <MenuItem onClick={() => handleMenuClick('/settings')}>
                                    <ListItemIcon>
                                        <SettingsIcon fontSize="small" />
                                    </ListItemIcon>
                                    Settings
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();
                                        logout();
                                    }}
                                >
                                    <ListItemIcon>
                                        <OFLogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Box>
                        {/* Mobile view */}
                        <Box
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}
                        >
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIconStyled />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{ display: { xs: 'block', md: 'none' } }}
                            >
                                {/* <MenuItem onClick={handleCloseNavMenu} >
                                    <Typography textAlign="center" sx={{ color: 'white' }}>Contact us</Typography>
                                </MenuItem> */}

                                <MenuItem component={Link} to="#product" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Home</Typography>
                                </MenuItem>
                                <MenuItem component={Link} to="#about-us" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Products</Typography>
                                </MenuItem>
                                <MenuItem component={Link} to="/signin" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Need a space</Typography>
                                </MenuItem>
                                <MenuItem component={Link} to="/signin" onClick={handleCloseNavMenu}>

                                    <Typography textAlign="center">Loan on property</Typography>
                                </MenuItem>
                                <MenuItem component={Link} to="/signin" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">About us</Typography>
                                </MenuItem>
                                <MenuItem component={Link} to="/signin" onClick={handleCloseNavMenu} className={activeMenu === 'contact-us' ? 'active' : ''}>
                                    <Typography textAlign="center">Contact us</Typography>
                                </MenuItem>


                                <MenuItem
                                    button
                                    onClick={() => isLoggedIn ? handleLogout() : handleLogin()}
                                >
                                    {isLoggedIn ?
                                        <Typography textAlign="center">Logout</Typography> :
                                        <Typography textAlign="center">Login</Typography>
                                    }

                                </MenuItem>

                                {!isLoggedIn && (
                                    <MenuItem
                                        button
                                        onClick={() => handleOpenPopup()}
                                    >
                                        <Typography textAlign="center">Register</Typography>
                                    </MenuItem>
                                )}
                                {isLoggedIn &&
                                    <MenuItem onClick={() => handleMenuClick('/dashboard')}>

                                        <Typography textAlign="center">Dashboard</Typography>
                                    </MenuItem>
                                }

                                <Stack direction="row" spacing={2} ml={1}></Stack>
                                {isLoggedIn && (
                                    <Box display="flex" alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
                                        <IconButton color="inherit">
                                            <Avatar alt="User Avatar" src="/images/avatar1.png" />
                                        </IconButton>
                                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            <Typography variant="body1" sx={{ fontSize: '18px', color: 'black' }}>
                                                {`${userInfo?.firstName} ${userInfo?.lastName}`}
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontSize: '14px', color: 'blue' }}>
                                                {userInfo?.roles[0]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Menu>

                        </Box>

                    </Toolbar>
                </Container>
            </AppBar>

            {/* Popup Menu for Mobile */}
            < Menu
                anchorEl={anchorNav}
                open={Boolean(anchorNav)}
                onClose={closeMenu}
                PaperProps={{
                    sx: {
                        marginTop: '10px',
                        maxHeight: 'calc(100vh - 64px)', // Adjust based on the header height
                    },
                }
                }
            >
                <MenuItem onClick={() => handleNavigate('home')}>Home</MenuItem>
                <MenuItem onClick={() => handleNavigate('products')}>Products</MenuItem>
                {/* Add other MenuItems similarly */}
                <MenuItem onClick={isLoggedIn ? handleLogout : handleLogin}>
                    {isLoggedIn ? 'Logout' : 'Login'}
                </MenuItem>
                {
                    !isLoggedIn && (
                        <MenuItem onClick={handleOpenPopup}>
                            Signup
                        </MenuItem>
                    )
                }
            </Menu >

            {/* Dialog for Login */}
            < Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        background: 'transparent',
                        boxShadow: 'none',
                        width: { xs: '90vw', sm: '60vw', md: '40vw', lg: '30vw' },
                    },
                }}
            >
                <Box display="flex" justifyContent="center" alignItems="center" position="relative" p={1}>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            zIndex: 1,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
                    <Button onClick={handleClose} color="primary" sx={{ padding: 0 }}>
                        <img src={CloseButton} width="80px" height="auto" alt="Close" />
                    </Button>
                </DialogActions>

                <DialogContent sx={{ background: 'white', borderRadius: 5, padding: 2 }}>
                    <LoginForm onSuccess={handleClose} />
                </DialogContent>
            </Dialog >

            {openPopup && <Popup handleClose={handleClosePopup} />}
        </>
    );
};

export default OFNavbar;
