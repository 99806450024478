
import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery, Grid, Typography, TextField, Button, Breadcrumbs, Stack, Paper, InputAdornment, Container } from '@mui/material';
import CardDisplay from './CardDisplay';
import SearchPageForm from './SearchPageForm';
import { oneFranHubtheme } from '../../theme/theme';
import { fetchCardDetails, fetchIndustries, fetchSectors, fetchStates } from '../../axios/api-service';
import { Link, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { OFMail2, OFSearchIcon1, OFSearchIcon2, OFSearchIcon3 } from '../../icons/CustomIcon';
import { API } from '../../axios/api-path';

const SearchPage = () => {

    const navigate = useNavigate();

    const [locations, setLocations] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [categories] = useState([
        { id: '1', name: 'FRANCHISER' },
        { id: '2', name: 'BUSINESS_COLLAB' },
        { id: '3', name: 'DEALER' },
        // { id: '4', name: 'Franchise' },
        { id: '4', name: 'BUSINESS_SALE' },
    ]);
    const [data, setData] = useState([]); // Full data fetched from API
    const [filteredItems, setFilteredItems] = useState([]); // Data to be passed to CardDisplay
    const [selectedFilters, setSelectedFilters] = useState({
        industry: [],
        sector: [],
        category: null,
        location: [],
        investmentRange: [0, Infinity], // Default range covering all possible values
    });

    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    useEffect(() => {
        // Fetch initial data
        fetchIndustries(setIndustries);
        fetchSectors(setSectors);
        fetchStates(setLocations, 1);

        // const pathFranchise = API.getCardList()
        // const pathBSeller = API.getBSellerCardList()
        // const pathPartner = API.getPartnerCardList()
        // const pathDealer = API.getDealerCardList()

        const fetchData = async () => {
            try {
                const responseFranchise = await fetchCardDetails(API.getCardList());
                const responseBSeller = await fetchCardDetails(API.getBSellerCardList());
                const responsePartner = await fetchCardDetails(API.getPartnerCardList());
                const responseDealer = await fetchCardDetails(API.getDealerCardList());

                // Merge all response data into a single array
                // const mergedData = [
                //     ...(responseFranchise.data || []),
                //     ...(responseBSeller.data || []),
                //     ...(responsePartner.data || []),
                //     ...(responseDealer.data || []),
                // ];

                // Merge all response data into a single array
                const allData = [
                    ...(responseFranchise.data || []),
                    ...(responseBSeller.data || []),
                    ...(responsePartner.data || []),
                    ...(responseDealer.data || []),
                ];

                // Remove duplicates based on entityCode
                const mergedData = Array.from(
                    new Map(allData.map(item => [item.entityCode, item])).values()
                );

                // Set the merged data
                setData(mergedData);
                setFilteredItems(mergedData);
                //setData(responseFranchise.data);
                //setFilteredItems(responseFranchise.data);
                //console.log("responseFranchise " + JSON.stringify(mergedData, null, 2))
            } catch (error) {
                console.error('Error fetching card details:', error);
            }
        };

        fetchData();
    }, []);
    const industryNames = industries.map((industry) => industry.name);
    //console.log('Industry Names:', JSON.stringify(industryNames, null, 2));

    useEffect(() => {
        // console.log('Data:', data);
        // console.log('Selected Filters:', selectedFilters);

        const applyFilters = () => {
            let filtered = data.filter(card => {

                const industryNames = selectedFilters.industry.map(filter => filter.name);
                const sectorNames = selectedFilters.sector.map(filter => filter.name);
                const locationNames = selectedFilters.location.map(filter => filter.name);
                const categoryNames = selectedFilters.category.name;

                const minRangeSelected = selectedFilters.investmentRange[0];
                const maxRangeSelected = selectedFilters.investmentRange[1];




                const matchesIndustry = industryNames.length === 0 || industryNames.includes(card.industryName);
                // console.log(`Card ID ${card.id} - Matches Industry: ${matchesIndustry} (Card Industry: ${card.industryName})`);

                const matchesSector = sectorNames.length === 0 || sectorNames.includes(card.industrySectorName);

                const matchesLocation = locationNames.length === 0 || locationNames.includes(card.state);


                const matchesCategory = categoryNames.trim().toLowerCase() === card.entityType.trim().toLowerCase();

                const minRangeCard = card.investmentRange?.startIndex
                const maxRangeCard = card.investmentRange?.endIndex

                const matchesInvestmentRange = (
                    (minRangeCard !== undefined && minRangeCard !== null && maxRangeCard !== undefined && maxRangeCard !== null)
                        ? (minRangeCard >= minRangeSelected && maxRangeCard <= maxRangeSelected)
                        : true
                );



                // console.log(" matchesIndustry ---> " + matchesIndustry)
                // console.log(" matchesSector --->" + matchesSector)
                // console.log(" matchesLocation ---> " + matchesLocation)
                // console.log(" matchesCategory ---> " + matchesCategory)
                // console.log(" matchesInvestmentRange ---> " + matchesInvestmentRange)


                const isMatch = (
                    (industryNames.length === 0 || matchesIndustry) &&
                    (sectorNames.length === 0 || matchesSector) &&
                    (matchesCategory) &&
                    (locationNames.length === 0 || matchesLocation) &&
                    (minRangeSelected === 0 && maxRangeSelected === Infinity || matchesInvestmentRange)
                );




                return isMatch;
            });

            if (filtered.length === 0) {
                console.log('No results found with the current filters');
            } else {

            }

            setFilteredItems(filtered);
        };

        applyFilters();
    }, [selectedFilters, data]);


    const handleFilterChange = (filters) => {

        setSelectedFilters(filters);
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/"
        //onClick={navigate("/")}
        >
            Home
        </Link >,
        // <Link
        //     underline="hover"
        //     key="2"
        //     color="inherit"
        //     href="/material-ui/getting-started/installation/"
        // // onClick={handleClick}
        // >
        //     Core
        // </Link>,
        <Typography key="3" sx={{ color: 'text.primary' }}>
            Personalised
        </Typography>,
    ];

    return (
        <Box sx={{
            backgroundColor: '#FFFFFF', boxSizing: 'border-box',
            // paddingX: isMobile ? 2 : isTablet ? 5 : 25,
            // paddingY: 2,

        }}>
            <Container maxWidth={'xl'} >
                <Box
                //  paddingY={2}
                >




                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>

                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{ minHeight: '15rem', borderRadius: '18px', backgroundColor: 'yellow' }}>
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: 'center', // horizontal center
                                    alignItems: 'center',     // vertical center
                                    height: '15rem'           // or any height you need
                                }}>
                                    <Typography>Advertisement</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Left Panel */}
                        <Grid item xs={12} md={2.5}>
                            <Box >
                                <SearchPageForm
                                    industries={industries}
                                    sectors={sectors}
                                    categories={categories}
                                    locations={locations}
                                    onFilterChange={handleFilterChange}
                                />
                            </Box>
                        </Grid>
                        {/* Main Content Area */}
                        <Grid item xs={12} md={9.5}>
                            <Box sx={{ padding: 2, }}>
                                <Grid container spacing={4} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="TITLE1">Personalized</Typography> {/* Use appropriate variant for your design */}
                                    </Grid>
                                    <Grid item>
                                        <Stack direction="row" spacing={2} alignItems="center"> {/* Added spacing between TextField and Button */}
                                            <TextField
                                                type="text"
                                                placeholder="Search"
                                                variant="outlined"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '100px', // Rounded corners
                                                        paddingRight: '8.5rem', // Space for adornment
                                                        height: '2.75rem', // Increased height
                                                        width: { xs: '100%', sm: 'auto' }, // Responsive width
                                                        '& fieldset': {
                                                            borderColor: 'grey', // Border color
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'grey', // Border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'grey', // Border color on focus
                                                        },
                                                    },
                                                    '& input': {
                                                        paddingLeft: '1rem', // Adjust padding inside the input
                                                        height: '100%', // Ensure input field stretches to fill the container
                                                    },
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <OFSearchIcon3 sx={{ color: '#505050' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Button variant="outlined">Sort by Popular</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box >
                                {/* Pass filtered items to CardDisplay */}
                                <CardDisplay filteredItems={filteredItems} />

                            </Box>
                        </Grid >
                    </Grid >
                </Box>
            </ Container>
        </Box >
    );
};

export default SearchPage;
