// import { Box, Container, Grid } from '@mui/material'
// import React from 'react'


// const MainCard = ({ children, colour, isBanner }) => {
//     return (


//         // <Grid
//         //     container
//         //     paddingX={2}
//         //     sx={{
//         //         paddingY: 4,
//         //         backgroundColor: colour,
//         //         ...(isBanner && {
//         //             //minHeight: '40vh', 
//         //             backgroundSize: 'contain', // cover
//         //             backgroundPosition: 'right center',
//         //             backgroundRepeat: 'no-repeat',
//         //             backgroundImage: `linear-gradient(rgba(13, 38, 89, 0.7), rgba(13, 38, 89, 0.7)), url(/images/bannerImage.gif)`,
//         //         }),
//         //     }}
//         // >

//         //     <Grid
//         //         item
//         //         xs={12}
//         //         md={10}
//         //         sx={{
//         //             display: 'flex',
//         //             justifyContent: 'center',
//         //             alignItems: 'center',
//         //         }}
//         //     >
//         //         {children} {/* Render your children components */}
//         //     </Grid>

//         // </Grid>

//         <Container maxWidth={false} disableGutters sx={{ flexGrow: 1 }}>
//             <Grid container spacing={0}>
//                 {/* Left Section */}
//                 <Grid item xs={12} md={2} sx={{ backgroundColor: colour, }}>

//                 </Grid>

//                 {isBanner && (
//                     <Grid
//                         container
//                         paddingX={2}
//                         sx={{
//                             paddingY: 4,
//                             backgroundColor: colour,
//                             backgroundSize: 'contain', // cover
//                             backgroundPosition: 'right center',
//                             backgroundRepeat: 'no-repeat',
//                             backgroundImage: `linear-gradient(rgba(13, 38, 89, 0.7), rgba(13, 38, 89, 0.7)), url(/images/bannerImage.gif)`,
//                         }}
//                     >
//                         <Grid item xs={12} md={2} sx={{ backgroundColor: colour, }}>

//                         </Grid>
//                         <Grid
//                             item
//                             xs={12}
//                             md={8}
//                             sx={{
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                             }}
//                         >
//                             {children} {/* Render your children components */}
//                         </Grid>

//                     </Grid>
//                 )}


//                 {!isBanner && (
//                     <Grid item xs={12} md={8} sx={{ backgroundColor: colour, }}>

//                         {children}

//                     </Grid>
//                 )}

//                 {/* Right Section */}
//                 <Grid item xs={12} md={2} sx={{ backgroundColor: colour, }}>

//                 </Grid>
//             </Grid>
//         </Container >

//     )
// }

// export default MainCard

import { Box, Container, Grid } from '@mui/material'
import React from 'react'


const MainCard = ({ children, colour, isBanner }) => {
    return (

        <Box sx={{ backgroundColor: colour }} paddingY={2}>

            {/* {isBanner && (
                <Grid
                    container
                    paddingX={2}
                    md={12}
                    sx={{
                        paddingY: 4,
                        backgroundColor: colour,
                        backgroundSize: 'contain', // cover
                        backgroundPosition: 'right center',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `linear-gradient(rgba(13, 38, 89, 0.7), rgba(13, 38, 89, 0.7)), url(/images/bannerImage.gif)`,
                        border: '1px solid red'
                    }}
                >
                    <Grid container maxWidth={'xl'}>
                        <Grid item md={10}>

                            {children}


                        </Grid>
                    </Grid>
                </Grid>)} */}
            {isBanner && (
                <Grid
                    container
                    xs={12} // Full width for the outer grid
                    md={12}
                    sx={{
                        paddingY: 4,
                        backgroundColor: colour,
                        backgroundSize: 'contain',
                        backgroundPosition: 'right center',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `linear-gradient(rgba(13, 38, 89, 0.7), rgba(13, 38, 89, 0.7)), url(/images/bannerImage.gif)`,

                    }}
                >
                    <Container maxWidth={'xl'}>
                        <Grid container justifyContent="center" > {/* Centers the inner grid */}
                            <Grid item xs={12} md={10}> {/* Inner grid with 10 columns centered */}
                                {children}
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            )}



            <Container maxWidth={'xl'}>

                <Grid container justifyContent="center" alignItems="center">


                    {!isBanner && (

                        <Grid item md={10}>

                            {children}


                        </Grid>)}


                    {/* <Box sx={{ border: '1px solid green' }} paddingX={8}>

                 


                </Box> */}

                </Grid>
            </Container>
        </Box>

        // <Container maxWidth={false} disableGutters sx={{ flexGrow: 1 }}>
        //     <Grid container spacing={0}>
        //         {/* Left Section */}
        //         <Grid item xs={12} md={2} sx={{ backgroundColor: colour, }}>

        //         </Grid>

        //         {isBanner && (
        //             <Grid
        //                 container
        //                 paddingX={2}
        //                 sx={{
        //                     paddingY: 4,
        //                     backgroundColor: colour,
        //                     backgroundSize: 'contain', // cover
        //                     backgroundPosition: 'right center',
        //                     backgroundRepeat: 'no-repeat',
        //                     backgroundImage: `linear-gradient(rgba(13, 38, 89, 0.7), rgba(13, 38, 89, 0.7)), url(/images/bannerImage.gif)`,
        //                 }}
        //             >
        //                 <Grid item xs={12} md={2} sx={{ backgroundColor: colour, }}>

        //                 </Grid>
        //                 <Grid
        //                     item
        //                     xs={12}
        //                     md={8}
        //                     sx={{
        //                         display: 'flex',
        //                         justifyContent: 'center',
        //                         alignItems: 'center',
        //                     }}
        //                 >
        //                     {children} {/* Render your children components */}
        //                 </Grid>

        //             </Grid>
        //         )}


        //         {!isBanner && (
        //             <Grid item xs={12} md={8} sx={{ backgroundColor: colour, }}>

        //                 {children}

        //             </Grid>
        //         )}

        //         {/* Right Section */}
        //         <Grid item xs={12} md={2} sx={{ backgroundColor: colour, }}>

        //         </Grid>
        //     </Grid>
        // </Container >

    )
}

export default MainCard