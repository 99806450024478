// // // src/components/DialogCommonHooks.js
// // import React from 'react';
// // import { Dialog, DialogContent, DialogActions, IconButton, Box, Button } from '@mui/material';
// // // import { useRecoilState } from 'recoil';
// // import CloseIcon from '@mui/icons-material/Close';
// // // import LoadingOverlay from 'react-loading-overlay';
// // // import { globalSpinner } from '../recoilState'; // Adjust the import path as needed
// // import CloseButton from "../../assets/icons/CloseButton.svg";

// // export const DialogCommonHooks = (props) => {
// //     // const [spinner, setSpinner] = useRecoilState(globalSpinner);

// //     return (
// //         <Dialog
// //             open={props.show}
// //             onClose={props.handleClose}
// //             PaperProps={{
// //                 sx: {
// //                     borderRadius: 2,
// //                     background: 'transparent',
// //                     boxShadow: 'none',
// //                     width: { xs: '90vw', sm: '60vw', md: '40vw', lg: '30vw' },
// //                 },
// //             }}
// //             aria-labelledby="customized-dialog-title"
// //         >
// //             <Box display="flex" justifyContent="center" alignItems="center" position="relative" p={1}>
// //                 <IconButton
// //                     onClick={props.handleClose}
// //                     sx={{
// //                         position: 'absolute',
// //                         right: 0,
// //                         top: 0,
// //                         zIndex: 1,
// //                     }}
// //                 >
// //                     <CloseIcon />
// //                 </IconButton>
// //             </Box>

// //             <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
// //                 <Button onClick={props.handleClose} color="primary" sx={{ padding: 0 }}>
// //                     <img src={CloseButton} width="80px" height="auto" alt="Close" />
// //                 </Button>
// //             </DialogActions>

// //             <DialogContent
// //                 sx={{
// //                     background: 'white',
// //                     borderRadius: 5,
// //                     padding: 2,
// //                 }}
// //             >
// //                 {/* <LoadingOverlay active={spinner} spinner text="Please wait a moment"> */}
// //                 {props.body}
// //                 {/* </LoadingOverlay> */}
// //             </DialogContent>
// //         </Dialog>
// //     );
// // };
// // src/components/DialogCommonHooks.js
// import React from 'react';
// import { Dialog, DialogContent, DialogActions, IconButton, Box, Button } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import CloseButton from "../../assets/icons/CloseButton.svg";

// export const DialogCommonHooks = (props) => {
//     return (
//         <Dialog
//             open={props.show}
//             onClose={props.handleClose}
//             PaperProps={{
//                 sx: {
//                     borderRadius: 2,
//                     background: 'transparent',
//                     boxShadow: 'none',
//                     // Responsive width adjustments
//                     width: { xs: '90vw', sm: '75vw', md: '60vw', lg: '40vw' },
//                     maxWidth: '100%',
//                 },
//             }}
//             aria-labelledby="customized-dialog-title"
//         >
//             <Box
//                 display="flex"
//                 justifyContent="center"
//                 alignItems="center"
//                 position="relative"
//                 p={{ xs: 1, md: 2 }} // Responsive padding
//             >
//                 <IconButton
//                     onClick={props.handleClose}
//                     sx={{
//                         position: 'absolute',
//                         right: { xs: 4, sm: 8 }, // Adjust right spacing based on screen size
//                         top: { xs: 4, sm: 8 },
//                         zIndex: 1,
//                     }}
//                 >
//                     <CloseIcon />
//                 </IconButton>
//             </Box>

//             <DialogActions
//                 sx={{
//                     justifyContent: 'center',
//                     padding: { xs: 1, md: 2 }, // Responsive padding
//                 }}
//             >
//                 <Button
//                     onClick={props.handleClose}
//                     color="primary"
//                     sx={{
//                         padding: 0,
//                         // Responsive button size adjustments
//                         '& img': {
//                             width: { xs: '60px', sm: '70px', md: '80px' },
//                             height: 'auto',
//                         },
//                     }}
//                 >
//                     <img src={CloseButton} alt="Close" />
//                 </Button>
//             </DialogActions>

//             <DialogContent
//                 sx={{
//                     background: 'white',
//                     borderRadius: 5,
//                     padding: { xs: 1.5, md: 2.5 }, // Responsive padding
//                 }}
//             >
//                 {props.body}
//             </DialogContent>
//         </Dialog>
//     );
// };
// src/components/DialogCommonHooks.js
import React from 'react';
import { Dialog, DialogContent, DialogActions, IconButton, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloseButton from "../../assets/icons/CloseButton.svg";

export const DialogCommonHooks = (props) => {
    return (
        <Dialog
            open={props.show}
            onClose={props.handleClose}
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    background: 'transparent',
                    boxShadow: 'none',
                    width: { xs: '90vw', sm: '75vw', md: '60vw', lg: '40vw' },
                    maxWidth: '519px',
                    height: 'auto',
                    maxHeight: '741px',
                    overflowY: 'auto',
                },
            }}
            aria-labelledby="customized-dialog-title"
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="relative"
                p={{ xs: 1, md: 2 }}
            >
                <IconButton
                    onClick={props.handleClose}
                    sx={{
                        position: 'absolute',
                        right: { xs: 4, sm: 8 },
                        top: { xs: 4, sm: 8 },
                        zIndex: 1,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <DialogActions
                sx={{
                    justifyContent: 'center',
                    padding: { xs: 1, md: 2 },
                }}
            >
                <Button
                    onClick={props.handleClose}
                    color="primary"
                    sx={{
                        padding: 0,
                        '& img': {
                            width: { xs: '60px', sm: '70px', md: '80px' },
                            height: 'auto',
                        },
                    }}
                >
                    <img src={CloseButton} alt="Close" />
                </Button>
            </DialogActions>

            <DialogContent
                sx={{
                    background: 'white',
                    borderRadius: 5,
                    padding: { xs: 1.5, md: 2.5 },
                    maxHeight: 'calc(741px - 80px)', // Account for padding and actions height
                    overflowY: 'auto', // Ensure content is scrollable if it exceeds height
                }}
            >
                {props.body}
            </DialogContent>
        </Dialog>
    );
};
