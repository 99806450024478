// // import * as React from 'react';
// // import Paper from '@mui/material/Paper';
// // import Table from '@mui/material/Table';
// // import TableBody from '@mui/material/TableBody';
// // import TableCell from '@mui/material/TableCell';
// // import TableContainer from '@mui/material/TableContainer';
// // import TableHead from '@mui/material/TableHead';
// // import TablePagination from '@mui/material/TablePagination';
// // import TableRow from '@mui/material/TableRow';

// // const columns = [
// //     { id: 'name', label: 'Name', minWidth: 170 },
// //     { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
// //     {
// //         id: 'population',
// //         label: 'Population',
// //         minWidth: 170,
// //         align: 'right',
// //         format: (value) => value.toLocaleString('en-US'),
// //     },
// //     {
// //         id: 'size',
// //         label: 'Size\u00a0(km\u00b2)',
// //         minWidth: 170,
// //         align: 'right',
// //         format: (value) => value.toLocaleString('en-US'),
// //     },
// //     {
// //         id: 'density',
// //         label: 'Density',
// //         minWidth: 170,
// //         align: 'right',
// //         format: (value) => value.toFixed(2),
// //     },
// // ];

// // function createData(name, code, population, size) {
// //     const density = population / size;
// //     return { name, code, population, size, density };
// // }

// // const rows = [
// //     createData('India', 'IN', 1324171354, 3287263),
// //     createData('China', 'CN', 1403500365, 9596961),
// //     createData('Italy', 'IT', 60483973, 301340),
// //     createData('United States', 'US', 327167434, 9833520),
// //     createData('Canada', 'CA', 37602103, 9984670),
// //     createData('Australia', 'AU', 25475400, 7692024),
// //     createData('Germany', 'DE', 83019200, 357578),
// //     createData('Ireland', 'IE', 4857000, 70273),
// //     createData('Mexico', 'MX', 126577691, 1972550),
// //     createData('Japan', 'JP', 126317000, 377973),
// //     createData('France', 'FR', 67022000, 640679),
// //     createData('United Kingdom', 'GB', 67545757, 242495),
// //     createData('Russia', 'RU', 146793744, 17098246),
// //     createData('Nigeria', 'NG', 200962417, 923768),
// //     createData('Brazil', 'BR', 210147125, 8515767),
// // ];

// // export default function MaterialTable() {
// //     const [page, setPage] = React.useState(0);
// //     const [rowsPerPage, setRowsPerPage] = React.useState(10);

// //     const handleChangePage = (event, newPage) => {
// //         setPage(newPage);
// //     };

// //     const handleChangeRowsPerPage = (event) => {
// //         setRowsPerPage(+event.target.value);
// //         setPage(0);
// //     };

// //     return (
// //         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
// //             <TableContainer sx={{ maxHeight: 440 }}>
// //                 <Table stickyHeader aria-label="sticky table">
// //                     <TableHead>
// //                         <TableRow>
// //                             {columns.map((column) => (
// //                                 <TableCell
// //                                     key={column.id}
// //                                     align={column.align}
// //                                     style={{ minWidth: column.minWidth }}
// //                                 >
// //                                     {column.label}
// //                                 </TableCell>
// //                             ))}
// //                         </TableRow>
// //                     </TableHead>
// //                     <TableBody>
// //                         {rows
// //                             .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
// //                             .map((row) => {
// //                                 return (
// //                                     <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
// //                                         {columns.map((column) => {
// //                                             const value = row[column.id];
// //                                             return (
// //                                                 <TableCell key={column.id} align={column.align}>
// //                                                     {column.format && typeof value === 'number'
// //                                                         ? column.format(value)
// //                                                         : value}
// //                                                 </TableCell>
// //                                             );
// //                                         })}
// //                                     </TableRow>
// //                                 );
// //                             })}
// //                     </TableBody>
// //                 </Table>
// //             </TableContainer>
// //             <TablePagination
// //                 rowsPerPageOptions={[10, 25, 100]}
// //                 component="div"
// //                 count={rows.length}
// //                 rowsPerPage={rowsPerPage}
// //                 page={page}
// //                 onPageChange={handleChangePage}
// //                 onRowsPerPageChange={handleChangeRowsPerPage}
// //             />
// //         </Paper>
// //     );
// // }
// // Sort
// import * as React from 'react';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
// import TableSortLabel from '@mui/material/TableSortLabel';
// import { Icon } from '@mui/material';
// import { ArrowDownward, ArrowUpward } from '@mui/icons-material'; // Import MUI icons

// const columns = [
//     { id: 'name', label: 'Name', minWidth: 170 },
//     { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
//     {
//         id: 'population',
//         label: 'Population',
//         minWidth: 170,
//         align: 'right',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'size',
//         label: 'Size\u00a0(km\u00b2)',
//         minWidth: 170,
//         align: 'right',
//         format: (value) => value.toLocaleString('en-US'),
//     },
//     {
//         id: 'density',
//         label: 'Density',
//         minWidth: 170,
//         align: 'right',
//         format: (value) => value.toFixed(2),
//     },
// ];

// function createData(name, code, population, size) {
//     const density = population / size;
//     return { name, code, population, size, density };
// }

// const rows = [
//     createData('India', 'IN', 1324171354, 3287263),
//     createData('China', 'CN', 1403500365, 9596961),
//     createData('Italy', 'IT', 60483973, 301340),
//     createData('United States', 'US', 327167434, 9833520),
//     createData('Canada', 'CA', 37602103, 9984670),
//     createData('Australia', 'AU', 25475400, 7692024),
//     createData('Germany', 'DE', 83019200, 357578),
//     createData('Ireland', 'IE', 4857000, 70273),
//     createData('Mexico', 'MX', 126577691, 1972550),
//     createData('Japan', 'JP', 126317000, 377973),
//     createData('France', 'FR', 67022000, 640679),
//     createData('United Kingdom', 'GB', 67545757, 242495),
//     createData('Russia', 'RU', 146793744, 17098246),
//     createData('Nigeria', 'NG', 200962417, 923768),
//     createData('Brazil', 'BR', 210147125, 8515767),
// ];

// export default function StickyHeadTable() {

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    // {
    //     id: 'population',
    //     label: 'Population',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    // {
    //     id: 'size',
    //     label: 'Size\u00a0(km\u00b2)',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    // {
    //     id: 'density',
    //     label: 'Density',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toFixed(2),
    // },
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States United States United States United States United States United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia AustraliaAustraliaAustraliaAustraliaAustraliaAustraliaAustraliaAustraliaAustraliaAustraliaAustraliaAustralia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

export default function StickyHeadTable() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (property) => {
        const isAscending = orderBy === property && order === 'asc';
        setOrder(isAscending ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const sortedRows = React.useMemo(() => {
        return rows.slice().sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === 'asc' ? -1 : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }, [order, orderBy]);

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {/* <TableContainer sx={{ maxHeight: '440' }}> */}
            <TableContainer sx={{ maxHeight: '40vh' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                        maxWidth: '10rem',
                                        backgroundColor: 'rgba(220, 231, 255, 1)',
                                        position: 'relative', // Ensure icon positioning within cell
                                        paddingRight: 40, // Space for the icon
                                        fontWeight: 'bold', // Ensure header text is bold
                                    }}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            color: 'black', // Ensure text color
                                            whiteSpace: 'nowrap', // Prevent text wrapping
                                            '& .MuiTableSortLabel-icon': {
                                                opacity: 1, // Ensure icon is always visible
                                                color: 'black', // Icon color
                                            },
                                            '& .MuiTableSortLabel-active': {
                                                color: 'black', // Active header text color
                                            },
                                            '& .MuiTableSortLabel-iconDirectionDesc': {
                                                color: 'black', // Icon color when descending
                                            },
                                            '& .MuiTableSortLabel-iconDirectionAsc': {
                                                color: 'black', // Icon color when ascending
                                            },
                                        }}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {/* <TableBody>
                        {sortedRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                    </TableBody> */}
                    <TableBody>
                        {sortedRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    maxWidth: '10px',
                                                    //overflow: 'hidden', 
                                                    //textOverflow: 'ellipsis', 
                                                    whiteSpace: 'normal',
                                                    wordBreak: 'break-word',
                                                    padding: '8px',
                                                }}
                                            >
                                                {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
