import { Container, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { oneFranHubtheme } from '../../theme/theme';
import { Box } from '@mui/system';
import AboutUsImg from "./../../assets/about-us.svg"
import ExpertTalk from '../header/ExpertTalk';
import ExpertSearchForm from '../header/ExpertSearchForm';
import FeedbackForm from '../forms/FeedbackForm';


const ContactUs = () => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    return (
        <Box>
            <Container maxWidth="xl">

                <Box paddingY={2} >

                    <Grid container justifyContent="center" alignItems="left">


                        <Grid item xs={12} md={12}>
                            <Box >

                                <Typography variant='h4' sx={{ color: '#002659', py: 4 }}>Contact us</Typography>
                                <Typography variant='Content' sx={{ color: '#666666' }} paragraph>BusinessEx is thoughtfully conceived and created to address selling and exchanging of businesses across industry verticals. It’s a one-of-kind platform that interconnects the like-minded, gives exposure to Businesses, Start-ups, Investors, Lenders, Mentors and Incubators to find suitable business associates. Over the last 15 years we have mastered the art of creating a springboard that is capable of addressing the scopes of selling and exchanging of businesses.</Typography>

                            </Box>
                        </Grid>


                        <Grid item xs={12} md={6} paddingY={4}>

                            <Box bgcolor={'#E7F0FF'} sx={{ width: '100%', p: 4 }}>

                                <Typography variant='h6' sx={{ color: '#002659' }} paragraph>Let's talk with us</Typography>
                                <Typography variant='Content' sx={{ color: '#666666', maxWidth: '60%' }} paragraph> Questions, comments, or suggestions? Simply fill in the form and we’ll be in touch shortly.</Typography>
                                <Typography variant='Content' sx={{ color: '#666666' }} paragraph>+1 234 678 9108 99</Typography>
                                <Typography variant='Content' sx={{ color: '#666666' }} paragraph>Contact@moralizer.com</Typography>


                            </Box>



                        </Grid>
                        <Grid item xs={12} md={6} paddingY={2} >

                            <Box
                                sx={{

                                    justifyContent: "center", alignItems: "center",
                                    alignItems: 'center',


                                }}
                            >
                                <FeedbackForm />

                            </Box>


                        </Grid>




                    </Grid>

                </Box>


            </Container>



        </Box>


    )
}

export default ContactUs