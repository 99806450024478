
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../helpers/CustomButton';
import { toast, ToastContainer } from 'react-toastify';
import CustomDropDown from '../../helpers/dropdown/CustomDropdown';
import { OFBriefcase, OFComment, OFCountry } from '../../icons/CustomIcon';
import { fetchCities, fetchRange, fetchStates } from '../../axios/api-service';
import CustomInput from '../../helpers/custom-input';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { postApiCall } from '../../axios/axios-methods';
import { API } from '../../axios/api-path';
import { booleanValidation, cityValidation, dropdownValidation, dropdownValidationMulti, scoreValidationOptional, stateValidation, textValidation } from '../../utils/validations';
import { perInfoState } from '../../recoil/profile-atom';
import { useRecoilState } from 'recoil';



const validationSchema = yup.object().shape({
    state: stateValidation,
    city: cityValidation,
    investmentRange: dropdownValidation,
    comments: textValidation('Comments must be at least 10 characters long')
});

function BusinessInterestForm({ name }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { handleSubmit, control, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            state: null,
            city: null,
            investmentRange: null,
            comments: ''
        }
    });

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [ranges, setRanges] = useState([]);
    const [userInfo, setUserInfo] = useRecoilState(perInfoState);
    console.log("userInfo" + JSON.stringify(userInfo, null, 2))

    useEffect(() => {
        fetchRange(setRanges);
        fetchStates(setStates, 1);
    }, []);

    useEffect(() => {
        if (selectedCountry) {
            fetchStates(setStates, selectedCountry);
            setValue('state', null);
            setValue('city', null);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
            setValue('city', null);
        }
    }, [selectedState, setValue]);

    const onSubmit = async (data) => {
        const apiCallData = {
            // businessProfileId: userInfo?.businessProfile,
            businessProfileId: Number(userInfo?.businessProfile),

            state: data.state,
            city: data.city,
            investmentRange: data?.investmentRange,
            comments: data.comments,

        };




        const path = API.postInterest();
        try {
            await postApiCall({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {
                    toast.success("Successfully Sent");
                    reset();
                },
                onError: (error) => {
                    console.error('Submission error:', error);
                },
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    return (
        <Box padding={6} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <ToastContainer position="top-right" autoClose={5000} />
                <Box mb={2}>
                    <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.875rem', lineHeight: '2.3625rem' }}>
                        Interested in {name}? Apply here
                    </Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    sicon={<OFCountry />}
                                    fullWidth
                                    label="State"
                                    option={states}
                                    defaultValue={field.value}
                                    error={!!errors.state}
                                    helperText={errors.state?.message || ''}
                                    placeholder="Select State"
                                    changeCallBack={({ id, name }) => {
                                        setSelectedState(id);
                                        field.onChange({ id, name });
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    label="City"
                                    sicon={<OFCountry />}
                                    option={cities}
                                    defaultValue={field.value}
                                    error={!!errors.city}
                                    helperText={errors.city?.message || ''}
                                    placeholder="Select City"
                                    changeCallBack={({ id, name }) => {
                                        setSelectedCity(id);
                                        field.onChange({ id, name });
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>

                    <Controller
                        name="investmentRange"
                        control={control}
                        render={({ field }) => (

                            <CustomDropDown
                                {...field}
                                field={field}
                                fullWidth sicon={<OFBriefcase />}
                                label="Investment Range"
                                required
                                variant="outlined"
                                option={ranges}
                                defaultValue={field.value}
                                displayEmpty
                                error={!!errors.investmentRange}
                                helperText={errors.investmentRange?.message || ''}
                                changeCallBack={({ id, value }) => {


                                    field.onChange(id, value);
                                }}

                            />

                        )}
                    />
                </Grid>
                <Grid item xs={12} md={12} mt={2}>
                    <Controller
                        name="comments"
                        control={control}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                label="Expected Commitment (How often will the advisor be required?)"
                                required
                                fullWidth
                                sicon={<OFComment />}
                                variant="outlined"
                                error={!!errors.comments}
                                helperText={errors.comments?.message || ''}
                                placeholder="Comments"
                            />
                        )}
                    />
                </Grid>
                <Box mt={4} sx={{ display: 'flex', justifyContent: 'center', position: 'relative', top: isMobile ? '2rem' : '0' }}>
                    <CustomButton
                        type="submit"
                        width={{ xs: '75%', sm: '14rem' }}
                        variant="contained"
                        label="Submit Request"
                    />
                </Box>
            </Box>
        </Box >
    );
}

export default BusinessInterestForm;
