

import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneFranHubtheme } from '../../../theme/theme';
import { API } from '../../../axios/api-path';
import CustomButton from '../../../helpers/CustomButton';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError, toastSuccess } from '../../../utils/toast-notify';
import { fetchBusinessProfile, fetchCities, fetchCountries, fetchFormInfo, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import InvestorDetailsForm from './InvestorDetailsForm';
import { booleanValidation, cityValidation, countryValidation, currencyValidation, dropdownValidation, emailValidation, numericValidation, phoneValidation, pincodeValidation, scoreValidation, scoreValidationOptional, stateValidation, textValidation } from '../../../utils/validations';

const InvestorDetails = ({ isDashboardMode }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState(null);
    const [isFormData, setIsFormData] = useState(false);
    // const [outletStates, setOutletStates] = useState([])

    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    useEffect(() => {
        fetchCountries(setCountries);
        fetchIndustries(setIndustries);
        fetchRange(setRanges);
        const fetchData = async () => {
            const path = API.getInvestorDetails();

            try {
                const response = await fetchFormInfo(path);

                if (response.data.completed === true) {
                    setFormData(response.data);

                    setIsFormData(true);
                } else {
                    setIsFormData(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);



    // const validationSchema = yup.object({
    //     // alternateMobileNo: phoneValidation,
    //     alternateEmailId: emailValidation('Required'),
    //     street: textValidation({ message: 'Required', min: 3, max: 100 }),

    //     country: countryValidation,
    //     state: stateValidation,
    //     city: cityValidation,

    //     pinCode: pincodeValidation,
    //     legalSupportRequired: yup.string().required('Please select a value for legal support.'),
    //     loanSupportRequired: yup.string().required('Please select a value for loan support.'),
    //     interestedIndustry: yup
    //         .array()
    //         .of(
    //             yup.object().shape({
    //                 id: yup.string().required('Industry ID is required'),
    //                 //label: yup.string().required('Industry Label is required'),
    //             })
    //         )
    //         .min(1, 'Please select at least one industry'), // Ensure at least one selection
    //     investmentRange: dropdownValidation,

    //     availableCapitalRange: dropdownValidation,

    //     hsoonToInvestRange: dropdownValidation,

    //     educationalQualification: dropdownValidation,
    //     occupation: dropdownValidation,
    //     lookingForBusinessType: dropdownValidation,
    //     investorType: dropdownValidation,
    //     investmentPreference: dropdownValidation,
    //     about: textValidation({ message: 'Required', min: 50, max: 1000 }),

    //     desiredLoanAmount: currencyValidation,
    //     monthlyIncome: currencyValidation,
    //     existingDebt: currencyValidation,
    //     creditScore: scoreValidationOptional,
    //     typeOfProperty: dropdownValidation,
    //     propertyValue: currencyValidation,
    //     propertyArea: numericValidation,
    //     doYouWantSaySomething: textValidation({ message: 'Required', min: 3, max: 1000 }),
    //     acceptTermAndConditions: booleanValidation('acceptTermAndConditions')

    // });

    const validationSchema = yup.object({


        loanSupportRequired: yup
            .string()
            .required('Please select a value for loan support.'),

        desiredLoanAmount: yup
            .number()
            .nullable()
            .when('loanSupportRequired', {
                is: 'Yes',
                then: () => currencyValidation,
                otherwise: () => yup.number().nullable(),
            }),

        monthlyIncome: yup
            .number()
            .nullable()
            .when('loanSupportRequired', {
                is: 'Yes',
                then: () => currencyValidation,
                otherwise: () => yup.number().nullable(),
            }),

        existingDebt: yup
            .number()
            .nullable()
            .when('loanSupportRequired', {
                is: 'Yes',
                then: () => currencyValidation,
                otherwise: () => yup.number().nullable(),
            }),

        creditScore: yup
            .number()
            .nullable()
            .when('loanSupportRequired', {
                is: 'Yes',
                then: () => scoreValidationOptional,
                otherwise: () => yup.number().nullable(),
            }),

        typeOfProperty: yup
            .string()
            .nullable()
            .when('loanSupportRequired', {
                is: 'Yes',
                then: () => dropdownValidation,
                otherwise: () => yup.string().nullable(),
            }),

        propertyValue: yup
            .number()
            .nullable()
            .when('loanSupportRequired', {
                is: 'Yes',
                then: () => currencyValidation,
                otherwise: () => yup.number().nullable(),
            }),

        propertyArea: yup
            .number()
            .nullable()
            .when('loanSupportRequired', {
                is: 'Yes',
                then: () => numericValidation,
                otherwise: () => yup.number().nullable(),
            }),

        doYouWantSaySomething: yup
            .string()
            .nullable()
            .when('loanSupportRequired', {
                is: 'Yes',
                then: () => textValidation({ message: 'Required', min: 3, max: 1000 }),
                otherwise: () => yup.string().nullable(),
            }),

        acceptTermAndConditions: yup
            .boolean()
            .nullable()
            .when('loanSupportRequired', {
                is: 'Yes',
                then: () => booleanValidation('acceptTermAndConditions'),
                otherwise: () => yup.boolean().nullable(),
            }),

        // Non-conditional fields
        interestedIndustry: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.string().required('Industry ID is required'),
                })
            )
            .min(1, 'Please select at least one industry'),

        street: textValidation({ message: 'Required', min: 3, max: 100 }),

        country: countryValidation,
        state: stateValidation,
        city: cityValidation,

        pinCode: pincodeValidation,
        investmentRange: dropdownValidation,
        availableCapitalRange: dropdownValidation,
        hsoonToInvestRange: dropdownValidation,
        educationalQualification: dropdownValidation,
        occupation: dropdownValidation,
        lookingForBusinessType: dropdownValidation,
        investorType: dropdownValidation,
        investmentPreference: dropdownValidation,
        about: textValidation({ message: 'Required', min: 50, max: 1000 }),
        acceptTermAndConditions: booleanValidation('acceptTermAndConditions')
    });



    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue, getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {




        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        country,
        city,
        state,
        loanSupportRequired,
    }
        = watch({ control });


    useEffect(() => {
        console.log(" HII" + loanSupportRequired)
        if (loanSupportRequired === 'No') {
            setValue("acceptTermAndConditions", null);
            setValue("desiredLoanAmount", null);
            setValue("preferredLoanTerm", null);
            setValue("monthlyIncome", null);
            setValue("existingDebt", null);
            setValue("creditScore", null);
            setValue("specificRequirementOnLoan", null);
            setValue("loanAgainstProperty", null);
            setValue("typeOfProperty", null);
            setValue("propertyValue", null);
            setValue("propertyArea", null);
            setValue("doYouWantSaySomething", null);

        }
    }, [loanSupportRequired, setValue]);

    useEffect(() => {

        if (country) {
            fetchStates(setStates, country.id);

        }
    }, [country, setValue]);
    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);

    useEffect(() => {

        if (industry) {
            fetchSectors(setIndustrySectors, industry.id);

        }
    }, [industry, setValue]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [industrySectors, setIndustrySectors] = useState([]);
    const [ranges, setRanges] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedRange, setSelectedRange] = useState('');

    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);



    useEffect(() => {

        if (selectedCountry) {

            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedIndustry) {
            fetchSectors(setIndustrySectors, selectedIndustry);
        }
    }, [selectedIndustry]);

    function extractParts(ownerMobileNo) {
        if (ownerMobileNo != null) {

            const [countryCode, phoneNumber] = ownerMobileNo.split('-');


            return {
                countryCode: countryCode || null,
                phoneNumber: phoneNumber || null
            };
        } else {

            return {
                countryCode: null,
                phoneNumber: null
            };
        }
    }


    useEffect(() => {
        if (formData) {

            reset({
                "alternateMobileNo": formData?.alternateMobileNo,
                ownerMobileNo: {
                    countryCode: extractParts(formData?.alternateMobileNo).countryCode || '',
                    phoneNumber: extractParts(formData?.alternateMobileNo).phoneNumber || '',
                },
                "alternateEmailId": formData?.alternateEmailId,
                "street": formData?.address?.street,
                "state": formData?.address?.state,
                "country": formData?.address?.country,
                "city": formData?.address?.city,
                "pinCode": formData?.address?.postalCode,

                "interestedIndustry": formData?.interestedIndustry,
                "investmentRange": formData?.investmentRange,
                "availableCapitalRange": formData?.availableCapitalRange,

                "hsoonToInvestRange": formData?.hsoonToInvestRange,
                "educationalQualification": formData?.educationalQualification,
                "occupation": formData?.occupation,

                "lookingForBusinessType": formData?.lookingForBusinessType,

                "interestedStates": formData?.interestedStates,
                "interestedCities": formData?.interestedCities,

                "investorType": formData?.investorType,
                "investmentPreference": formData?.investmentPreference,
                "about": formData?.about,

                "legalSupportRequired": formData?.legalSupportRequired === true ? 'Yes' : 'No',
                "loanSupportRequired": formData?.loanSupportRequired === true ? 'Yes' : 'No',
                "desiredLoanAmount": formData?.desiredLoanAmount,
                "preferredLoanTerm": formData?.preferredLoanTerm,
                "monthlyIncome": formData?.monthlyIncome,
                "existingDebt": formData?.existingDebt,
                "creditScore": formData?.creditScore,
                "specificRequirementOnLoan": formData?.specificRequirementOnLoan,
                "loanAgainstProperty": formData?.loanAgainstProperty === true ? 'Yes' : 'No',
                "typeOfProperty": formData?.typeOfProperty,
                "propertyValue": formData?.propertyValue,
                "propertyArea": formData?.propertyArea,
                "doYouWantSaySomething": formData?.doYouWantSaySomething

            });
        }
    }, [formData, reset]);

    const onSubmit = async (data) => {

        const apiCallData = {


            "alternateMobileNo": data?.alternateMobileNo?.countryCode + "-" + data.alternateMobileNo?.phoneNumber,
            "alternateEmailId": data?.alternateEmailId,



            address: {
                street: data?.street,
                city: { id: parseInt(data?.city?.id, 10) },
                state: { id: parseInt(data?.state?.id, 10) },
                postalCode: data?.pinCode,
                country: { id: parseInt(data?.country?.id, 10) }
            },

            "interestedIndustry": data?.interestedIndustry,
            // "investmentRange": data?.investmentRange,
            investmentRange: {
                id: parseInt(data?.investmentRange?.id, 10),
                // startIndex: parseInt(data.investmentRange.startIndex, 10),
                // endIndex: parseInt(data.investmentRange.endIndex, 10),
                name: data?.investmentRange?.name,
                type: data?.indexType
            },
            // "availableCapitalRange": data?.availableCapitalRange,
            availableCapitalRange: {
                id: parseInt(data?.availableCapitalRange?.id, 10),
                // startIndex: parseInt(data.investmentRange.startIndex, 10),
                // endIndex: parseInt(data.investmentRange.endIndex, 10),
                name: data?.availableCapitalRange?.name,
                type: data?.indexType
            },

            // "hsoonToInvestRange": data?.hsoonToInvestRange,
            hsoonToInvestRange: {
                id: parseInt(data?.hsoonToInvestRange?.id, 10),
                // startIndex: parseInt(data.investmentRange.startIndex, 10),
                // endIndex: parseInt(data.investmentRange.endIndex, 10),
                name: data?.hsoonToInvestRange?.name,
                type: data?.indexType
            },
            "educationalQualification": data?.educationalQualification,
            "occupation": data?.occupation,

            "lookingForBusinessType": data?.lookingForBusinessType,

            "interestedStates": data?.interestedStates,
            "interestedCities": data?.interestedCities,

            "investorType": data?.investorType,
            "investmentPreference": data?.investmentPreference,
            "about": data?.about,

            "legalSupportRequired": data?.legalSupportRequired === 'Yes' ? true : false,
            "loanSupportRequired": data?.loanSupportRequired === 'Yes' ? true : false,

            "acceptTermAndConditions": data?.acceptTermAndConditions,
            "desiredLoanAmount": data?.desiredLoanAmount,
            "preferredLoanTerm": data?.preferredLoanTerm,
            "monthlyIncome": data?.monthlyIncome,
            "existingDebt": data?.existingDebt,
            "creditScore": data?.creditScore,
            "specificRequirementOnLoan": data?.specificRequirementOnLoan,
            "loanAgainstProperty": data?.loanAgainstProperty === 'Yes' ? true : false,
            "typeOfProperty": data?.typeOfProperty,
            "propertyValue": data?.propertyValue,
            "propertyArea": data?.propertyArea,
            "doYouWantSaySomething": data?.doYouWantSaySomething



        };


        const path = API.signupInvestorDetails();
        try {

            console.log(" Submit data " + JSON.stringify(apiCallData, null, 2))
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {

                    if (isDashboardMode) {
                        setIsEditing(false)

                    }
                    else {
                        handleNext();


                    }



                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        navigate('/');
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '1'));
    };


    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <InvestorDetailsForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

                    countries={countries}
                    cities={cities}
                    states={states}
                    industries={industries}
                    industrySectors={industrySectors}
                    ranges={ranges}
                    setSelectedCity={setSelectedCity}
                    setSelectedCountry={setSelectedCountry}
                    setSelectedIndustry={setSelectedIndustry}
                    setSelectedSector={setSelectedSector}
                    setSelectedState={setSelectedState}
                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    loanSupportRequired={loanSupportRequired}

                />


                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}



            </Paper>

        </>
    )
}

export default InvestorDetails