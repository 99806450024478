import { createTheme } from "@mui/material";

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1245,
    xl: 1440,
    xxl: 1920,
  },
};

const baseFontSize = 16;
const primaryFontFamily = 'Outfit-Regular,Open Sans, sans-serif';
const secondaryFontFamily = "Outfit-Regular";
const fontWeights = {
  extraBold: 800,
  bold: 700,
  semiBold: 600,
  regular: 400,
};

let theme = createTheme({


  palette: {
    common: {
      main: "#000000",
      light: "#FFFFFF",
    },

    primary: {
      main: "#2f80ed",
    },

    secondary: {
      main: "#000435",
    },

    success: {
      main: "#30B085",
      // light: "#30B08515",
      // dark: "#00CB14",
    },

    warning: {
      main: "#FFB210",
      // light: "#FFB21015",
    },

    info: {
      main: "#438BED",
      // light: "#438BED15",
    },

    error: {
      main: "#D94166",
      // light: "#D9416615",
    },
    A1: { main: "#2F80ED30" },
    A8: { main: "#DBDBDB" },
    A7: { main: "#22222260" },
    A6: { main: "#22222275" },
    A5: { main: "#00000075" },
    A4: { main: "#F97B34" },
    fullWhite: { main: "#ffffff" },



    warninglight: { main: "#FFB21020" },
    primarylight: { main: "#2F80ED35" },
    accent: {
      A1: "#2F80ED30",
      A2: "#F5F9FA",
      A3: "#FAFAFA",
      A4: "#F97B34",
      A5: "#00000075",
      A6: "#22222275",
      A7: "#22222260",
      A8: "#DBDBDB",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Outfit-Regular",
          paddingBottom: "5px",
          fontSize: "14px",
        },
      },
    },

    MuiAvatar: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "max-content",
          borderRadius: "50px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Outfit-Regular", // bold
          fontSize: "12px",
          letterSpacing: "0rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { fontFamily: "Outfit-Regular", borderRadius: "8px" },
        input: {
          padding: "16px 14px",
          fontSize: "16px",
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#2F80ED",
          color: "white",
        },
      },
    },

    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
      },
      styleOverrides: {
        tooltip: {
          color: "#657285",
          backgroundColor: "#E3F1FF",
          margin: '2px !important'
        },
        arrow: {
          color: "#E3F1FF",
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#C4C4C440",
            width: "10px",
            borderRadius: 100,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 100,
            backgroundColor: "#C4C4C4",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
          {
            backgroundColor: "#8b8b8b",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
          {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          {
            backgroundColor: "#959595",
          },
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            cursor: "poOutfit",
          },
        },
      },
    },

    // Mui Stepper custom color of icon and line Start
    // MuiStepIcon: {
    //   styleOverrides: {
    //     root: {
    //       "&.Mui-completed.Mui-active": {
    //         color: "#47F158",
    //       },
    //       "&.Mui-active": {
    //         color: "#2f80ed",
    //       },
    //     },
    //   },
    // },

    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          color: "#00000030",
          "&.Mui-completed.Mui-active, &.Mui-completed, &.Mui-completed.Mui-active.Mui-disabled":
          {
            color: "#47F158",
          },
          "&.Mui-active, &.Mui-active.Mui-disabled": {
            color: "#2f80ed",
          },

          "&.Mui-disabled": {
            color: "#00000030",
          },
        },
      },
    },

    MuiStep: {
      styleOverrides: {
        root: {
          "&.Mui-completed .MuiStepContent-root": {
            borderLeft: "2px solid #47F158",
          },
          "& .MuiStepContent-root": {
            borderLeft: "2px solid #00000030",
          },
        },
      },
    },

    MuiStepConnector: {
      styleOverrides: {
        root: {
          "&.Mui-active.Mui-disabled .MuiStepConnector-line": {
            borderLeft: "2px solid #47F158",
          },

          "&.Mui-disabled .MuiStepConnector-line": {
            borderLeft: "2px solid #00000030",
          },
        },
      },
    },
    // Mui Stepper custom color of icon and line End
  },

  typography: {
    fontFamily: "Outfit-Regular",

    button: {
      fontFamily: "Outfit-Regular",
      fontSize: "18px",
      letterSpacing: " .1rem ",
      textTransform: "capitalize",
    },

    // TITLE1: {
    //   fontFamily: 'Outfit, sans-serif',
    //   fontWeight: fontWeights.semiBold,
    //   fontSize: 24 ,
    //   lineHeight: '30.24px',

    // },


    // TITLE2: {
    //   fontFamily: 'Outfit, sans-serif',
    //   fontWeight: fontWeights.semiBold,
    //   fontSize: 22 ,
    //   lineHeight: '27.72px',



    // },
    TITLE4: {
      fontFamily: 'Outfit, sans-serif',
      fontWeight: fontWeights.semiBold,
      fontSize: 18,
      lineHeight: '27.72px',

    },
    TITLE1: {
      fontFamily: 'Outfit, sans-serif',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '30.24px',
      color: '#042F85'
    },


    TITLE2: {
      fontFamily: 'Outfit, sans-serif',
      fontWeight: 600,
      fontSize: '22px',
      lineHeight: '27.72px',
      color: '#0D2659'


    },
    TITLE3: {
      fontFamily: 'Outfit, sans-serif',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '22.68px',
      color: '#0D2659'


    },


    LABEL: {
      fontFamily: "Outfit-Regular",
      fontSize: "18px",

      // fontSize: 18,
      // fontWeightMedium: 500,
      // lineHeight: '22px',
      // textAlign: 'left',
    },
    h1: {
      fontSize: 78,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.extraBold,
    },
    h2: {
      fontSize: 70,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.bold,
    },
    h3: {
      fontSize: 60,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.bold,
    },
    h4: {
      fontSize: 48,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.bold,

    },
    h5: {
      fontSize: 36,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.semiBold,
    },

    h6: {
      fontSize: 30,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.bold,
    },
    Body: {
      fontSize: 16,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.regular,
      lineHeight: '20.16px'
    },
    BodyBold: {
      fontSize: 16,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.semiBold,
    },
    Small: {
      fontSize: 14,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.regular,
    },
    SmallBold: {
      fontSize: 14,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.semiBold,
    },
    Info: {
      fontSize: 12,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.regular,
    },
    error: {
      fontSize: 10,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.regular,
    },

    content: {
      fontSize: 30,
      fontFamily: primaryFontFamily,
      fontWeight: fontWeights.regular,


    },

    body1: {
      fontSize: "16px",
    },
    body2: {
      fontSize: "14px",
    },
    body3: {
      fontSize: "18px",
    },
    overline: {
      fontSize: "12px",
    },
    caption: {
      fontSize: "10px",
    },
  },
});

// theme = {
//   ...theme,
//   typography: {
//     ...theme.typography,
//     h1: {
//       ...theme.typography.h1,
//       [theme.breakpoints.between("sm", "md")]: {
//         fontSize: "36px",
//       },
//       [theme.breakpoints.between("xs", "sm")]: {
//         fontSize: "28px",
//       },
//     },
//     h2: {
//       ...theme.typography.h2,
//       [theme.breakpoints.between("sm", "md")]: {
//         fontSize: "27px",
//       },
//       [theme.breakpoints.between("xs", "sm")]: {
//         fontSize: "22px",
//       },
//     },
//     h3: {
//       ...theme.typography.h3,
//       [theme.breakpoints.between("sm", "md")]: {
//         fontSize: "21px",
//       },
//       [theme.breakpoints.between("xs", "sm")]: {
//         fontSize: "18px",
//       },
//     },
//     h4: {
//       ...theme.typography.h4,
//       [theme.breakpoints.between("sm", "md")]: {
//         fontSize: "18px",
//       },
//       [theme.breakpoints.between("xs", "sm")]: {
//         fontSize: "16px",
//       },
//     },
//     h5: {
//       ...theme.typography.h5,
//       [theme.breakpoints.between("sm", "md")]: {
//         fontSize: "16px",
//       },
//       [theme.breakpoints.between("xs", "sm")]: {
//         fontSize: "15px",
//       },
//     },
//     h6: {
//       ...theme.typography.h6,
//       [theme.breakpoints.between("sm", "md")]: {
//         fontSize: "15px",
//       },
//       [theme.breakpoints.between("xs", "sm")]: {
//         fontSize: "14px",
//       },
//     },
//     body1: {
//       ...theme.typography.body1,
//       [theme.breakpoints.between("sm", "md")]: {
//         fontSize: "15px",
//       },
//       [theme.breakpoints.between("xs", "sm")]: {
//         fontSize: "14px",
//       },
//     },
//     body2: {
//       ...theme.typography.body2,
//       [theme.breakpoints.between("sm", "md")]: {
//         fontSize: "13px",
//       },
//       [theme.breakpoints.between("xs", "sm")]: {
//         fontSize: "12px",
//       },
//     },
//     body3: {
//       ...theme.typography.body3,
//       [theme.breakpoints.between("sm", "md")]: {
//         fontSize: "17px",
//       },
//       [theme.breakpoints.between("xs", "sm")]: {
//         fontSize: "16px",
//       },
//     },
//     disable: {
//       ...theme.typography.disable,
//       [theme.breakpoints.between("sm", "md")]: {
//         fontSize: "12px",
//       },
//       [theme.breakpoints.between("xs", "sm")]: {
//         fontSize: "8px",
//       },
//     },

//     button: {
//       ...theme.typography.button,
//       [theme.breakpoints.between("sm", "md")]: {
//         fontFamily: "Outfit-Regular",  //bold
//         fontSize: "16px",
//         letterSpacing: " .1rem ",
//         textTransform: "capitalize",
//       },
//       [theme.breakpoints.between("xs", "sm")]: {
//         fontFamily: "Outfit-regular",
//         fontSize: "14px",
//         letterSpacing: "0rem ",
//         textTransform: "capitalize",
//       },
//     },
//   },
// };

export const oneFranHubtheme = theme;
