import React from 'react'
import OFCustomNavbar from '../header/OFCustomNavbar';
import OFFooter from '../footer/OFFooter';
import { Box, Container } from '@mui/material';


export const MainLayout = ({ children }) => (
    <>


        <Box>

            <OFCustomNavbar />


            {children}
            <OFFooter />

        </Box>






    </>
);