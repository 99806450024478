
// import {
//     FormControl,
//     FormControlLabel,
//     Radio,
//     RadioGroup,
//     Typography,
//     FormLabel,
//     Divider,
//     Box
// } from "@mui/material";
// import React from "react";

// export default function RadioButtonGroup({
//     label,
//     sicon,
//     required,
//     data = [],
//     value, // Controlled value
//     onChange, // Controlled change handler
//     direction = "column",
//     labelColor = "false",
//     field,
//     helperText,
//     error = null,
//     labelComponent,
//     borderBottom = false,
//     style,
//     row = true,
//     isViewMode = false, // New prop for view mode
//     ...props
// }) {
//     const errorMessageFontSize = 14;

//     // Determine the label of the selected value
//     // const selectedLabel = data.find(item => item.value === value)?.label || '';
//     const selectedLabel = value === false
//         ? "No"
//         : data.find(item => item.value === value)?.label || '';


//     return (
//         <FormControl
//             component="fieldset"
//             error={Boolean(error)}
//             style={{ width: '100%' }}
//             {...props}
//         >
//             <FormLabel
//                 component="legend"
//                 sx={{
//                     color: isViewMode ? 'grey' : '#000000 !important',
//                     marginBottom: '0.5rem',
//                     display: 'flex',
//                     alignItems: 'center'
//                 }}
//             >
//                 {isViewMode && sicon && (
//                     <span style={{ marginRight: '0.5rem', }}>
//                         {React.isValidElement(sicon) ? sicon : typeof sicon === 'function' ? sicon(24, 24) : <span>{sicon}</span>}
//                     </span>
//                 )}
//                 {label}
//                 {labelComponent ? labelComponent : null}
//                 {required && (
//                     <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
//                         *
//                     </Typography>
//                 )}
//             </FormLabel>


//             {isViewMode ? (
//                 // Display selected value in view mode
//                 <Box
//                     style={{
//                         padding: '8px 16px',



//                         marginLeft: '1rem', // Add space before the options
//                     }}
//                 >
//                     {selectedLabel}
//                 </Box>
//             ) : (
//                 // Render RadioGroup for editing
//                 <RadioGroup
//                     row={row}
//                     value={value || ""} // Use value or empty string
//                     onChange={onChange} // Controlled change handler
//                     style={{
//                         marginLeft: '1rem', // Add space before the options
//                         ...style
//                     }}
//                 >
//                     {data.map(item => (
//                         <FormControlLabel
//                             key={item.value}
//                             value={item.value}
//                             control={<Radio />}
//                             label={item.label}
//                             sx={{
//                                 '& .MuiFormControlLabel-label': {
//                                     color: item.value === value ? 'primary.main' : 'text.secondary',
//                                     transition: 'color 0.3s ease', // Smooth transition
//                                 },
//                                 marginRight: row ? '1rem' : '0', // Space between options horizontally
//                                 marginBottom: row ? '0' : '1rem', // Space between options vertically if not in row
//                             }}
//                         />
//                     ))}
//                 </RadioGroup>
//             )}

//             {borderBottom && <Divider />}
//             {helperText && (
//                 <Typography variant="body2" color="error" style={{ fontSize: errorMessageFontSize, marginTop: '0.5rem' }}>
//                     {helperText}
//                 </Typography>
//             )}
//         </FormControl>
//     );
// }
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    FormLabel,
    Divider,
    Box
} from "@mui/material";
import React from "react";

export default function RadioButtonGroup({
    label,
    sicon,
    required,
    data = [],
    value, // Controlled value
    onChange, // Controlled change handler
    direction = "column",
    labelColor = "false",
    field,
    helperText,
    error = null,
    labelComponent,
    borderBottom = false,
    style,
    row = true,
    isViewMode = false, // New prop for view mode
    ...props
}) {
    const errorMessageFontSize = 14;

    // Determine the label of the selected value
    const selectedLabel = value === false
        ? "No"
        : data.find(item => item.value === value)?.label || '';

    return (
        <FormControl
            component="fieldset"
            error={Boolean(error)}
            style={{ width: '100%' }}
            {...props}
        >
            <FormLabel
                component="legend"
                sx={{
                    color: isViewMode ? 'grey' : '#000000 !important',
                    marginBottom: '0.5rem',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {isViewMode && sicon && (
                    <span style={{ marginRight: '0.5rem', }}>
                        {React.isValidElement(sicon) ? sicon : typeof sicon === 'function' ? sicon(24, 24) : <span>{sicon}</span>}
                    </span>
                )}
                {label}
                {labelComponent ? labelComponent : null}
                {required && (
                    <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
                        *
                    </Typography>
                )}
            </FormLabel>

            {isViewMode ? (
                // Display selected value in view mode
                <Box
                    style={{
                        padding: '8px 16px',
                        marginLeft: '1rem', // Add space before the options
                    }}
                >
                    {selectedLabel}
                </Box>
            ) : (
                // Render RadioGroup for editing
                <RadioGroup
                    row={row}
                    value={value !== undefined ? value : ""} // Use value or empty string for controlled input
                    onChange={onChange} // Controlled change handler
                    style={{
                        marginLeft: '1rem', // Add space before the options
                        ...style
                    }}
                >
                    {data.map(item => (
                        <FormControlLabel
                            key={item.value}
                            value={item.value}
                            control={<Radio />}
                            label={item.label}
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    color: item.value === value ? 'primary.main' : 'text.secondary',
                                    transition: 'color 0.3s ease', // Smooth transition
                                },
                                marginRight: row ? '1rem' : '0', // Space between options horizontally
                                marginBottom: row ? '0' : '1rem', // Space between options vertically if not in row
                            }}
                        />
                    ))}
                </RadioGroup>
            )}

            {borderBottom && <Divider />}
            {helperText && (
                <Typography variant="body2" color="error" style={{ fontSize: errorMessageFontSize, marginTop: '0.5rem' }}>
                    {helperText}
                </Typography>
            )}
        </FormControl>
    );
}
