

import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneFranHubtheme } from '../../../theme/theme';
import { API } from '../../../axios/api-path';
import CustomButton from '../../../helpers/CustomButton';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError, toastSuccess } from '../../../utils/toast-notify';
import { fetchBusinessProfile, fetchCities, fetchCountries, fetchFormInfo, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import LoanPropertyForm from './LoanPropertyForm';
import { booleanValidationNormal, cityValidation, currencyValidation, dropdownValidation, emailValidation, numericValidation, phoneValidation, scoreValidation, stateValidation, textValidation } from '../../../utils/validations';
import { extractParts } from '../../../utils/UtilFunctions';

const LoanProperty = ({ title }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState(null);
    const [isFormData, setIsFormData] = useState(false);


    // const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    useEffect(() => {
        fetchCountries(setCountries);
        fetchStates(setStates, 1);
        fetchIndustries(setIndustries);
        fetchRange(setRanges);
        const fetchData = async () => {
            const path = API.getLoanOnProperty();

            try {
                const response = await fetchFormInfo(path);

                if (response.status === 'success') {
                    if (response.data.length > 0) {
                        setFormData(response.data[response.data.length - 1]);
                        setIsFormData(true);
                    } else {
                        setIsFormData(false);
                    }
                } else {
                    setIsFormData(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);
    const validationSchema = yup.object({
        fullName: textValidation({ message: 'Rrequired' }),
        emailId: emailValidation('Required'),
        mobileNo: phoneValidation,
        residentialAddress: textValidation({ message: 'Rrequired' }),
        location: textValidation({ message: 'Rrequired' }),
        state: stateValidation,
        city: cityValidation,
        purposeOfLoan: dropdownValidation,
        typeOfLoan: dropdownValidation,
        desiredLoanAmount: currencyValidation,
        //preferredLoanTerm: textValidation({ message: 'Rrequired' }),  //checkbox
        monthlyIncome: currencyValidation,
        existingDebt: currencyValidation,





        // creditScore: scoreValidation,
        // additionalRequirements: textValidation({ message: 'Rrequired' }),
        // loanAgainstProperty: booleanValidationNormal('loanAgainstProperty'),
        // typeOfProperty: dropdownValidation,
        //   propertyValue: currencyValidation,
        //  propertyArea: numericValidation

    });

    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue, getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            companyName: '',
            ownerName: '',
            ownerEmail: '',
            businessDescription: '',

        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        country,
        city,
        state,
    }
        = watch({ control });








    useEffect(() => {

        if (country) {
            fetchStates(setStates, country.id);


        }
    }, [country, setValue]);
    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);

    useEffect(() => {

        if (industry) {
            fetchSectors(setIndustrySectors, industry.id);

        }
    }, [industry, setValue]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [industrySectors, setIndustrySectors] = useState([]);
    const [ranges, setRanges] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedRange, setSelectedRange] = useState('');

    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);

    useEffect(() => {
        fetchCountries(setCountries);
        fetchIndustries(setIndustries);
        fetchRange(setRanges);
    }, []);

    useEffect(() => {


        if (selectedCountry) {


            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedIndustry) {
            fetchSectors(setIndustrySectors, selectedIndustry);
        }
    }, [selectedIndustry]);

    const transformData = (data) => {
        return data.map(item => ({
            value: item.id,
            label: item.name
        }));
    };


    // const transformedNoOfFranchise = (data) => data.map(item => ({ value: item.id, label: item.indexTitle }));
    const transformedNoOfFranchise = (data) => {

        return data.map(item => ({
            id: item.id,
            name: item.indexTitle
        }));
    };

    const transformedCommencedYear = (data) => {

        const newValue = [{
            id: data,
            name: data
        }];


        return newValue
    };





    useEffect(() => {
        if (formData) {

            reset({

                fullName: formData?.fullName,
                emailId: formData?.emailId,
                //  mobileNo: formData?.mobileNo,
                residentialAddress: formData?.residentialAddress,
                location: formData?.location,
                state: formData?.state,
                city: formData?.city,
                purposeOfLoan: formData?.purposeOfLoan,
                typeOfLoan: formData?.typeOfLoan,
                desiredLoanAmount: formData?.desiredLoanAmount,
                preferredLoanTerm: formData?.preferredLoanTerm,
                monthlyIncome: formData?.monthlyIncome,
                existingDebt: formData?.existingDebt,
                creditScore: formData?.creditScore,
                additionalRequirements: formData?.additionalRequirements,
                loanAgainstProperty: formData?.loanAgainstProperty === true ? 'Yes' : formData?.loanAgainstProperty === false ? 'No' : '',
                typeOfProperty: formData?.typeOfProperty?.propertyType,
                propertyValue: formData?.propertyValue,
                propertyArea: formData?.propertyArea,


                mobileNo: {
                    countryCode: extractParts(formData?.mobileNo).countryCode || '',
                    phoneNumber: extractParts(formData?.mobileNo).phoneNumber || '',
                },

            });
        }
    }, [formData, reset]);

    const onSubmit = async (data) => {

        const apiCallData = {

            fullName: data?.fullName,
            emailId: data?.emailId,
            //  mobileNo: data?.mobileNo,
            residentialAddress: data?.residentialAddress,
            location: data?.location,
            state: data?.state,
            city: data?.city,
            purposeOfLoan: data?.purposeOfLoan,
            typeOfLoan: data?.typeOfLoan,
            desiredLoanAmount: data?.desiredLoanAmount,
            preferredLoanTerm: data?.preferredLoanTerm,
            monthlyIncome: data?.monthlyIncome,
            existingDebt: data?.existingDebt,
            creditScore: data?.creditScore,
            additionalRequirements: data?.additionalRequirements,
            loanAgainstProperty: data?.loanAgainstProperty === 'Yes' ? true : data?.loanAgainstProperty === 'No' ? false : '',
            typeOfProperty: data?.typeOfProperty?.propertyType,
            propertyValue: data?.propertyValue,
            propertyArea: data?.propertyArea,
            mobileNo: data?.mobileNo?.countryCode + "-" + data.mobileNo?.phoneNumber,

        };


        const path = API.createLoanOnProperty();
        try {
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {


                    setTimeout(() => {
                        handleNext();
                    }, 2000);
                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        // navigate(location.pathname.replace(/[^/]*$/, '3'));
        navigate('/');
    };

    const handleBack = () => {
        navigate('/');
    };


    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <LoanPropertyForm title={title} control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

                    countries={countries}
                    cities={cities}
                    states={states}
                    industries={industries}
                    industrySectors={industrySectors}
                    ranges={ranges}
                    setSelectedCity={setSelectedCity}
                    setSelectedCountry={setSelectedCountry}
                    setSelectedIndustry={setSelectedIndustry}
                    setSelectedSector={setSelectedSector}
                    setSelectedState={setSelectedState}


                />
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: '100%', p: 2 }}
                >
                    <CustomButton
                        type="button"
                        width={{ xs: '75%', sm: '10rem' }}
                        variant="contained"
                        label="Back"
                        onClick={handleBack}
                    />
                    <CustomButton
                        type="submit"
                        width={{ xs: '75%', sm: '14rem' }}
                        variant="contained"
                        label="Save and Next"
                        onClick={handleSubmit(onSubmit)}
                    />
                </Stack>
            </Paper>

        </>
    )
}

export default LoanProperty