
import React from 'react';
import { TextField, Typography, Box, InputAdornment, Stack, InputLabel } from '@mui/material';

const CustomMultiLineInput = ({
    field = {},
    error,
    label,
    labelSize = '16px',
    helperText,
    placeholder = "Enter Describe your Business",
    rows = 4,
    maxLength = 50,
    required = false,
    icon: IconComponent,
    labelRightComponent,
    isViewMode = false,
    onNextLine,
    sicon,
    ...rest
}) => {
    if (isViewMode) {
        return (
            <Box {...rest} mb={2} mt={1}>
                <Stack direction={onNextLine ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', fontSize: labelSize, color: 'grey' }}>
                            {sicon && <InputAdornment position="start">{sicon}</InputAdornment>}
                            <Box sx={{ ml: sicon ? 1 : 0 }}>{label}</Box>
                        </Typography>
                        {required && !isViewMode && (
                            <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
                                *
                            </Typography>
                        )}
                    </Box>
                    <InputLabel>{labelRightComponent}</InputLabel>
                </Stack>
                <Typography
                    variant="body1"
                    sx={{ padding: 1, backgroundColor: '#FBFCFF', ml: 4 }}
                >
                    {field.value || placeholder} {/* Use placeholder if no value */}
                </Typography>
                {helperText && (
                    <Typography variant="body2" color="error" sx={{ fontSize: '14px', marginTop: 0.5 }}>
                        {helperText}
                    </Typography>
                )}
            </Box>
        );
    }

    return (
        <Box mb={2} mt={1}>
            <InputLabel style={{ fontSize: labelSize, color: '#000000', marginBottom: 1 }}>
                {label}
                {required && (
                    <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
                        *
                    </Typography>
                )}
            </InputLabel>
            <TextField
                {...field}
                required={required}
                sx={{ marginTop: 0.5 }}
                fullWidth
                size="large"
                placeholder={placeholder}
                multiline
                rows={rows}
                inputProps={{ maxLength: maxLength }}
                error={!!error}
                helperText={helperText}
                FormHelperTextProps={{
                    sx: { fontSize: '14px', color: 'black', marginLeft: 0, marginTop: 0.5 }
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {IconComponent && <IconComponent />}
                        </InputAdornment>
                    ),
                    ...rest.InputProps
                }}
                {...rest}
            />
        </Box>
    );
};

export default CustomMultiLineInput;
