
import React from 'react';
import { Box, Divider, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Controller } from 'react-hook-form';
import CustomFileUpload from '../../../helpers/CustomFileUpload';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomInput from '../../../helpers/custom-input';
import { OFAddress, OFEditIcon, OFVideoLink } from '../../../icons/CustomIcon';
import { oneFranHubtheme } from '../../../theme/theme';
import MediaUpload from '../../../helpers/MediaUpload';

const AppearanceAndMediaForm = ({ control, errors, resetFiles,




    isDashboardMode,
    setIsEditing,
    isEditing,
    loanSupportRequired,
    previewUrls,
    handleFileUpload,
    handleFileView,
    handleFileClear,
    formData
}) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>
            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3} sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="h5">Franchisor Registration</Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperFranchisor />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }
            <Box paddingX={6} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Appearance and Media</Typography>
                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}
                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={2}>

                    <Grid item xs={12} md={6}>
                        <Box mb={6}>
                            <MediaUpload
                                control={control}
                                name="image1"
                                label="Upload Images (Image - 1)"
                                fileType="image/jpg"
                                accept="image/jpeg"
                                //  resetFile={resetFiles.image1} // Pass the reset state

                                onFileSelect={(file) => handleFileUpload(file, "image1")}
                                onViewSelect={(file) => handleFileView(file, "image1")}
                                onClearMedia={(file) => handleFileClear(file, "image1")}
                                isViewMode={!isEditing}
                                externalPreviewUrl={previewUrls?.image1}
                                initialFileName={formData?.image1?.name}
                            />

                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box mb={6}>
                            <MediaUpload
                                control={control}
                                name="image2"
                                label="Upload Images (Image - 2)"
                                fileType="image/jpg"
                                accept="image/jpeg"
                                // resetFile={resetFiles.image2} // Pass the reset state

                                onFileSelect={(file) => handleFileUpload(file, "image2")}
                                onViewSelect={(file) => handleFileView(file, "image2")}
                                onClearMedia={(file) => handleFileClear(file, "image2")}
                                isViewMode={!isEditing}
                                externalPreviewUrl={previewUrls?.image2}
                                initialFileName={formData?.image2?.name}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box mb={6}>
                            <MediaUpload
                                control={control}
                                name="image3"
                                label="Upload Images (Image - 3)"
                                fileType="image/jpg"
                                accept="image/jpeg"
                                // resetFile={resetFiles.image3} // Pass the reset state

                                onFileSelect={(file) => handleFileUpload(file, "image3")}
                                onViewSelect={(file) => handleFileView(file, "image3")}
                                onClearMedia={(file) => handleFileClear(file, "image3")}
                                isViewMode={!isEditing}
                                externalPreviewUrl={previewUrls?.image3}
                                initialFileName={formData?.image3?.name}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box mb={6}>
                            <MediaUpload
                                control={control}
                                name="image4"
                                label="Upload Images (Image - 4)"
                                fileType="image/jpg"
                                // resetFile={resetFiles.image4} // Pass the reset state

                                onFileSelect={(file) => handleFileUpload(file, "image4")}
                                onViewSelect={(file) => handleFileView(file, "image4")}
                                onClearMedia={(file) => handleFileClear(file, "image4")}
                                isViewMode={!isEditing}
                                externalPreviewUrl={previewUrls?.image4}
                                initialFileName={formData?.image4?.name}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box mb={6}>
                            <MediaUpload
                                control={control}
                                name="image5"
                                label="Upload Images (Image - 5)"
                                fileType="image/jpg"
                                // resetFile={resetFiles.image4} // Pass the reset state

                                onFileSelect={(file) => handleFileUpload(file, "image5")}
                                onViewSelect={(file) => handleFileView(file, "image5")}
                                onClearMedia={(file) => handleFileClear(file, "image5")}
                                isViewMode={!isEditing}
                                externalPreviewUrl={previewUrls?.image4}
                                initialFileName={formData?.image5?.name}
                            />
                        </Box>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Controller
                            name="video1"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Video Links (Promotional or Explainer Videos)"
                                    required
                                    fullWidth sicon={<OFAddress />}
                                    variant="outlined"
                                    error={!!errors.video1}
                                    helperText={errors.video1 ? errors.video1.message : ''}
                                    placeholder={'Enter Video Link'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box mb={6}>
                            <MediaUpload
                                control={control}
                                name="brandLogo"
                                label="Brand Logo"
                                fileType="image/jpg"
                                // resetFile={resetFiles.image4} // Pass the reset state

                                onFileSelect={(file) => handleFileUpload(file, "brandLogo")}
                                onViewSelect={(file) => handleFileView(file, "brandLogo")}
                                onClearMedia={(file) => handleFileClear(file, "brandLogo")}
                                isViewMode={!isEditing}
                                externalPreviewUrl={previewUrls?.image4}
                                initialFileName={formData?.brandLogo?.name}
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </>
    );
};

export default AppearanceAndMediaForm;
