

import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneFranHubtheme } from '../../../theme/theme';
import { API } from '../../../axios/api-path';
import CustomButton from '../../../helpers/CustomButton';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError, toastSuccess } from '../../../utils/toast-notify';
import { fetchBusinessProfile, fetchCities, fetchCountries, fetchFormInfo, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';

import { booleanValidation, booleanValidationNormal, cityValidation, countryValidation, currencyValidation, dropdownValidation, emailValidation, industryValidation, numericValidation, phoneValidation, pincodeValidation, sectorValidation, stateValidation, textValidation, websiteValidation } from '../../../utils/validations';
import DealerBusinessInfoForm from './DealerBusinessInfoForm';
import DealerMarketingDetailsForm from './DealerMarketingDetailsForm';
import { dealerRegistrationState } from '../../../recoil/profile-atom';
import { useRecoilState } from 'recoil';

const DealerMarketingDetails = ({ isDashboardMode }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState(null);
    const [isFormData, setIsFormData] = useState(false);
    // const [outletStates, setOutletStates] = useState([])

    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    const [dealerState, setDealerRegistrationState] = useRecoilState(dealerRegistrationState);


    useEffect(() => {
        fetchCountries(setCountries);
        fetchIndustries(setIndustries);
        fetchRange(setRanges)
        const fetchData = async () => {
            const path = API.getDealerInfo();

            try {
                const response = await fetchFormInfo(path);

                if (response.data.marketingStatus === 'COMPLETE') {
                    setFormData(response.data.marketing);

                    setIsFormData(true);
                } else {
                    setIsFormData(false);
                }
                setDealerRegistrationState((prevState) => ({
                    //  ...prevState,
                    businessInfoStatus: response.data.businessInfoStatus,
                    marketingStatus: response.data.marketingStatus,
                    trainingStatus: response.data.trainingStatus,
                    appearanceStatus: response.data.appearanceStatus,
                }));



            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);



    const validationSchema = yup.object({




        country: countryValidation,
        state: stateValidation,
        city: cityValidation,
        pincode: pincodeValidation,
        //street: textValidation({ message: 'Rrequired' }),


        "investmentAmount": currencyValidation,
        "spaceRequired": numericValidation,
        "margin": currencyValidation,
        "industry": industryValidation,
        "sector": sectorValidation,
        "exclusiveTerritorialRight": booleanValidationNormal('exclusiveTerritorialRight'),
        "specifyTerritory": textValidation({ message: 'Rrequired' }),
        "breakEvenPoint": textValidation({ message: 'Rrequired' }),
        // "propertyPreference": dropdownValidation,
        "aboutBusiness": textValidation({ message: 'Rrequired' }),
        "locationExpansionPlans": textValidation({ message: 'Rrequired' }),

        "productCategories": textValidation({ message: 'Rrequired' }),
        "productNames": textValidation({ message: 'Rrequired' }),
        "productPricingRangeStart": currencyValidation,
        "productPricingRangeEnd": currencyValidation,
        "trainingForProduct": textValidation({ message: 'Rrequired' }),
        "productWarranty": textValidation({ message: 'Rrequired' }),
        // "marketingCollateral": booleanValidationNormal('marketingCollateral'),
        "advertisementSupport": booleanValidationNormal('advertisementSupport'),
        "salesTraining": booleanValidationNormal('salesTraining'),
        "leadGenerationSupport": booleanValidationNormal('leadGenerationSupport'),
        "toolsProvided": booleanValidationNormal('toolsProvided'),
        // "brandingGuidelines": textValidation({ message: 'Rrequired' }),
        "creditTerm": textValidation({ message: 'Rrequired' }),
        "financialSupportForDealer": textValidation({ message: 'Rrequired' }),
        "financialProjectionsYear1": currencyValidation,
        "financialProjectionsYear2": currencyValidation,
        "financialProjectionsYear3": currencyValidation












    });




    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue, getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {




        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        country,
        city,
        state,
        loanSupportRequired,
    }
        = watch({ control });




    useEffect(() => {

        if (country) {
            fetchStates(setStates, country.id);

        }
    }, [country, setValue]);
    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);



    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);


    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [industries, setIndustries] = useState([]);
    const [industrySectors, setIndustrySectors] = useState([]);
    const [ranges, setRanges] = useState([]);

    useEffect(() => {
        if (selectedIndustry) {
            fetchSectors(setIndustrySectors, selectedIndustry);
        }
    }, [selectedIndustry]);

    useEffect(() => {

        if (industry) {
            fetchSectors(setIndustrySectors, industry.id);

        }
    }, [industry, setValue]);

    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);



    useEffect(() => {

        if (selectedCountry) {

            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);



    function extractParts(ownerMobileNo) {
        if (ownerMobileNo != null) {

            const [countryCode, phoneNumber] = ownerMobileNo.split('-');


            return {
                countryCode: countryCode || null,
                phoneNumber: phoneNumber || null
            };
        } else {

            return {
                countryCode: null,
                phoneNumber: null
            };
        }
    }


    useEffect(() => {
        if (formData) {

            reset({

                "investmentAmount": formData?.investmentAmount,
                "spaceRequired": formData?.spaceRequired,
                "margin": formData?.margin,
                "industry": formData?.industry,
                "sector": formData?.sector,
                "exclusiveTerritorialRight": formData?.exclusiveTerritorialRight,
                "specifyTerritory": formData?.specifyTerritory,
                "breakEvenPoint": formData?.breakEvenPoint,
                "propertyPreference": formData?.propertyPreference,
                "aboutBusiness": formData?.aboutBusiness,
                "locationExpansionPlans": formData?.locationExpansionPlans,

                "productCategories": formData?.productCategories,
                "productNames": formData?.productNames,
                "productPricingRangeStart": formData?.productPricingRangeStart,
                "productPricingRangeEnd": formData?.productPricingRangeEnd,
                "trainingForProduct": formData?.trainingForProduct,
                "productWarranty": formData?.productWarranty,
                "marketingCollateral": formData?.marketingCollateral,
                "advertisementSupport": formData?.advertisementSupport,
                "salesTraining": formData?.salesTraining,
                "leadGenerationSupport": formData?.leadGenerationSupport,
                "toolsProvided": formData?.toolsProvided,
                "brandingGuidelines": formData?.brandingGuidelines,
                "creditTerm": formData?.creditTerm,
                "financialSupportForDealer": formData?.financialSupportForDealer,
                "financialProjectionsYear1": formData?.financialProjectionsYear1,
                "financialProjectionsYear2": formData?.financialProjectionsYear2,
                "financialProjectionsYear3": formData?.financialProjectionsYear3,

                "street": formData?.street,
                "city": formData?.city,
                "state": formData?.state,
                "country": formData?.country,
                "pincode": formData?.pincode,



                ownerMobileNo: {
                    countryCode: extractParts(formData?.ownerMobileNo).countryCode || '',
                    phoneNumber: extractParts(formData?.ownerMobileNo).phoneNumber || '',
                },
                ownerAlternateMobileNo: {
                    countryCode: extractParts(formData?.ownerAlternateMobileNo).countryCode || '',
                    phoneNumber: extractParts(formData?.ownerAlternateMobileNo).phoneNumber || '',
                },




            });
        }
    }, [formData, reset]);

    const onSubmit = async (data) => {

        const apiCallData = {



            "investmentAmount": data?.investmentAmount,
            "spaceRequired": data?.spaceRequired,
            "margin": data?.margin,
            "industry": data?.industry,
            "sector": data?.sector,
            "exclusiveTerritorialRight": data?.exclusiveTerritorialRight,
            "specifyTerritory": data?.specifyTerritory,
            "breakEvenPoint": data?.breakEvenPoint,
            "propertyPreference": data?.propertyPreference,
            "aboutBusiness": data?.aboutBusiness,
            "locationExpansionPlans": data?.locationExpansionPlans,

            "productCategories": data?.productCategories,
            "productNames": data?.productNames,
            "productPricingRangeStart": data?.productPricingRangeStart,
            "productPricingRangeEnd": data?.productPricingRangeEnd,
            "trainingForProduct": data?.trainingForProduct,
            "productWarranty": data?.productWarranty,
            "marketingCollateral": data?.marketingCollateral,
            "advertisementSupport": data?.advertisementSupport,
            "salesTraining": data?.salesTraining,
            "leadGenerationSupport": data?.leadGenerationSupport,
            "toolsProvided": data?.toolsProvided,
            "brandingGuidelines": data?.brandingGuidelines,
            "creditTerm": data?.creditTerm,
            "financialSupportForDealer": data?.financialSupportForDealer,
            "financialProjectionsYear1": data?.financialProjectionsYear1,
            "financialProjectionsYear2": data?.financialProjectionsYear2,
            "financialProjectionsYear3": data?.financialProjectionsYear3,




            street: data?.street,
            city: { id: parseInt(data?.city?.id, 10) },
            state: { id: parseInt(data?.state?.id, 10) },
            pincode: data?.pincode,
            country: { id: parseInt(data?.country?.id, 10) }



        };


        const path = API.signupDealerMarketingInfo();
        try {

            console.log(" Submit data " + JSON.stringify(apiCallData, null, 2))
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {

                    if (isDashboardMode) {
                        setIsEditing(false)

                    }
                    else {
                        handleNext();


                    }



                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '4'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '2'));
    };


    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <DealerMarketingDetailsForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

                    countries={countries}
                    cities={cities}
                    states={states}
                    industries={industries}
                    industrySectors={industrySectors}
                    setSelectedCity={setSelectedCity}
                    setSelectedCountry={setSelectedCountry}
                    setSelectedIndustry={setSelectedIndustry}
                    setSelectedSector={setSelectedSector}
                    ranges={ranges}
                    setSelectedState={setSelectedState}
                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}


                />


                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}



            </Paper>

        </>
    )
}

export default DealerMarketingDetails