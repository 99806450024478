
import React, { useEffect, useState } from "react";
import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/system';

// Create a styled component for the arrow icon with margin-right
const CustomIconComponent = styled(KeyboardArrowDownIcon)({
    marginRight: '10px',
});

export default function CustomDropdownSingle({
    option = [], // Array of strings
    defaultValue = "",
    changeCallBack = () => { },
    disabled,
    id,
    justifyContent = 'center',
    state,
    style,
    label,
    required = false,
    field,
    colorDisabled,
    limitWidth,
    helperText,
    labelComponent,
    backgroundColor,
    labelRightComponent,
    error = null,
    IconComponent = CustomIconComponent, // Use the custom icon with spacing
    sicon,
    eicon,
    isViewMode = false, // New prop to toggle view mode
}) {
    const [status, setStatus] = useState(defaultValue);

    useEffect(() => {
        setStatus(defaultValue); // Sync status with defaultValue
    }, [defaultValue]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setStatus(newValue);
        changeCallBack(newValue);
        if (field?.onChange) {
            field.onChange(newValue);
        }
    };

    const renderSelectValue = (selected) => {
        if (!selected || !option.includes(selected)) {
            return <> </>; // Return an empty space if no value is selected
        }
        return selected;
    };

    return (
        <Box justifyContent={justifyContent} mt={0} mb={1}>
            {labelComponent ? labelComponent : (
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <InputLabel style={{ color: '#000000', marginBottom: '0.5rem' }}>
                        {isViewMode ? (
                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'grey' }}>
                                {sicon && <InputAdornment position="start">{sicon}</InputAdornment>}
                                <Box sx={{ ml: sicon ? 1 : 0 }}>{label}</Box>
                            </Typography>
                        ) : (
                            label
                        )}
                        {required && !isViewMode && (
                            <Typography variant="small" color="error" sx={{ marginLeft: 1 }}>
                                *
                            </Typography>
                        )}
                    </InputLabel>
                    <InputLabel>{labelRightComponent}</InputLabel>
                </Stack>
            )}
            <FormControl style={limitWidth ? { minWidth: '50%' } : {}} fullWidth={!limitWidth}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    {sicon && !isViewMode && (
                        <IconButton style={{ position: 'absolute', left: 6, zIndex: 1 }}>
                            {sicon}
                        </IconButton>
                    )}
                    {isViewMode ? (
                        <Typography style={{ ...style, paddingLeft: sicon ? 40 : 16, paddingRight: eicon ? 40 : 16, backgroundColor: '#FBFCFF', width: '100%' }}>
                            {renderSelectValue(status)}
                        </Typography>
                    ) : (
                        <Select
                            style={{
                                ...style,
                                paddingLeft: sicon ? 40 : 16,
                                paddingRight: eicon ? 40 : 16,
                                backgroundColor: '#FBFCFF',
                                width: '100%',
                                position: 'relative',
                            }}
                            value={status}
                            onChange={handleChange}
                            IconComponent={IconComponent}
                            displayEmpty
                            renderValue={renderSelectValue}
                        >
                            {option.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    {eicon && (
                        <IconButton style={{ position: 'absolute', right: 0 }}>
                            {eicon}
                        </IconButton>
                    )}
                </Box>
            </FormControl>
            {helperText && (
                <Typography variant="body2" color="error" style={{ fontSize: 14, marginTop: '0.5rem' }}>
                    {helperText}
                </Typography>
            )}
        </Box>
    );
}
