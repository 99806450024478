
import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { oneFranHubtheme } from "../../theme/theme";
import { Box } from '@mui/system';
import ExpertSearchForm from './ExpertSearchForm';

const ExpertTalk = () => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    return (
        <Box

        >
            <Box
                sx={{
                    bgcolor: '#FFFFFF',

                    paddingY: isMobile ? 1 : 0,
                    paddingX: 1
                }}
            >
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"

                    sx={{
                        width: '100%',
                        paddingX: 0,
                        marginX: 0,
                    }}
                >
                    <Grid item xs={12} md={6}>
                        <Box mt={isMobile ? 2 : 6} mb={isMobile ? 2 : 6} >

                            <Box mt={0}
                                flexDirection={isMobile ? 'column' : 'row'}
                                alignItems="center">
                                <Typography
                                    variant={isMobile ? 'h6' : 'h4'}
                                    sx={{
                                        textAlign: isMobile ? 'center' : 'left',
                                        paddingBottom: 2

                                    }} paragraph
                                >
                                    To get more insights,
                                </Typography>
                                <Typography
                                    variant={isMobile ? 'h6' : 'h4'}
                                    sx={{
                                        textAlign: isMobile ? 'center' : 'left',

                                    }} paragraph
                                >
                                    Talk to our experts
                                </Typography>

                            </Box>
                            <Box mt={4}>
                                <Typography
                                    //variant='content'
                                    sx={{
                                        //  fontWeight: 400,
                                        // fontSize: isMobile ? '0.875rem' : isTablet ? '1rem' : '1.125rem',
                                        lineHeight: '30px',
                                        textAlign: isMobile ? 'center' : 'left',
                                        mt: 2,
                                    }}
                                >
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box

                        >
                            <ExpertSearchForm />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ExpertTalk;
