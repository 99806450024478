
import React, { useState } from 'react';
import { Grid, Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import TopFrCard from './TopFrCard';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const CardDisplay = ({ title, description, noOfCards, defaultCard, cardData = [] }) => {
    const [showAll, setShowAll] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const cardsToShow = Array.isArray(cardData) ? (showAll ? cardData : cardData.slice(0, defaultCard)) : [];

    const handleViewMore = () => {
        navigate('/search-page');
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, paddingTop: 4 }}>
                <Grid container >
                    <Grid item md={12} mb={1}>
                        <Typography variant='H4'
                            style={{

                                fontSize: '2.25rem',
                                color: '#0d2659',

                            }}
                        >
                            {title}
                        </Typography>
                    </Grid>


                    <Grid item md={10} mb={1}>
                        <Typography
                            style={{
                                maxWidth: isMobile ? '100%' : '80%',
                                fontWeight: 400,
                                fontSize: '18px',
                                lineHeight: '30px',
                                color: '#666',
                            }}
                        >
                            {description}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={2}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',

                            width: '100%',
                        }}
                    >
                        <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ width: '100%', paddingRight: 7 }}>
                            {!showAll && cardData.length > defaultCard && (
                                <>
                                    <Typography
                                        component="span"
                                        onClick={handleViewMore}
                                        sx={{
                                            color: '#0D2659',
                                            cursor: 'pointer',
                                            //textDecoration: 'underline',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: 1, // Space between text and icon
                                        }}
                                    >
                                        View more
                                    </Typography>
                                    <ArrowForward
                                        sx={{
                                            color: '#0D2659',
                                            fontSize: '1rem',
                                        }}
                                    />
                                </>
                            )}
                            {showAll && (
                                <Typography
                                    component="span"
                                    onClick={() => setShowAll(false)}
                                    sx={{
                                        color: '#0D2659',
                                        cursor: 'pointer',
                                        //textDecoration: 'underline',
                                        marginLeft: 2, // Space before "View Less"
                                    }}
                                >
                                    View less
                                </Typography>
                            )}
                        </Box>
                    </Grid>


                </Grid>



            </Box>

            {Array.isArray(cardData) && cardData.length === 0 ? (
                <Box paddingY={1} paddingX={1} >
                    <Typography
                        style={{
                            textAlign: 'left',
                            color: 'red',
                            fontSize: '1.25rem',
                        }}
                    >
                        No cards available.
                    </Typography>
                </Box>
            ) : (
                <Grid container spacing={3} padding={isMobile ? 2 : 1} >
                    {cardsToShow.map((card, index) => (

                        < TopFrCard
                            key={card?.entityCode}  // unique
                            entityCode={card?.entityCode}
                            imageId={card?.logo?.id}
                            category={card?.industryName}
                            location={card?.location}
                            description={card?.businessName}
                            investmentRange={card?.investmentRange}
                            profitMargin={card?.profitMarginPerYear}
                            outlets={card?.franchiseOutlets}
                            noOfCards={noOfCards}
                            card={card}
                        />

                    ))}
                </Grid>
            )}
        </>
    );
};

export default CardDisplay;
