import axios from "axios";
import { API } from "../axios/api-path";
import { fetchSingleImage } from "../axios/api-service";
import { postApiFileUpload } from "../axios/axios-methods";
import { toastError, toastSuccess } from "./toast-notify";

export const uploadMedia = async (data) => {
    const { key, file, category, userCode } = data;

    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    formData.append('category', category);
    formData.append('userCode', userCode);

    try {
        const response = await postApiFileUpload({ data: formData, path: API.fileUpload() });
        toastSuccess(`File successfully uploaded: ${response.name}`);
        return { key, ...response };
    } catch (error) {
        console.error(`File upload error (${key}):`, error);
        toastError(`File upload failed for ${key}: ${error.message}`);
        return null;
    }
};



export const viewMedia = async (id) => {
    if (!id) return null;

    try {

        const fileUrl = await fetchSingleImage(API.getSingleImage(), id);
        return fileUrl;
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
};
