import { Box, Paper, Typography, Grid } from '@mui/material';
import React from 'react';
import { OFMailAtSign, OFCallIcon } from '../../../icons/CustomIcon'
import { ReactComponent as WhatsappIcon } from "../../../assets/icons/WhatsApp.svg"


const SupportPage = () => {
    return (
        <div>
            <Box
                paddingX={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={1}>
                    {/* Add any other content here */}
                </Box>

                {/* <Paper
                    elevation={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 4,
                        paddingBottom: 4,
                        marginBottom: 4,
                        marginTop: 4,
                        backgroundColor: '#FFF5F1'
                    }}
                >
                    <Box padding={2} sx={{ position: 'relative' }}>
                        <Typography variant='TITLE3' noWrap>Support Manager</Typography>
                    </Box>
                    <Grid container paddingX={2} spacing={1} ml={2}>
                        <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="flex-start">
                            <Box display="flex" alignItems="center">
                                <OFCallIcon />
                                <Box ml={1} display="flex" flexDirection="column" justifyContent="center">
                                    <Typography>Mobile Number</Typography>
                                    <Typography>+91 98765 43210</Typography>
                                </Box>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="flex-start">
                            <Box display="flex" alignItems="center">
                                <WhatsappIcon />
                                <Box ml={1} display="flex" flexDirection="column" justifyContent="center">
                                    <Typography>Whatsapp</Typography>
                                    <Typography>+91 98765 43210</Typography>
                                </Box>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="flex-start">
                            <Box display="flex" alignItems="center">
                                <OFMailAtSign width={36} height={36} />
                                <Box ml={1} display="flex" flexDirection="column" justifyContent="center">
                                    <Typography>Email ID</Typography>
                                    <Typography>support@onfranhub.com</Typography>
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </Paper> */}
                <Paper
                    elevation={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 4,
                        paddingBottom: 4,
                        marginBottom: 4,
                        marginTop: 4,
                        backgroundColor: '#FFF5F1',
                        maxWidth: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <Box padding={2} sx={{ position: 'relative', overflow: 'hidden' }}>
                        <Typography variant='TITLE3' noWrap>
                            Support Manager
                        </Typography>
                    </Box>
                    <Grid container paddingX={2} spacing={1} ml={2}>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                        >
                            <Box display="flex" alignItems="center">
                                <OFCallIcon />
                                <Box
                                    ml={1}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{ overflow: 'hidden' }}
                                >
                                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        Mobile Number
                                    </Typography>
                                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        +91 98765 43210
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                        >
                            <Box display="flex" alignItems="center">
                                <WhatsappIcon />
                                <Box
                                    ml={1}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{ overflow: 'hidden' }}
                                >
                                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        Whatsapp
                                    </Typography>
                                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        +91 98765 43210
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                        >
                            <Box display="flex" alignItems="center">
                                <OFMailAtSign width={36} height={36} />
                                <Box
                                    ml={1}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{ overflow: 'hidden' }}
                                >
                                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        Email ID
                                    </Typography>
                                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        support@onfranhub.com
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>


                {/* <Paper
                    elevation={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 4,
                        paddingBottom: 4,
                        backgroundColor: '#F1F6FF'
                    }}
                >
                    <Box padding={2}>
                        <Typography variant='TITLE3'>Advertise with us</Typography>
                    </Box>
                    <Grid container paddingX={2} spacing={1} ml={2}>
                        <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="flex-start">
                            <Box display="flex" alignItems="center">
                                <OFCallIcon />
                                <Box ml={1} display="flex" flexDirection="column" justifyContent="center">
                                    <Typography>Mobile Number</Typography>
                                    <Typography>+91 98765 43210</Typography>
                                </Box>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={4} display="flex" alignItems="center">
                            <Box display="flex" alignItems="center">
                                <WhatsappIcon />
                                <Box ml={1} display="flex" flexDirection="column" justifyContent="center">
                                    <Typography>Whatsapp</Typography>
                                    <Typography>+91 98765 43210</Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="flex-start">
                            <Box display="flex" alignItems="center">
                                <OFMailAtSign width={36} height={36} color='#717171' />

                                <Box ml={1} display="flex" flexDirection="column" justifyContent="center">
                                    <Typography>Email ID</Typography>
                                    <Typography>advertise@onfranhub.com</Typography>
                                </Box>

                            </Box>

                        </Grid>
                    </Grid>
                </Paper> */}
                <Paper
                    elevation={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 4,
                        paddingBottom: 4,
                        backgroundColor: '#F1F6FF',
                        maxWidth: '100%', // Ensures the Paper does not exceed its parent’s width
                        overflow: 'hidden', // Prevents any overflow content
                    }}
                >
                    <Box padding={2}>
                        <Typography variant='TITLE3' noWrap>
                            Advertise with us
                        </Typography>
                    </Box>
                    <Grid container paddingX={2} spacing={1} ml={2}>
                        <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="flex-start">
                            <Box display="flex" alignItems="center">
                                <OFCallIcon />
                                <Box ml={2} display="flex" flexDirection="column" justifyContent="center" sx={{ overflow: 'hidden' }}>
                                    <Typography variant="body2" noWrap>
                                        Mobile Number
                                    </Typography>
                                    <Typography variant="body2" noWrap>
                                        +91 98765 43210
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="flex-start">
                            <Box display="flex" alignItems="center">
                                <WhatsappIcon />
                                <Box ml={2} display="flex" flexDirection="column" justifyContent="center" sx={{ overflow: 'hidden' }}>
                                    <Typography variant="body2" noWrap>
                                        Whatsapp
                                    </Typography>
                                    <Typography variant="body2" noWrap>
                                        +91 98765 43210
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="flex-start">
                            <Box display="flex" alignItems="center">
                                <OFMailAtSign width={36} height={36} color='#717171' />
                                <Box ml={2} display="flex" flexDirection="column" justifyContent="center" sx={{ overflow: 'hidden' }}>
                                    <Typography variant="body2" noWrap>
                                        Email ID
                                    </Typography>
                                    <Typography variant="body2" noWrap>
                                        advertise@onfranhub.com
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>

            </Box>
        </div>
    );
}

export default SupportPage;
