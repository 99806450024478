

import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { API } from '../../../axios/api-path';
import CustomButton from '../../../helpers/CustomButton';
import { postApiCallWithCred, postApiFileUpload } from '../../../axios/axios-methods';
import { toastError, toastSuccess } from '../../../utils/toast-notify';
import { fetchFormInfo, fetchSingleImage, } from '../../../axios/api-service';
import { useRecoilState } from 'recoil';
import { perInfoState } from '../../../recoil/profile-atom';
import { numericValidation, textValidation } from '../../../utils/validations';
import { uploadMedia, viewMedia, viewSingleMedia } from '../../../utils/MediaOperatiosns';
import BCAppearanceAndMediaForm from './BCAppearanceAndMediaForm';




const BCAppearanceAndMedia = ({ isDashboardMode }) => {

    const [userInfo, setUserInfo] = useRecoilState(perInfoState);
    const [resetFiles, setResetFiles] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    const [appearanceInfo, setAppearanceInfo] = useState(null)
    const [isAppearanceInfo, setIsAppearanceInfo] = useState(false)
    const [formData, setFormData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const path = API.getPartnerInfo();

            try {
                const response = await fetchFormInfo(path);

                if (response.data.appearanceMediaStatus === 'COMPLETE') {
                    setFormData(response.data.appearanceMedia);
                    setBusinessPhotos(response.data.appearanceMedia)
                    setIsAppearanceInfo(true);
                } else {
                    setIsAppearanceInfo(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);


    const validationSchema = yup.object({
        brandLogo: yup
            .mixed()
            .required('Brand logo is required'),




    });




    const [businessPhotos, setBusinessPhotos] = useState(formData);




    const handleFileUpload = async (file, name) => {
        const uploadData = { file, category: 'image/jpg', userCode: userInfo?.userCode };
        const response = await uploadMedia(uploadData);
        console.log("Media uploaded:", JSON.stringify(response, null, 2));

        if (response) {
            const responseData = {
                id: response.id,
                name: response.name,
                type: response.type,
                path: response.path,
                createdAt: response.createdAt,
            };



            setBusinessPhotos((prevPhotos) => ({
                ...prevPhotos,
                [name]: responseData, // Overwrite
            }));
        }
    };


    const handleFileClear = async (file, name) => {

        setBusinessPhotos((prevState) => ({
            ...prevState,
            [name]: null,
        }));
    }

    console.log(" All Media uploaded " + JSON.stringify(businessPhotos, null, 2))





    const handleFileView = async (file, name) => {
        console.log("View file " + name);
        const fetchedMedia = formData[name] || businessPhotos[name];

        if (fetchedMedia?.id) {
            try {
                const fileUrl = await viewMedia(fetchedMedia.id);

                if (fileUrl) {

                    window.open(fileUrl, '_blank');
                } else {
                    console.error('Failed to fetch the file URL.');
                }
            } catch (error) {
                console.error('Error viewing file:', error);
            }
        }
    };







    const { control, handleSubmit, setValue, getValues, formState: { errors }, reset } = useForm({
        defaultValues: {

        },
        resolver: yupResolver(validationSchema),
    });




    const onSubmit = async (data) => {




        const path = API.signupPartnerAppearanceInfo();


        const apiCallData = {
            ...businessPhotos,
            "video1": data?.sellingPrice,

        };


        try {
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {
                    if (isDashboardMode) {
                        setIsEditing(false);
                    } else {
                        handleNext();
                    }
                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };


    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '7'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '5'));
    };





    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);











    useEffect(() => {
        if (formData) {

            reset({

                "video1": formData?.video1,


            });
        }
    }, [formData, reset]);




    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <BCAppearanceAndMediaForm control={control} errors={errors}
                    //  watch={watch}
                    setValue={setValue} getValues={getValues}


                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    handleFileView={handleFileView}
                    handleFileUpload={handleFileUpload}
                    handleFileClear={handleFileClear}
                    formData={formData}

                />


                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}



            </Paper>

        </>
    )
}

export default BCAppearanceAndMedia