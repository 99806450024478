
import React, { useEffect, useState } from 'react';
import { Grid, Paper, Box, IconButton, Typography, useMediaQuery, Container } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { oneFranHubtheme } from '../../theme/theme';
import CustomView from '../../helpers/CustomView';
import { OFBackIcon, OFNextIcon } from '../../icons/CustomIcon';
import { useLocation } from 'react-router-dom';
import { fetchPropertyInfo, fetchSingleCardDetails, fetchSingleImage } from '../../axios/api-service';
import { API } from '../../axios/api-path';
import { GenericCard } from '../../utils/GenericCard'
import BusinessInterestForm from './BusinessInterestForm';
import { getDetailsAsText } from '../../utils/UtilFunctions';
import Advertisement1 from './SecondAdvertisement';
import SecondAdvertisement from './SecondAdvertisement';

const DealersDetailedPage = () => {



    const location = useLocation();
    const {
        entityCode,
        image,
        category,
        location: franchiseLocation,
        description,
        investmentRange,
        profitMargin,
        outlets
    } = location.state || {};



    const [cardDetails, setCardDetails] = useState([]);
    const [appearanceMedia, setAppearanceMedia] = useState([]);
    const [images, setImages] = useState([]);
    const [img2, setImg2] = useState(1)
    const [img3, setImg3] = useState(2)
    const [img4, setImg4] = useState(3)

    const [currentImage, setCurrentImage] = useState(0);

    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));



    useEffect(() => {
        const fetchData = async () => {
            const path = API.getDealerSingleCardDetails();
            try {
                const response = await fetchSingleCardDetails({ dealerCode: entityCode, }, path);

                if (response.status === "success" && response.data) {
                    setCardDetails(response.data);

                    setAppearanceMedia(response.data.appearanceMedia)
                } else {
                    // Handle unexpected response
                    console.error("Unexpected response structure", response);
                }
            } catch (err) {
                // Handle error
                console.error("Error fetching data", err);
            }
        };

        if (entityCode) fetchData();
    }, []);












    useEffect(() => {
        const fetchData = async () => {
            const path = API.getDealerSingleCardDetails();
            try {
                const response = await fetchSingleCardDetails({ dealerCode: entityCode, }, path);

                if (response.status === "success" && response.data) {
                    setCardDetails(response.data);

                    setAppearanceMedia(response.data.appearanceMedia)
                } else {
                    // Handle unexpected response
                    console.error("Unexpected response structure", response);
                }
            } catch (err) {
                // Handle error
                console.error("Error fetching data", err);
            }
        };

        if (entityCode) fetchData();
    }, [entityCode]);




    useEffect(() => {
        if (!appearanceMedia) {
            console.error('appearanceMedia is undefined');
            return;
        }

        const fetchImages = async () => {
            const path = API.getSingleImage();

            const imageKeys = ['image1', 'image2', 'image3', 'image4', 'brandLogo'];
            try {

                const fetchedImages = await Promise.all(
                    imageKeys.map(async (key) => {
                        if (appearanceMedia[key] && appearanceMedia[key].id) {
                            try {

                                const image = await fetchSingleImage(path, appearanceMedia[key].id);
                                return image;
                            } catch (err) {
                                console.error(`Error fetching ${key}:`, err);
                                return null;
                            }
                        }
                        return null;
                    })
                );


                setImages(fetchedImages.filter(Boolean));
            } catch (err) {
                console.error('Error fetching images:', err);
            } finally {
                // console.log('Image fetching process completed.');
            }
        };

        fetchImages();
    }, [appearanceMedia]);








    // const handleNext = () => {
    //     setCurrentImage((prev) => (prev + 1) % images.length);
    // };

    // const handleBack = () => {
    //     setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
    // };
    const handleNext = () => {
        setCurrentImage((prev) => {
            const newCurrent = (prev + 1) % images.length;
            setImg2((newCurrent + 1) % images.length);
            setImg3((newCurrent + 2) % images.length);
            setImg4((newCurrent + 3) % images.length);
            return newCurrent;
        });
    };

    const handleBack = () => {
        setCurrentImage((prev) => {
            const newCurrent = (prev - 1 + images.length) % images.length;
            setImg2((newCurrent + 1) % images.length);
            setImg3((newCurrent + 2) % images.length);
            setImg4((newCurrent + 3) % images.length);
            return newCurrent;
        });
    };

    useEffect(() => {
        const getAdjacentImages = () => {
            if (images.length < 4) {
                return images;
            }
            const result = [];
            for (let i = 1; i <= 3; i++) {
                result.push(images[(currentImage + i) % images.length]);
            }

            return result;
        };
        getAdjacentImages()
    }, [currentImage])

    const radius1 = '18px'
    const radius2 = '24px'
    const businessName = cardDetails?.businessInfo?.companyName
    const details1 = [
        { label: 'Company Name', value: cardDetails?.businessInfo?.companyName },
        { label: 'Business description ', value: cardDetails?.businessInfo?.establishmentYear },

    ];
    const details2 = [

        { label: 'Investment amount', value: cardDetails?.marketing?.investmentAmount },
        { label: 'Space required', value: cardDetails?.marketing?.spaceRequired },
        { label: 'Margin commission', value: cardDetails?.marketing?.margin },
        { label: 'Breakeven point', value: cardDetails?.marketing?.breakEvenPoint },
        { label: 'Property preference', value: cardDetails?.marketing?.propertyPreference?.name },
        { label: 'Product names', value: cardDetails?.marketing?.productNames },
        { label: 'Product Pricing ranges', value: `${cardDetails?.marketing?.productPricingRangeStart} - ${cardDetails?.marketing?.productPricingRangeEnd}` },
        { label: 'Product training', value: cardDetails?.marketing?.trainingForProduct },
        { label: 'Product warranty', value: cardDetails?.marketing?.productWarranty },
        { label: 'Marketing collateral', value: getDetailsAsText(cardDetails?.marketing?.marketingCollateral) },
        { label: 'Sales training', value: cardDetails?.marketing?.salesTraining ? 'Yes' : 'No' },
        { label: 'Lead generation support', value: cardDetails?.marketing?.leadGenerationSupport },
        { label: 'Tools/ Applications', value: cardDetails?.marketing?.toolsProvided ? 'Yes' : 'No' },
        { label: 'Branding guidelines', value: cardDetails?.marketing?.brandingGuidelines },
        { label: 'Credit Terms', value: cardDetails?.marketing?.creditTerm },
        { label: 'Financing support for dealers/distributors', value: cardDetails?.marketing?.financialSupportForDealer ? 'Yes' : 'No' },
        { label: 'Financial projections (Year-1)', value: cardDetails?.marketing?.financialProjectionsYear1 },
        { label: 'Financial projections (Year-2)', value: cardDetails?.marketing?.financialProjectionsYear2 },
        { label: 'Financial projections (Year-3)', value: cardDetails?.marketing?.financialProjectionsYear3 },




    ];

    const details3 = [
        { label: 'Initial Training', value: cardDetails?.training?.initialTrainingDays },
        { label: 'Location of training', value: getDetailsAsText(cardDetails?.training?.locationOfTraining) },
        { label: 'Ongoing training', value: cardDetails?.training?.ongoingTraining ? 'Yes' : 'No' },
        { label: 'Operational support', value: cardDetails?.training?.operationalSupport ? 'Yes' : 'No' },
        { label: 'Customer support ', value: cardDetails?.training?.customerSupport ? 'Yes' : 'No' },
        { label: 'Logistic support', value: cardDetails?.training?.logisticSupport ? 'Yes' : 'No' },
        { label: 'Stock Management Guidance', value: cardDetails?.training?.stockManagementGuidance ? 'Yes' : 'No' },
        { label: 'Licenses permits requirements', value: cardDetails?.training?.licensesPermits },
        { label: 'Compliance with local regulations', value: cardDetails?.training?.compliance },


    ];



    return (








        <Box sx={{ backgroundColor: '#F1F5F9', boxSizing: 'border-box', paddingX: 1 }}>
            <Container maxWidth={'xl'}>
                <Grid container spacing={2} style={{ padding: 4 }}>

                    <Grid item xs={12} md={9}>
                        <Paper elevation={3} style={{
                            height: '100%',

                            display: 'flex',
                            //   justifyContent: 'center',
                            //   alignItems: 'center',
                            // height: '40vh',
                            position: 'relative',
                            borderTopLeftRadius: '24px', // Rounds the top-left corner
                            borderBottomLeftRadius: '24px', // Rounds the bottom-left corner
                            // overflow: 'hidden', // Ensures content follows the rounded corners

                        }}>

                            {/* Image Display */}
                            {/* <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '40vh',
                                    position: 'relative',
                                }}
                            >
                               
                                {images.length > 0 && (
                                    <img
                                        src={images[currentImage]}
                                        alt="current"
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    />
                                )}

                            </Box> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 'auto',
                                    position: 'relative',

                                }}
                            >
                                {images.length > 0 ? (
                                    <img
                                        src={images[currentImage]}
                                        alt="current"
                                        style={{
                                            width: '100%', height: '100%',
                                            borderRadius: '16px',
                                            objectFit: 'cover'
                                        }}

                                    />
                                ) : (
                                    <p></p>
                                )}
                            </Box>

                            {/* Center Bottom Icons */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 20,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 2,
                                }}
                            >
                                <IconButton onClick={handleBack} color="primary">
                                    <OFBackIcon fontSize="large" />
                                </IconButton>
                                <IconButton onClick={handleNext} color="primary">
                                    <OFNextIcon fontSize="large" />
                                </IconButton>
                            </Box>

                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={3}>

                        <Paper elevation={3} style={{
                            height: '33.33%',
                            borderTopRightRadius: radius2,
                            overflow: 'hidden',
                        }}>
                            <img
                                src={images[img2]}
                                alt="advertisement1"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </Paper>


                        <Paper elevation={3} style={{
                            height: '33.33%',
                            borderRadius: '0',
                            overflow: 'hidden',
                        }}>
                            <img
                                src={images[img3]}
                                alt="advertisement2"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </Paper>


                        <Paper elevation={3} style={{
                            height: '33.33%',
                            borderBottomRightRadius: radius2,
                            overflow: 'hidden',
                        }}>
                            <img
                                src={images[img4]}
                                alt="advertisement3"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </Paper>
                    </Grid>

                    {/*Business Details */}
                    <Grid item xs={12} md={9} >
                        <Box >

                            <GenericCard data={details1} heading="Dealerships and distributorships" height="30vh" width="100%" />

                        </Box>


                        <Box mt={3} mb={3}>
                            <Paper elevation={3} style={{
                                height: 'auto',
                                //overflowY: 'auto',
                                borderRadius: { radius1 }
                            }}>


                                <Box sx={{ padding: 4 }}>
                                    <Box mb={2}>
                                        <Typography variant="TITLE2" sx={{ mb: 2 }}>

                                            Describe about the business
                                        </Typography>

                                    </Box>

                                    <Typography>
                                        `Alliance Recruitment Agency is one of the leading global recruitment, staffing, and executive search companies. We operate through a network of worldwide teams, with specialists in recruitment for different industries. Our recruitment agency is everywhere you need us, with teams on four continents.
                                    </Typography> <Typography>
                                        Alliance Recruitment Agency strives to help businesses put together a staff of highly efficient and skilled professionals, and also serves employees by opening up new vistas of job opportunities for them. We take pride in lending our hand in creating the kind of professional environment that would fulfil the mission and vision that enterprises are built upon. Furthermore, we operate in highly dynamic and diversified global markets. Alliance Recruitment Agency is a Low Cost Recruitment Franchise Provider. Apply now for Alliance International Services franchise.
                                    </Typography> <Typography>
                                        We are a global recruitment company offering manpower consultancy, hire-train-deploy staffing, RPO, offshore hiring, headhunting, and executive recruitment services in the USA, Canada, the UK, the Middle East, India, and 20+ other countries. With global/ local recruiters, geo-specific and industry-specific talent pools, and data-driven hiring processes, we optimize recruitment benefits and reduce costs! We have developed our presence in 25+ countries, worked with 9000+ global clients, and fulfilled over 30,000+ successful recruitment projects.`
                                    </Typography>
                                </Box>


                            </Paper>
                        </Box>

                        <Box mb={3} >



                            <GenericCard data={details2} heading="" width="100%" />

                        </Box>

                        <Box mb={3}>



                            <GenericCard data={details3} heading="" width="100%" />

                        </Box>


                        <Box >
                            <Paper elevation={3} style={{ height: 'auto', overflowY: 'auto', borderRadius: { radius1 } }}>

                                < BusinessInterestForm name={businessName} />
                            </Paper>

                        </Box>

                    </Grid>

                    {/* Second Advertisement */}
                    <SecondAdvertisement />

                </Grid>
            </Container >
        </Box >
    );
};


export default DealersDetailedPage;
