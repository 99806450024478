import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Grid, Typography } from '@mui/material';
import CustomView from '../helpers/CustomView';


// export const GenericCard = ({ data, heading, height, width }) => {
//     return (
//         <Box sx={{ paddingTop: 2 }}>
//             <Paper elevation={3} style={{ height, width, borderRadius: 8 }}>
//                 <Box sx={{ padding: 4 }}>
//                     {heading}
//                     <Grid container spacing={2}>
//                         {data.map((item, index) => (
//                             <Grid item md={6} key={index}>
//                                 <CustomView
//                                     label={item.label}
//                                     value={item.value}
//                                 />
//                             </Grid>
//                         ))}
//                     </Grid>
//                 </Box>
//             </Paper>
//         </Box>
//     );
// };
export const GenericCard = ({ data, heading, width }) => {
    return (
        <Box sx={{ paddingTop: 0 }}>
            <Paper elevation={3} style={{ width, borderRadius: 8 }}>
                <Box sx={{ padding: 4 }}>

                    <Typography variant="TITLE2"
                    //   sx={{ fontWeight: 700, fontSize: '1.875rem', lineHeight: '2.3625rem' }}
                    >
                        {heading}
                    </Typography>



                    <Grid container spacing={2}>
                        {data.map((item, index) => (
                            <Grid item md={6} key={index}>
                                <CustomView
                                    label={item.label}
                                    value={item.value}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
};


GenericCard.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ).isRequired,
    heading: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

GenericCard.defaultProps = {
    heading: '',
    height: '25vh',
    width: '100%',
};


