import React from 'react'

import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import { Box, Divider, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Controller } from 'react-hook-form';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import { OFAddress, OFCalendar2, OFCountry, OFEditIcon, OFRupeeIcon, OFStore, OFTerritoryIcon, OFTimeQuarter, OFYesNoIcon } from '../../../icons/CustomIcon';
import { CustomInputWithDropdown } from '../../../helpers/CustomInputWithDropdown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomInput from '../../../helpers/custom-input';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import { oneFranHubtheme } from '../../../theme/theme';

const FinancialDetailsForm = ({ control, errors,


    ranges,
    isDashboardMode,
    setIsEditing,
    isEditing }) => {

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    return (
        <>
            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3} sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="h5">Franchisor Registration</Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperFranchisor />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }
            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Financial Projections</Typography>
                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}

                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}


                <Grid container spacing={4}>

                    {/* Main Componnet */}

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="initialInvestmentRange"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field} required
                                    field={field}
                                    fullWidth sicon={<OFRupeeIcon />}
                                    label="Initial Investment Range"
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.initialInvestmentRange}
                                    helperText={errors.initialInvestmentRange ? errors.initialInvestmentRange.message : ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="expectedRevenueProjection"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Expected Revenue Projections (per year)"
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.expectedRevenueProjection}
                                    helperText={errors.expectedRevenueProjection ? errors.expectedRevenueProjection.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="breakEvenPoint"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Break-Even Point (Expected Timeframe to reach Profitability)"
                                    fullWidth sicon={<OFCalendar2 />}
                                    variant="outlined"
                                    error={!!errors.breakEvenPoint}
                                    helperText={errors.breakEvenPoint ? errors.breakEvenPoint.message : ''}
                                    placeholder={'Enter details'}
                                    isViewMode={!isEditing}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="financialFranchiseResponsibility"
                            control={control}
                            render={({ field }) => (
                                <CustomCheckbox
                                    {...field} // Spread the field props
                                    required
                                    mainLabel="Franchisee’s Financial Responsibility"
                                    control={control}
                                    options={[
                                        { id: 1, name: 'Inventory' },
                                        { id: 2, name: 'Equipment' },
                                        { id: 3, name: 'Staff' },
                                        { id: 4, name: 'Marketing' }
                                    ]}
                                    error={!!errors.financialFranchiseResponsibility}
                                    helperText={errors.financialFranchiseResponsibility ? errors.financialFranchiseResponsibility.message : ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                </Grid>


            </Box>
            <Divider />
            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={1}>
                    <Typography variant="TITLE1">Training Support</Typography>

                </Box>


                <Grid container spacing={4}>

                    {/* Main Componnet */}

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="trainingSupport"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Comprehensive Operating Manuals (Availability)"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.trainingSupport?.message}
                                    helperText={errors.trainingSupport ? errors.trainingSupport.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="locationOfTraining"
                            control={control}
                            render={({ field }) => (
                                <CustomCheckbox
                                    {...field} // Spread the field props
                                    required
                                    mainLabel="Location of training "
                                    control={control}
                                    options={[
                                        { id: 1, name: 'Head Office' },
                                        { id: 2, name: 'Reginal Centers' },
                                        { id: 3, name: 'On-Site' },

                                    ]}
                                    error={!!errors.locationOfTraining}
                                    helperText={errors.locationOfTraining ? errors.locationOfTraining.message : ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                        {/* <Controller
                            name="locationOfTraining"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Comprehensive Operating Manuals (Availability)"
                                    data={[
                                        { value: "Head Office", label: "Head Office" },
                                        { value: "Regional Centers", label: "Regional Centers" },
                                        { value: "On-Site", label: "On-Site" },

                                    ]}
                                    error={errors?.locationOfTraining?.message}
                                    helperText={errors.locationOfTraining ? errors.locationOfTraining.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        /> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="durationOfTraining"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field} required
                                    field={field}
                                    fullWidth sicon={<OFTimeQuarter />}
                                    label="Duration of Training"
                                    variant="outlined"
                                    option={[{
                                        "id": 2,
                                        "name": "1 week",
                                        "type": "NUMBER"
                                    }]}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.durationOfTraining}
                                    helperText={errors.durationOfTraining ? errors.durationOfTraining.message : ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="contentOfTraining"
                            control={control}
                            render={({ field }) => (
                                <CustomCheckbox
                                    {...field} // Spread the field props
                                    required
                                    mainLabel="Content of Training"
                                    control={control}
                                    options={[
                                        { id: 1, name: 'Operations' },
                                        { id: 2, name: 'Marketing' },
                                        { id: 3, name: 'HR' },
                                        { id: 4, name: 'Customer Service' }
                                    ]}
                                    error={!!errors.contentOfTraining}
                                    helperText={errors.contentOfTraining ? errors.contentOfTraining.message : ''}
                                    isViewMode={!isEditing}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="fieldSupportForFranchise"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Field Support for Franchisees"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.fieldSupportForFranchise?.message}
                                    helperText={errors.fieldSupportForFranchise ? errors.fieldSupportForFranchise.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="supportServices"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Ongoing support Services"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.supportServices?.message}
                                    helperText={errors.supportServices ? errors.supportServices.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="marketingSupport"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Marketing Support (example Kits, brochers etc.)"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.marketingSupport?.message}
                                    helperText={errors.marketingSupport ? errors.marketingSupport.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="operationalSupport"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Operational Support"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.operationalSupport?.message}
                                    helperText={errors.operationalSupport ? errors.operationalSupport.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="mentorShipSupport"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Mentorship Programs"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.mentorShipSupport?.message}
                                    helperText={errors.mentorShipSupport ? errors.mentorShipSupport.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                </Grid>


            </Box>


        </>

    );
};


export default FinancialDetailsForm