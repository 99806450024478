import React from 'react';
import {
    Stack,
    Typography,
    Box,
    InputLabel,
} from '@mui/material';

function CustomView({
    label,
    value,
    sicon, // prefix Icon
    labelRightComponent,
    labelSize,
    marginBottom = 0,
    marginTop = 0,
    onNextLine,
    boxProps,
}) {
    return (
        <Box {...boxProps} mb={marginBottom} mt={marginTop}>
            <Stack direction={onNextLine ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
                <InputLabel style={{ fontSize: labelSize, color: '#000000', }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'grey', marginTop: 2 }}>
                        {sicon && <Box sx={{ mr: 1 }}>{sicon}</Box>}
                        {label}
                    </Typography>
                </InputLabel>
                <InputLabel>{labelRightComponent}</InputLabel>
            </Stack>
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', mt: 0 }}>
                <Box sx={{ ml: sicon ? 5 : 0 }}>{value}</Box>
            </Typography>
        </Box>
    );
}

export default CustomView;
