

import React, { useEffect, useState } from 'react';
import CustomInput from '../../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import ProgressStepper from '../progress/ProgressSteper';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomRadioButton from '../../../helpers/CustomRadioButton';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../../helpers/CustomButton';
import { OFAddress, OFAppleReminder, OFBackpack1, OFBriefcase, OFBuilding5, OFCalendar, OFCalendar2, OFCastle, OFCompany, OFCountry, OFDescription, OFEditIcon, OFHouse, OFHouse4, OFIndustry, OFLegalDocument1, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFNoOfEmployees, OFPercentageIcon, OFPhone, OFRoadLocation, OFRupeeIcon, OFSector, OFStore, OFUser, OFUserSharing, OFWebDesign, OFWebsiteLink, OFYesNoIcon } from '../../../icons/CustomIcon';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../../helpers/CustomInputPhone';

import { toastError } from '../../../utils/toast-notify';

import { getApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import MultiSelectDropdown from '../../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomDropdownSingle from '../../../helpers/dropdown/CustomDropdownSingle';
import CustomMultiLineInput from '../../../helpers/CustomMultiLineInput';

import ProgressStepperInvestor from '../progress/ProgressSteperInvestor';
import ProgressSteperSellMyBusiness from '../progress/ProgressSteperSellMyBusiness';
import ProgressSteperBusinessCollaboration from '../progress/ProgressSteperBusinessCollaboration';




const BCCompanyAndGoalInfoForm = ({ control, errors, setValue, watch, getValues,

    countries,


    cities,
    states,
    industries,
    outletStates,
    ranges,
    industrySectors,
    setSelectedCity,
    setSelectedCountry,
    setSelectedIndustry,
    setSelectedSector,

    setSelectedState,
    isDashboardMode,
    setIsEditing,
    isEditing,



}) => {


    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));



    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>

            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3}
                        sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="h5" sx={{ color: "#0D2659" }}>Business Collaboration </Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperBusinessCollaboration />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }

            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Company Information and Collaboration Goals</Typography>


                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}



                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={4}>





                    <Grid item xs={12} md={4}>
                        <Controller
                            name="businessName"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Business Name "
                                    fullWidth sicon={<OFBriefcase />}
                                    required
                                    variant="outlined"
                                    error={!!errors.businessName}
                                    helperText={errors.businessName ? errors.businessName.message : ''}
                                    placeholder={'Enter Business name'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="establishmentYear"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Establishment Year"
                                    required
                                    fullWidth sicon={<OFCalendar />}
                                    variant="outlined"
                                    error={!!errors.establishmentYear}
                                    helperText={errors.establishmentYear ? errors.establishmentYear.message : ''}
                                    placeholder={'eg 1900'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>


                        <Controller
                            name="industry"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFIndustry />}
                                    label="Industry"
                                    option={industries}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedIndustry(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.industry?.name}
                                    helperText={errors.industry?.name?.message || ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Controller
                            name="sector"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFSector />}
                                    label="Sector"
                                    option={industrySectors}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedSector(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.sector?.name}
                                    helperText={errors.sector?.name?.message || ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>

                        <Controller
                            name="businessDescription"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Business Description (Summarize your business, its mission, and products/services) "
                                    required
                                    fullWidth sicon={<OFAppleReminder />}
                                    variant="outlined"
                                    error={!!errors.businessDescription}
                                    helperText={errors.businessDescription ? errors.businessDescription.message : ''}
                                    placeholder={'Business Summary'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>




                    <Grid item xs={12} md={4}>



                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    sicon={<OFCountry />} // Replace with your actual icon
                                    label="Country"
                                    option={countries}
                                    defaultValue={field.value} // Ensure defaultValue is an object { id, name }

                                    error={!!errors.country?.name}
                                    helperText={errors.country?.name?.message || ''}
                                    changeCallBack={({ id, name }) => {


                                        setSelectedCountry(id);
                                        field.onChange({ id, name }); // Pass { id, name } to form state
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required sicon={<OFCountry />}
                                    fullWidth
                                    label="State"
                                    option={states}
                                    defaultValue={field.value}
                                    error={!!errors.state?.name}
                                    helperText={errors.state?.name?.message || ''}
                                    placeholder="Select State"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedState(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    label="City" sicon={<OFCountry />}
                                    option={cities}
                                    defaultValue={field.value}
                                    error={!!errors.city?.name}
                                    helperText={errors.city?.name?.message || ''}
                                    placeholder="Select City"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedCity(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="pinCode"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    required
                                    label="PIN Code"
                                    fullWidth sicon={<OFCountry />}
                                    variant="outlined"
                                    error={!!errors.pinCode}
                                    helperText={errors.pinCode ? errors.pinCode.message : ''}
                                    placeholder={'Enter PIN Code'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <Controller
                            name="businessCollaborationType"
                            control={control}
                            render={({ field }) => (


                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFBriefcase />}
                                    label="Business Collaboration Type"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.businessCollaborationType?.name}
                                    helperText={errors.businessCollaborationType?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="share"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Share"
                                    fullWidth sicon={<OFPercentageIcon />}
                                    required
                                    variant="outlined"
                                    error={!!errors.share}
                                    helperText={errors.share ? errors.share.message : ''}
                                    placeholder={'Enter share percentage'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="investmentRequired"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Investment amount Requirement"
                                    fullWidth sicon={<OFRupeeIcon />}
                                    required
                                    variant="outlined"
                                    error={!!errors.investmentRequired}
                                    helperText={errors.investmentRequired ? errors.investmentRequired.message : ''}
                                    placeholder={'Enter investment Amount'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="companyType"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFBuilding5 />}
                                    label="Company Type"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.companyType?.name}
                                    helperText={errors.companyType?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="ownershipDetails"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Ownership Details"
                                    fullWidth sicon={<OFUser />}
                                    required
                                    variant="outlined"
                                    error={!!errors.ownershipDetails}
                                    helperText={errors.ownershipDetails ? errors.ownershipDetails.message : ''}
                                    placeholder={'Singal Owner'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="keyMember1"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Key Team Members (Founders, C-level executives)"
                                    fullWidth sicon={<OFUser />}
                                    required
                                    variant="outlined"
                                    error={!!errors.keyMember1}
                                    helperText={errors.keyMember1 ? errors.keyMember1.message : ''}
                                    placeholder={'Founders'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="keyMember2"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Key Team Members (Founders, C-level executives)"
                                    fullWidth sicon={<OFUser />}
                                    required
                                    variant="outlined"
                                    error={!!errors.keyMember2}
                                    helperText={errors.keyMember2 ? errors.keyMember2.message : ''}
                                    placeholder={'Founders'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="numberOfEmployees"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Number of Employees"
                                    fullWidth sicon={<OFNoOfEmployees />}
                                    required
                                    variant="outlined"
                                    error={!!errors.numberOfEmployees}
                                    helperText={errors.numberOfEmployees ? errors.numberOfEmployees.message : ''}
                                    placeholder={'Enter the Employee no.'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="advisoryBoardMember"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Advisory Board Members (If any)"
                                    fullWidth sicon={<OFUser />}
                                    required
                                    variant="outlined"
                                    error={!!errors.advisoryBoardMember}
                                    helperText={errors.advisoryBoardMember ? errors.advisoryBoardMember.message : ''}
                                    placeholder={'Singal Owner'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Controller
                            name="desiredOutcome"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Desired Outcome from Investor (e.g., scaling, new market entry, product expansion)"
                                    fullWidth sicon={<OFUser />}
                                    required
                                    variant="outlined"
                                    error={!!errors.desiredOutcome}
                                    helperText={errors.desiredOutcome ? errors.desiredOutcome.message : ''}
                                    placeholder={'Singal Owner'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>







                </Grid>
            </Box>



        </>);
};

export default BCCompanyAndGoalInfoForm;
