

import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneFranHubtheme } from '../../../theme/theme';
import { API } from '../../../axios/api-path';
import CustomButton from '../../../helpers/CustomButton';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError, toastSuccess } from '../../../utils/toast-notify';
import { fetchBusinessProfile, fetchCities, fetchCountries, fetchFormInfo, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import NeedSpaceForm from './NeedSpaceForm';
import { booleanValidation, booleanValidationNormal, cityValidation, dropdownValidation, emailValidation, industryValidation, numericValidation, phoneNumberValidation, phoneValidation, stateValidation, textValidation } from '../../../utils/validations';
import { extractParts } from '../../../utils/UtilFunctions';

const NeedSpace = ({ title }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const navigate = useNavigate();
    const location = useLocation();




    const [formData, setFormData] = useState(null);
    const [isFormData, setIsFormData] = useState(false);


    // const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    useEffect(() => {
        fetchCountries(setCountries);
        fetchStates(setStates, 1);
        fetchIndustries(setIndustries);
        fetchRange(setRanges);
        const fetchData = async () => {
            const path = API.getNeedSpace();

            try {
                const response = await fetchFormInfo(path);

                if (response.data.status === 'success') {
                    if (response.data.length > 0) {
                        setFormData(response.data[response.data.length - 1]);
                        setIsFormData(true);
                    } else {
                        setIsFormData(false);
                    }
                } else {
                    setIsFormData(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);

    const validationSchema = yup.object({
        contactPersonName: textValidation({ message: 'Rrequired' }),
        emailId: emailValidation('Required'),
        mobileNo: phoneValidation,

        businessType: dropdownValidation,

        industry: industryValidation,
        location: textValidation({ message: 'Rrequired' }),
        state: stateValidation,
        city: cityValidation,
        desiredSquareFt: numericValidation,
        noOfFloors: numericValidation,
        budgetForRent: dropdownValidation,
        // preferredLeaseTerm: textValidation({ message: 'Rrequired' }), //checkbox
        willingToPayForPL: booleanValidationNormal('willingToPayForPL'),
        howSoonToMove: numericValidation,
        // additionalRequirements: textValidation({ message: 'Rrequired' }),

    });

    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue, getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            companyName: '',
            ownerName: '',
            ownerEmail: '',
            businessDescription: '',

        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        country,
        city,
        state,
    }
        = watch({ control });








    useEffect(() => {

        if (country) {
            fetchStates(setStates, country.id);


        }
    }, [country, setValue]);
    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);

    useEffect(() => {

        if (industry) {
            fetchSectors(setIndustrySectors, industry.id);

        }
    }, [industry, setValue]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [industrySectors, setIndustrySectors] = useState([]);
    const [ranges, setRanges] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedRange, setSelectedRange] = useState('');

    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);

    useEffect(() => {
        fetchCountries(setCountries);
        fetchIndustries(setIndustries);
        fetchRange(setRanges);
    }, []);

    useEffect(() => {


        if (selectedCountry) {


            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedIndustry) {
            fetchSectors(setIndustrySectors, selectedIndustry);
        }
    }, [selectedIndustry]);

    const transformData = (data) => {
        return data.map(item => ({
            value: item.id,
            label: item.name
        }));
    };


    // const transformedNoOfFranchise = (data) => data.map(item => ({ value: item.id, label: item.indexTitle }));
    const transformedNoOfFranchise = (data) => {

        return data.map(item => ({
            id: item.id,
            name: item.indexTitle
        }));
    };

    const transformedCommencedYear = (data) => {

        const newValue = [{
            id: data,
            name: data
        }];


        return newValue
    };


    useEffect(() => {
        if (formData) {

            reset({


                contactPersonName: formData?.contactPersonName,
                emailId: formData?.emailId,

                businessType: formData?.businessType,
                industry: formData?.industry,
                location: formData?.location,
                state: formData?.state,
                city: formData?.city,
                desiredSquareFt: formData?.desiredSquareFt,
                noOfFloors: formData?.noOfFloors,
                budgetForRent: formData?.budgetForRent,
                preferredLeaseTerm: formData?.preferredLeaseTerm,
                willingToPayForPL: formData?.willingToPayForPL,
                howSoonToMove: formData?.howSoonToMove,
                additionalRequirements: formData?.additionalRequirements,

                mobileNo: {
                    countryCode: extractParts(formData?.mobileNo).countryCode || '',
                    phoneNumber: extractParts(formData?.mobileNo).phoneNumber || '',
                },
            });
        }
    }, [formData, reset]);

    const onSubmit = async (data) => {

        const apiCallData = {

            contactPersonName: data?.contactPersonName,
            emailId: data?.emailId,

            businessType: data?.businessType,
            industry: data?.industry,
            location: data?.location,
            state: data?.state,
            city: data?.city,
            desiredSquareFt: data?.desiredSquareFt,
            noOfFloors: data?.noOfFloors,
            budgetForRent: data?.budgetForRent,
            preferredLeaseTerm: data?.preferredLeaseTerm,
            willingToPayForPL: data?.willingToPayForPL,
            howSoonToMove: data?.howSoonToMove,
            additionalRequirements: data?.additionalRequirements,
            mobileNo: data?.mobileNo?.countryCode + "-" + data.mobileNo?.phoneNumber,


        };


        const path = API.createNeedSpace();
        try {
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {

                    toastSuccess(`Data successfully submitted`);
                    setTimeout(() => {
                        handleNext();
                    }, 2000);
                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        // navigate(location.pathname.replace(/[^/]*$/, '3'));
        navigate('/');
    };

    const handleBack = () => {
        navigate('/'
        );
    };


    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <NeedSpaceForm title={title} control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

                    countries={countries}
                    cities={cities}
                    states={states}
                    industries={industries}
                    industrySectors={industrySectors}
                    ranges={ranges}
                    setSelectedCity={setSelectedCity}
                    setSelectedCountry={setSelectedCountry}
                    setSelectedIndustry={setSelectedIndustry}
                    setSelectedSector={setSelectedSector}
                    setSelectedState={setSelectedState}


                />
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: '100%', p: 2 }}
                >
                    <CustomButton
                        type="button"
                        width={{ xs: '75%', sm: '10rem' }}
                        variant="contained"
                        label="Back"
                        onClick={handleBack}
                    />
                    <CustomButton
                        type="submit"
                        width={{ xs: '75%', sm: '14rem' }}
                        variant="contained"
                        label="Save and Next"
                        onClick={handleSubmit(onSubmit)}
                    />
                </Stack>
            </Paper>

        </>
    )
}

export default NeedSpace