

import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneFranHubtheme } from '../../theme/theme';
import { API } from '../../axios/api-path';
import CustomButton from '../../helpers/CustomButton';
import { postApiCallWithCred } from '../../axios/axios-methods';
import { toastError, toastSuccess } from '../../utils/toast-notify';
import { fetchBusinessProfile, fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../axios/api-service';
import PaymentInfoForm from './PaymentInfoForm';

const PaymentInfo = ({ isDashboardMode = true }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const navigate = useNavigate();
    const location = useLocation();

    const [profile, setProfile] = useState(null);
    const [isBusinessProfile, setIsBusinessProfile] = useState(false);
    const [outletStates, setOutletStates] = useState([])

    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    useEffect(() => {
        fetchCountries(setCountries);
        fetchIndustries(setIndustries);
        fetchRange(setRanges);
        const fetchData = async () => {
            try {
                const response = await fetchBusinessProfile();

                if (response.data.completed === true) {
                    setProfile(response.data);

                    setIsBusinessProfile(true);
                } else {
                    setIsBusinessProfile(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);

    const validationSchema = yup.object({
        companyName: yup.string().required('Company Name is required'),
        ownnerName: yup.string().required('Owner Name is required'),
        ownerEmail: yup.string().email('Invalid email format').required('Owner Email is required'),


        managerName: yup.string().required('Manager Name is required'),
        addressDetails: yup.string().required('Address Details are required'),

        country: yup.object().shape({
            name: yup.string().required('Country is required')
        }).required('Country is required').nullable(), // Add .nullable() to handle null values
        state: yup.object().shape({
            name: yup.string().required('State is required'),
        }).required('State is required').nullable(), // Add .nullable() to handle null values
        city: yup.object().shape({
            name: yup.string().required('City is required')
        }).required('City is required').nullable(),
        industry: yup.object().shape({
            name: yup.string().required('Industry is required')
        }).required('Industry is required'),
        sector: yup.object().shape({
            name: yup.string().required('Sector is required')
        }).required('Sector is required'),

        yearCommencedFranchising: yup.string().required('Year Commenced Franchising is required'),

        pinCode: yup.string().required('PIN Code is required'),

        websiteLink: yup.string().url('Invalid URL').required('Website Link is required'),
        alternateEmail: yup.string().email('Invalid email format').required('Alternate email is required'),


        noOfFranchiseOutlets: yup.object().shape({
            name: yup.string().required('No of Franchise Outlets is required')
        }).required('No of Franchise Outlets is required'),
        outletsLocationState: yup.array()
            .of(
                yup.object().shape({
                    id: yup.string(),
                    label: yup.string().required('Label is required')
                })
            )
            .min(1, 'At least one state must be selected'),

        businessDescription: yup.string().required('Business Description is required'),
        ownerMobileNoNew: yup.object().shape({
            countryCode: yup.string().required('Country code is required'),
            phoneNumber: yup.string().required('Phone numberss is required'),
        }),
        ownerMobileNo: yup.object().shape({
            countryCode: yup.string().required('Country code is required'),
            phoneNumber: yup.string().required('Phone number is required'),
        }),



        // outletsLocationCity: yup.string().required('Outlets Location City is required'),
        // isMarketingKitAvailable: yup.string().required('Marketing kits Available is required'),
        // marketingKitDetails: yup.array().of(yup.string()),

    });

    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue, getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {




        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        country,
        city,
        state,
    }
        = watch({ control });








    useEffect(() => {

        if (country) {
            fetchStates(setStates, country.id);

        }
    }, [country, setValue]);
    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);

    useEffect(() => {

        if (industry) {
            fetchSectors(setIndustrySectors, industry.id);

        }
    }, [industry, setValue]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [industrySectors, setIndustrySectors] = useState([]);
    const [ranges, setRanges] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedRange, setSelectedRange] = useState('');

    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);

    // useEffect(() => {
    //     fetchCountries(setCountries);
    //     fetchIndustries(setIndustries);
    //     fetchRange(setRanges);
    // }, []);

    useEffect(() => {


        if (selectedCountry) {


            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedIndustry) {
            fetchSectors(setIndustrySectors, selectedIndustry);
        }
    }, [selectedIndustry]);

    const transformData = (data) => {
        return data.map(item => ({
            value: item.id,
            label: item.name
        }));
    };
    function extractParts(ownerMobileNo) {
        if (ownerMobileNo != null) {

            const [countryCode, phoneNumber] = ownerMobileNo.split('-');


            return {
                countryCode: countryCode || null,
                phoneNumber: phoneNumber || null
            };
        } else {

            return {
                countryCode: null,
                phoneNumber: null
            };
        }
    }

    // const transformedNoOfFranchise = (data) => data.map(item => ({ value: item.id, label: item.indexTitle }));
    const transformedNoOfFranchise = (data) => {

        return data.map(item => ({
            id: item.id,
            name: item.indexTitle
        }));
    };

    const transformedCommencedYear = (data) => {

        const newValue = [{
            id: data,
            name: data
        }];


        return newValue
    };


    useEffect(() => {
        if (profile) {

            reset({
                companyName: profile.companyName || '',
                ownnerName: profile.ownnerName || '',
                ownerEmail: profile.ownerEmail || '',
                ownerMobileNo: {
                    countryCode: extractParts(profile?.ownerMobileNo).countryCode || '',
                    phoneNumber: extractParts(profile?.ownerMobileNo).phoneNumber || '',
                },
                // ownerMobileNo: {
                //     countryCode: '81',
                //     phoneNumber: '7777777779',
                // },
                ownerMobileNoNew: {
                    countryCode: extractParts(profile?.contact?.mobile).countryCode || '',
                    phoneNumber: extractParts(profile?.contact?.mobile).phoneNumber || '',
                },
                // ownerMobileNoNew: {
                //     countryCode: '91',
                //     phoneNumber: '7777777778',
                // },
                managerName: profile?.managerName || '',
                addressDetails: profile?.businessAddress?.street || '',
                country: profile.businessAddress.country || '',
                state: profile.businessAddress.state || '',
                city: profile.businessAddress.city || '',
                pinCode: profile?.businessAddress?.postalCode || '',
                mobileNo: profile?.contact?.mobile || '',
                websiteLink: profile?.website || '',
                alternateEmail: profile?.secondaryEmail || '',
                industry: profile.industry || '',
                sector: profile.sector || '',
                yearCommencedFranchising: "1915" || '',

                noOfFranchiseOutlets: profile.noOfFranchise || '',

                outletsLocationState: [{ "id": 4, "label": "Assam" }, { "id": 10, "label": "Gujarat" }] || [],
                businessDescription: profile?.businessDescription || ''
            });
        }
    }, [profile, reset]);

    const onSubmit = async (data) => {

        const apiCallData = {
            companyName: data.companyName,
            ownnerName: data.ownnerName,
            ownerEmail: data.ownerEmail,
            ownerMobileNo: data.ownerMobileNoNew?.countryCode + "-" + data.ownerMobileNoNew?.phoneNumber,
            managerName: data.managerName,
            // ownerMobileNoNew: {
            //     countryCode: data.ownerMobileNoNew?.countryCode || '', // Ensure countryCode is extracted correctly
            //     phoneNumber: data.ownerMobileNoNew?.phoneNumber || '', // Ensure phoneNumber is extracted correctly
            // },

            // ownerMobileNoNew: data.ownerMobileNo?.countryCode + "-" + data.ownerMobileNo?.phoneNumber,
            businessAddress: {
                street: data.addressDetails,
                city: { id: parseInt(data.city.id, 10) },
                state: { id: parseInt(data.state.id, 10) },
                postalCode: data.pinCode,
                country: { id: parseInt(data.country.id, 10) }
            },
            contact: {
                mobile: data.ownerMobileNo?.countryCode + "-" + data.ownerMobileNo?.phoneNumber,
                landline: null,
                fax: null
            },
            website: data.websiteLink,
            secondaryEmail: data.alternateEmail,
            industry: {
                id: data.industry?.id,
                title: data.industry?.name
            },
            sector: {
                id: parseInt(data.sector.id, 10),
                title: data.sector.name
            },
            franchiseCommenceYear: data.yearCommencedFranchising,
            outletsLocationState: data.outletsLocationState,
            noOfFranchise: {
                id: parseInt(data.noOfFranchiseOutlets.id, 10),
                startIndex: parseInt(data.outletsLocationState, 10),
                endIndex: parseInt(data.outletsLocationCity, 10),
                indexTitle: data.noOfFranchiseOutlets.name,
                indexType: data.indexType
            },
            noOfFranchiseOutlets: data.noOfFranchiseOutlets,
            // currentOutletsLocalted: data.outletsLocationCity.split(',').map(id => parseInt(id, 10)),
            outletsLocationState: data.outletsLocationState,
            businessDescription: data.businessDescription


        };


        const path = API.signupFranBusinessDetails();
        try {
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {

                    if (isDashboardMode) {
                        setIsEditing(false)

                    }
                    else {
                        handleNext();


                    }



                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '3'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '1'));
    };


    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 2,
                    //  margin: 4,
                }}
            >
                <PaymentInfoForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

                    countries={countries}
                    cities={cities}
                    states={states}
                    industries={industries}
                    industrySectors={industrySectors}
                    ranges={ranges}
                    setSelectedCity={setSelectedCity}
                    setSelectedCountry={setSelectedCountry}
                    setSelectedIndustry={setSelectedIndustry}
                    setSelectedSector={setSelectedSector}
                    setSelectedState={setSelectedState}
                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}

                />


                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}



            </Paper>

        </>
    )
}

export default PaymentInfo