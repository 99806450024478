

import React, { useEffect, useState } from 'react';
import CustomInput from '../../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import ProgressStepper from '../progress/ProgressSteper';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomRadioButton from '../../../helpers/CustomRadioButton';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../../helpers/CustomButton';
import { OFAddress, OFAppleReminder, OFBackpack1, OFBriefcase, OFCalendar, OFCalendar2, OFCastle, OFCompany, OFContracts, OFCountry, OFDescription, OFEditIcon, OFHouse, OFHouse4, OFIndustry, OFLegalDocument1, OFLicense, OFLicenseDraft, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFPhone, OFRepository, OFRoadLocation, OFRupeeIcon, OFSector, OFStore, OFUser, OFWebDesign, OFWebsiteLink, OFYesNoIcon } from '../../../icons/CustomIcon';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../../helpers/CustomInputPhone';

import { toastError } from '../../../utils/toast-notify';

import { getApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import MultiSelectDropdown from '../../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomDropdownSingle from '../../../helpers/dropdown/CustomDropdownSingle';
import CustomMultiLineInput from '../../../helpers/CustomMultiLineInput';

import ProgressStepperInvestor from '../progress/ProgressSteperInvestor';
import ProgressSteperSellMyBusiness from '../progress/ProgressSteperSellMyBusiness';
import ProgressSteperDealer from '../progress/ProgressSteperDealer';




const DealerTrainingAndOperationForm = ({ control, errors, setValue, watch, getValues,

    countries,
    cities,
    states,

    setSelectedCity,
    setSelectedCountry,
    setSelectedState,
    isDashboardMode,
    setIsEditing,
    isEditing,



}) => {


    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));



    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>

            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3}
                        sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="h5" sx={{ color: "#0D2659" }}>Dealer / Distributor </Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperDealer />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }

            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Training and Operations</Typography>


                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}



                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={4}>





                    <Grid item xs={12} md={6}>
                        <Controller
                            name="initialTrainingDays"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Initial Training (In Days)*"
                                    fullWidth sicon={<OFCalendar2 />}
                                    required
                                    variant="outlined"
                                    error={!!errors.initialTrainingDays}
                                    helperText={errors.initialTrainingDays ? errors.initialTrainingDays.message : ''}
                                    placeholder={'Days(DD)'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="locationOfTraining"
                            control={control}
                            render={({ field }) => (
                                <CustomCheckbox
                                    {...field} // Spread the field props
                                    required
                                    mainLabel="Location of Training"
                                    control={control}
                                    options={[
                                        { id: 1, name: 'Head Office' },
                                        { id: 2, name: 'Regional Centers' },
                                        { id: 3, name: 'On-site' },

                                    ]}
                                    error={!!errors.locationOfTraining}
                                    helperText={errors.locationOfTraining ? errors.locationOfTraining.message : ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="ongoingTraining"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Ongoing Training (Workshops, seminars, online courses)*"
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.ongoingTraining?.message}
                                    helperText={errors.ongoingTraining ? errors.ongoingTraining.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="customerSupport"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Customer Support (After-sales service, warranties) "
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.customerSupport?.message}
                                    helperText={errors.customerSupport ? errors.customerSupport.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="operationalSupport"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Operational Support (Day-to-day operational guidance)"
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.operationalSupport?.message}
                                    helperText={errors.operationalSupport ? errors.operationalSupport.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="logisticSupport"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Logistics Support  (Warehousing, transportation, inventory management)*"
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.logisticSupport?.message}
                                    helperText={errors.logisticSupport ? errors.logisticSupport.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="stockManagementGuidance"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Stock Management Guidance  (Inventory systems, reordering processes) "
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.stockManagementGuidance?.message}
                                    helperText={errors.stockManagementGuidance ? errors.stockManagementGuidance.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="orderFulfillmentProcess"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Order Fulfilment Process (How orders are processed and delivered) "
                                    required
                                    fullWidth sicon={<OFCalendar2 />}
                                    variant="outlined"
                                    error={!!errors.orderFulfillmentProcess}
                                    helperText={errors.orderFulfillmentProcess ? errors.orderFulfillmentProcess.message : ''}
                                    placeholder={'Enter Order Fulfillment Process'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="reportingRequirements"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Reporting Requirements (Sales reports, inventory reports, etc.)  "
                                    required
                                    fullWidth sicon={<OFRepository />}
                                    variant="outlined"
                                    error={!!errors.reportingRequirements}
                                    helperText={errors.reportingRequirements ? errors.reportingRequirements.message : ''}
                                    placeholder={'Enter details'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="contract"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Contract (Standard terms and conditions)"
                                    required
                                    fullWidth sicon={<OFContracts />}
                                    variant="outlined"
                                    error={!!errors.contract}
                                    helperText={errors.contract ? errors.contract.message : ''}
                                    placeholder={'Enter Contract '}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="licensesPermits"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Licenses, permits required (Trade, Food safety, etc.) "
                                    fullWidth sicon={<OFLicense />}
                                    variant="outlined"
                                    error={!!errors.licensesPermits}
                                    helperText={errors.licensesPermits ? errors.licensesPermits.message : ''}
                                    placeholder={'Enter Licenses, permits required'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="compliance"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Compliance with Local Regulations (Health, safety, and environmental regulations)*"
                                    required
                                    fullWidth sicon={<OFLicenseDraft />}
                                    variant="outlined"
                                    error={!!errors.compliance}
                                    helperText={errors.compliance ? errors.compliance.message : ''}
                                    placeholder={'Enter Compliance with Local Regulations'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>












                </Grid>
            </Box>



        </>);
};

export default DealerTrainingAndOperationForm;
