

import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneFranHubtheme } from '../../../theme/theme';
import { API } from '../../../axios/api-path';
import CustomButton from '../../../helpers/CustomButton';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError, toastSuccess } from '../../../utils/toast-notify';
import { fetchBusinessProfile, fetchCities, fetchCountries, fetchFormInfo, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';


import { booleanValidation, currencyValidation, dropdownValidation, textValidation } from '../../../utils/validations';
import BCFinancialInfoAndGrowthForm from './BCFinancialInfoAndGrowthForm';

const BCFinancialInfoAndGrowth = ({ isDashboardMode }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState(null);
    const [isFormData, setIsFormData] = useState(false);
    // const [outletStates, setOutletStates] = useState([])

    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    useEffect(() => {
        fetchCountries(setCountries);
        fetchIndustries(setIndustries);
        fetchRange(setRanges);
        const fetchData = async () => {
            const path = API.getPartnerInfo();

            try {
                const response = await fetchFormInfo(path);

                if (response.data.financialInfoStatus === 'COMPLETE') {
                    setFormData(response.data.financialInfo);

                    setIsFormData(true);
                } else {
                    setIsFormData(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);



    const validationSchema = yup.object({
        "annualRevenueProjectionYearOne": currencyValidation,

        "profitMargin": currencyValidation,
        "currentValuation": currencyValidation,

        "investorName": textValidation({ message: 'Required' }),
        "financialProjectionOneYear": currencyValidation,

        "proposedPercentageShare": currencyValidation,
        "proposedInvestmentAmount": currencyValidation,
        "growthPlan": textValidation({ message: 'Required' }),
        "marketExpansion": textValidation({ message: 'Required' }),
        "currentBusinessHurdles": textValidation({ message: 'Required' }),
        "developmentAreas": textValidation({ message: 'Required' }),



        // "annualRevenueProjectionYearTwo": currencyValidation,
        // "annualRevenueProjectionYearThree": currencyValidation,
        // "financialProjectionSecondYear": currencyValidation,
        //"financialProjectionThirdYear": currencyValidation,
        //"priorInvestment": booleanValidation('priorInvestment'),


    });




    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue, getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {




        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        country,
        city,
        state,
        loanSupportRequired,
    }
        = watch({ control });


    useEffect(() => {
        console.log(" HII" + loanSupportRequired)
        if (loanSupportRequired === 'No') {
            setValue("acceptTermAndConditions", null);
            setValue("desiredLoanAmount", null);
            setValue("preferredLoanTerm", null);
            setValue("monthlyIncome", null);
            setValue("existingDebt", null);
            setValue("creditScore", null);
            setValue("specificRequirementOnLoan", null);
            setValue("loanAgainstProperty", null);
            setValue("typeOfProperty", null);
            setValue("propertyValue", null);
            setValue("propertyArea", null);
            setValue("doYouWantSaySomething", null);

        }
    }, [loanSupportRequired, setValue]);

    useEffect(() => {

        if (country) {
            fetchStates(setStates, country.id);

        }
    }, [country, setValue]);
    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);

    useEffect(() => {

        if (industry) {
            fetchSectors(setIndustrySectors, industry.id);

        }
    }, [industry, setValue]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [industrySectors, setIndustrySectors] = useState([]);
    const [ranges, setRanges] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedRange, setSelectedRange] = useState('');

    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);



    useEffect(() => {

        if (selectedCountry) {

            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedIndustry) {
            fetchSectors(setIndustrySectors, selectedIndustry);
        }
    }, [selectedIndustry]);

    function extractParts(ownerMobileNo) {
        if (ownerMobileNo != null) {

            const [countryCode, phoneNumber] = ownerMobileNo.split('-');


            return {
                countryCode: countryCode || null,
                phoneNumber: phoneNumber || null
            };
        } else {

            return {
                countryCode: null,
                phoneNumber: null
            };
        }
    }


    useEffect(() => {
        if (formData) {

            reset({
                "annualRevenueProjectionYearOne": formData?.annualRevenueProjectionYearOne,
                "annualRevenueProjectionYearTwo": formData?.annualRevenueProjectionYearTwo,
                "annualRevenueProjectionYearThree": formData?.annualRevenueProjectionYearThree,
                "profitMargin": formData?.profitMargin,
                "currentValuation": formData?.currentValuation,
                "priorInvestment": formData?.priorInvestment === true ? 'Yes' : formData?.priorInvestment === false ? 'No' : '',
                "investorName": formData?.investorName,
                "financialProjectionOneYear": formData?.financialProjectionOneYear,
                "financialProjectionSecondYear": formData?.financialProjectionSecondYear,
                "financialProjectionThirdYear": formData?.financialProjectionThirdYear,
                "proposedPercentageShare": formData?.proposedPercentageShare,
                "proposedInvestmentAmount": formData?.proposedInvestmentAmount,
                "growthPlan": formData?.growthPlan,
                "marketExpansion": formData?.marketExpansion,
                "currentBusinessHurdles": formData?.currentBusinessHurdles,
                "developmentAreas": formData?.developmentAreas

            });
        }
    }, [formData, reset]);

    const onSubmit = async (data) => {

        const apiCallData = {




            "annualRevenueProjectionYearOne": data?.annualRevenueProjectionYearOne,
            "annualRevenueProjectionYearTwo": data?.annualRevenueProjectionYearTwo,
            "annualRevenueProjectionYearThree": data?.annualRevenueProjectionYearThree,
            "profitMargin": data?.profitMargin,
            "currentValuation": data?.currentValuation,
            "priorInvestment": data?.priorInvestment === 'Yes' ? true : data?.priorInvestment === 'No' ? false : '',
            "investorName": data?.investorName,
            "financialProjectionOneYear": data?.financialProjectionOneYear,
            "financialProjectionSecondYear": data?.financialProjectionSecondYear,
            "financialProjectionThirdYear": data?.financialProjectionThirdYear,
            "proposedPercentageShare": data?.proposedPercentageShare,
            "proposedInvestmentAmount": data?.proposedInvestmentAmount,
            "growthPlan": data?.growthPlan,
            "marketExpansion": data?.marketExpansion,
            "currentBusinessHurdles": data?.currentBusinessHurdles,
            "developmentAreas": data?.developmentAreas

        };


        const path = API.signupPartnerFinancialInfo();
        try {

            console.log(" Submit data " + JSON.stringify(apiCallData, null, 2))
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {

                    if (isDashboardMode) {
                        setIsEditing(false)

                    }
                    else {
                        handleNext();


                    }



                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '4'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '2'));
    };


    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <BCFinancialInfoAndGrowthForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

                    countries={countries}
                    cities={cities}
                    states={states}
                    industries={industries}
                    industrySectors={industrySectors}
                    ranges={ranges}
                    setSelectedCity={setSelectedCity}
                    setSelectedCountry={setSelectedCountry}
                    setSelectedIndustry={setSelectedIndustry}
                    setSelectedSector={setSelectedSector}
                    setSelectedState={setSelectedState}
                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    loanSupportRequired={loanSupportRequired}

                />


                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}



            </Paper>

        </>
    )
}

export default BCFinancialInfoAndGrowth