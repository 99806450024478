// // Array of light shades for better readability with black text
// const colors = ['#FFB3B3', '#B3FFB3', '#B3D9FF', '#FFB3E6', '#FFEB99', '#E6FFCC', '#D9B3FF'];


// // Function to return a random color from the array
// export const getRandomColor = () => {
//     // Get a random index from the colors array
//     const randomIndex = Math.floor(Math.random() * colors.length);
//     // Return the color at the random index
//     return colors[randomIndex];
// };

// Define the arrays of colors
const lightColors = ['#FFB3B3', '#B3FFB3', '#B3D9FF', '#FFB3E6', '#FFEB99', '#E6FFCC', '#D9B3FF'];
const darkColors = ['#CC3333', '#33CC33', '#3366CC', '#CC3399', '#CC9900', '#99CC66', '#9966CC'];





// Function to get a random index
export const getRandomIndex = () => Math.floor(Math.random() * lightColors.length);

// Function to get both light and dark colors for a specific index
export const getColorPair = (index) => ({
    lightColor: lightColors[index],
    darkColor: darkColors[index]
});
