

import React from 'react';
import { TextField, Select, MenuItem, Stack, Box, FormControl, InputAdornment, InputLabel, Typography } from '@mui/material';
import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        border: 'none', // Remove the border
        '& fieldset': {
            border: 'none', // Remove the fieldset border
        },
        '& input': {
            padding: '16px 14px', // Ensure consistent padding
        },
    },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    '.MuiSelect-select': {
        padding: '10px 14px', // Ensure consistent padding and space for icon
        display: 'flex',
        alignItems: 'center', // Center items vertically
        border: 'none', // Remove internal border
        backgroundColor: '#FBFCFF',
        position: 'relative', // Required for positioning the icon
    },
    '& .MuiSelect-icon': {
        position: 'relative', // Position the icon absolutely
        right: '10px', // Space between icon and right edge
        top: '100%', // Vertically center the icon
        transform: 'translateY(0%)', // Adjust for vertical centering
        fontSize: '24px', // Ensure the icon is large enough
        color: theme.palette.text.primary, // Ensure the icon is visible
    },
}));

function CustomInputPhone({
    label,
    countryCodeOptions = [],
    selectedCountryCode = '',
    onCountryCodeChange,
    disabled,
    limitWidth,
    value = '',
    onChange,
    placeholder = 'Enter phone number',
    helperText,
    error,
    style,
    required = false,
    labelComponent,
    labelRightComponent,
    IconComponent = KeyboardArrowDownIcon,
    boxProps,
    marginBottom,
    marginTop,
    isViewMode = false,
    sicon,
}) {
    const handleCountryCodeChange = (event) => {
        const newCountryCode = event.target.value;
        onCountryCodeChange(newCountryCode);
    };

    const handlePhoneNumberChange = (event) => {
        const newPhoneNumber = event.target.value;
        onChange(newPhoneNumber);
    };

    return (
        <Box {...boxProps} mb={marginBottom} mt={marginTop}>
            {labelComponent ? (
                labelComponent
            ) : (
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <InputLabel style={{ color: '#000000', marginBottom: isViewMode ? 2 : 4 }}>
                        {isViewMode ? (
                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'grey' }}>
                                {sicon && <InputAdornment position="start">{sicon}</InputAdornment>}
                                <Box sx={{ ml: sicon ? 1 : 0 }}>{label}</Box>
                            </Typography>
                        ) : (
                            label
                        )}
                        {required && (
                            <Typography component={'span'} color={'error.main'} sx={{ marginLeft: 1 }}>
                                *
                            </Typography>
                        )}
                    </InputLabel>
                    <InputLabel>{labelRightComponent}</InputLabel>
                </Stack>
            )}
            {isViewMode ? (
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 5 }}>
                        <Typography variant="body1">{selectedCountryCode}</Typography>
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            {value}
                        </Typography>
                    </Box>
                </Typography>
            ) : (
                <FormControl style={limitWidth ? { minWidth: '50%' } : {}} fullWidth={!limitWidth}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid', // Unified border around both elements
                            borderColor: (theme) => theme.palette.grey[400],
                            borderRadius: '8px', // Rounded corners for a unified appearance
                            overflow: 'hidden', // Prevent any overflow from showing unwanted edges
                            backgroundColor: '#FBFCFF',
                        }}
                    >
                        <StyledSelect
                            value={selectedCountryCode}
                            onChange={handleCountryCodeChange}
                            variant="standard"
                            disableUnderline
                            IconComponent={IconComponent}
                            placeholder={'Select'}
                        >
                            {countryCodeOptions.map((code) => (
                                <MenuItem key={code.value} value={code.value}>
                                    {code.label}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        <StyledTextField
                            style={{
                                ...style,
                                backgroundColor: '#FBFCFF',
                                width: '100%',
                                border: 'none', // Ensure no border
                            }}
                            value={value}
                            onChange={handlePhoneNumberChange}
                            placeholder={placeholder}
                            error={error}
                            disabled={disabled}
                            InputProps={{
                                startAdornment: null, // Remove the startAdornment since it's in the Select
                            }}
                        />
                    </Box>
                </FormControl>
            )}
            {helperText && (
                <Typography variant="body2" color="error" style={{ fontSize: 14, marginTop: '0.5rem' }}>
                    {helperText}
                </Typography>
            )}
        </Box>
    );
}

export default CustomInputPhone;
