//npm install react-toastify


import React, { useContext, useEffect, useState } from 'react';
import PersonalRegistrationForm from './PersonalInfoForm';
import { Button, Stack, Box, useMediaQuery, Paper, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { oneFranHubtheme } from '../../theme/theme';
import { API } from '../../axios/api-path'
import { postApiCall } from '../../axios/axios-methods'
import { toastError, toastSuccess, toastClientSideMsg } from '../../utils/toast-notify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton from '../../helpers/CustomButton';
import { AuthContext } from '../../context/AuthContext';
import { isLoggedInState, perInfoState } from '../../recoil/profile-atom';
import { useRecoilState } from 'recoil';
import { phoneValidation } from '../../utils/validations';
import { extractParts } from '../../utils/UtilFunctions';

const PersonalInfo = ({ user, title }) => {

    const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);

    const [perInfo, setPerInfo] = useRecoilState(perInfoState);
    const [isShow, setIsShow] = useState(false)
    console.log(" Personla Info " + JSON.stringify(perInfo, null, 2))
    console.log(" isShow " + JSON.stringify(isShow, null, 2))
    // console.log(" User and Title " + user + "-" + title)
    //console.log(" param " + useParams())
    const { userType, step } = useParams();
    const stepNumber = parseInt(step, 10);

    const { login } = useContext(AuthContext);
    const { auth } = useContext(AuthContext);
    const location = useLocation();
    //console.log(" loc " + location)
    const type = location.state?.type;


    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const navigate = useNavigate();


    const validationSchema = Yup.object({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        emailId: Yup.string().email('Invalid email format').required('Email is required'),
        password: Yup.string().min(6, 'Password is required').required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        mobileNo: phoneValidation
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            emailId: '',
            password: '',
            confirmPassword: '',
            mobileNo: '',
        },
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {

        const response = perInfo
        const fetchUserData = async () => {
            try {
                // const path = API.getUserInfo(); // Assuming you have an endpoint for fetching user info
                // const response = await getApiCall({ path });
                setIsShow(response ? true : false)
                // Update form with fetched data
                reset({
                    firstName: response.firstName || '',
                    lastName: response.lastName || '',
                    emailId: response.emailId || '',
                    // mobileNo: response.mobileNo || '',
                    mobileNo: {
                        countryCode: extractParts(response?.mobileNo).countryCode || '',
                        phoneNumber: extractParts(response?.mobileNo).phoneNumber || '',
                    },
                    password: '',
                    confirmPassword: '',
                });
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [reset]);

    const onSubmit = async (data) => {

        if (isLoggedIn) {
            goToNext();
        } else {

            const path = API.signupPersonalInfo();
            const rolesArray = [user];

            // "firstName": "sonu",
            //   "middleName": "Michael",
            //   "lastName": "Doe",
            //   "emailId": "ravish511@gmail.com",
            //   "mobNo": "932324221",
            //   "password": "password123",
            //   "roles":["businesssale"]
            console.log(" Datta " + JSON.stringify(data, null, 2))

            const apiCallData = {
                firstName: data.firstName,
                middleName: data.middleName,
                lastName: data.lastName,
                emailId: data.emailId,
                mobileNo: data?.mobileNo?.countryCode + "-" + data.mobileNo?.phoneNumber,
                password: data.password,
                roles: rolesArray
            };

            // const { confirmPassword, ...apiCallData } = data;
            //  const updatedApiCallData = { ...apiCallData, roles: rolesArray };
            console.log(" API Datta " + JSON.stringify(apiCallData, null, 2))
            try {
                await postApiCall({
                    data: apiCallData,
                    path: path,
                    onSuccess: (res) => {
                        handleSignup(res) // Handle Submit
                    },
                    onError: (error) => {
                        console.error('Error during registration:', error.message);
                        toastError(error);
                    }
                });
            } catch (error) {
                console.error('Unexpected error:', error);
            }
        }
    };


    const handleSignup = ((res) => {
        console.log('Registration successful:', res);
        // setCurrentUser(res.data.user)
        login(res);
        goToNext();

    })

    const goToPrevious = () => {

        navigate("/landing")

    };

    const goToNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '2'))
    };





    return (


        <>

            <ToastContainer />
            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <PersonalRegistrationForm control={control} errors={errors}
                    title={title}
                    user={user}
                    isShow={isShow}

                />
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: '100%', p: 2
                    }}
                >
                    <CustomButton
                        type="button"
                        width={{ xs: '75%', sm: '10rem' }}
                        variant="contained"
                        label="Back"
                        onClick={() => goToPrevious()}
                    />
                    <CustomButton
                        type="submit"
                        width={{ xs: '75%', sm: '14rem' }}
                        variant="contained"
                        label="Save and Next"
                        onClick={() => {
                            if (isLoggedIn) {
                                goToNext();
                            } else {

                                handleSubmit(onSubmit)();
                            }
                        }}
                    />
                </Stack>
            </Paper>

        </>

    );
};

export default PersonalInfo;
