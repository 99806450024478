

import React, { useState, useEffect, useContext } from 'react';
import {
    AppBar, Grid, Toolbar, Stack, IconButton, Button, Box, ListItemIcon, Menu, MenuItem, useMediaQuery, DialogActions, Dialog, Avatar, Typography,
    Container,
    makeStyles
} from '@mui/material';
import CompanyLogo from '../../assets/CompanyLogoHeader.svg';
import CompanyLogo2 from '../../assets/CompanyLogo2.svg';
import MenuIcon from '@mui/icons-material/Menu';

import { oneFranHubtheme } from "../../theme/theme";
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import CloseButton from "../../assets/icons/CloseButton.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Popup from './Popup';
import LoginForm from '../../pages/signin/LoginForm';
import { AuthContext } from '../../context/AuthContext';
import { isLoggedInState, perInfoState } from '../../recoil/profile-atom';
import { useRecoilState } from 'recoil';
import { toProper } from '../../utils/UtilFunctions';
import SettingsIcon from '@mui/icons-material/Settings';
import { OFCompany, OFCompanyLogo, OFDashboardIcon, OFHeartIcon, OFLogoutIcon, OFMyProfileIcon, OFSearchIcon1, OFSearchIcon2 } from '../../icons/CustomIcon';
import { styled } from '@mui/system';
import { DialogCommonHooks } from '../../helpers/modal/DialogCommonHooks';
import SignUp from '../../pages/signup/SignUp';

// Define styled components
const StyledTypography = styled(Typography)({
    color: '#222222',
});

const LogoAll = styled(Box)({
    alignItems: 'center',
    textDecoration: 'none',
});

// const MenuIconStyled = styled(MenuIcon)({
//     color: '#FFFFFF',
// });

const MenuIconStyled = styled(MenuIcon)(({ color }) => ({
    color: color || '#FFFFFF',
}));

// const MenuItemTypography = styled(Typography)({
//     color: isLoggedIn ? '#0D2659' : '#FFFFFF',
// });
const MenuItemTypography = styled(Typography)(({ colour }) => ({
    color: colour,
}));


const OFCustomNavbar = () => {

    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));

    const location = useLocation();


    const getNavbarStyles = () => {
        switch (location.pathname) {
            case '/landing':
                return {
                    backgroundColor: 'rgba(13, 38, 89, 3)',
                    color: '#FFFFFF',
                    logo: CompanyLogo,
                    pValue: 0,
                    searchIcon: <OFSearchIcon1 />

                };
            case '/search-page':
            case '/franchise/business-page':
            case '/contact-us':

                return {
                    backgroundColor: '#FFFFFF',
                    color: '#0D2659',
                    logo: CompanyLogo2,
                    pValue: isMobile ? 0 : 22,
                    searchIcon: <OFSearchIcon2 />
                };
            default:
                return {

                    // backgroundColor: 'rgba(13, 38, 89, 3)',
                    // color: '#FFFFFF',
                    backgroundColor: '#FFFFFF',
                    color: '#0D2659',
                    logo: CompanyLogo2,
                    pValue: isMobile ? 0 : 22,
                    searchIcon: <OFSearchIcon2 />
                };
        }
    };


    const { backgroundColor, color, logo, searchIcon, pValue } = getNavbarStyles();







    const { logout } = useContext(AuthContext);
    const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
    const navigate = useNavigate();
    const [userInfo] = useRecoilState(perInfoState);
    const [open, setOpen] = useState(false);
    const [anchorNav, setAnchorNav] = useState(null);
    const [activeMenu, setActiveMenu] = useState('home');
    const [openPopup, setOpenPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);



    useEffect(() => {
        const storedActiveMenu = localStorage.getItem('activeMenu');
        if (storedActiveMenu) {
            setActiveMenu(storedActiveMenu);
        }
    }, []);

    const openMenu = (event) => {
        setAnchorNav(event.currentTarget);
    };

    const [openContactModal, setContactModalOpen] = useState(false);
    const handleContactModalOpen = () => {
        setContactModalOpen(true);
    };

    const handleContactModalClose = () => {
        setContactModalOpen(false);
    };

    const closeMenu = () => {
        setAnchorNav(null);
    };

    const handleNavigate = (section) => {
        setActiveMenu(section);
        localStorage.setItem('activeMenu', section);
        closeMenu();
    };

    const handleLogin = () => {
        //  setOpen(true);
        handleOpen();
        closeMenu();
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleOpenPopup = () => {
        // setOpenPopup(true);
        setOpenRegisterDialog(true)
        closeMenu();
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (path) => {
        handleMenuClose();
        navigate(path);
    };

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    //const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => setOpenDialog(true);
    const handleClose = () => setOpenDialog(false);

    const [openRegisterDialog, setOpenRegisterDialog] = useState(false);

    const handleRegisterOpen = () => setOpenRegisterDialog(true);
    const handleRegisterClose = () => setOpenRegisterDialog(false);









    return (
        <>


            <AppBar position="static" sx={{ backgroundColor: backgroundColor, fontcolor: 'white', py: 2, }}>
                <Container maxWidth="xl">
                    <Box >
                        <Toolbar disableGutters>
                            <LogoAll
                                component="a"
                                href="/"
                            >
                                <img src={logo} width="75%" alt="Company Logo" />

                            </LogoAll>



                            {/* Desktop Menu */}
                            <Box
                                sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}
                            >
                                <Button></Button>

                                <MenuItem component={Link} to="/">
                                    <MenuItemTypography colour={color}>Home</MenuItemTypography>
                                </MenuItem>
                                <MenuItem component={Link} to="#about-us">
                                    <MenuItemTypography colour={color}>Products</MenuItemTypography>
                                </MenuItem>
                                <MenuItem component={Link} to="/need-space">
                                    <MenuItemTypography colour={color}>Need a space</MenuItemTypography>
                                </MenuItem>
                                <MenuItem component={Link} to="/loan-support">
                                    <MenuItemTypography colour={color}>Loan on property</MenuItemTypography>
                                </MenuItem>
                                <MenuItem component={Link} to="/about-us">
                                    <MenuItemTypography colour={color}>About us</MenuItemTypography>
                                </MenuItem>
                                <MenuItem component={Link} to="/contact-us" className={activeMenu === 'contact-us' ? 'active' : ''}>
                                    <MenuItemTypography colour={color}>Contact us</MenuItemTypography>
                                </MenuItem>
                                <MenuItem component={Link} to="/signin">
                                    {searchIcon}
                                </MenuItem>
                                {!isLoggedIn && (
                                    <MenuItem

                                        onClick={() => isLoggedIn ? handleLogout() : handleLogin()}
                                    >
                                        <MenuItemTypography colour={color}>Login</MenuItemTypography>
                                    </MenuItem>
                                )}
                                {!isLoggedIn && (
                                    <MenuItem

                                        onClick={() => handleOpenPopup()}
                                    >
                                        <MenuItemTypography colour={color}>Register</MenuItemTypography>
                                    </MenuItem>
                                )}

                                <Stack direction="row" spacing={2} ml={1}></Stack>
                                {isLoggedIn && (
                                    <Box display="flex" alignItems="center" gap={1} onClick={handleMenuOpen} sx={{ cursor: 'pointer' }}>
                                        <IconButton color="inherit">
                                            <Avatar alt="User Avatar" src="/images/avatar1.png" />
                                        </IconButton>
                                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            <Typography variant="body1" sx={{
                                                fontSize: '18px', color: color
                                            }}>
                                                {`${userInfo?.firstName} ${userInfo?.lastName}`
                                                }
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontSize: '14px', color: 'blue' }}>
                                                {userInfo?.roles[0]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    <MenuItem onClick={() => handleMenuClick('/dashboard')}>
                                        <ListItemIcon>
                                            <OFDashboardIcon fontSize="small" />
                                        </ListItemIcon>
                                        Dashboard
                                    </MenuItem>
                                    <MenuItem onClick={() => handleMenuClick('/profile')}>
                                        <ListItemIcon>
                                            <OFMyProfileIcon fontSize="small" />
                                        </ListItemIcon>
                                        My Profile
                                    </MenuItem>
                                    <MenuItem onClick={() => handleMenuClick('/wishlist')}>
                                        <ListItemIcon>
                                            <OFHeartIcon fontSize="small" />
                                        </ListItemIcon>
                                        Wishlist
                                    </MenuItem>
                                    <MenuItem onClick={() => handleMenuClick('/settings')}>
                                        <ListItemIcon>
                                            <SettingsIcon fontSize="small" />
                                        </ListItemIcon>
                                        Settings
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleMenuClose();
                                            handleLogout()
                                        }}
                                    >
                                        <ListItemIcon>
                                            <OFLogoutIcon fontSize="small" />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </Box>
                            {/* Mobile view */}
                            <Box
                                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}
                            >
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color={color}
                                >
                                    {/* <MenuIconStyled  /> */}
                                    <MenuIconStyled color={color} />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{ display: { xs: 'block', md: 'none' } }}
                                >
                                    {/* <MenuItem onClick={handleCloseNavMenu} >
                                    <Typography textAlign="center" sx={{ color: 'white' }}>Contact us</Typography>
                                </MenuItem> */}

                                    <MenuItem component={Link} to="#product" onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">Home</Typography>
                                    </MenuItem>
                                    <MenuItem component={Link} to="#about-us" onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">Products</Typography>
                                    </MenuItem>
                                    <MenuItem component={Link} to="/need-space" onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">Need a space</Typography>
                                    </MenuItem>
                                    <MenuItem component={Link} to="/loan-support" onClick={handleCloseNavMenu}>

                                        <Typography textAlign="center">Loan on property</Typography>
                                    </MenuItem>
                                    <MenuItem component={Link} to="/signin" onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">About us</Typography>
                                    </MenuItem>
                                    <MenuItem component={Link} to="/signin" onClick={handleCloseNavMenu} className={activeMenu === 'contact-us' ? 'active' : ''}>
                                        <Typography textAlign="center">Contact us</Typography>
                                    </MenuItem>


                                    <MenuItem

                                        onClick={() => isLoggedIn ? handleLogout() : handleLogin()}
                                    >
                                        {isLoggedIn ?
                                            <Typography textAlign="center">Logout</Typography> :
                                            <Typography textAlign="center">Login</Typography>
                                        }

                                    </MenuItem>

                                    {!isLoggedIn && (
                                        <MenuItem

                                            onClick={() => handleOpenPopup()}
                                        >
                                            <Typography textAlign="center">Register</Typography>
                                        </MenuItem>
                                    )}
                                    {isLoggedIn &&
                                        <MenuItem onClick={() => handleMenuClick('/dashboard')}>

                                            <Typography textAlign="center">Dashboard</Typography>
                                        </MenuItem>
                                    }

                                    <Stack direction="row" spacing={2} ml={1}></Stack>
                                    {isLoggedIn && (
                                        <Box display="flex" alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
                                            <IconButton color="inherit">
                                                <Avatar alt="User Avatar" src="/images/avatar1.png" />
                                            </IconButton>
                                            <Box display="flex" flexDirection="column" alignItems="flex-start">
                                                <Typography variant="body1" sx={{ fontSize: '18px', color: 'black' }}>
                                                    {`${userInfo?.firstName} ${userInfo?.lastName}`}
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '14px', color: 'blue' }}>
                                                    {userInfo?.roles[0]}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Menu>

                            </Box>

                        </Toolbar>
                    </Box>
                </Container>
            </AppBar >

            {/* Popup Menu for Mobile */}
            < Menu
                anchorEl={anchorNav}
                open={Boolean(anchorNav)}
                onClose={closeMenu}
                PaperProps={{
                    sx: {
                        marginTop: '10px',
                        maxHeight: 'calc(100vh - 64px)', // Adjust based on the header height
                    },
                }
                }
            >
                <MenuItem onClick={() => handleNavigate('home')}>Home</MenuItem>
                <MenuItem onClick={() => handleNavigate('products')}>Products</MenuItem>
                {/* Add other MenuItems similarly */}
                <MenuItem onClick={isLoggedIn ? handleLogout : handleLogin}>
                    {isLoggedIn ? 'Logout' : 'Login'}
                </MenuItem>
                {
                    !isLoggedIn && (
                        <MenuItem onClick={handleOpenPopup}>
                            Signup
                        </MenuItem>
                    )
                }
            </Menu >

            {/* Dialog for Login */}
            {/* < Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        background: 'transparent',
                        boxShadow: 'none',
                        width: { xs: '90vw', sm: '60vw', md: '40vw', lg: '30vw' },
                    },
                }}
            >
                <Box display="flex" justifyContent="center" alignItems="center" position="relative" p={1}>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            zIndex: 1,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
                    <Button onClick={handleClose} color="primary" sx={{ padding: 0 }}>
                        <img src={CloseButton} width="80px" height="auto" alt="Close" />
                    </Button>
                </DialogActions>

                <DialogContent sx={{ background: 'white', borderRadius: 5, padding: 2 }}>
                    <LoginForm onSuccess={handleClose} />
                </DialogContent>
            </Dialog >*/}

            {openPopup && <Popup handleClose={handleClosePopup} />}
            <DialogCommonHooks
                show={openDialog}
                handleClose={handleClose}
                body={<LoginForm onSuccess={handleClose} />} // Replace this with your form component
            />
            <DialogCommonHooks
                show={openRegisterDialog}
                handleClose={handleRegisterClose}
                body={<SignUp onSuccess={handleRegisterClose} handleClose={handleRegisterClose} />} // Replace this with your form component
            />
        </>
    );
};

export default OFCustomNavbar;
