import React, { useEffect, useState } from 'react'

import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import { Box, Divider, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Controller } from 'react-hook-form';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import { OFAddress, OFBuilding5, OFComment, OFCountry, OFEditIcon, OFHouse1, OFHouse4, OFRupeeIcon, OFStore, OFTerritoryIcon, OFYesNoIcon } from '../../../icons/CustomIcon';
import { CustomInputWithDropdown } from '../../../helpers/CustomInputWithDropdown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomInput from '../../../helpers/custom-input';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import SpaceRequirementDetails from './SpaceRequirementDetails';
import { oneFranHubtheme } from '../../../theme/theme';

const SpaceRequirementDetailsForm = ({ control, errors,


    cities,
    states,

    setSelectedCity,
    setSelectedState,
    isDashboardMode,
    setIsEditing,
    isEditing


}) => {






    // Function to toggle edit mode
    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    // useEffect(() => {
    //     if (!isDashboard) {
    //         setIsEditing(false); // Ensure it starts in view-only mode
    //     }
    // }, [isDashboard]);

    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    return (
        <>
            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3} sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="h5">Franchisor Registration</Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperFranchisor />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }


            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Space Requirements</Typography>
                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}

                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={4}>

                    {/* Main Componnet */}



                    <Grid item xs={12} md={6}>
                        <Controller
                            name="floorAreaRequirement"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Floor Area Requirements (Square Feet)"
                                    fullWidth sicon={<OFHouse1 />}
                                    variant="outlined"

                                    error={!!errors.floorAreaRequirement}
                                    helperText={errors.floorAreaRequirement ? errors.floorAreaRequirement.message : ''}
                                    placeholder={'Enter value'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="preferredPropertyType"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Preferred Property Type (Commercial Space, Retail Location, Mall, etc.)"
                                    fullWidth sicon={<OFHouse1 />}
                                    variant="outlined"
                                    error={!!errors.preferredPropertyType}
                                    helperText={errors.preferredPropertyType ? errors.preferredPropertyType.message : ''}
                                    placeholder={'Enter Preferred Property Type'}
                                    isViewMode={!isEditing}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required sicon={<OFCountry />}
                                    fullWidth
                                    label="State"
                                    option={states}
                                    defaultValue={field.value}
                                    error={!!errors.state}
                                    helperText={errors.state ? errors.state.message : ''}
                                    placeholder="Select State"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedState(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    label="City" sicon={<OFCountry />}
                                    option={cities}
                                    defaultValue={field.value}
                                    error={!!errors.city}
                                    helperText={errors.city ? errors.city.message : ''}
                                    placeholder="Select City"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedCity(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="assistanceForSiteSelection"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Assistance for site selection "
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.assistanceForSiteSelection?.message}
                                    helperText={errors.assistanceForSiteSelection ? errors.assistanceForSiteSelection.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //assistanceForSiteSelection(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>



                </Grid>



            </Box>
            <Divider />
            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={1}>
                    <Typography variant="TITLE1">Agreement, Legal and Compliance</Typography>

                </Box>


                <Grid container spacing={4}>

                    {/* Main Componnet */}

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="standardFranchiseAgreement"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Standard Franchise Agreement "
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.standardFranchiseAgreement?.message}
                                    helperText={errors.standardFranchiseAgreement ? errors.standardFranchiseAgreement.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="durationOfFranchiseContract"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Duration of Franchise Contract (in years) "
                                    fullWidth sicon={<OFTerritoryIcon />}
                                    variant="outlined"
                                    error={!!errors.durationOfFranchiseContract}
                                    helperText={errors.durationOfFranchiseContract ? errors.durationOfFranchiseContract.message : ''}
                                    placeholder={'Enter value'}
                                    isViewMode={!isEditing}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="contractRenewal"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Contract Renewal? "
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.contractRenewal?.message}
                                    helperText={errors.contractRenewal ? errors.contractRenewal.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="renewalTerms"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Renewal Terms (if any)"
                                    fullWidth sicon={<OFComment />}
                                    variant="outlined"
                                    error={!!errors.renewalTerms}
                                    helperText={errors.renewalTerms ? errors.renewalTerms.message : ''}
                                    placeholder={'Enter value'}

                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="insuranceRequirement"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Insurance Requirements (Liability, Property, etc.)"
                                    fullWidth sicon={<OFComment />}
                                    variant="outlined"
                                    error={!!errors.insuranceRequirement}
                                    helperText={errors.insuranceRequirement ? errors.insuranceRequirement.message : ''}
                                    placeholder={'Enter value'}

                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="licensingRequirement"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Licensing Requirements from franchise (Business Licenses, Permits ETC)"
                                    fullWidth sicon={<OFComment />}
                                    variant="outlined"
                                    error={!!errors.licensingRequirement}
                                    helperText={errors.licensingRequirement ? errors.licensingRequirement.message : ''}
                                    placeholder={'Enter value'}
                                    isViewMode={!isEditing}

                                />
                            )}
                        />
                    </Grid>
                </Grid>


            </Box>

        </>





    );
};

export default SpaceRequirementDetailsForm