

import React, { useEffect, useState } from 'react';
import CustomInput from '../../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import ProgressStepper from '../progress/ProgressSteper';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomRadioButton from '../../../helpers/CustomRadioButton';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../../helpers/CustomButton';
import { OFAddress, OFAppleReminder, OFBackpack1, OFBriefcase, OFCalendar, OFCalendar2, OFCastle, OFCompany, OFCountry, OFDescription, OFEditIcon, OFHouse, OFHouse4, OFIndustry, OFLegalDocument1, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFPercentageIcon, OFPhone, OFRoadLocation, OFRupeeIcon, OFSector, OFStore, OFUser, OFWebDesign, OFWebsiteLink, OFYesNoIcon } from '../../../icons/CustomIcon';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../../helpers/CustomInputPhone';

import { toastError } from '../../../utils/toast-notify';

import { getApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import MultiSelectDropdown from '../../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomDropdownSingle from '../../../helpers/dropdown/CustomDropdownSingle';
import CustomMultiLineInput from '../../../helpers/CustomMultiLineInput';

import ProgressStepperInvestor from '../progress/ProgressSteperInvestor';
import ProgressSteperSellMyBusiness from '../progress/ProgressSteperSellMyBusiness';
import ProgressSteperDealer from '../progress/ProgressSteperDealer';




const DealerMarketingDetailsForm = ({ control, errors, setValue, watch, getValues,

    countries,
    cities,
    states,
    industries,
    ranges,
    industrySectors,
    setSelectedCity,
    setSelectedCountry,
    setSelectedIndustry,
    setSelectedSector, setSelectedState,
    isDashboardMode,
    setIsEditing,
    isEditing,
    loanSupportRequired



}) => {


    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));



    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>

            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3}
                        sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="h5" sx={{ color: "#0D2659" }}>Dealer / Distributor </Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperDealer />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }

            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Business and Marketing Details</Typography>


                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}



                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={4}>





                    <Grid item xs={12} md={4}>
                        <Controller
                            name="investmentAmount"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Investment Amount"
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.investmentAmount}
                                    helperText={errors.investmentAmount ? errors.investmentAmount.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="spaceRequired"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Space Required "
                                    required
                                    fullWidth sicon={<OFHouse4 />}
                                    variant="outlined"
                                    error={!!errors.spaceRequired}
                                    helperText={errors.spaceRequired ? errors.spaceRequired.message : ''}
                                    placeholder={'eg 1000 sq feet'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="margin"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Margin/Commission (%) "
                                    required
                                    fullWidth sicon={<OFPercentageIcon />}
                                    variant="outlined"
                                    error={!!errors.margin}
                                    helperText={errors.margin ? errors.margin.message : ''}
                                    placeholder={'eg 10.00 %'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>


                        <Controller
                            name="industry"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFIndustry />}
                                    label="Industry"
                                    option={industries}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedIndustry(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.industry?.name}
                                    helperText={errors.industry?.name?.message || ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Controller
                            name="sector"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFSector />}
                                    label="Sector"
                                    option={industrySectors}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedSector(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.sector?.name}
                                    helperText={errors.sector?.name?.message || ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="exclusiveTerritorialRight"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Exclusive Territorial Rights "
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.exclusiveTerritorialRight?.message}
                                    helperText={errors.exclusiveTerritorialRight ? errors.exclusiveTerritorialRight.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e);

                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFRupeeIcon}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="specifyTerritory"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Specify the territory "
                                    required
                                    fullWidth sicon={<OFHouse4 />}
                                    variant="outlined"
                                    error={!!errors.specifyTerritory}
                                    helperText={errors.specifyTerritory ? errors.specifyTerritory.message : ''}
                                    placeholder={'Enter Territory Name'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="breakEvenPoint"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Break-Even Point "
                                    required
                                    fullWidth sicon={<OFCalendar2 />}
                                    variant="outlined"
                                    error={!!errors.breakEvenPoint}
                                    helperText={errors.breakEvenPoint ? errors.breakEvenPoint.message : ''}
                                    placeholder={'Enter value'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="propertyPreference"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFHouse4 />}
                                    label="Property Preference "
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.propertyPreference?.name}
                                    helperText={errors.propertyPreference?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>




                    <Grid item xs={12} md={12}>
                        <Controller
                            name="aboutBusiness"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Describe about business "
                                    required
                                    fullWidth sicon={<OFAddress />}
                                    variant="outlined"
                                    error={!!errors.aboutBusiness}
                                    helperText={errors.aboutBusiness ? errors.aboutBusiness.message : ''}
                                    placeholder={'Employee description'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />



                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="locationExpansionPlans"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Location Expansion Plans (targeted for expansion) "
                                    required
                                    fullWidth sicon={<OFAddress />}
                                    variant="outlined"
                                    error={!!errors.locationExpansionPlans}
                                    helperText={errors.locationExpansionPlans ? errors.locationExpansionPlans.message : ''}
                                    placeholder={'Enter description'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />



                    </Grid>
                    <Grid item xs={12} md={4}>



                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    sicon={<OFCountry />} // Replace with your actual icon
                                    label="Country"
                                    option={countries}
                                    defaultValue={field.value} // Ensure defaultValue is an object { id, name }

                                    error={!!errors.country?.name}
                                    helperText={errors.country?.name?.message || ''}
                                    changeCallBack={({ id, name }) => {


                                        setSelectedCountry(id);
                                        field.onChange({ id, name }); // Pass { id, name } to form state
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required sicon={<OFCountry />}
                                    fullWidth
                                    label="State"
                                    option={states}
                                    defaultValue={field.value}
                                    error={!!errors.state?.name}
                                    helperText={errors.state?.name?.message || ''}
                                    placeholder="Select State"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedState(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    label="City" sicon={<OFCountry />}
                                    option={cities}
                                    defaultValue={field.value}
                                    error={!!errors.city?.name}
                                    helperText={errors.city?.name?.message || ''}
                                    placeholder="Select City"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedCity(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="pincode"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    required
                                    label="PIN Code"
                                    fullWidth sicon={<OFCountry />}
                                    variant="outlined"
                                    error={!!errors.pincode}
                                    helperText={errors.pincode ? errors.pincode.message : ''}
                                    placeholder={'Enter PIN Code'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="productCategories"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Product Categories "
                                    required
                                    fullWidth sicon={<OFHouse4 />}
                                    variant="outlined"
                                    error={!!errors.productCategories}
                                    helperText={errors.productCategories ? errors.productCategories.message : ''}
                                    placeholder={'Enter product categories'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />



                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="productNames"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Product Names"
                                    required
                                    fullWidth sicon={<OFAddress />}
                                    variant="outlined"
                                    error={!!errors.productNames}
                                    helperText={errors.productNames ? errors.productNames.message : ''}
                                    placeholder={'Enter product names'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />



                    </Grid>


                    <Grid item xs={12} md={2}>
                        <Controller
                            name="productPricingRangeStart"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Products Pricing Range "
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.productPricingRangeStart}
                                    helperText={errors.productPricingRangeStart ? errors.productPricingRangeStart.message : ''}
                                    placeholder={'Enter start range'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Controller
                            name="productPricingRangeEnd"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    // label="Products Pricing Range "
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.productPricingRangeEnd}
                                    helperText={errors.productPricingRangeEnd ? errors.productPricingRangeEnd.message : ''}
                                    placeholder={'Enter end range'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />


                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="trainingForProduct"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Product Training "
                                    required
                                    fullWidth sicon={<OFHouse4 />}
                                    variant="outlined"
                                    error={!!errors.trainingForProduct}
                                    helperText={errors.trainingForProduct ? errors.trainingForProduct.message : ''}
                                    placeholder={'Enter details'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />



                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="productWarranty"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Product Warranties "
                                    required
                                    fullWidth sicon={<OFHouse4 />}
                                    variant="outlined"
                                    error={!!errors.productWarranty}
                                    helperText={errors.productWarranty ? errors.productWarranty.message : ''}
                                    placeholder={'Enter details'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="marketingCollateral"
                            control={control}
                            render={({ field }) => (
                                <CustomCheckbox
                                    {...field} // Spread the field props
                                    required
                                    mainLabel="Marketing Collateral "
                                    control={control}
                                    options={[
                                        { id: 1, name: 'Brochures' },
                                        { id: 2, name: 'Flyers' },
                                        { id: 3, name: 'Digital Marketing Materials' },

                                    ]}
                                    error={!!errors.marketingCollateral}
                                    helperText={errors.marketingCollateral ? errors.marketingCollateral.message : ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="advertisementSupport"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Advertising Support "
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.advertisementSupport?.message}
                                    helperText={errors.advertisementSupport ? errors.advertisementSupport.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="salesTraining"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Sales Training "
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.salesTraining?.message}
                                    helperText={errors.salesTraining ? errors.salesTraining.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="leadGenerationSupport"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Lead Generation Support "
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.leadGenerationSupport?.message}
                                    helperText={errors.leadGenerationSupport ? errors.leadGenerationSupport.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="toolsProvided"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Tools/Applications provided? "
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.toolsProvided?.message}
                                    helperText={errors.toolsProvided ? errors.toolsProvided.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>





                    <Grid item xs={12} md={4}>
                        <Controller
                            name="brandingGuidelines"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Branding Guidelines  "
                                    required
                                    fullWidth sicon={<OFDescription />}
                                    variant="outlined"
                                    error={!!errors.brandingGuidelines}
                                    helperText={errors.brandingGuidelines ? errors.brandingGuidelines.message : ''}
                                    placeholder={'Enter guidelines'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

























                    <Grid item xs={12} md={4}>
                        <Controller
                            name="creditTerm"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Credit Terms (Payment terms, credit lines, etc.)"
                                    required
                                    fullWidth sicon={<OFDescription />}
                                    variant="outlined"
                                    error={!!errors.creditTerm}
                                    helperText={errors.creditTerm ? errors.creditTerm.message : ''}
                                    placeholder={'Enter details'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="financialSupportForDealer"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Financing support for dealers/distributors "
                                    data={[
                                        { value: true, label: "Yes" },
                                        { value: false, label: "No" },
                                    ]}
                                    error={errors?.financialSupportForDealer?.message}
                                    helperText={errors.financialSupportForDealer ? errors.financialSupportForDealer.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="financialProjectionsYear1"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Financial Projections (Year-1) "
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.financialProjectionsYear1}
                                    helperText={errors.financialProjectionsYear1 ? errors.financialProjectionsYear1.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="financialProjectionsYear2"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Financial Projections (Year-2) "
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.financialProjectionsYear2}
                                    helperText={errors.financialProjectionsYear2 ? errors.financialProjectionsYear2.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="financialProjectionsYear3"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Financial Projections (Year-3) "
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.financialProjectionsYear3}
                                    helperText={errors.financialProjectionsYear3 ? errors.financialProjectionsYear3.message : ''}
                                    placeholder={'eg 99999.99'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                </Grid>
            </Box>





        </>);
};

export default DealerMarketingDetailsForm;
